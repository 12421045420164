import { Component, Input } from '@angular/core'

@Component({
    selector: 'label-image',
    templateUrl: './label-image.component.html',
    styleUrls: ['label-image.component.scss']
})
export class LabelImageComponent {
    @Input() preLabel: string = ''
    @Input() preClass: string = 'pre-label font-weight-bold'
    @Input() label: string = ''
    @Input() image: string = ''
    @Input() defaultImageSource: string = 'default-profile-pic.png'
    @Input() imageSize: number = 48
    @Input() imageClass: string = 'align-middle'
    @Input() zClass: string = ''

    constructor(
    ) {
    }
}

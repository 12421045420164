import { Action } from '@ngrx/store'
import { PermanentlyHolidayDate } from 'app/core/models'

import { type } from '../../util'

export const GET_TOP_5 = type('[PermanentlyHolidayDate] GET_TOP_5')
export const GET_TOP_5_SUCCESS = type('[PermanentlyHolidayDate] GET_TOP_5_SUCCESS')
export const GET_TOP_5_ERROR = type('[PermanentlyHolidayDate] GET_TOP_5_ERROR')


export class GetTop5 implements Action {
    readonly type = GET_TOP_5
}

export class GetTop5Success implements Action {
    readonly type = GET_TOP_5_SUCCESS

    constructor(public payload: PermanentlyHolidayDate[]) { }
}

export class GetTop5Error implements Action {
    readonly type = GET_TOP_5_ERROR
}

export type All =
GetTop5 | GetTop5Success | GetTop5Error

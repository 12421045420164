import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

interface InfiniteScrollOptions<T> {
  container: T[]
  take: number
  itemsGenerator: (take: number, lastItem?: T) => Observable<T[]>
}

@Injectable()
export class InfiniteScrollService<T> {

  private onScrollSubscription: Subscription

  private container: T[]
  private take: number
  private itemsGenerator: (lastItem: T) => Observable<T[]>

  private areItemsBeingLoaded: boolean = false
  private isItemsEndReached: boolean = false

  constructor(private modalService: ModalService) {
  }

  initialize(opts: InfiniteScrollOptions<T>): void {
    this.container = opts.container
    this.take = opts.take
    this.itemsGenerator = (lastItem: T) =>
      lastItem ? opts.itemsGenerator(opts.take, lastItem) : opts.itemsGenerator(opts.take)

    this.ensureScrollbar()
    this.createOnScrollSubscription()
  }

  destroy(): void {
    this.isItemsEndReached = false
    this.destroyOnScrollSubscription()
  }

  loadNext(): Subscription {
    this.areItemsBeingLoaded = true

    const subscription: Subscription = this.itemsGenerator(this.getLastItem())
      .subscribe((items: T[]) => {
        this.areItemsBeingLoaded = false

        this.container.push(...items)

        if (this.areItemsLast(items)) {
          this.handleLastItemReached()
          // only if loadNext() is called manually (e.g. check if new items have been added via a button click)
        } else if (this.isItemsEndReached) {
          this.createOnScrollSubscription()
          this.isItemsEndReached = false
        }
      }, (error: any) => {
        this.modalService.info(ModalTypes.Alert, error)
      }, () => {
        subscription.unsubscribe()
      })

    return subscription
  }

  private getLastItem(): T {
    const container: T[] = this.container
    return container && container.length && container[container.length - 1] || null
  }

  private createOnScrollSubscription(): void {
    this.destroyOnScrollSubscription()

    this.onScrollSubscription = Observable.fromEvent(document, 'scroll')
      .sampleTime(500)
      .filter(() => !this.areItemsBeingLoaded)
      .subscribe(() => {
        if (this.getScrollPercent() > 90) {
          this.loadNext()
        }
      }, (error: any) => {
        this.modalService.info(ModalTypes.Alert, error)
      })
  }

  private destroyOnScrollSubscription(): void {
    if (this.onScrollSubscription && !this.onScrollSubscription.closed) {
      this.onScrollSubscription.unsubscribe()
    }
  }

  /*
    WARNING:
    Keeps fetching TAKE number of items until the vertical scrollbar appears or the items list is exausted.
    Might cause problems with very large / infinite lists of items, where the the document.body.scrollHeight doesn't change.
  */
  private ensureScrollbar(): void {
    if (document.body.scrollHeight <= window.innerHeight && !this.isItemsEndReached) {
      this.loadNext().add(() => {
        setTimeout(this.ensureScrollbar.bind(this), 0)
      })
    }
  }

  private areItemsLast(items: T[]): boolean {
    return !items || items.length < this.take
  }

  private handleLastItemReached(): void {
    this.isItemsEndReached = true
    this.destroyOnScrollSubscription()
  }

  getIsItemsEndReached(): boolean {
    return this.isItemsEndReached
  }

  private getScrollPercent(): number {
    return window.innerHeight / (document.body.scrollHeight - document.body.scrollTop) * 100
  }
}

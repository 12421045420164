import { createFeatureSelector, createSelector} from '@ngrx/store'
import { BaseModel } from 'app/core/models'


export interface UserStatusTypeState {
    listOptions: BaseModel[]
}

export const initialState: UserStatusTypeState = {
    listOptions: []
}

export namespace userStatusTypeSelector {
    export const getUserStatusTypeState = createFeatureSelector<UserStatusTypeState>('userStatusTypes')
    export const getUserStatusTypesAll = createSelector(getUserStatusTypeState, state => state.listOptions)
}

import * as LanguageActions from '../actions/language.actions'
import { initialState } from '../states/language.state'

export type Action = LanguageActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {

        case LanguageActions.SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload
            }
        }

        default: {
            return state
        }
    }
}

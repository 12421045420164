import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { CoreModule } from 'app/core'
import { SharedModule } from 'app/shared'
import { SmartTableModule } from 'app/smart-table/smart-table.module'
import { MultiselectDropdownModule } from 'ng2-multiselect'

import { SharedComponentsModule } from '../shared-components/shared-components.module'
import { TimeSheetModalComponent } from '../shared-components/time-sheet-modal/time-sheet-modal.component'
import { SickLeaveMgmtComponent } from './sick-leave-mgmt.component'
import { SickLeaveReportComponent } from './sick-leave-report/sick-leave-report.component'
import { SickLeaveComponent } from './sick-leave.component'
import { SickLeaveRoutingModule } from './sick-leave.routing'

import { DialogsModule } from 'app/dialogs/dialogs.module'
import { CommonDatePipe } from 'app/shared/pipes/common-date.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedComponentsModule,
    SmartTableModule,
    MultiselectDropdownModule,

    SharedModule,
    CoreModule,
    DialogsModule,
    SickLeaveRoutingModule
  ],
  declarations: [
    SickLeaveComponent,
    SickLeaveReportComponent,
    SickLeaveMgmtComponent,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [
    SickLeaveComponent,
    TimeSheetModalComponent
  ],
  providers: [
    CommonDatePipe,
  ]
})
export class SickLeaveModule {
  constructor() { }
}

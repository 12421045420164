export enum AppModules {
    Notes = 'Notes',
    // Vacation = 'Vacation',
    SickLeave = 'SickLeave',
    HomeOffice = 'HomeOffice',
    TimeSheet = 'TimeSheet',
    Tests = 'Tests',
    Handbook = 'Handbook',
    Tickets = 'Tickets',
}

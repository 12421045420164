import { Action } from '@ngrx/store'
import { MsTeam } from 'app/core/models'

import { type } from '../../util'

export const GET_MY_TEAMS = type('[MICROSOFT] GET_MY_TEAMS')
export const GET_MY_TEAMS_SUCCESS = type('[MICROSOFT] GET_MY_TEAMS_SUCCESS')
export const GET_MY_TEAMS_ERROR = type('[MICROSOFT] GET_MY_TEAMS_ERROR')

export class GetMyTeams implements Action {
    readonly type = GET_MY_TEAMS
}

export class GetMyTeamsSuccess implements Action {
    readonly type = GET_MY_TEAMS_SUCCESS

    constructor(public payload: MsTeam[]) { }
}

export class GetMyTeamsError implements Action {
    readonly type = GET_MY_TEAMS_ERROR
}

export type All
    = GetMyTeams
    | GetMyTeamsSuccess
    | GetMyTeamsError

import { initialState } from '../states/leave-types.states'

import * as LeaveTypesActions from 'app/core/store/actions/leave-types.actions'

export type Action = LeaveTypesActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case LeaveTypesActions.GET_LIST_OPTIONS_SUCCESS: {
            return {
                ...state,
                listOptions: action.payload
            }
        }
        default: return state
    }
}

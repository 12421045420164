import { Optional } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { TableFilter } from 'app/smart-table/models/table-filter.model'

import { BaseModel } from '../../models'
import { ApiService } from './api.service'

export abstract class HttpObjectService {
    object = ''

    constructor(private httpService: ApiService, @Optional() httpObject?: string) {
        this.object = httpObject || ''
    }

    save(model) {
        const resourceURL = `${this.object}/save`

        return this.httpService.post(resourceURL, model)
    }

    create(model) {
        const resourceURL = `${this.object}/create`

        return this.httpService.post(resourceURL, model)
    }

    update(model) {
        const resourceURL = `${this.object}/update`

        return this.httpService.post(resourceURL, model)
    }

    canDelete(objectId) {
        const resourceURL = `${this.object}/can-delete/${objectId}`

        return this.httpService.get(resourceURL)
    }

    changeIsActive(objectId) {
        const resourceURL = `${this.object}/is-active/${objectId}`

        return this.httpService.patch(resourceURL)
    }

    delete(objectId) {
        const resourceURL = `${this.object}/${objectId}`

        return this.httpService.delete(resourceURL)
    }

    getById(objectId) {
        const resourceURL = `${this.object}/${objectId}`

        return this.httpService.get(resourceURL)
    }

    getListOptions(isBackground = false, parentId?: number): Observable<any[]> {
        let resourceUrl = `${this.object}/list-options`

        if (parentId) {
            resourceUrl += `/${parentId}`
        }

        return this.httpService.get(resourceUrl, true, isBackground)
    }

    getPage(filter: TableFilter, isBackground: boolean = false): Observable<any> {
        const resourceUrl = `${this.object}/page`

        return this.httpService.post(resourceUrl, filter, true, isBackground)
    }

    search(term: string, parentId: number = null): Observable<BaseModel[]> {
        const url = `${this.object}/search?term=${term}` + (parentId ? `&parentId=${parentId}` : '').toString()
        return this.httpService.get(url)
    }
}

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'


export const maxDateFn = (maxDate: Date): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!isPresent(maxDate)) { return null }
    if (isPresent(Validators.required(control))) { return null }



    let v: any = new Date(control.value)

    v = Date.parse(v);
    if (isNaN(v)) {
      return { dateInvalid: true }
    } else {
      return v <= maxDate ? null : { actualValue: v, requiredValue: maxDate, maxDate: true };
    }
  }
}

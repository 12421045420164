import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAuthComponent } from 'app/components/base/base-auth.component';
import { State } from 'app/core/store/states';

@Component({
    selector: 'notify-user',
    templateUrl: './notify-user.component.html',
})

export class NotifyUserComponent extends BaseAuthComponent {

    @Output() notified: EventEmitter<number> = new EventEmitter<number>()

    constructor(
        store: Store<State>,
    ) {
        super(store)
    }

    notify(type: number) {
        this.notified.emit(type)
    }
}

import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeBg from '@angular/common/locales/bg';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HotkeyModule } from 'angular2-hotkeys';
import { CoreModule, HttpLoaderFactory } from 'app/core';
import { SharedModule } from 'app/shared';
import { MultiselectDropdownModule } from 'ng2-multiselect';
import { ToastrModule } from 'ngx-toastr';

import { AdministrationModule } from './app-modules/administration';
import { ResetPasswordComponent } from './app-modules/administration/user/reset-password/reset-password.component';
import { HomeOfficeModule } from './app-modules/home-office/home-office.module';
import { SharedComponentsModule } from './app-modules/shared-components/shared-components.module';
import { SickLeaveModule } from './app-modules/sick-leave';
import { TimeSheetModule } from './app-modules/time-sheet';
import { UserSettingsModule } from './app-modules/user-settings/user-settings.module';
import { VacationRequestModule } from './app-modules/vacation-request';
import { routing } from './app.routing';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { KeyBindingComponent } from './components/common/key-bindings/key-binding/key-binding.component';
import { KeyBindingsComponent } from './components/common/key-bindings/key-bindings.component';
import { NotAuthorizedComponent } from './components/common/not-authorized/not-authorized.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import {
  ExternalAccountButtonComponent,
} from './components/external-accounts/external-account-button/external-account-button.component';
import { LoginExternalComponent } from './components/external-accounts/external-login/external-login.component';
import { LinkAccountsComponent } from './components/external-accounts/link-accounts/link-accounts.component';
import { HandbookComponent } from './components/handbook/handbook.component';
import { CalendarComponent } from './components/home/calendar';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CommandService } from './core/services/common/command.service';
import { AuthEffects } from './core/store/effects';
import { AppEffects } from './core/store/effects/app.effects';
import { DaysTypeEffects } from './core/store/effects/enums/days-types.effects';
import { RequestStatusTypeEffects } from './core/store/effects/enums/request-status-type.effects';
import { UserStatusTypeEffects } from './core/store/effects/enums/user-status-type.effects';
import { LeaveTypeEffects } from './core/store/effects/leave-types.effects';
import { MicrosoftEffects } from './core/store/effects/microsoft.effects';
import { CompanyEffects } from './core/store/effects/noms/company.effects';
import { LocationEffects } from './core/store/effects/noms/location.effects';
import { PositionEffects } from './core/store/effects/noms/position.effects';
import { ProjectEffects } from './core/store/effects/noms/project.effects';
import { PermanentHolidayEffects } from './core/store/effects/permanent-holiday.effects';
import { SettingsEffects } from './core/store/effects/settings.effects';
import { TenantEffects } from './core/store/effects/tenant.effects';
import { UserEffects } from './core/store/effects/user.effects';
import { WorkTimeTypeEffects } from './core/store/effects/work-time-type.effects';
import { metaReducers, reducers } from './core/store/reducers';
import { DialogsModule } from './dialogs/dialogs.module';
import { SmartTableModule } from './smart-table/smart-table.module';
import { SmarterTableEffects } from './smart-table/store/smarter-table.effects';
import { TimeSheetEffects } from './core/store/effects/time-sheet.effects';


registerLocaleData(localeBg)

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LinkAccountsComponent,
    LoginExternalComponent,
    ExternalAccountButtonComponent,

    CalendarComponent,
    HandbookComponent,
    HeaderComponent,
    KeyBindingsComponent,
    KeyBindingComponent,
    SidebarComponent,
    ProfileComponent,
    NotAuthorizedComponent,
  ],
  imports: [
    HotkeyModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SharedModule,
    CoreModule,
    SmartTableModule,
    DialogsModule,
    NgbModule,
    SharedComponentsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),

    EffectsModule.forRoot([
      AppEffects,
      AuthEffects,
      LocationEffects,
      SmarterTableEffects,
      ProjectEffects,
      WorkTimeTypeEffects,
      CompanyEffects,
      PositionEffects,
      SettingsEffects,
      TimeSheetEffects,
      PermanentHolidayEffects,
      TenantEffects,
      UserEffects,
      DaysTypeEffects,
      RequestStatusTypeEffects,
      UserStatusTypeEffects,
      LeaveTypeEffects,
      MicrosoftEffects,
    ]),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),

    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    routing,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),

    AngularFontAwesomeModule,

    MultiselectDropdownModule,

    VacationRequestModule,
    AdministrationModule,
    TimeSheetModule,
    UserSettingsModule,
    SickLeaveModule,
    HomeOfficeModule
  ],
  providers: [
    CommandService
  ],
  entryComponents: [
    ResetPasswordComponent,
    KeyBindingsComponent,
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

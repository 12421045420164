import { initialState } from '../states/settings.states'

import * as SettingsActions from 'app/core/store/actions/settings.actions'


export type Action = SettingsActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case SettingsActions.GET_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                userSettings: action.payload
            }
        }
        case SettingsActions.GET_TIMESHEET_SETTINGS_SUCCESS: {
            return {
                ...state,
                timeSheetSettings: action.payload
            }
        }

        default: return state
    }
}

import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { FileType } from 'app/core'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { DownloaderService, ExportService } from 'app/core/services'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './annual-team-report.component.html'
})

export class AnnualTeamReportComponent extends BaseAuthComponent implements OnInit {

  allowedYears = []
  filter: TableFilter = new TableFilter()

  constructor(private exportService: ExportService, store: Store<State>,
    private reportDownloaderService: DownloaderService) {
    super(store)

  }

  ngOnInit() {
    this.filter.customFilter = {
      year: new Date().getFullYear()
    }
    this.filter.orderBy = 'UserName asc'

    this.allowedYears = Array(3).fill(this.filter.customFilter.year).map((x, i) => {
      const year = x - i
      return { id: year, name: year }
    })
  }

  changeYear() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  public export(): void {
    this.exportService.annualVacationReport(this.filter)
      .first()
      .subscribe(
        response => {
          const fileName = `annual-team-report-${this.filter.customFilter.year}`
          this.reportDownloaderService.downloadFile(response, fileName, FileType.Excel)
        })
  }

}

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'

export const maxLengthFn = (maxLength: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!isPresent(maxLength)) { return null }
        if (isPresent(Validators.required(control))) { return null }
        const v: number = +control.value.length
        return v <= +maxLength ? null : { actualValue: v, requiredValue: +maxLength, maxlength: true }
    }
}

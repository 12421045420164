import { Injectable } from '@angular/core';

import { FileType } from '../enums/file-type.enum';
import { FileInfo } from '../models/file-info';

@Injectable()
export class DownloaderService {

  fileTypes = { }

  constructor() {
    this.fileTypes[FileType.Pdf] = new FileInfo('application/pdf', 'pdf')
    this.fileTypes[FileType.Excel] = new FileInfo('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', 'xlsx')
    this.fileTypes[FileType.Word] = new FileInfo('application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8', 'docx')
  }

  public downloadFile(data, reportName: string, fileType: FileType) {

    const contentType = this.fileTypes[fileType].contentType
    const extension = this.fileTypes[fileType].extension
    const name = `${reportName}.${extension}`

    // Initialize Blob
    const blob = new Blob([data], { type: contentType })

    // Make the download process
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}


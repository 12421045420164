import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from '../common/api.service'

import { SickLeaveInput } from 'app/core/models'


@Injectable()

export class SickLeaveService {
  constructor(private httpClient: ApiService) {
  }

  public addSickLeave(model: SickLeaveInput): Observable<any> {
    return this.httpClient.post('sick-leave/add', model)
  }

  public getAllSickLeavesPageByUserId(id: number, page: number, itemsPerPage: number): Observable<any> {
    const params = `?page=${page}&itemsPerPage=${itemsPerPage}`

    return this.httpClient.get('sick-leave/user/' + id + params)
  }

  public export(model): Observable<any> {
    return this.httpClient.postExport('export/sick-leave', model)
  }
}

import { Component, Input } from '@angular/core'

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'f-clipboard',
    templateUrl: 'f-clipboard.component.html',
    styleUrls: [
        'f-clipboard.component.scss',
    ]
})
export class FormClipboardComponent {
    @Input() copyText: string = this.transateService.instant('general.copy')
    @Input() copiedText: string = this.transateService.instant('general.copied')
    @Input() value: string

    constructor(private transateService: TranslateService) { }

    copy(tooltip: NgbTooltip) {
        tooltip.close()
        tooltip.ngbTooltip = this.copiedText

        const textBox = document.createElement('textarea')
        textBox.style.position = 'fixed'
        textBox.style.left = '0'
        textBox.style.top = '0'
        textBox.style.opacity = '0'
        textBox.value = this.value
        document.body.appendChild(textBox)
        textBox.focus()
        textBox.select()
        document.execCommand('copy')
        document.body.removeChild(textBox)

        tooltip.open()
    }

    resetText(tooltip: NgbTooltip) {
        tooltip.ngbTooltip = this.copyText
    }
}

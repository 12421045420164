import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'

@Component({
  selector: 'f-textarea',
  templateUrl: 'f-textarea.component.html',
  providers: [
    MakeValidatorProviders(FormTextareaComponent),
    MakeValueAccessorProviders(FormTextareaComponent)
  ]
})
export class FormTextareaComponent extends FormBaseComponent {
  @Input() maxLength?: number
  @Input() minLength?: number
  @Input() cols?: number
  @Input() rows?: number
}

import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor';
import { ActionComponent } from './action.component';

@Component({
  selector: 'f-action-select',
  templateUrl: './f-action-select.component.html',
  styleUrls: ['./f-action-select.component.scss'],
  providers: [
    MakeValidatorProviders(FormActionSelectComponent),
    MakeValueAccessorProviders(FormActionSelectComponent)
  ]
})
export class FormActionSelectComponent implements AfterViewInit {
  @ContentChildren(ActionComponent) actions: QueryList<ActionComponent>

  @Input() btnClass = 'btn-primary'
  @Input() disabled = false
  @Input() cssClass = ''
  @Input() isHorizontal?= false

  selectedItem: ActionComponent = new ActionComponent(this.translateService)

  constructor(private translateService: TranslateService) {

  }

  ngAfterViewInit() {
    const defaultAction = this.actions.find(a => a.default)

    if (defaultAction) {
      this.selectedItem = Object.assign({}, defaultAction)
    }
  }

  changeItem(item) {
    this.selectedItem = Object.assign({}, item)
  }

  click() {
    if (this.selectedItem.click.observers.length > 0) {
      this.selectedItem.click.emit()
    }
  }
}

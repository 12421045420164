import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { AddPermanentHolidaysComponent } from './add-permanent-holidays/add-permanent-holidays.component'
import { WorkScheduleChangeComponent } from './work-schedule-change.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { WorkScheduleChangeService } from 'app/core/services'

import { BaseActiveModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: './work-schedule-change-mgmt.component.html'
})
export class WorkScheduleChangeMgmtComponent extends BaseManagementComponent {
  constructor(public store: Store<State>,
    private modal: ModalService,
    private workScheduleChangeService: WorkScheduleChangeService) {
    super(store)
  }

  public add() {
    const item: BaseActiveModel = { name: '', isActive: true }
    this.openModal(item)
  }

  public edit(item) {
    this.openModal(item)
  }

  openModal(item) {
    this.modal.open(WorkScheduleChangeComponent, ModalSizeTypes.Medium, { entity: item })
      .subscribe(result => {
        if (result.isSubmit) {
          this.workScheduleChangeService.save(result.data).first().subscribe(() => this.store.dispatch(new FilterAction()))
        }
      })
  }

  addPermanentHolidays() {
    this.modal.open(AddPermanentHolidaysComponent, ModalSizeTypes.Small)
      .subscribe(result => {
        if (result.isSubmit) {
          this.workScheduleChangeService.loadPermanentHolidays(result.data).first().subscribe(() => this.store.dispatch(new FilterAction()))
        }
      })
  }
}

import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { RecordType } from '../shared-components/time-sheet-modal/enums/record-type-enum'
import { ExportRecordInput } from '../shared-components/time-sheet-modal/models/export-record-input'
import { TimeSheetModalComponent } from '../shared-components/time-sheet-modal/time-sheet-modal.component'
import { SickLeaveComponent } from './sick-leave.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { CalendarEventCreatedService, SickLeaveService, TimeSheetService, ToastrService } from 'app/core/services'

import { GetEnteredHours } from 'app/core/store/actions/time-sheet.actions'
import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: './sick-leave-mgmt.component.html'
})
export class SickLeaveMgmtComponent extends BaseManagementComponent {
  constructor(public store: Store<State>,
    private modal: ModalService,
    private timeSheetService: TimeSheetService,
    private sickLeaveService: SickLeaveService,
    private toastrService: ToastrService,
    private calendarEventCreatedService: CalendarEventCreatedService,
  ) {
    super(store)
  }

  parentId: number = this.userId

  public add() {
    this.modal.open(SickLeaveComponent, ModalSizeTypes.Medium, {
      entity: {
        vacationUserId: this.userId
      },
      isMyRequest: true
    })
      .first()
      .subscribe(result => {
        if (result.isSubmit) {
          this.sickLeaveService.addSickLeave(result.data).first().subscribe(() => {
            this.calendarEventCreatedService.subject.next(result.componentId)
            this.store.dispatch(new FilterAction())
          })
        }
      })
  }

  public export(item) {

    this.timeSheetService.checkAny(item.fromDate, item.toDate, this.userId)
      .first()
      .subscribe(exists => {
        if (exists) {
          this.toastrService.warning('messages.sickLeaveAlreadyExported', null, true)
          return;
        }

        const recordToExport: ExportRecordInput = {
          fromDate: item.fromDate,
          toDate: item.toDate,
          recordType: RecordType.SickLeave
        }
        this.modal.open(TimeSheetModalComponent, ModalSizeTypes.Large, { recordToExport: recordToExport })
          .first()
          .subscribe(result => {
            if (result.isSubmit) {
              this.timeSheetService.exportLeave(result.data)
                .first()
                .subscribe(() => {
                  this.store.dispatch(new FilterAction())
                  this.store.dispatch(new GetEnteredHours())
                })
            }
          })

      })
  }
}

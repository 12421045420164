import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermanentlyHolidayDate } from 'app/core/models';

export interface State {
    permanentHolidays: PermanentlyHolidayDate[]
}

export const initialState: State = {
    permanentHolidays: []
}

export const getPermanentHolidayState = createFeatureSelector<State>('permanentHoliday')
export const getPermanentHolidayAll = createSelector(getPermanentHolidayState, state => state.permanentHolidays)

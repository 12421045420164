import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { Roles } from 'app/core'

import { AnnualTeamReportComponent } from './annual-team-request/аnnual-team-report.component'
import { MyRequestsComponent } from './my-requests/my-requests.component'
import { VacationReportByYearComponent } from './report-by-year/report-by-year.component'
import { TeamRequestsComponent } from './team-requests/team-requests.component'
import { VacationApproveComponent } from './vacation-approve/vacation-approve.component'
import { VacationReportComponent } from './vacation-request-report/vacation-report.component'

import { AuthGuard, NotRoleGuard, RoleGuard } from 'app/core/guards'

const requestRoutes: Routes = [
    {
        path: 'approve/:requestId/:teamLeadId',
        component: VacationApproveComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.teamLeadRole] }
    },
    {
        path: 'approve/:requestId',
        component: VacationApproveComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.teamLeadRole] }
    },
    {
        path: 'my-requests',
        component: MyRequestsComponent,
        canActivate: [AuthGuard, NotRoleGuard],
        data: { roles: [Roles.companyAdminRole] }
    }, {
        path: 'vacation-request-report',
        component: VacationReportComponent,
        canActivate: [AuthGuard]
    }, {
        path: 'team-requests',
        component: TeamRequestsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.teamLeadRole] }
    }, {
        path: 'annual-team-requests',
        component: AnnualTeamReportComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.teamLeadRole, Roles.companyAdminRole] }
    }, {
        path: 'leaves-by-year',
        component: VacationReportByYearComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(requestRoutes)],
    exports: [RouterModule]
})
export class VacationRequestRoutingModule { }

import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'

import { SettingsStoreService } from 'app/core/services/store/settings-store.service'

import { BaseActiveModel, TimeSheetSetting } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './settings.component.html'  ,
  styleUrls: [
    './settings.component.scss'
  ]
})

export class TimeSheetSettingsComponent extends BaseAuthComponent implements OnInit {

  entity: TimeSheetSetting = new TimeSheetSetting()
  users: BaseActiveModel[] = []

  constructor(
    private settingsStoreService: SettingsStoreService,
    store: Store<State>) {
    super(store)
  }

  ngOnInit() {
    this.settingsStoreService.getTimesheetSettings$
      .takeWhile(() => this.isAlive)
      .subscribe(data => {
        this.entity = Object.assign({}, data)
      })
  }

  checkboxChange($event) {
    const dateKey = event.currentTarget['attributes'].name.nodeValue

    const value = this.entity[dateKey]
    if (value) {
      const isCopy = dateKey.includes('Copy')

      Object.keys(this.entity)
        .filter(k => {
          return k.toLowerCase().includes('date') &&
            (isCopy ? k.includes('Copy') : k.indexOf('Copy') === -1)
        })
        .forEach(k => {
          if (k !== dateKey) {
            this.entity[k] = false;
          }
        })
    }
  }

  save() {
    this.settingsStoreService.timeSheetSettings = this.entity
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'base-btn',
    template: ''
})
export class MDBaseButtonComponent {
  @Input() disabled = false
  @Input() cssClass = 'btn-primary'
  @Output() onClick = new EventEmitter<any>()

  onBtnClick(event) {
    if (!this.disabled) {
      this.onClick.emit(event)
    }
  }
}

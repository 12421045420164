import { Component, EventEmitter, Input, Output } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['../styles.scss']
})

export class ConfirmDialogComponent {

  @Input() modalContent = ''
  @Input() title = ''

  @Output() dialogClose = new EventEmitter<any>()

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onClose() {
    this.activeModal.close(false)
    this.dialogClose.emit()
  }

  confirm() {
    this.activeModal.close(true)
    this.dialogClose.emit()
  }
}

import { Action } from '@ngrx/store'
import { BaseModel } from 'app/core/models'

import { type } from '../../../util'


export const GET_ALL = type('[DAYSTYPE] GET_ALL')
export const GET_ALL_SUCCESS = type('[DAYSTYPE] GET_ALL_SUCCESS')
export const GET_ALL_ERROR = type('[DAYSTYPE] GET_ALL_ERROR')


export class GetAll implements Action {
    readonly type = GET_ALL
}

export class GetAllSuccess implements Action {
    readonly type = GET_ALL_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetAllError implements Action {
    readonly type = GET_ALL_ERROR
}

export type All =
    GetAll | GetAllSuccess | GetAllError

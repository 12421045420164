import { Component, EventEmitter, Input, Output } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'


@Component({
  selector: 'f-upload',
  templateUrl: 'f-upload.component.html',
  styleUrls: ['./f-upload.component.scss'],
  providers: [
    MakeValidatorProviders(FormUploadComponent),
    MakeValueAccessorProviders(FormUploadComponent)
  ]
})
export class FormUploadComponent extends FormBaseComponent {
  inputType = 'file'
  maxFileSize = 2000000
  maxSizeExceeded = false

  @Input() imageCss = 'img-default'
  @Input() inIconMode = false
  @Input() icon = 'fas fa-upload'
  @Input() defaultSrc = '../../assets/images/not_available.png'

  @Output() openWebcam = new EventEmitter()

  changeTrigger(event) {
    const file = event.srcElement.files[0]
    const reader = new FileReader()

    reader.onload = (readerEvent: any) => {
      this.value = reader.result
    }

    if (file.size <= this.maxFileSize) {
      reader.readAsDataURL(file)
      this.maxSizeExceeded = false
    } else {
      this.clearUploadInput()
      this.maxSizeExceeded = true
    }
  }

  clearUploadInput() {
    this.value = ''
  }

  onOpenWebcam() {
    this.openWebcam.emit()
  }

}

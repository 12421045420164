import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store'

import { SetLanguage } from 'app/core/store/actions/language.actions'
import { languageSelectors, LanguageState } from 'app/core/store/states/language.state'
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LanguageStoreService {

  constructor(private store$: Store<LanguageState>) { }

  public get language$(): Observable<string> {
      return this.store$.select(languageSelectors.getLanguage)
  }

  public set language(value) {
      this.store$.dispatch(new SetLanguage(value))
  }

}

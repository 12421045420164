import { Component, ElementRef, Input } from '@angular/core'

@Component(
    {
        selector: 'f-tooltip',
        template: `<span *ngIf="tooltip !== '' || img !== ''" class="bg-secondary pl-2 pr-2 text-white rounded-circle" [ngbPopover]="htmlContent" 
        triggers="mouseenter:mouseleave" [popoverTitle]="title" [placement]="placement" container="body" (click)="onBtnClick()"> ? </span>
        
        <ng-template #htmlContent>
            <p> {{tooltip}}    </p>
            <img *ngIf="img !== ''" src="{{img}}" class="d-block pt-2" alt="">            
            <ng-content></ng-content>                              
        </ng-template>`
    })

export class FormTooltipComponent {
    @Input() tooltip = ''
    @Input() title = ''
    @Input() placement = 'right'
    @Input() href = ''
    @Input() img = ''

    constructor(protected el: ElementRef) { }

    onBtnClick() {
        if (this.href !== '') {
            window.open(this.href)
        }
    }

}

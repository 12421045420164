import { Component } from '@angular/core';
import { KeyBinding } from 'app/core/models';

@Component({
  selector: 'app-key-bindings',
  templateUrl: './key-bindings.component.html',
  styleUrls: [
    './key-bindings.component.scss'
  ]
})
export class KeyBindingsComponent {

  timeSheetBindings: KeyBinding[] = [
    new KeyBinding('general.addOperation', ['N']),
  ]

  questionBindings: KeyBinding[] = [
    new KeyBinding('general.addQuestion', ['Q']),
    new KeyBinding('general.addAnswer', ['A']),
  ]
}

import * as UserStatusTypeActions from 'app/core/store/actions/enums/user-status-type.actions'
import {initialState} from '../../states/enums/user-status-type.states'

export type Action = UserStatusTypeActions.All

export function reducer(state = initialState, action: Action){
    switch(action.type){
        case UserStatusTypeActions.GET_ALL_SUCCESS : {
            return {
                ...state,
                listOptions: action.payload
            }
        }
        default: return state
    }
}
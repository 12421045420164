import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js'
import * as Chart from 'chart.js'
import * as ChartJsColorSchemes from 'chartjs-plugin-colorschemes'

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit, OnChanges {
    @Input() id: any
    @Input() data: ChartDataSets[] = []
    @Input() labels: string[] = []
    @Input() chartType: ChartType
    @Input() showLegend: boolean = true
    @Input() options: ChartOptions = {}
    @Input() hasCustomColors: boolean = false

    baseFont = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

    dataLoaded: boolean = false

    chartOptions: ChartOptions = {}
    chartData: ChartDataSets[] = []
    chartLabels: string[] = []

    ngOnInit(): void {
        Chart.pluginService.register(ChartJsColorSchemes)

        this.chartOptions = {
            responsive: true,

            elements: {
                line: {
                    tension: 0,
                    fill: false,
                },
            },

            plugins: {
                colorschemes: {
                    scheme: 'brewer.SetOne8',
                    override: !this.hasCustomColors,
                },
            },

            ...this.options,
        }

        if (this.chartOptions.legend) {
            this.chartOptions.legend.labels.fontFamily = this.baseFont
        } else {
            this.chartOptions.legend = {
                labels: {
                    fontFamily: this.baseFont
                }
            }
        }

        this.updateData()
        this.dataLoaded = true
    }

    ngOnChanges(): void {
        this.updateData()
    }

    updateData(): void {
        this.chartData = [{ data: [] }]
        this.chartLabels = []

        this.chartData = this.data.length === 0 ? this.chartData : this.data
        this.chartLabels = this.labels
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChangePasswordComponent } from 'app/app-modules/shared-components/change-password/change-password.component';
import {
  AdminService,
  AuthService,
  AuthStoreService,
  PicturesService,
  ToastrService,
  TranslationService,
  UserService,
} from 'app/core';
import { ExternalAccountEndpoints } from 'app/core/constants/external-account-endpoints';
import { ExternalProviderInfo } from 'app/core/models/auth/external-provider-info';
import * as AuthActions from 'app/core/store/actions/auth.actions';
import { State } from 'app/core/store/states';
import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types';
import { ModalTypes } from 'app/dialogs/interfaces/modal-types';
import { ModalService } from 'app/dialogs/modal.service';

import { BaseAuthComponent } from '../base/base-auth.component';
import { ExternalLinkInfo } from 'app/core/models';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseAuthComponent implements OnInit {

  teamleaders: string

  isAdmin: boolean = false

  profile: any

  loginProviders: ExternalProviderInfo[] = []

  linkedAccounts: ExternalProviderInfo[] = []
  notLinkedAccounts: ExternalProviderInfo[] = []

  constructor(
    private userService: UserService,
    public store: Store<State>,
    private route: ActivatedRoute,
    private modal: ModalService,
    private adminService: AdminService,
    private authService: AuthService,
    private authStoreService: AuthStoreService,
    private translationService: TranslationService,
    private picturesService: PicturesService,
    private toastr: ToastrService,
    private router: Router) {
    super(store)
  }

  ngOnInit() {
    this.isAdmin = this.router.url.includes('admin')
    this.loadProfile()

    this.loginProviders = this.authService.getLoginProviders(ExternalAccountEndpoints.linkAccount)

    this.authStoreService
      .externalLinks$
      .takeWhile(_ => this.isAlive)
      .subscribe((links: ExternalLinkInfo[]) => {
        this.notLinkedAccounts = this.loginProviders.filter(p => links.findIndex(l => l.provider === p.provider) < 0)
        this.linkedAccounts = []

        links.forEach(l => {
          const providerInfo = this.loginProviders.find(p => p.provider === l.provider)
          providerInfo.userEmail = l.email

          this.linkedAccounts.push(providerInfo)
        })
      })
  }

  getProfilePicture(): string {
    if (this.profile && this.profile.id) {
      return this.picturesService.getProfilePic(this.profile.id)
    }

    return null
  }

  edit() {
    if (this.profile) {
      const object = this.isAdmin ? `/admin/${this.profile.id}` : `/user/${this.profile.id}`
      this.router.navigate([object])
    } else {
      this.router.navigate(['/users'])
    }
  }

  loadProfile() {
    const id = +this.route.snapshot.paramMap.get('userId')

    if (this.isAdmin) {
      this.adminService.getAdmin(id)
        .takeWhile(_ => this.isAlive)
        .subscribe(admin => {
          this.profile = admin
        })
    } else {
      this.userService.getProfile(id)
        .takeWhile(_ => this.isAlive)
        .subscribe(data => {
          this.profile = data
          this.teamleaders = data.teamLeaders.map(tl => tl.name).join(', ')
        })
    }
  }

  openChangePasswordModal(id: number) {
    this.modal.open(ChangePasswordComponent, ModalSizeTypes.Medium, { id })
      .subscribe(result => {
        if (result.isSubmit) {
          this.userService.changePassword(result.data).first().subscribe(() => {
            this.toastr.success(this.translationService.translateMsg('messages.passwordSuccessfullyChanged'))
          })
        }
      })
  }

  linkRemoved(providerInfo: ExternalProviderInfo) {
    this.modal
      .confirm(ModalTypes.Warning, this.translationService.translateMsg('messages.confirmUnlinkingAccount'))
      .first()
      .subscribe(confirmed => {
        if (confirmed) {
          this.authService
            .unlinkExternalAccount(providerInfo.provider)
            .first()
            .subscribe(_ => {
              this.store.dispatch(new AuthActions.GetExternalLinksAction())
              this.toastr.success(this.translationService.translateMsg('messages.accountUnlinkedSuccessfully'))
            })
        }
      })
  }

  get isMyProfile(): boolean {
    return this.profile.id === this.userId
  }
}

import { TeamLeader } from 'app/core/models/team-leader'

export class User {
  id: number
  fullname: string
  position: string
  positionId: string
  location: string
  locationId: string
  password: string
  username: string
  email: string
  office365Account: string
  phoneNumber: string
  newPassword: string
  isTeamLead: boolean
  workHours: number
  vacationDays: number
  teamLeaders: Array<TeamLeader> = []
  company: string
  companyId: number
  birthDate: Date
  employmentDate: Date
  tenantId: string
  tenant: string
  statusId: number
  status: string
  avatar: string
  generatePassword: boolean = true
  sendPasswordEmail: boolean
  moduleAdmins: Array<number> = []
}

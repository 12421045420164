import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { AuthHelperService } from '../services/common/auth-helper.service'

@Injectable()
export class NotRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authHelperService: AuthHelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const roles = route.data['roles'] as Array<string>

    if (roles.filter(r => this.authHelperService.isInRole(r)).length === 0) {
      return true
    }

    return this.router.parseUrl('/not-authorized')
  }
}

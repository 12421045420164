import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface PositionState {
    listOptions: BaseModel[]
}

export const initialState: PositionState = {
    listOptions: []
}

export namespace positionSelectors {
    export const getPositionState = createFeatureSelector<PositionState>('position')
    export const getPositionListOptions = createSelector(getPositionState, state => state.listOptions)
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'

import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { HotKeys } from 'app/core/constants/hot-keys'

export class Command {
  name: string;
  combo: string;
  ev: KeyboardEvent;
}

@Injectable()
export class CommandService {

  private subject: Subject<Command>;
  commands: Observable<Command>;

  constructor(hotkeysService: HotkeysService) {
    this.subject = new Subject<Command>()
    this.commands = this.subject.asObservable()

    const hotKeysProps = Object.keys(HotKeys)

    for (const key in hotKeysProps) {
      if (key) {
        const commands = HotKeys[hotKeysProps[key]]
        hotkeysService.add(new Hotkey(hotKeysProps[key], (ev, combo) => this.hotkey(ev, combo, commands)))
      }
    }
  }

  hotkey(ev: KeyboardEvent, combo: string, commands: string[]): boolean {
    commands.forEach(c => {
      const command = {
        name: c,
        ev: ev,
        combo: combo,
      } as Command

      this.subject.next(command)
    })
    return true
  }
}

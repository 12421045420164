import { Injectable } from '@angular/core'

import { ApiService } from '../common/api.service'
import { HttpObjectService } from '../common/http-object.service'

@Injectable()

export class LocationService extends HttpObjectService {
  object = 'location'

  constructor(public apiService: ApiService) {
    super(apiService)
  }
}

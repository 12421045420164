import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class CalendarEventCreatedService {

  subject: Subject<Guid>
  objectCreated: Observable<Guid>

  constructor() {
    this.subject = new Subject<Guid>()
    this.objectCreated = this.subject.asObservable()
  }
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commonDate' })
export class CommonDatePipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    transform(value: any): any {
        const storedLanguage = localStorage.getItem('language')
        const locale = !!storedLanguage ? JSON.parse(storedLanguage).language : 'bg-BG'

        const format = 'mediumDate'
        return value ? this.datePipe.transform(new Date(value), format, undefined, locale) : null
    }
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TenantService } from 'app/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { mergeMap } from 'rxjs/operators';

import * as TenantActions from '../actions/tenant.actions';
import { BaseModel } from 'app/core/models';


@Injectable()
export class TenantEffects {

    constructor(
        private actions$: Actions,
        private tenantService: TenantService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<TenantActions.GetListOptions>(TenantActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.tenantService.getListOptions(true).map((tenants: BaseModel[]) => {
                return new TenantActions.GetListOptionsSuccess(tenants)
            }, _ => of(new TenantActions.GetListOptionsError()))
        })
    )

    @Effect()
    GetEnterpriseListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<TenantActions.GetEnterpriseListOptions>(TenantActions.GET_ENTERPRISE_LIST_OPTIONS),
        mergeMap((action) => {
            return this.tenantService.getEnterpriseListOptions(true).map((enterprises: BaseModel[]) => {
                return new TenantActions.GetEnterpriseListOptionsSuccess(enterprises)
            }, _ => of(new TenantActions.GetEnterpriseListOptionsError()))
        })
    )
}

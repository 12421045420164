export const environment = {
  production: false,
  apiBaseUrl: 'https://smartinternalsystem.azurewebsites.net/api/',
  appBaseUrl: 'https://smartinternalsystem.azurewebsites.net/',
  // apiBaseUrl: 'http://10.10.10.141:80/api/',
  // appBaseUrl: 'http://10.10.10.141:80/',
  tokenStorageKey: 'vacation-token',
  pushUrl: 'https://smartinternalsystem.azurewebsites.net/push',

  microsoftAppId: '32f3bcfb-6490-4e9b-b2c1-3f075bd475d0',
  microsoftAuthLink: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
  response_type=code&link-accounts&response_mode=query&
  scope=openid offline_access profile email`,

  googleAppId: '279489046658-ib6f2kbjf4j5o96rf2bdsei7tk7gcu1h.apps.googleusercontent.com',
  googleAuthLink: `https://accounts.google.com/o/oauth2/v2/auth?scope=openid profile email&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code`,
}

export enum NotificationEvent {
    HomeOfficeNew = 2,
    VacationRequestNew = 3,
    VacationRequestAproved = 4,
    VacationRequestRejected = 5,
    VacationRequestCanceled = 6,
    VacationResponse = 7,
    SurveyCampaign = 8,
    UpcomingVacation = 14,
}

import { Component } from '@angular/core'

import { LocationModel } from 'app/core/models/noms/location'

@Component({
  templateUrl: './location.component.html',
})

export class LocationComponent {
  entity: LocationModel = { id: 0, tenantId: '', name: '', isActive: true }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

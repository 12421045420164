import { Component, Input, OnChanges } from '@angular/core'
import 'moment/locale/bg'
import { LanguageStoreService } from 'app/core/services/store/language-store.service'
import { BaseComponent } from 'app/components/base/base.component'
import { interval } from 'rxjs/internal/observable/interval'

const moment = require('moment')

@Component({
  selector: 'audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent extends BaseComponent implements OnChanges {

  @Input() createdDate: Date
  @Input() placement = 'right'
  @Input() intervalSeconds = 15
  @Input() updatedDate: Date
  lastUpdated: string

  constructor(private languageStoreService: LanguageStoreService) {
    super()
  }

  ngOnChanges() {
    this.languageStoreService.language$
      .takeWhile(_ => this.isAlive)
      .subscribe(locale => {
        this.updatedDate = this.updatedDate ? this.updatedDate : this.createdDate
        this.lastUpdated = moment(this.updatedDate).locale(locale).fromNow()

        interval(1000 * this.intervalSeconds).subscribe(_ => {
          this.lastUpdated = moment(this.updatedDate).locale(locale).fromNow()
        })
      })
  }
}

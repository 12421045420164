import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AngularEditorModule } from '@kolkov/angular-editor'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { DialogsModule } from 'app/dialogs/dialogs.module'
import { SharedModule } from 'app/shared'

import { ChangePasswordComponent } from './change-password/change-password.component'
import { AddCommentComponent } from './comments/add-comment/add-comment.component'
import { CommentComponent } from './comments/comment/comment.component'
import { TeamsMessageComponent } from './microsoft/teams-message/teams-message.component'
import { MultiSelectUserListComponent } from './multi-select-user-list/multi-select-user-list'
import { NotifyUserComponent } from './notify-user/notify-user.component'
import { PermanentHolidaysComponent } from './permanent-holidays/permanent-holidays-component'
import { TenantSelectComponent } from './tenant-select/tenant-select.component'
import { TimeSheetModalComponent } from './time-sheet-modal/time-sheet-modal.component'

@NgModule({
    declarations: [
        MultiSelectUserListComponent,
        PermanentHolidaysComponent,
        TimeSheetModalComponent,
        TenantSelectComponent,

        CommentComponent,
        AddCommentComponent,

        TeamsMessageComponent,

        NotifyUserComponent,

        ChangePasswordComponent,
    ],
    imports: [
        FormsModule,
        NgbModule,

        AngularEditorModule,

        SharedModule,
        DialogsModule,
    ],
    exports: [
        MultiSelectUserListComponent,
        PermanentHolidaysComponent,
        TimeSheetModalComponent,
        TenantSelectComponent,

        CommentComponent,
        AddCommentComponent,

        TeamsMessageComponent,

        NotifyUserComponent,

        ChangePasswordComponent,
    ],
    providers: []
})

export class SharedComponentsModule {
}

import { ActionReducer, MetaReducer } from '@ngrx/store'
import { localStorageSync } from 'ngrx-store-localstorage'

import * as SmarterTable from '../../../smart-table/store/smarter-table.reducers'
import { ActionTypes } from '../actions/auth.actions'
import * as App from '../reducers/app.reducers'
import * as Language from '../reducers/language.reducer'
import * as LeaveType from '../reducers/leave-types.reducer'
import * as Company from '../reducers/noms/company.reducers'
import * as Location from '../reducers/noms/location.reducers'
import * as Position from '../reducers/noms/position.reducers'
import * as Project from '../reducers/noms/project.reducers'
import * as PermanentHolidays from '../reducers/permanent-holiday.reducers'
import * as Settings from '../reducers/settings.reducers'
import * as Tenant from '../reducers/tenant.reducers'
import * as TimeSheet from '../reducers/time-sheet.reducers'
import * as User from '../reducers/user.reducers'
import { State } from '../states'
import * as Auth from './auth.reducers'
import * as Day from './enums/days-type.reducer'
import * as RequestStatus from './enums/request-status-type.reducer'
import * as UserStatus from './enums/user-status-type.reducer'
import * as Loader from './loading-mask.reducers'
import * as Microsoft from './microsoft.reducers'
import * as WorkTimeType from './work-time-type.reducers'

export const reducers = {
  auth: Auth.reducer,
  loader: Loader.reducer,
  smarterTable: SmarterTable.reducer,
  project: Project.reducer,
  workTimeType: WorkTimeType.reducer,
  company: Company.reducer,
  location: Location.reducer,
  position: Position.reducer,
  settings: Settings.reducer,
  timeSheet: TimeSheet.reducer,
  permanentHoliday: PermanentHolidays.reducer,
  language: Language.reducer,
  tenant: Tenant.reducer,
  user: User.reducer,
  daysType: Day.reducer,
  requestStatus: RequestStatus.reducer,
  userStatusTypes: UserStatus.reducer,
  leaveTypes: LeaveType.reducer,
  app: App.reducer,
  microsoft: Microsoft.reducer,
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'language'], rehydrate: true })(reducer)
}

export function clearState(reducer) {
  return function (state, action) {

    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer, clearState];







import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'

export const minFn = (min: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!isPresent(min)) { return null }
        if (isPresent(Validators.required(control))) { return null }

        const v: number = +control.value
        return v >= +min ? null : { actualValue: v, requiredValue: +min, min: true }
    }
}

export class UserCompensation {
  id: number
  fullName: string
  location: string
  position: string
  userName: string
  overTimeHours: number
  calculatedDays: number
  totalHours: number
}

export class UserWithCount {
  totalHoursTransferred: number
  users: UserCompensation[]
}

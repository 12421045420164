import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Store } from '@ngrx/store'
import { PicturesService } from 'app/core'

import { Comment } from '../../models/comment'
import { AbstractCommentComponent } from '../abstract-comment/abstract-comment.component'

import { State } from 'app/core/store/states'

@Component({
    selector: 'app-comment',
    templateUrl: 'comment.component.html',
    styleUrls: [
        'comment.component.scss'
    ]
})

export class CommentComponent extends AbstractCommentComponent implements OnInit {
    @Input() comment: Comment = new Comment()

    @Output() onDelete = new EventEmitter<number>()

    newValue: string = ''

    constructor(
        store: Store<State>,
        picturesService: PicturesService,
        cd: ChangeDetectorRef,
    ) {
        super(store, picturesService, cd)
    }

    ngOnInit() {
        this.newValue = this.comment.text
    }

    edit() {
        this.toggleTypingMode(true)
    }

    save() {
        this.comment.text = this.newValue
        this.toggleTypingMode(false)
        this.onSave.emit(this.comment)
    }

    toggleTypingMode(inTypingMode: boolean) {
        if (this.inTypingMode === false) {
            this.newValue = this.comment.text
        }

        super.toggleTypingMode(inTypingMode)
    }

    delete() {
        this.onDelete.emit(this.comment.id)
    }
}

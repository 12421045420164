import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { Roles } from '../constants'
import { AuthHelperService } from '../services'

@Injectable()
export class AuthGuard implements CanActivate {

  tenantSelectUrl = '/tenants'
  ticketGuestUrl = '/tickets'

  constructor(
    private router: Router,
    private authHelperService: AuthHelperService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    if (this.authHelperService.loggedIn) {
      if (this.authHelperService.isInRole(Roles.ticketGuest)) {
        if (state.url === this.ticketGuestUrl) {
          return true
        } else {
          return this.router.parseUrl(this.ticketGuestUrl)
        }
      }
       else
       if (this.authHelperService.tenantId || state.url === this.tenantSelectUrl) {
        return true
      } else {
        return this.router.parseUrl(this.tenantSelectUrl)
      }
    }

    // Navigate to the login page with extras
    return this.router.parseUrl(`/login?returnUrl=${state.url}`)
  }
}

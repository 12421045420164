import { Action } from '@ngrx/store'
import { BaseModel } from 'app/core/models'

import { type } from '../../util'

export const GET_MODULES = type('[APP] GET_MODULES')
export const GET_MODULES_SUCCESS = type('[APP] GET_MODULES_SUCCESS')
export const GET_MODULES_ERROR = type('[APP] GET_MODULES_ERROR')


export class GetModules implements Action {
    readonly type = GET_MODULES
}

export class GetModulesSuccess implements Action {
    readonly type = GET_MODULES_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetModulesError implements Action {
    readonly type = GET_MODULES_ERROR
}

export type All = GetModules | GetModulesSuccess | GetModulesError

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/states';
import { settingsSelectors } from 'app/core/store/states/settings.states';
import { Observable } from 'rxjs/Observable';
import { TimeSheetSetting, UserSettings } from 'app/core/models';
import { SaveUserSettings, SaveTimeSheetSettings } from 'app/core/store/actions/settings.actions';

@Injectable()
export class SettingsStoreService {

    constructor(private store$: Store<State>) { }

    public get getTimesheetSettings$(): Observable<TimeSheetSetting> {
        return this.store$.select(settingsSelectors.getTimeSheetSettings)
    }

    public set timeSheetSettings(timeSheetSettings: TimeSheetSetting) {
        this.store$.dispatch(new SaveTimeSheetSettings(timeSheetSettings))
    }

    public get userSettings$(): Observable<UserSettings> {
        return this.store$.select(settingsSelectors.getUserSettings)
    }

    public set userSettings(userSettings: UserSettings) {
        this.store$.dispatch(new SaveUserSettings(userSettings))
    }
}

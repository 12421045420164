import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Store } from '@ngrx/store'
import { ResetPasswordComponent } from 'app/app-modules/administration/user/reset-password/reset-password.component'
import { ExternalAccountEndpoints } from 'app/core/constants/external-account-endpoints'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { AuthService, ToastrService, UserService } from 'app/core/services'

import { ExternalProviderInfo } from 'app/core/models/auth/external-provider-info'

import { LoginAction, LogoutAction } from 'app/core/store/actions/auth.actions'
import { State } from 'app/core/store/states'

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

  model: any = {}

  loginProviders: ExternalProviderInfo[] = []

  constructor(
    private route: ActivatedRoute,
    public store: Store<State>,
    private modal: ModalService,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.store.dispatch(new LogoutAction())

    this.loginProviders = this.authService.getLoginProviders(ExternalAccountEndpoints.loginAccount)

    this.model.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  login() {
    this.store.dispatch(new LoginAction(this.model))
  }

  forgottenPassword() {
    this.modal.open(ResetPasswordComponent, ModalSizeTypes.Small, {})
      .subscribe(result => {
        if (result.isSubmit) {
          this.userService.resetPassword(result.data)
            .first()
            .subscribe(_ => {
              this.toastr.success('messages.passwordSuccessfullyGenerated', null, true)
            })
        }
      })
  }
}

export let Resources = {
  // SUCCESS
  successRegister: 'Successfully registered.',
  successUpdated: 'Successfully updated.',
  savedSuccessfully: 'The changes were saved successfully.',
  passChangedSuccess: 'The password was changed successfully.',
  mailSent: 'The mail was sent successfully.',
  passRessSuccess: 'Your password were successfully reset.',
  recordSuccessfullyAdded: 'Record added successfully.',
  recordSuccessfullyAddedToUser: 'Record successfully added to user: ',
  successDelete: 'Successfully deleted.',
  successChange: 'The change was added successfully.',
  successExportRecordsToTimeSheet: 'Records were successfully exported to time sheet',
  weCantShowYou: 'We can\'t show you anything at this moment.',
  thereAreNoTimeSheetOvertimes: 'There are\'t any overtime timesheets to display',

  wantToDeleteUser: 'Do you want to delete this user?',
  passwordKeyExpired: 'The reset password key is expired!',
  passwordSixCharacters: 'Your password must be at least 6 characters!',
  mailError: 'The mail can\'t be sent.',
  dontHaveDays: 'You don\'t have enough days.',
  zeroDaysVacation: 'You can\'t submit a \'0\' days vacation.',
  zeroDaysSickLeave: 'You can\'t submit a \'0\' days sick leave.',
  NotAllowedAddDays: 'It is not allowed to add days for this year.',
  doYouWantToDeleteRecord: 'Do you want to delete this record?',
  areYouSureYouWantToDeletePrefix: 'Are you sure you want to delete ',
  invalidDateRangeEqual: 'Invalid date range, second date must be greater than or equal to first date',
  invalidDateRange: 'Invalid date range, second date must be greater than the first date.',
  invalidItemId: 'Invalid item ID!',
  enterValidDate: 'You must enter a valid date.',
  loginToAccess: 'First, you must logout to access login form.',
  invalidCredentials: 'Invalid username or password.',
  oops: 'Oops! Something went wrong.',
  sessionExpired: 'Your session was expired. Please, log in again.',
  chooseCriteria: 'You must choose at least one criteria.',
  cancelRequest: 'Do you want to cancel this request?',
  invalidMessageCode: 'Invalid message code!',
  editSuccessfully: ' was edited successfully.',
  addedSuccessfully: ' was added successfully.',
  deletedSuccessfully: ' was deleted successfully.',
  noDataForThisYear: 'There is no data for this year.',

  // single words or little phrases
  vacationRequestWas: ' vacation request was ',
  project: 'Project ',
  position: 'Position ',
  location: 'Location ',
  newsItem: 'News item ',
  unauthorized: 'Unauthorized!',
  company: 'Company '
}

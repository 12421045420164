import { Injectable } from '@angular/core';
import { ExternalProviderInfo } from 'app/core/models/auth/external-provider-info';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

import { ApiService } from './api.service';
import { ExternalProvider } from 'app/core/enums';


@Injectable()
export class AuthService {

  constructor(private httpClient: ApiService) {
  }

  login(user): Observable<any> {
    return this.httpClient.post('account/login', user, false)
  }

  loginWithExternalAccount(model: any): Observable<any> {
    return this.httpClient.post(`account/login-external`, model, false)
  }

  linkExternalAccount(model: any): Observable<any> {
    return this.httpClient.post(`account/link-external`, model)
  }

  unlinkExternalAccount(provider: ExternalProvider): Observable<any> {
    return this.httpClient.delete(`account/unlink-external?provider=${provider}`)
  }

  getExternalAccountLinks(userId: number = null): Observable<ExternalProvider[]> {
    const uriParams = userId ? `?userId=${userId}` : ''
    return this.httpClient.get(`account/external-links${uriParams}`)
  }

  getExternalAccountLinksWithEmails(userId: number = null): Observable<any[]> {
    const uriParams = userId ? `?userId=${userId}` : ''
    return this.httpClient.get(`account/external-links-with-emails${uriParams}`)
  }

  getLoginProviders(redirectUrl: string): ExternalProviderInfo[] {
    return [
      {
        provider: ExternalProvider.Microsoft,
        authUrl: `${environment.microsoftAuthLink}&state=${ExternalProvider.Microsoft}&client_id=${environment.microsoftAppId}&redirect_uri=${environment.appBaseUrl}${redirectUrl}`,
        userEmail: '',
      },
      {
        provider: ExternalProvider.Google,
        authUrl: `${environment.googleAuthLink}&state=${ExternalProvider.Google}&client_id=${environment.googleAppId}&redirect_uri=${environment.appBaseUrl}${redirectUrl}`,
        userEmail: '',
      },
    ]
  }

  private getUTCDate(date?: Date): Date {
    if (!date) {
      date = new Date()
    }
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())

    return utcDate
  }

  public calculateExpirationDate(exp: number): Date {
    const date = this.getUTCDate()
    date.setSeconds(date.getUTCSeconds() + exp)

    return date
  }
}

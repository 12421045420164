import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { BaseAuthComponent } from '../base/base-auth.component'

import { AuthStoreService, UserService } from 'app/core/services'

import { State } from 'app/core/store/states'

@Component({
  selector: 'app-handbook',
  templateUrl: './handbook.component.html',
  styleUrls: ['./handbook.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HandbookComponent extends BaseAuthComponent implements OnInit {
  hasBeenRead$: Observable<boolean>
  handbookPage: SafeHtml = ''

  constructor(private userService: UserService,
    private authStoreService: AuthStoreService,
    store: Store<State>) {
    super(store)
  }

  ngOnInit() {
    this.hasBeenRead$ = this.authStoreService.readHandbook$
      .takeWhile(_ => this.isAlive)

    this.authStoreService.tenantId$
      .takeWhile(_ => this.isAlive)
      .subscribe(tenantId => {
        if (tenantId) {
          fetch(`/assets/tenants/${tenantId}/handbook.html`)
            .then(res => res.text())
            .then(data => {
              this.handbookPage = data              
            })
        }
      })
  }

  markAsRead() {
    this.userService.markAsRead(true, this.userId)
      .first()
      .subscribe(resp => this.authStoreService.readHandbook = true)
  }
}

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Store } from '@ngrx/store'
import { formatPeriod } from 'app/core/helpers/common/date-helper'

import { ToastrService, TranslationService, VacationApproveService } from 'app/core/services'

import { VacationApproveInputModel, VacationApproveViewModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './vacation-approve.component.html',
  styleUrls: ['./vacation-approve.component.scss']
})

export class VacationApproveComponent extends BaseAuthComponent implements OnInit {
  approveView: VacationApproveViewModel = new VacationApproveViewModel()
  approveInput: VacationApproveInputModel = null

  private requestId: number
  isRightTeamLead: boolean = false

  formatPeriod = formatPeriod
  description: string = ''

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private approveService: VacationApproveService,
    store: Store<State>,
    private translationService: TranslationService,
    private toastrService: ToastrService) {
    super(store)

    // taking the parameters from the URL
    const params: any = this.activatedRoute.snapshot.params
    this.requestId = params.requestId
  }

  ngOnInit() {
    this.approveService.getApproveModel(this.requestId)
      .first()
      .subscribe(obs => {
        this.approveView = obs
        this.isRightTeamLead = true
      })
  }

  redirect() {
    this.router.navigate(['/home'])
  }

  submitApprove(isApproved: boolean) {
    this.approveInput = new VacationApproveInputModel(this.requestId, this.userId, isApproved)
    this.approveInput.description = this.description
    const approves = [this.approveInput]

    this.approveService.vacationApprove(approves)
      .first()
      .subscribe(app => {
        const status = this.translationService.translateMsg(`general.${app.statusName[0].toLowerCase() + app.statusName.slice(1)}`)
        this.toastrService.info(`${app.count} ${this.translationService.translateMsg('general.vacationRequests')} ${this.translationService.translateMsg('general.were')} ${status}!`)

        this.redirect()
      })
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core'

import { Store } from '@ngrx/store'

import { PermanentlyHolidayDate } from 'app/core/models/permanently-holiday-date'

import { State } from 'app/core/store/states'
import { getPermanentHolidayAll } from 'app/core/store/states/permanent-holiday.states'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  selector: 'holiday-date',
  templateUrl: './permanent-holidays.html',
  styleUrls: ['./permanent-holidays.scss'],
})

export class PermanentHolidaysComponent extends BaseComponent implements OnInit {

  top5Dates: PermanentlyHolidayDate[]
  @Output() hasHolidayDates = new EventEmitter()

  ngOnInit(): void {

    this.store
    .select(getPermanentHolidayAll)
    .takeWhile(_ => this.isAlive)
    .delay(0)
    .subscribe(data => {
      this.top5Dates = data
      this.hasHolidayDates.emit(data && data.length > 0)
  })

  }

  constructor(private store: Store<State>) {
    super()
  }
}

import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as st from './smarter-table.reducers'

export interface SmartTableState {
    st: st.State
}

export interface State {
    st: SmartTableState
}

export const reducers = {
    st: st.reducer,
}

export const getSmartTableModuleState = createFeatureSelector<SmartTableState>('st')

export const getSmartTableState = createSelector(getSmartTableModuleState, state => state.st)

export const getActivePage = createSelector(getSmartTableState, st => st.activePage)
export const getFilter = createSelector(getSmartTableState, st => st.filter)
export const getIsFiltering = createSelector(getSmartTableState, st => st.isFiltering)

import { Component, Input, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { TranslationService } from 'app/core'
import { CommonDatePipe } from 'app/shared/pipes/common-date.pipe'
import * as moment from 'moment'

import { UserStoreService } from 'app/core/services/store/user-store.service'

import { BaseModel } from 'app/core/models'
import { HomeOfficeInput } from 'app/core/models/home-office/home-office-input'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './home-office.component.html'
})
export class HomeOfficeComponent extends BaseAuthComponent implements OnInit {

  @Input() entity: HomeOfficeInput = {
    id: null,
    date: null,
    vacationUserId: undefined,
    description: ''
  }

  users$: Observable<BaseModel[]>
  user: BaseModel = null
  isMyRequest: boolean = false

  constructor(store: Store<State>,
    private userStoreService: UserStoreService,
    private translationService: TranslationService,
    private datePipe: CommonDatePipe,
  ) {
    super(store)
  }

  ngOnInit() {
    if (!this.entity.vacationUserId && !this.isCompanyAdmin) {
      this.entity.vacationUserId = this.userId
    }

    this.users$ = this.userStoreService.listOptions$
      .takeWhile(_ => this.isAlive)
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity, componentId: this.componentId })
  }

  selectUser(user) {
    this.user = user
  }


  get disableUsers() {
    return (!this.isTL && !this.isCompanyAdmin) || this.isMyRequest
  }

  get isTodayOrLater() {
    return this.entity.date && moment(this.entity.date).isSameOrAfter(moment(), 'day')
  }

  get defaultMessage() {
    const baseMessage: string = this.isMyRequest
      ? this.translationService.translateMsg('messages.homeOfficeDefaultMessage')
      : `${this.user ? this.user.name : ''} ${this.translationService.translateMsg('messages.homeOfficeOtherPersonDefaultMessage')}`

    const today = moment()
    const date = moment(this.entity.date)

    const dateMessage: string = today.isSame(date, 'day')
      ? `${this.translationService.translateMsg('general.today').toLowerCase()}.`
      : `${this.translationService.translateMsg('general.on').toLowerCase()} ${this.datePipe.transform(date)}`

    return `${baseMessage} ${dateMessage}`
  }
}

import * as LocationActions from 'app/core/store/actions/noms/location.actions';
import { initialState } from '../../states/noms/location.states';


export type Action = LocationActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case LocationActions.GET_LIST_OPTIONS_SUCCESS : {
            return {
                ...state,
                listOptions: action.payload
            }
        }

        default: return state
    }
}

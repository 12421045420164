import * as MicrosoftActions from 'app/core/store/actions/microsoft.actions';

import { initialState } from '../../store/states/microsoft.states';


export type Action = MicrosoftActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case MicrosoftActions.GET_MY_TEAMS_SUCCESS: {
            return {
                ...state,
                myTeams: action.payload || []
            }
        }
        default: return state
    }
}

import { Injectable } from '@angular/core'

import { hasRole } from 'app/core/helpers/common/auth-helper'

import { AuthStoreService } from 'app/core/services/store'


@Injectable()
export class AuthHelperService {

    public loggedIn: boolean
    public currentUserRole: string = ''
    public tenantId: string = null
    public moduleAdmins: string[] = []
    public userId: number

    constructor(private authStoreService: AuthStoreService) {
        this.authStoreService.role$
            .subscribe(role => this.currentUserRole = role)

        this.authStoreService.loggedIn$
            .subscribe(loggedIn => this.loggedIn = loggedIn)

        this.authStoreService.tenantId$
            .subscribe(tenantId => this.tenantId = tenantId)

        this.authStoreService.moduleAdmins$
            .subscribe(data => this.moduleAdmins = data)

        this.authStoreService.userId$
            .subscribe(userId => this.userId = userId)
    }

    isInRole(role: string) {
        return hasRole(this.currentUserRole, role)
    }

    isNotInRole(role: string) {
        return !hasRole(this.currentUserRole, role)
    }
}

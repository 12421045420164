import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'
import { appSelectors } from 'app/core/store/states/app.states'
import { dayTypeSelector } from 'app/core/store/states/enums/days-types.states'
import { requestStatusTypeSelector } from 'app/core/store/states/enums/request-status-type.states'
import { userStatusTypeSelector } from 'app/core/store/states/enums/user-status-type.states'
import { leaveTypesSelector } from 'app/core/store/states/leave-types.states'

@Injectable()
export class EnumStoreService {
    constructor(private store$: Store<State>) { }

    public get dayTypes$(): Observable<BaseModel[]> {
        return this.store$.select(dayTypeSelector.getDayTypesAll)
    }

    public get requestStatusTypes$(): Observable<BaseModel[]> {
        return this.store$.select(requestStatusTypeSelector.getRequestStatusTypesAll)
    }

    public get userStatusTypes$(): Observable<BaseModel[]> {
        return this.store$.select(userStatusTypeSelector.getUserStatusTypesAll)
    }

    public get leaveTypes$(): Observable<BaseModel[]> {
        return this.store$.select(leaveTypesSelector.getLeaveTypesAll)
    }

    public get appModules$(): Observable<BaseModel[]> {
        return this.store$.select(appSelectors.getModules)
    }
}

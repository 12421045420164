import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'

import { minDateFn } from './validator'

const MIN_DATE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinDateValidator),
    multi: true
}

@Directive({
    selector: '[minDate][formControlName],[minDate][formControl],[minDate][ngModel]',
    providers: [MIN_DATE_VALIDATOR]
})
export class MinDateValidator implements Validator, OnInit, OnChanges {
    @Input() minDate: Date

    private validator: ValidatorFn
    private onChange: () => void

    ngOnInit() {
        this.validator = minDateFn(this.minDate)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const key in changes) {
            if (key === 'minDate') {
                this.validator = minDateFn(changes[key].currentValue)
                if (this.onChange) { this.onChange() }
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c)
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn
    }
}

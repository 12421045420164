import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router'

import { Resources } from 'app/core/constants'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

@Injectable()
export class IntIdGuard implements CanActivate {

  constructor(
    private router: Router,
    private modalService: ModalService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const isIdInt: boolean = Number.isInteger(Number(route.params['id']))

    if (!isIdInt) {
      this.modalService.info(ModalTypes.Alert, Resources.invalidItemId)
      return this.router.parseUrl('/home')
    }

    return isIdInt
  }
}

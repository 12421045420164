import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MsTeam } from 'app/core/models';

export interface MicrosoftState {
    myTeams: MsTeam[]
}

export const initialState: MicrosoftState = {
    myTeams: []
}

export namespace microsoftSelectors {
    export const getMicrosoftState = createFeatureSelector<MicrosoftState>('microsoft')
    export const getMyTeams = createSelector(getMicrosoftState, state => state.myTeams)
}

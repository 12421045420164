import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'

import { maxLengthFn } from './validator'

const MAX_LENGTH_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxLengthValidator),
    multi: true
}

@Directive({
    selector: '[maxLength][formControlName],[maxLength][formControl],[maxLength][ngModel]',
    providers: [MAX_LENGTH_VALIDATOR]
})
export class MaxLengthValidator implements Validator, OnInit, OnChanges {
    @Input() maxLength: number

    private validator: ValidatorFn
    private onChange: () => void

    ngOnInit() {
        this.validator = maxLengthFn(this.maxLength)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const key in changes) {
            if (key === 'maxLength') {
                this.validator = maxLengthFn(changes[key].currentValue)
                if (this.onChange) { this.onChange() }
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c)
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn
    }
}

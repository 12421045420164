export class VacationApproveViewModel {
  fullname: string
  description: string
  fromDate: Date
  toDate: Date
  totalDays: number
  isTeamLead: boolean
  hasAction: boolean
  notApprovable: boolean
  isTeamLeadApprover: boolean
  canceled: boolean
  leaves: LeaveViewModel[]
  approves: RequestApproveViewModel[]
}

export class LeaveViewModel {
  fromDate: Date
  toDate: Date
  userId: number
  userName: string
  totalDays: number
  leaveTypeName: string
}

export class RequestApproveViewModel {
  fullname: string
  status: string
  actionDate: Date
  description: string
}

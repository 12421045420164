import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, ViewChild, SimpleChange } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor';

import { FormBaseComponent } from '../f-base.component';

@Component({
  selector: 'f-date-range',
  templateUrl: './f-date-range.component.html',
  providers: [MakeValidatorProviders(FormDateRangeComponent), MakeValueAccessorProviders(FormDateRangeComponent)],
  styleUrls: [
    './f-date-range.component.scss',
  ]
})
export class FormDateRangeComponent extends FormBaseComponent implements OnInit, OnChanges {
  @ViewChild('d', { static: true }) datepicker

  @Input() minDate: Date
  @Input() maxDate: Date

  @Input() from: Date
  @Input() to: Date

  @Input() dateHighlights: Date[] = []

  @Output() fromChange: EventEmitter<Date> = new EventEmitter<Date>()
  @Output() toChange: EventEmitter<Date> = new EventEmitter<Date>()

  @Output() change: EventEmitter<any> = new EventEmitter<any>()

  date: any = null
  hoveredDate: NgbDate

  fromDate: NgbDate
  toDate: NgbDate

  ngbDateHighlights: NgbDate[] = []

  constructor(
    injector: Injector,
    private dateParser: NgbDateParserFormatter,
  ) {
    super(injector)
  }

  oldFrom: Date = null
  oldTo: Date = null

  ngOnInit() {
    this.control = this
      .injector
      .get(NgControl)
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes['from']) {
      this.fromDate = this.dateToValue(this.from)
    }

    if (changes['to']) {
      this.toDate = this.dateToValue(this.to)
    }

    this.ngbDateHighlights = this.dateHighlights.map(d => this.dateToValue(d))
  }

  updateDate(dateString: string, dateId: 'from' | 'to') {
    // allow null values
    if (!dateString) {
      if (dateId === 'from') {
        this.setFromDate(undefined)
      }

      this.setToDate(undefined)

      return
    }

    const date = this.dateParser.parse(dateString)
    this.validateDate(date, dateId)
  }

  validateDate(value, dateId: 'from' | 'to') {
    if (value) {
      const _date = new Date(value.year, value.month - 1, value.day)

      if (dateId === 'from') {
        if (this.isValidDate(value) && (!this.to || (this.to && _date <= this.to))) {
          this.setFromDate(value)
          return
        }
      } else {
        if (this.isValidDate(value) && (!this.from || _date >= this.from)) {
          this.setToDate(value)
          return
        }
      }
    }

    if (dateId === 'from') {
      const date = this.getValidDate(this.oldFrom)
      this.setFromDate(this.dateToValue(date))
    } else {
      const date = this.getValidDate(this.oldTo)
      this.setToDate(this.dateToValue(date))
    }
  }

  onDateSelection(date: NgbDate) {
    const validDate = this.getValidDate(date == null ? null : new Date(date.year, date.month - 1, date.day))
    date = new NgbDate(validDate.getFullYear(), validDate.getMonth() + 1, validDate.getDate())

    if (!this.fromDate && !this.toDate) {
      this.setFromDate(date)
      this.setToDate(null)
    } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
      this.setToDate(date)
    } else {
      this.setFromDate(date)
      this.setToDate(null)
    }
  }

  getValidDate(value: Date) {
    if (!value) {
      return (this.from > this.minDate ? this.from : this.minDate) || this.getValidDate(new Date())
    }

    const minDate = this.getNewDateFromDate(this.minDate)
    const maxDate = this.getNewDateFromDate(this.maxDate)

    if (value && value < minDate) {
      return minDate
    } else if (value && value > maxDate) {
      return maxDate
    }

    return value
  }

  dateToValue(value: Date): NgbDate {
    if (value) {
      const date = this.getNewDateFromDate(value)
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }

    return undefined
  }

  getNewDateFromDate(date: Date) {
    const newDate = new Date(date)
    return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
  }

  setFromDate(ngbDate: NgbDate) {
    this.from = this.getUtcDate(ngbDate)
    this.oldFrom = this.from
    this.fromDate = ngbDate

    this.fromChange.emit(this.from)
    this.change.emit()
  }

  setToDate(ngbDate: NgbDate) {
    this.to = this.getUtcDate(ngbDate)
    this.oldTo = this.to
    this.toDate = ngbDate

    this.toChange.emit(this.to)
    this.change.emit()

    if (!this.from) {
      if (!this.from) {
        this.setFromDate(ngbDate)
      }
    }
  }

  isValidDate(date: NgbDate) {
    const value = new Date(date.year, date.month - 1, date.day)

    if (value && (!this.minDate || value >= this.minDate) && (!this.maxDate || value <= this.maxDate)) {
      return true
    }

    return false
  }

  isHovered(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day)
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day)
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day)
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  isHighlighted(date: NgbDate) {
    return this.ngbDateHighlights.findIndex(d => d.year === date.year && d.month === date.month && d.day === date.day) >= 0
  }

  getUtcDate(value: NgbDate) {
    if (!value) {
      return null
    }

    return new Date(Date.UTC(value.year, value.month - 1, value.day))
  }
}

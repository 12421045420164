import { Component, Input, OnChanges } from '@angular/core'

import { UserStatus } from '../resources/user-status-enum'
import { userStatuses } from '../resources/user-statuses'

@Component({
    selector: 'status-image',
    templateUrl: './status-image.component.html',
    styleUrls: ['status-image.component.scss']
})
export class StatusImageComponent implements OnChanges {

    @Input() image: string = ''
    @Input() defaultImageSource: string = 'default-profile-pic.png'
    @Input() imageSize: number = 30
    @Input() statusId: number = 1
    @Input() placement = 'bottom-right'

    userStatus: any = userStatuses.find(s => s.id === UserStatus.Available)

    ngOnChanges() {
        this.userStatus = userStatuses.find(s => s.id === this.statusId) ||
            userStatuses.find(s => s.id === UserStatus.Available)
    }
}


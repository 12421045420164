import { Component } from '@angular/core'

import { ProjectModel } from 'app/core/models/noms/project'

@Component({
  templateUrl: './project.component.html',
})

export class ProjectComponent {
  entity: ProjectModel = { id: 0, tenantId: '', name: '', isActive: true }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

import { Action } from '@ngrx/store'
import { BaseModel } from 'app/core/models'

import { type } from '../../util'

export const GET_LIST_OPTIONS = type('[WORKTIMETYPE] GET_LIST_OPTIONS')
export const GET_LIST_OPTIONS_SUCCESS = type('[WORKTIMETYPE] GET_LIST_OPTIONS_SUCCESS')
export const GET_LIST_OPTIONS_ERROR = type('[WORKTIMETYPE] GET_LIST_OPTIONS_ERROR')


export class GetListOptions implements Action {
    readonly type = GET_LIST_OPTIONS
}

export class GetListOptionsSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetListOptionsError implements Action {
    readonly type = GET_LIST_OPTIONS_ERROR
}

export type All =
    GetListOptions | GetListOptionsSuccess | GetListOptionsError

import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { ProjectComponent } from './project.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { ProjectService } from 'app/core/services'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'


import * as ProjectActions from 'app/core/store/actions/noms/project.actions'
import { ProjectModel } from 'app/core/models/noms/project'

@Component({
  templateUrl: './project-mgmt.component.html'
})
export class ProjectMgmtComponent extends BaseManagementComponent {

  constructor(
    public store: Store<State>,
    private modal: ModalService,
    private projectService: ProjectService) {
    super(store)
  }

  public add() {
    const item: ProjectModel = { id: 0, tenantId: '', name: '', isActive: true }
    this.openModal(item, false)
  }

  public edit(item) {
    this.openModal(item, true)
  }

  openModal(item, isEdit: boolean) {
    this.modal.open(ProjectComponent, ModalSizeTypes.Small, { entity: item, isEdit })
      .subscribe(result => {
        if (result.isSubmit) {
          this.projectService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
            this.store.dispatch(new ProjectActions.GetListOptions())
          })
        }
      })
  }
}

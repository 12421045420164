import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { TimeSheetComponent } from 'app/app-modules/time-sheet/time-sheet/time-sheet.component'
import { Operation } from 'app/core/enums/common/operation.enum'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction, FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { TimeSheetService, TimeSheetStoreService, UserStoreService } from 'app/core/services'
import { CommandService } from 'app/core/services/common/command.service'

import { BaseModel, TimeSheet } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: './time-sheet-mgmt.component.html',
  styleUrls: ['./time-sheet-mgmt.component.scss']
})
export class TimeSheetMgmtComponent extends BaseManagementComponent implements OnInit {

  workDays: number
  currentWorkDays: number = 0
  enteredHours: number = 0
  date: Date = new Date()
  users$: Observable<BaseModel[]>
  minDate: Date = new Date()
  filter: TableFilter = new TableFilter()
  isTeams: boolean = false
  closeAfterNew: boolean

  operation: Operation

  constructor(public store: Store<State>,
    private modal: ModalService,
    private timeSheetService: TimeSheetService,
    private commandService: CommandService,
    private route: ActivatedRoute,
    private timeSheetStoreService: TimeSheetStoreService,
    private userStoreService: UserStoreService) {
    super(store)

    this.route.data.takeWhile(_ => this.isAlive).subscribe(data => {
      this.isTeams = data.isTeams
    })

    this.filter.orderBy = 'Date desc'

    if (this.isCompanyAdmin || this.isTeams) {
      this.filter.customFilter.fromDate = this.date
      this.filter.customFilter.toDate = this.date
    } else {
      this.filter.customFilter.userId = this.userId
      const month = new Date().getMonth()
      this.filter.customFilter.month = month + 1
    }

    this.commandService.commands
      .takeWhile(_ => this.isAlive)
      .subscribe(c => {
        if (c.name === 'TimeSheet.New') {
          this.add()
        }
      })
  }

  ngOnInit() {
    this.date = new Date()

    if (!this.isCompanyAdmin && !this.isTeams) {
      this.timeSheetStoreService.workDays$
        .takeWhile(_ => this.isAlive)
        .subscribe(data => {
          this.workDays = data
        })

      this.timeSheetStoreService.currentWorkDays$
        .takeWhile(_ => this.isAlive)
        .subscribe(data => {
          this.currentWorkDays = data
        })

      this.timeSheetStoreService
        .monthlyEnteredHours$
        .takeWhile(_ => this.isAlive)
        .subscribe(hours => {
          this.enteredHours = hours
        })
    }

    this.getUsers()

    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  }

  public add() {
    this.operation = Operation.Insert
    const item = new TimeSheet()

    if (this.isTeams || this.isCompanyAdmin) {
      item.date = this.date
      item.vacationUserId = this.filter.customFilter.userId
    }

    this.openModal(item)
  }

  public edit(item) {
    this.operation = Operation.Update
    this.openModal(item)
  }

  public copy(item) {
    if (this.isTeams || this.isCompanyAdmin) {
      item.vacationUserId = this.filter.customFilter.userId
    }

    this.operation = Operation.Copy
    this.openModal(item)
  }

  delete(id: number) {
    this.timeSheetService.delete(id).first().subscribe(() => {
      this.store.dispatch(new FilterAction())
      if (!this.isCompanyAdmin && !this.isTeams) {
        this.timeSheetStoreService.resetMonthlyEnteredHours()
      }
    })
  }

  filterData() {
    this.filter.customFilter.fromDate = this.date
    this.filter.customFilter.toDate = this.date
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  openModal(item) {
    this.modal.open(TimeSheetComponent, ModalSizeTypes.Large,
      { entity: item, isTeams: this.isTeams, users$: this.users$, operation: this.operation })
      .takeWhile(_ => this.isAlive)
      .subscribe()
  }

  getUsers() {
    this.users$ = this.userStoreService.listOptions$
      .takeWhile(_ => this.isAlive)
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ExternalProvider } from 'app/core/enums'

import { ExternalLinkInfo } from 'app/core/models'

export interface AuthState {
  accessToken: string
  refreshToken: string
  userName: string
  userFullName: string
  userId: number
  loggedIn: boolean
  exp: Date,
  error: string
  role: string
  guid: string
  tenantId: string
  readHandbook: boolean
  workHoursPerDay: number
  isCompanyAdmin: boolean
  isTenantAdmin: boolean
  isGlobalAdmin: boolean
  isTL: boolean
  isGuest: boolean
  statusId: number
  moduleAdmins: string[]
  externalLinks: ExternalLinkInfo[]
  tenantModules: string[],
  loadedGuestModule: boolean
}

export const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  userName: null,
  userFullName: null,
  userId: null,
  loggedIn: false,
  exp: null,
  error: '',
  role: '',
  guid: '',
  tenantId: '',
  readHandbook: false,
  workHoursPerDay: 0,
  isCompanyAdmin: false,
  isTenantAdmin: false,
  isGlobalAdmin: false,
  isTL: false,
  isGuest: false,
  statusId: 1,
  moduleAdmins: [],
  externalLinks: [],
  tenantModules: [],
  loadedGuestModule: false
}

export namespace selectors {
  export const getAuthState = createFeatureSelector<AuthState>('auth')
  export const getAuthLoggedIn = createSelector(getAuthState, state => state.loggedIn)
  export const getUserId = createSelector(getAuthState, state => state.userId)
  export const getAuthRole = createSelector(getAuthState, state => state.role)
  export const getAuthReadHandbook = createSelector(getAuthState, state => state.readHandbook)
  export const getStatusId = createSelector(getAuthState, state => state.statusId)
  export const getTenantId = createSelector(getAuthState, state => state.tenantId)
  export const getAccessToken = createSelector(getAuthState, state => state.accessToken)
  export const getModuleAdmins = createSelector(getAuthState, state => state.moduleAdmins)
  export const getTenantModules = createSelector(getAuthState, state => state.tenantModules)

  export const getMyExternalLinks = createSelector(getAuthState, state => state.externalLinks)
  export const hasMicrosoftAccount = createSelector(getAuthState, state => state.externalLinks.findIndex(l => l.provider === ExternalProvider.Microsoft) >= 0)
  export const hasGoogleAccount = createSelector(getAuthState, state => state.externalLinks.findIndex(l => l.provider === ExternalProvider.Google) >= 0)

  export const isModuleAdmin = createSelector(getModuleAdmins,
    state => (module: string) => {
      return state.some(m => m === module)
    })
}


import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { ExternalLinkInfo } from 'app/core/models'

import { SetreadHandbookAction, SetStatusAction } from 'app/core/store/actions/auth.actions'
import { AuthState, selectors } from 'app/core/store/states/auth.states'

@Injectable()

export class AuthStoreService {

    constructor(private store$: Store<AuthState>) { }

    public get loggedIn$(): Observable<boolean> {
        return this.store$.select(selectors.getAuthLoggedIn)
    }

    public get role$(): Observable<string> {
        return this.store$.select(selectors.getAuthRole)
    }

    public get tenantId$(): Observable<string> {
        return this.store$.select(selectors.getTenantId)
    }

    public get readHandbook$(): Observable<boolean> {
        return this.store$.select(selectors.getAuthReadHandbook)
    }

    public set readHandbook(value: boolean) {
        this.store$.dispatch(new SetreadHandbookAction(value))
    }

    public get statusId$(): Observable<number> {
        return this.store$.select(selectors.getStatusId)
    }

    public set statusId(value: number) {
        this.store$.dispatch(new SetStatusAction(value))
    }

    public get accessToken$(): Observable<string> {
        return this.store$.select(selectors.getAccessToken)
    }

    public get moduleAdmins$(): Observable<string[]> {
        return this.store$.select(selectors.getModuleAdmins)
    }

    public get tenantModules$(): Observable<string[]> {
        return this.store$.select(selectors.getTenantModules)
    }

    public get externalLinks$(): Observable<ExternalLinkInfo[]> {
        return this.store$.select(selectors.getMyExternalLinks)
    }

    public get hasMicrosoftAccount$(): Observable<boolean> {
        return this.store$.select(selectors.hasMicrosoftAccount)
    }

    public get hasGoogleAccount$(): Observable<boolean> {
        return this.store$.select(selectors.hasGoogleAccount)
    }

    public get userId$(): Observable<number> {
        return this.store$.select(selectors.getUserId)
    }
}


import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CoreModule } from 'app/core'
import { DialogsModule } from 'app/dialogs/dialogs.module'
import { SharedModule } from 'app/shared'
import { SmartTableModule } from 'app/smart-table/smart-table.module'

import { SharedComponentsModule } from '../shared-components/shared-components.module'
import { AddVacationDaysComponent } from './add-vacation-days/add-vacation-days.component'
import { AdminMgmtComponent } from './admin/admin-mgmt.component'
import { AdminComponent } from './admin/admin.component'
import { AdministrationRoutingModule } from './administration.routing'
import { CompensationComponent } from './compensations/compensation.component'
import { CompanyMgmtComponent } from './noms/company/company-mgmt.component'
import { CompanyComponent } from './noms/company/company.component'
import { LocationMgmtComponent } from './noms/location/location-mgmt.component'
import { LocationComponent } from './noms/location/location.component'
import { PositionMgmtComponent } from './noms/position/position-mgmt.component'
import { PositionComponent } from './noms/position/position.component'
import { ProjectMgmtComponent } from './noms/project/project-mgmt.component'
import { ProjectComponent } from './noms/project/project.component'
import { TeanantMgmtComponent } from './noms/tenant/tenant-mgmt.component'
import { TenantSettingsComponent } from './noms/tenant/tenant-settings.component'
import { TenantComponent } from './noms/tenant/tenant.component'
import { ProjectToTeamLeadComponent } from './project-to-teamlead/project-to-teamlead.component'
import { ResetPasswordComponent } from './user/reset-password/reset-password.component'
import { ResetUserPasswordComponent } from './user/reset-user-password/reset-user-password.component'
import { UserMgmtComponent } from './user/users/user-mgmt.component'
import { UserComponent } from './user/users/user.component'
import {
  AddPermanentHolidaysComponent,
} from './work-schedule-change/add-permanent-holidays/add-permanent-holidays.component'
import { WorkScheduleChangeMgmtComponent } from './work-schedule-change/work-schedule-change-mgmt.component'
import { WorkScheduleChangeComponent } from './work-schedule-change/work-schedule-change.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    SmartTableModule,
    DialogsModule,
    AdministrationRoutingModule,
    SharedComponentsModule
  ],
  declarations: [
    AddVacationDaysComponent,
    CompensationComponent,
    CompanyComponent,
    CompanyMgmtComponent,
    PositionComponent,
    PositionMgmtComponent,
    ProjectComponent,
    ProjectMgmtComponent,
    TeanantMgmtComponent,
    TenantComponent,
    TenantSettingsComponent,
    LocationComponent,
    LocationMgmtComponent,
    ProjectToTeamLeadComponent,
    UserMgmtComponent,
    UserComponent,
    WorkScheduleChangeComponent,
    WorkScheduleChangeMgmtComponent,
    ResetPasswordComponent,
    AdminMgmtComponent,
    AdminComponent,
    AddPermanentHolidaysComponent,
    ResetUserPasswordComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [
    CompanyComponent,
    LocationComponent,
    ProjectComponent,
    TenantComponent,
    TenantSettingsComponent,
    PositionComponent,
    WorkScheduleChangeComponent,
    UserComponent,
    AddPermanentHolidaysComponent,
    ResetUserPasswordComponent
  ],
  providers: [],

})
export class AdministrationModule {
  constructor() { }
}

import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { BaseModel } from 'app/core/models'
import { UserService } from 'app/core/services'
import { State } from 'app/core/store/states'
import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import * as UserStatusTypeActions from '../../actions/enums/user-status-type.actions'

@Injectable()
export class UserStatusTypeEffects {

    constructor(
        private action$: Actions,
        private store: Store<State>,
        private userService: UserService
    ) { }

    @Effect()
    GetAll$: Observable<Action> = this.action$.pipe(
        ofType<UserStatusTypeActions.GetAll>(UserStatusTypeActions.GET_ALL),
        mergeMap((action) => {
            return this.userService.getStatuses().map(
                (userStatusTypes: BaseModel[]) => {
                    return new UserStatusTypeActions.GetAllSuccess(userStatusTypes)
                }, _ => of(new UserStatusTypeActions.GetAllError()))
        })
    )
}

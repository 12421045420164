import { Component, OnInit } from '@angular/core'

import { TenantActiveModel } from 'app/core/models/tenant/tenant-model'
import { Observable } from 'rxjs'
import { BaseModel } from 'app/core/models'
import { EnumStoreService, TenantStoreService } from 'app/core'
import { BaseComponent } from 'app/components/base/base.component'
import { IMultiSelectSettings } from 'ng2-multiselect'


@Component({
  templateUrl: './tenant.component.html',
})

export class TenantComponent extends BaseComponent implements OnInit {
  entity: TenantActiveModel = { id: null, name: '', isActive: true, modules: [] }

  appModules$: Observable<BaseModel[]>

  enterpriseOptions$: Observable<BaseModel[]>

  settings: IMultiSelectSettings = {
    keyToSelect: 'id',
    lableToDisplay: 'name',
    isSimpleArray: false,
    buttonClasses: 'btn btn-outline-secondary w-100 text-truncate',
    checkedStyle: 'fontawesome',
    dynamicTitleMaxItems: 7
  }

  constructor(
    private enumStoreService: EnumStoreService,
    private tenantStoreService: TenantStoreService
  ) {
    super()
  }

  ngOnInit() {
    this.appModules$ = this.enumStoreService.appModules$
      .takeWhile(_ => this.isAlive)

    this.enterpriseOptions$ = this.tenantStoreService.enterpriseListOptions$
      .takeWhile(_ => this.isAlive)
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'


export interface RequestStatusTypeState {
    listOptions: BaseModel[]
}

export const initialState: RequestStatusTypeState = {
    listOptions: []
}

export namespace requestStatusTypeSelector {
    export const getStatusTypeState = createFeatureSelector<RequestStatusTypeState>('requestStatus')
    export const getRequestStatusTypesAll = createSelector(getStatusTypeState, state => state.listOptions)
}
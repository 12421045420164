import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/components/base/base.component';
import { KeyBinding } from 'app/core/models';

@Component({
    selector: 'app-key-binding',
    templateUrl: './key-binding.component.html',
    styleUrls: [
        './key-binding.component.scss'
    ]
})
export class KeyBindingComponent extends BaseComponent implements OnInit {

    constructor(
    ) {
        super()
    }

    @Input() binding: KeyBinding

    ngOnInit() {
    }
}

import { Injectable } from '@angular/core'

import 'rxjs/add/observable/fromPromise'
import 'rxjs/add/observable/of'
import { Observable } from 'rxjs/Observable'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { cloneDeep } from 'lodash'

import { BaseDialogComponent } from './components/base-dialog.component'
import { ConfirmDialogComponent } from './components/confirm-dialog.component'
import { ModalOptions } from './interfaces/modal-options'
import { ModalSizeTypes } from './interfaces/modal-size-types'
import { ModalTypes } from './interfaces/modal-types'
import { ModalMenuItem } from './interfaces/modal-menu-item';
import { MenuDialogComponent } from './components/menu-dialog/menu-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalTypeTitles } from './models/modal-type-titles';

@Injectable()

export class ModalService {

  constructor(private modal: NgbModal, private translateService: TranslateService) {
  }

  public info(modalType: ModalTypes, content: string): Observable<boolean> {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: 'static'
    }

    const modalRef = this.modal.open(BaseDialogComponent, modalOptions)

    modalRef.componentInstance.type = modalType
    modalRef.componentInstance.modalContent = content

    return Observable.fromPromise(modalRef.result)
  }

  public confirm(modalType: ModalTypes, text = '', modalSize: ModalSizeTypes = ModalSizeTypes.Medium): Observable<boolean> {
    const modalOptions = this.createModalOptions(modalSize)

    const modalRef = this.modal.open(ConfirmDialogComponent, modalOptions)

    modalRef.componentInstance.type = modalType
    modalRef.componentInstance.modalContent = text
    modalRef.componentInstance.title = this.translateService.instant(ModalTypeTitles.find(model => model.type === modalType).title)

    return Observable.fromPromise(modalRef.result)
  }

  public menu(title: string, items: ModalMenuItem[], modalSize: ModalSizeTypes): Observable<any> {
    const modalOptions = this.createModalOptions(modalSize)

    const modalRef = this.modal.open(MenuDialogComponent, modalOptions)

    modalRef.componentInstance.title = title
    modalRef.componentInstance.items = items

    return Observable.fromPromise(modalRef.result)
  }

  public open(component: any, modalSize: ModalSizeTypes, data: any = {}): Observable<any> {
    const modalOptions = this.createModalOptions(modalSize)

    const modalRef = this.modal.open(component, modalOptions)

    for (const key in data) {
      if (data[key]) {
        modalRef.componentInstance[key] = cloneDeep(data[key])
      }
    }

    return Observable.fromPromise(modalRef.result)
  }

  public dismissAll(reason?: any) {
    this.modal.dismissAll(reason)
  }

  private createModalOptions(size: string): ModalOptions {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: 'static',
      size: size
    }

    return modalOptions
  }
}

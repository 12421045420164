import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'

import { ExportRecordInput } from './models/export-record-input'
import { TimeSheetToExport } from './models/timesheet-to-export'

import { NomsStoreService, TimeSheetService } from 'app/core/services'

import { BaseModel, DefaultTimeSheetSetting, LeaveToTimeSheet } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
    selector: 'time-sheet-modal',
    templateUrl: './time-sheet-modal.component.html'
})

export class TimeSheetModalComponent extends BaseAuthComponent implements OnInit {
    recordToExport: ExportRecordInput = {
        fromDate: null,
        toDate: null,
        recordType: null
    }

    projects: BaseModel[] = []

    timeSheet: TimeSheetToExport = {
        task: '',
        description: '',
        workHours: null,
        spentTime: null,
        workTimeTypeId: null,
        projectId: null
    }

    defaultSetting: DefaultTimeSheetSetting = new DefaultTimeSheetSetting()
    workHours: number[] = []
    projectOrClient: string

    constructor(
        public store: Store<State>,
        private timeSheetService: TimeSheetService,
        private nomsStoreService: NomsStoreService) {
        super(store)
    }

    ngOnInit() {
        this.timeSheetService.getExportSetting(this.recordToExport.recordType)
            .first()
            .subscribe(
                data => {
                    this.defaultSetting = data

                    this.timeSheet.task = this.defaultSetting.defaultTimesheetTask
                    this.timeSheet.spentTime = this.workHoursPerDay
                    this.timeSheet.workTimeTypeId = this.defaultSetting.defaultTimesheetWorkTimeTypeId

                    this.getProjects()
                }
            )
    }

    getProjects() {
        this.nomsStoreService.projectListOptions$
            .takeWhile(_ => this.isAlive)
            .subscribe(data => {
                this.projects = data
                const defaultProjectId =
                    this.projects.filter(p => p.name === this.defaultSetting.defaultTimesheetProject)
                        .map(p => p.id)[0]

                this.timeSheet.projectId = defaultProjectId
            })
    }

    exportRecords(dialog) {
        const leave: LeaveToTimeSheet = {
            fromDate: this.recordToExport.fromDate,
            toDate: this.recordToExport.toDate,
            timeSheet: this.timeSheet,
            userId: this.userId
        }

        dialog.close({ isSubmit: true, data: leave })
    }
}

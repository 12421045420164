import '@ngrx/store'

import { TableFilter } from '../models/table-filter.model'
import { TablePage } from '../models/table-page.model'
import * as smarterTable from './smarter-table.actions'

export interface State {
  object: string
  activePage: TablePage
  filter: TableFilter
  selectedItem: any
  isFiltering: boolean
}

const initialState: State = {
  object: '',
  activePage: {
    data: [],
    totalCount: 0
  },
  filter: new TableFilter(),
  selectedItem: null,
  isFiltering: false
}
export function reducer(state = initialState,
  action: smarterTable.Actions) {
  switch (action.type) {
    case smarterTable.ActionTypes.FILTER_SUCCESS: {
      return Object.assign({}, state, {
        activePage: action.payload,
        isFiltering: false
      })
    }
    case smarterTable.ActionTypes.CHANGE_FILTER: {
      return Object.assign({}, state, { filter: action.payload })
    }
    case smarterTable.ActionTypes.GET_ACTIVE_ITEM: {
      return Object.assign({}, state, {
        selectedItem: Object.assign({}, action.payload)
      })
    }
    case smarterTable.ActionTypes.SET_FILTERING: {
      return Object.assign({}, state, { isFiltering: action.payload })
    }
    case smarterTable.ActionTypes.SET_OBJECT: {
      return Object.assign({}, state, {
        object: action.payload,
        activePage: {
          data: [],
          totalCount: 0
        }
      })
    }
    default: {
      return state
    }
  }
}

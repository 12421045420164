import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'
import { AddDaysType } from 'app/core'

import { EnumStoreService, ToastrService, VacationRequestService } from 'app/core/services'

import { BaseModel, UserAddDays } from 'app/core/models'

import { getSmarterTablePage, State } from 'app/core/store/states'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  templateUrl: './add-vacation-days.component.html'
})

export class AddVacationDaysComponent extends BaseComponent implements OnInit {
  currentYear = new Date().getFullYear()
  currentMonth = new Date().getMonth()
  nextYear: number = 0

  canAdd: boolean = true

  addDaysTypes$: Observable<BaseModel[]>
  addDaysTypeId: number = AddDaysType.Standard

  constructor(
    private vacationService: VacationRequestService,
    private toastrService: ToastrService,
    public store: Store<State>,

    private enumStoreService: EnumStoreService) {
    super()
  }

  ngOnInit() {
    this.init()
  }

  init(isFirst = true) {
    this.vacationService.getDefaultDays()
      .subscribe(settings => {

        this.nextYear = Number(settings.date) + 1
        this.canAdd = this.currentYear > Number(settings.date) ||
          (this.currentMonth > 10 && this.currentYear === Number(settings.date))

        if (!this.canAdd && isFirst) {
          this.toastrService.warning('messages.notAllowedAddDays', null, true)
        }
      })

    this.addDaysTypes$ = this.enumStoreService.dayTypes$
      .takeWhile(_ => this.isAlive)
  }

  addDays() {
    if (this.canAdd) {
      this.store.select(getSmarterTablePage)
        .first()
        .subscribe(data => {
          if (data.data && data.data.length > 0) {
            const addDays = data.data.map(u => new UserAddDays(u.id, u.addDays))

            this.vacationService.addDays(addDays)
              .subscribe(() => {
                this.toastrService.success('messages.daysAddedSuccessfully', null, true)
                this.init(false)
              })
          }
        })
    } else {
      this.toastrService.warning('messages.notAllowedAddDays', null, true)
    }
  }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'

import * as AppActions from '../actions/app.actions'

import { AppService } from 'app/core/services/app.service'

import { BaseModel } from 'app/core/models'


@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private appService: AppService
    ) { }

    @Effect()
    GetModules$: Observable<Action> = this.actions$.pipe(
        ofType<AppActions.GetModules>(AppActions.GET_MODULES),
        mergeMap((action) => {
            return this.appService.getModules().map((Apps: BaseModel[]) => {
                return new AppActions.GetModulesSuccess(Apps)
            }, _ => of(new AppActions.GetModulesError()))
        })
    )
}

import { Component, Input } from '@angular/core'

@Component({
  selector: 'label-color',
  templateUrl: './label-color.component.html',
  styleUrls: ['./label-color.component.scss']
})
export class LabelColorComponent {

  @Input() text = ''
  @Input() backgroundColor = '#283442'
  @Input() color = '#ffffff'
  @Input() cssClass = ''
  @Input() position = 'right'
  @Input() maxWidth = '50%'
  }

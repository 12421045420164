import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { HttpObjectService } from '../common'
import { ApiService } from '../common/api.service'

import { BaseModel, DefaultTimeSheetSetting, LeaveToTimeSheet } from 'app/core/models'
import { WorkDaysByPeriod } from 'app/core/models/time-sheet/work-days-by-period'

@Injectable()

export class TimeSheetService extends HttpObjectService {
  object = 'timesheet'
  
  constructor(private httpClient: ApiService) {
    super(httpClient)
  }

  getTotalEnteredHours(month: number) {
    const params = `?month=${month}`

    return this.httpClient.get(`${this.object}/entered-hours${params}`)
  }

  checkAny(fromDate: Date, toDate: Date, userId: number) {
    const params = `?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`

    return this.httpClient.get(`${this.object}/check-any${params}`)
  }

  getAllWorkDaysInMonth(toEndOfMonth: boolean = true): Observable<number> {
    const model = this.getMonthDateRange(toEndOfMonth)
    return this.httpClient.post(`${this.object}/all-workdays`, model, true, true)
  }

  export(model): Observable<any> {
    return this.httpClient.postExport('export/time-sheet', model)
  }

  getExportSetting(recordType): Observable<DefaultTimeSheetSetting> {
    return this.httpClient.get(`${this.object}/default-setting/${recordType}`)
  }

  exportLeave(model: LeaveToTimeSheet): Observable<any> {
    return this.httpClient.post(`${this.object}/export-leave`, model)
  } 

  prepareRecord(record, workHoursPerDay: number, isInsert: boolean) {
    record.workHoursPerDay = workHoursPerDay
    record.isInsert = isInsert
    return this.httpClient.post(`${this.object}/prepare-record`, record)
  }

  getWorkTimeTypes(): Observable<BaseModel[]> {
    return this.httpClient.get(`${this.object}/work-time-types`, true, true)
  }


  
  private getMonthDateRange(toEndOfMonth: boolean = true) {
    const moment = require('moment')
    const year = new Date().getFullYear()
    const month = new Date().getMonth()

    const startDate = moment([year, month]).format().slice(0, 10)
    // Clone the value before .endOf()
    const endDate = toEndOfMonth 
                      ? moment(startDate).endOf('month').format().slice(0, 10) 
                      : moment(new Date()).format().slice(0, 10)

    return { fromDate: startDate, toDate: endDate } as WorkDaysByPeriod
  }
}

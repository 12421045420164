import { ISidebarItem } from '../interfaces/sidebar-item.interface'

export class SidebarItemNodeModel {
  private _isActive: boolean = false
  private _isCollapsed?: boolean

  public children: SidebarItemNodeModel[] = []
  public parent: SidebarItemNodeModel = null

  public route?: string
  public icon: string = null
  public name: string = null
  public visible: boolean = true

  public get isActive(): boolean {
    return this._isActive
  }

  public set isActive(value: boolean) {
    this._isActive = value

    if (this.parent) {
      this.parent.isActive = value
      this.parent.isCollapsed = true
    }
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed
  }

  public set isCollapsed(value: boolean) {
    if (this.isLeaf) {
      return
    }

    if (!value) {
      this.children
        .filter((c: SidebarItemNodeModel) => !c.isLeaf)
        .forEach((c: SidebarItemNodeModel) => c.isCollapsed = false)
    } else if (value && this.parent) {
      this.parent.children.forEach((c: SidebarItemNodeModel) => c.isCollapsed = false)
    }

    this._isCollapsed = value
  }

  constructor(model: ISidebarItem) {
    this.route = model.route
    this.icon = model.icon
    this.name = model.name
    this.visible = model.visible

    if (model.children instanceof Array && model.children.length > 0) {
      this.isCollapsed = false
      model.children.forEach((c: ISidebarItem) => this.addChild(new SidebarItemNodeModel(c)))
    }
  }

  public addChild(node: SidebarItemNodeModel): void {
    node.parent = this
    this.children.push(node)
  }

  public get isLeaf(): boolean {
    return this.children.length === 0
  }
}

export const CalendarColors = {
    red: {
        primary: 'rgba(240, 36, 36, 0.33)',
        secondary: '#FAE3E3'
    },
    darkRed: {
        primary: '#cc0000',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#1e90ff'
    },
    gray: {
        primary: '#6c757d',
        secondary: '#6c757d42'
    },
    darkGray: {
        primary: '#343a40',
        secondary: '#343a4052'
    },
    green: {
        primary: '#98FB98',
        secondary: '#bcf8bc'
    },
    lightBlue: {
        primary: '#56BAEC',
        secondary: '#9ad5f2'
    },
    orange: {
        primary: '#FF8300',
        secondary: '#facd9e57'
    },
    darkGreen: {
        primary: '#005E38',
        secondary: '#005E3857'
    },
    purpul: {
        primary: '#580c9e',
        secondary: '#9f5edb57'
    },
    darkBlue: {
        primary: '#20086a',
        secondary: '#0b46cd57'
    },
    black: {
        primary: '#3f3f3f',
        secondary: 'rgb(241, 237, 237)'
    }
}


import { ElementRef } from '@angular/core'

import { IWysiwygElementService } from './wysiwyg.component'

declare const jQuery: any

export class SummernoteElementService implements IWysiwygElementService {
  private rootElement: ElementRef

  private getJQueryRootElement(): any {
    return jQuery(this.rootElement.nativeElement)
  }

  init(rootElement: ElementRef): void {
    this.rootElement = rootElement
    this.getJQueryRootElement().summernote()
  }

  setContent(content: string): void {
    this.getJQueryRootElement().summernote('code', content)
  }

  getContent(): string {
    return this.getJQueryRootElement().summernote('code')
  }

  getContentElement(): Node {
    return this.rootElement.nativeElement.nextElementSibling.children[2].children[2]
  }
}
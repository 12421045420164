import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAuthComponent } from 'app/components/base/base-auth.component';
import { ExternalAccountEndpoints } from 'app/core/constants/external-account-endpoints';
import { AuthService, ToastrService } from 'app/core/services';
import * as AuthActions from 'app/core/store/actions/auth.actions';
import { State } from 'app/core/store/states';
import { environment } from 'environments/environment';
import { ExternalProvider } from 'app/core';

@Component({
  templateUrl: './link-accounts.component.html'
})

export class LinkAccountsComponent extends BaseAuthComponent implements OnInit {
  constructor(
    public store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    super(store)
  }

  provider: ExternalProvider
  providerName: string

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const code = params['code']
      this.provider = params['state']
      this.providerName = ExternalProvider[this.provider]

      if (code) {
        this.authService.linkExternalAccount({ code: code, redirectUri: `${environment.appBaseUrl}${ExternalAccountEndpoints.linkAccount}`, provider: this.providerName })
          .takeWhile(_ => this.isAlive)
          .subscribe(_ => {
            this.store.dispatch(new AuthActions.GetExternalLinksAction())
            this.toastr.success('messages.accountLinkedSuccessfully', null, true)
          }, null, () => this.router.navigate([`/profile/${this.userId}`]))
      }
    })
  }
}

import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core'
import { NgForm, NgModel } from '@angular/forms'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Guid } from 'guid-typescript'

@Component({
  selector: 'dialog-form',
  templateUrl: 'dialog-form.component.html',
  styleUrls: ['../../styles.scss']
})
export class DialogFormComponent implements AfterViewInit, OnDestroy {
  @Input() dialogTitle = ''

  @Input() showButtons = true
  @Input() showSubmitBtn = true
  @Input() showCloseBtn = true
  @Input() disableSaveBtn = false
  @Input() disableCancelBtn = false
  @Input() hideHeader = false
  @Input() btnName: string
  @Input() cancelBtnText: string
  @Input() bodyClass = ''
  @Input() componentId: Guid = null
  @Input() overflowVisible = true

  @Input() closeOnCancel = true
  @Input() closeOnSubmit = true

  @Output() dialogClose = new EventEmitter<any>()
  @Output() submitForm = new EventEmitter<any>()

  @Input() outputOnSubmit: any = null

  @ContentChildren(NgModel) public models: QueryList<NgModel>
  @ViewChild(NgForm, { static: true }) public form: NgForm

  protected isAlive = true

  constructor(public activeModal: NgbActiveModal) { }

  public ngAfterViewInit(): void {
    this.models.forEach((model) => {
      this.form.addControl(model)
    })

    this.models.changes
    .takeWhile(_ => this.isAlive)
    .subscribe(() => {
      const formKeys = Object.keys(this.form.controls)

      this.models.forEach(child => {
        if (!formKeys.some(s => s === child.name)) {
          this.form.addControl(child)
        }
      })

      formKeys.forEach(child => {
        if (!this.models.some(s => s.name === child)) {
          this.form.form.removeControl(child)
        }
      })
    })
  }

  onClose() {
    this.dialogClose.emit(this.activeModal)

    if (this.closeOnCancel) {
      this.activeModal.close({ isSubmit: false, data: null })
    }
  }

  onSubmit() {
    this.submitForm.emit(this.activeModal)

    if (this.closeOnSubmit) {
      this.activeModal.close({ isSubmit: true, data: this.outputOnSubmit, componentId: this.componentId })
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false
  }
}

import { Component, Injector, Input } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'

import { FormSelectComponent } from '../f-select/f-select.component'

@Component({
  selector: 'f-icon-select',
  templateUrl: './f-icon-select.component.html',
  styleUrls: ['./f-icon-select.component.scss'],
  providers: [
    MakeValidatorProviders(FormIconSelectComponent),
    MakeValueAccessorProviders(FormIconSelectComponent)
  ]
})
export class FormIconSelectComponent extends FormSelectComponent {

  @Input() placement = 'left-top'

  constructor(public injector: Injector, translateService: TranslateService) {
    super(injector, translateService)

    this.cssClass = 'btn-outline-secondary'
  }
}

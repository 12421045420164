import { createFeatureSelector, createSelector } from '@ngrx/store'

export interface State {
    workDays: number
    currentWorkDays: number
    enteredHours: number
}

export const initialState: State = {
    workDays: 0,
    currentWorkDays: 0,
    enteredHours: 0
}

export namespace timeSheetSelectors {
    export const getTimeSheetState = createFeatureSelector<State>('timeSheet')
    export const getWorkDays = createSelector(getTimeSheetState, state => state.workDays)
    export const getCurrentWorkDays = createSelector(getTimeSheetState, state => state.currentWorkDays)
    export const getEnteredHours = createSelector(getTimeSheetState, state => state.enteredHours)
}

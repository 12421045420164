import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { AuthStoreService, EnumStoreService, UserService, CompanyService } from 'app/core'
import { IMultiSelectSettings } from 'ng2-multiselect'

import { NomsStoreService } from 'app/core/services/store/noms-store.service'
import { TenantStoreService } from 'app/core/services/store/tenant-store.service'

import { BaseModel, TeamLeader, User } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './user.component.html',
})
export class UserComponent extends BaseAuthComponent implements OnInit {

  entity: User = new User()
  users: BaseModel[] = []

  usersAll: BaseModel[] = []
  addedUserId: number

  positions$: Observable<BaseModel[]>
  locations$: Observable<BaseModel[]>
  companies$: Observable<BaseModel[]>
  tenants$: Observable<BaseModel[]>
  appModules$: Observable<BaseModel[]>

  statuses: BaseModel[]

  isEdit: boolean = false

  settings: IMultiSelectSettings = {
    keyToSelect: 'id',
    lableToDisplay: 'name',
    isSimpleArray: false,
    buttonClasses: 'btn btn-outline-secondary w-100 text-truncate',
    checkedStyle: 'fontawesome',
    dynamicTitleMaxItems: 7
  }

  // maximum age of employee - 100
  minBirthDate = new Date(new Date().getFullYear() - 100, 0, 1)

  // minimum age of employee - 14
  maxBirthDate = new Date(new Date().getFullYear() - 14, 11, 31)

  constructor(
    public store: Store<State>,
    private userService: UserService,
    private nomsStoreService: NomsStoreService,
    private tenantStoreService: TenantStoreService,
    private authStoreService: AuthStoreService,
    private enumStoreService: EnumStoreService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(store)
  }

  ngOnInit() {
    this.route.params
      .takeWhile(_ => this.isAlive)
      .subscribe((params) => {
        const id = +params['id']

        if (id) {
          this.isEdit = true

          this.userService.getUser(id)
            .takeWhile(_ => this.isAlive)
            .subscribe(user => {
              this.entity = user
            }, null, () => {
              if (!this.entity || this.entity && !this.entity.id) {
                this.back()
              }
            })
        } else {
          this.entity.statusId = 1
          this.entity.sendPasswordEmail = true
        }

        this.userService
          .getPosibleTeamLeads(id)
          .first()
          .subscribe(data => {
            this.users = Object.assign([], data.filter(u => !this.entity.teamLeaders.some(tl => tl.teamLeadId === u.id)))

            this.usersAll = Object.assign([], data)
          })
      })

    this.positions$ = this.nomsStoreService.positionListOptions$
      .takeWhile(_ => this.isAlive)

    this.locations$ = this.nomsStoreService.locationListOptions$
      .takeWhile(_ => this.isAlive)

    this.companies$ = this.nomsStoreService.companyListOptions$
      .takeWhile(_ => this.isAlive)

    this.tenants$ = this.tenantStoreService.tenantsListOptions$
      .takeWhile(_ => this.isAlive)

    this.appModules$ = this.enumStoreService.appModules$
      .takeWhile(_ => this.isAlive)

    this.authStoreService
      .tenantId$
      .first()
      .subscribe(tenantId => this.entity.tenantId = tenantId)

    this.userService.getStatuses()
      .takeWhile(_ => this.isAlive)
      .subscribe(data => {
        this.statuses = data
      })
  }

  addTeamLeader() {
    const teamleader = new TeamLeader()
    teamleader.id = this.entity.id

    const user = this.users.filter(u => u.id === this.addedUserId)[0]

    teamleader.teamLeadId = user.id
    teamleader.name = user.name
    teamleader.isApproverVR = true
    teamleader.isReceiverVREmail = true

  this.entity.teamLeaders.push(teamleader)

    this.users = this.usersAll.filter(u => !this.entity.teamLeaders.some(tl => tl.teamLeadId === u.id))
    this.addedUserId = null
  }

  removeTeamLeader(teamleader: TeamLeader) {
    this.entity.teamLeaders = this.entity.teamLeaders.filter(tl => tl.teamLeadId !== teamleader.teamLeadId)

    this.users = this.usersAll.filter(u => !this.entity.teamLeaders.some(tl => tl.teamLeadId === u.id))
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }

  save() {
    this.userService.save(this.entity).first().subscribe(() => {
      this.router.navigate(['/users'])
    })
  }

  setGeneratePassword() {
    this.entity.password = ''
  }

  back() {
    this.router.navigate(['/users'])
  }
}

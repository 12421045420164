import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { RoleIds, RolesLocalizeStrings } from 'app/core/constants'

import { AdminService, AuthStoreService, NomsStoreService, UserService } from 'app/core/services'

import { Admin, BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: 'admin.component.html'
})
export class AdminComponent extends BaseAuthComponent implements OnInit {
  entity: Admin = new Admin()

  companies$: Observable<BaseModel[]>

  requireCompanyInfo: boolean = true

  adminLevels: BaseModel[] = []

  statuses: BaseModel[]

  isEdit: boolean = false

  // maximum age of employee - 100
  minBirthDate = new Date(new Date().getFullYear() - 100, 0, 1)

  // minimum age of employee - 14
  maxBirthDate = new Date(new Date().getFullYear() - 14, 11, 31)

  constructor(
    public store: Store<State>,
    private userService: UserService,
    private nomStoreService: NomsStoreService,
    private authStoreService: AuthStoreService,
    private translateService: TranslateService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(store)

    this.adminLevels.push({ id: RoleIds.companyAdminRole, name: this.translateService.instant('general.' + RolesLocalizeStrings.companyAdminRole) })
  }

  ngOnInit() {
    this.route.params
      .takeWhile(_ => this.isAlive)
      .subscribe((params) => {
        const id = +params['id']

        if (id) {
          this.isEdit = true

          this.adminService.getAdmin(id)
            .takeWhile(_ => this.isAlive)
            .subscribe(admin => {
              this.entity = admin
            })
        } else {
          this.entity.statusId = 1
          this.entity.sendPasswordEmail = true
        }
      })


    this.companies$ = this.nomStoreService.companyListOptions$
      .takeWhile(_ => this.isAlive)

    this.authStoreService
      .tenantId$
      .takeWhile(_ => this.isAlive)
      .subscribe(tenantId => this.entity.tenantId = tenantId)

    this.userService.getStatuses()
      .takeWhile(_ => this.isAlive)
      .subscribe(statuses => {
        this.statuses = statuses
      })

    if (this.isTenantAdmin) {
      this.adminLevels.push({ id: RoleIds.tenantAdminRole, name: this.translateService.instant('general.' + RolesLocalizeStrings.tenantAdminRole) })
    }
    if (this.isGlobalAdmin) {
      this.adminLevels.push({ id: RoleIds.globalAdminRole, name: this.translateService.instant('general.' + RolesLocalizeStrings.globalAdminRole) })
    }
    if (!this.entity.adminLevel) {
      this.entity.adminLevel = RoleIds.companyAdminRole
    }
  }

  updateInfoRequirements() {
    const targetRole = this.entity.adminLevel

    switch (targetRole) {
      case RoleIds.globalAdminRole:
        this.entity.tenantId = null
        this.entity.companyId = null
        this.requireCompanyInfo = false
        break
      case RoleIds.tenantAdminRole:
        this.entity.companyId = null
        this.requireCompanyInfo = false
        break
      default:
        this.requireCompanyInfo = true
    }
  }

  save() {
    this.adminService.saveAdmin(this.entity).first().subscribe(() => {
      this.router.navigate(['/admins'])
    })
  }

  back() {
    this.router.navigate(['/admins'])
  }

  setGeneratePassword() {
    this.entity.password = ''
  }

}

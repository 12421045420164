export enum CalendarEventType {
    Vacation = 1,
    SickLeave = 2,
    Holiday = 3,
    Change = 4,
    HomeOffice = 5,
    BirthDate = 6,
    EmploymentDate = 7,
    Note = 8,
    Task = 9,
    Appointment = 10,
    Meeting = 11,
    Event = 12
}

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { JwtHelperService } from '@auth0/angular-jwt'

import { AuthHelperService } from '../services/common/auth-helper.service'

@Injectable()
export class TokenGuard implements CanActivate {

    constructor(
        private router: Router,
        private authHelperService: AuthHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const token = route.params['token'] as string

        const jwtHelper = new JwtHelperService()
        const authData = jwtHelper.decodeToken(token)

        if (authData.surveyType === 'Internal' && authData.id
            && parseInt(authData.id, 10) !== this.authHelperService.userId) {
            return this.router.parseUrl('/not-authorized')
        }

        return true;
    }
}

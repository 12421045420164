import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'

import { UserImage } from 'app/core/models//user/user-image'

import * as UserAction from 'app/core/store/actions/user.actions'

import { userSelectors } from 'app/core/store/states/user.states'
import { State } from 'app/core/store/states'

@Injectable()
export class PicturesService {
    profilePics: UserImage[] = []
    requestedProfilePics: number[] = []

    constructor(private store: Store<State>) {
        this
            .store
            .select(userSelectors.getProfilePictures)
            .subscribe(pics => {
                this.profilePics = pics
            })
    }

    getProfilePic(userId: number): string {
        const userImage = this.profilePics.find(i => i.userId === userId)

        if (userImage) {
            return userImage.base64Image
        } else {
            if (this.requestedProfilePics.indexOf(userId) < 0 && userId) {
                this.requestedProfilePics.push(userId)
                this.store.dispatch(new UserAction.GetUserImage(userId))
            }
        }

        return null
    }

    resetProfilePic(userId: number) {
        this.store.dispatch(new UserAction.GetUserImage(userId))
    }
}

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { CoreModule } from 'app/core/core.module'
import { SharedModule } from 'app/shared'
import {
  ColumnTemplateLoader,
  STButtonComponent,
  STColumnComponent,
  STFilterComponent,
  STFilterLoaderComponent,
} from 'app/smart-table/components/s-t-column.component'
import { SmartTableComponent } from 'app/smart-table/components/smart-table.component'
import { SmarterTableComponent } from 'app/smart-table/components/smarter-table.component'
import { NgTableService } from 'app/smart-table/services/ng-table.service'

import { RowTemplateLoader, STRowComponent } from './components/s-t-row.component'
import { RowBackgroudService } from './services/row-backgroud.service'
import { SmartTableService } from './services/smart-table.service'
import { reducers } from './store'
import { SmarterTableEffects } from './store/smarter-table.effects'

@NgModule({
  imports: [

    CommonModule,
    CoreModule,
    SharedModule,
    StoreModule.forFeature('st', reducers),
    EffectsModule.forRoot([
      SmarterTableEffects
    ])
  ],
  declarations: [
    STColumnComponent,
    STRowComponent,
    ColumnTemplateLoader,
    RowTemplateLoader,
    SmarterTableComponent,
    SmartTableComponent,
    STFilterComponent,
    STFilterLoaderComponent,
    STButtonComponent
  ],
  exports: [
    STColumnComponent,
    STRowComponent,
    ColumnTemplateLoader,
    RowTemplateLoader,
    SmarterTableComponent,
    SmartTableComponent,
    STFilterComponent,
    STFilterLoaderComponent,
    STButtonComponent
  ],
  providers: [NgTableService, RowBackgroudService, SmartTableService],

})
export class SmartTableModule {
  constructor() { }
}

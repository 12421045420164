import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { TranslationService } from 'app/core'
import { CommonDatePipe } from 'app/shared/pipes/common-date.pipe'
import * as moment from 'moment'

import { UserStoreService } from 'app/core/services/store/user-store.service'

import { BaseModel, SickLeaveInput } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  selector: 'sick-leave',
  templateUrl: 'sick-leave.component.html'
})

export class SickLeaveComponent extends BaseAuthComponent implements OnInit {
  entity: SickLeaveInput

  users$: Observable<BaseModel[]>
  user: BaseModel
  isMyRequest: boolean = false

  constructor(store: Store<State>,
    private userStoreService: UserStoreService,
    private translationService: TranslationService,
    private datePipe: CommonDatePipe,
  ) {
    super(store)
  }

  ngOnInit(): void {
    if (!this.entity.vacationUserId && !this.isCompanyAdmin) {
      this.entity.vacationUserId = this.userId
    }

    this.users$ = this.userStoreService.listOptions$
    .takeWhile(_ => this.isAlive)
  }

  onSubmit(dialog) {
    this.entity.totalDays = this.calcDaysDiff(this.entity.fromDate, this.entity.toDate);
    dialog.close({ isSubmit: true, data: this.entity , componentId: this.componentId})
  }

  selectUser(user) {
    this.user = user
  }

  calcDaysDiff(startDate: Date, endDate: Date): number {
    const start = moment(startDate)
    const end = moment(endDate)

    return end.diff(start, 'days') + 1
  }

  get disableUsers() {
    return (!this.isTL && !this.isCompanyAdmin) || this.isMyRequest
  }

  get isTodayOrLater() {
    return this.entity.toDate && moment(this.entity.toDate).isSameOrAfter(moment(), 'day')
  }

  get defaultMessage() {
    const baseMessage: string = this.isMyRequest
      ? this.translationService.translateMsg('messages.sickLeaveDefaultMessage')
      : `${this.user ? this.user.name : ''} ${this.translationService.translateMsg('messages.sickLeaveOtherPersonDefaultMessage')}`

    const dateMessage: string = `${this.translationService.translateMsg('general.from').toLowerCase()} ${this.datePipe.transform(this.entity.fromDate)} 
    ${this.translationService.translateMsg('general.to').toLowerCase()} ${this.datePipe.transform(this.entity.toDate)}`

    return `${baseMessage} ${dateMessage}`
  }
}

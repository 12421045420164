import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface CompanyState {
    listOptions: BaseModel[]
}

export const initialState: CompanyState = {
    listOptions: []
}

export namespace companySelectors {
    export const getCompanyState = createFeatureSelector<CompanyState>('company')
    export const getCompanyListOptions = createSelector(getCompanyState, state => state.listOptions)
}

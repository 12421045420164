export function formatPeriod(startDate: Date, endDate: Date, days: number) {

    const fromDate = new Date(startDate)
    const toDate = new Date(endDate)
    const startMonth = this.translationService.translateMsg(`months.${fromDate.toLocaleString('en-us', { month: 'long' }).toLocaleLowerCase()}`)
    const endMonth = this.translationService.translateMsg(`months.${toDate.toLocaleString('en-us', { month: 'long' }).toLocaleLowerCase()}`)
    const year = fromDate.getFullYear()
    const start = fromDate.getDate()
    const end = toDate.getDate()

    if (start === end) {
      return `${start} ${startMonth} ${year} (${days} ${this.translationService.translateMsg('general.day')})`
    } else if (startMonth === endMonth) {
      return `${start} - ${end} ${startMonth} ${year} (${days} ${this.translationService.translateMsg('general.days')})`
    } else {
      return `${start} ${startMonth} - ${end} ${endMonth} ${year} (${days} ${this.translationService.translateMsg('general.days')})`
    }
  }

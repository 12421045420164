import { createFeatureSelector, createSelector } from '@ngrx/store'

import { UserBasicInfo, UserLinks } from 'app/core/models'
import { BaseModel } from 'app/core/models/common/base-model'
import { UserImage } from 'app/core/models/user/user-image'

export interface State {
    listOptions: BaseModel[]
    listOptionsAll: BaseModel[]
    listOptionsWithEmails: UserBasicInfo[]
    listOptionsNoFiltered: BaseModel[]
    profileImages: UserImage[]
    externalLinks: UserLinks[]
}

export const initialState: State = {
    listOptions: [],
    listOptionsAll: [],
    listOptionsWithEmails: [],
    listOptionsNoFiltered: [],
    profileImages: [],
    externalLinks: [],
}

export namespace userSelectors {
    export const getUserState = createFeatureSelector<State>('user')
    export const getUserListOptions = createSelector(getUserState, state => state.listOptions)
    export const getUserListOptionsNoFiltered = createSelector(getUserState, state => state.listOptionsNoFiltered)
    export const getUserListOptionsAll = createSelector(getUserState, state => state.listOptionsAll)
    export const getUserListOptionsWithEmails = createSelector(getUserState, state => state.listOptionsWithEmails)
    export const getProfilePictures = createSelector(getUserState, state => state.profileImages)
    export const getExternalLinks = createSelector(getUserState, state => state.externalLinks)
}

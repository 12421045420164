export class VacationApproveInputModel {
  id: number
  vacationId: number
  vacationUserId: number
  isApproved: boolean
  description: string

  constructor(vacationId: number, vacationUserId: number, isApproved: boolean) {
    this.isApproved = isApproved
    this.vacationId = vacationId
    this.vacationUserId = vacationUserId
  }
}

import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { UserStoreService } from 'app/core/services'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({ templateUrl: './report-by-year.component.html' })

export class VacationReportByYearComponent extends BaseAuthComponent implements OnInit {
  userOptions$: Observable<BaseModel[]>

  filter: TableFilter = new TableFilter()

  constructor(store: Store<State>,
    private userStoreService: UserStoreService
  ) {
    super(store)

  }

  ngOnInit() {
    if (this.isTL || this.isCompanyAdmin) {
      this.userOptions$ = this.userStoreService.listOptions$
        .takeWhile(_ => this.isAlive)
    }

    this.filter.parentId = this.userId
    this.filter.orderBy = 'Year desc'
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }
}

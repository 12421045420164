import { Action } from '@ngrx/store'

import { type } from '../../util'

import { UserBasicInfo, UserLinks } from 'app/core/models'
import { BaseModel } from 'app/core/models/common/base-model'
import { UserImage } from 'app/core/models/user/user-image'

export const GET_LIST_OPTIONS = type('[USER] GET_LIST_OPTIONS')
export const GET_LIST_OPTIONS_SUCCESS = type('[USER] GET_LIST_OPTIONS_SUCCESS')
export const GET_LIST_OPTIONS_ERROR = type('[USER] GET_LIST_OPTIONS_ERROR')

export const GET_LIST_OPTIONS_ALL = type('[USER] GET_LIST_OPTIONS_ALL')
export const GET_LIST_OPTIONS_ALL_SUCCESS = type('[USER] GET_LIST_OPTIONS_ALL_SUCCESS')
export const GET_LIST_OPTIONS_ALL_ERROR = type('[USER] GET_LIST_OPTIONS_ALL_ERROR')

export const GET_LIST_OPTIONS_WITH_EMAILS = type('[USER] GET_LIST_OPTIONS_WITH_EMAILS')
export const GET_LIST_OPTIONS_WITH_EMAILS_SUCCESS = type('[USER] GET_LIST_OPTIONS_WITH_EMAILS_SUCCESS')
export const GET_LIST_OPTIONS_WITH_EMAILS_ERROR = type('[USER] GET_LIST_OPTIONS_WITH_EMAILS_ERROR')

export const GET_USER_IMAGE = type('[USER] GET_USER_IMAGE')
export const GET_USER_IMAGE_SUCCESS = type('[USER] GET_USER_IMAGE_SUCCESS')
export const GET_USER_IMAGE_ERROR = type('[USER] GET_USER_IMAGE_ERROR')

export const GET_USER_LINKS = type('[USER] GET_USER_LINKS')
export const GET_USER_LINKS_SUCCESS = type('[USER] GET_USER_LINKS_SUCCESS')
export const GET_USER_LINKS_ERROR = type('[USER] GET_USER_LINKS_ERROR')

export const GET_LIST_OPTIONS_NO_FILTERED = type('[USER] GET_LIST_OPTIONS_NO_FILTERED')
export const GET_LIST_OPTIONS_NO_FILTERED_SUCCESS = type('[USER] GET_LIST_OPTIONS_NO_FILTERED_SUCCESS')
export const GET_LIST_OPTIONS_NO_FILTERED_ERROR = type('[USER] GET_LIST_OPTIONS_NO_FILTERED_ERROR')

export class GetListOptions implements Action {
    readonly type = GET_LIST_OPTIONS
}

export class GetListOptionsSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetListOptionsError implements Action {
    readonly type = GET_LIST_OPTIONS_ERROR
}

export class GetListOptionsAll implements Action {
    readonly type = GET_LIST_OPTIONS_ALL
}

export class GetListOptionsAllSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_ALL_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetListOptionsAllError implements Action {
    readonly type = GET_LIST_OPTIONS_ALL_ERROR
}

export class GetListOptionsWithEmails implements Action {
    readonly type = GET_LIST_OPTIONS_WITH_EMAILS
}

export class GetListOptionsWithEmailsSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_WITH_EMAILS_SUCCESS

    constructor(public payload: UserBasicInfo[]) { }
}

export class GetListOptionsWithEmailsError implements Action {
    readonly type = GET_LIST_OPTIONS_WITH_EMAILS_ERROR
}

export class GetUserImage implements Action {
    readonly type = GET_USER_IMAGE

    constructor(public payload: number) { }
}

export class GetUserImageSuccess implements Action {
    readonly type = GET_USER_IMAGE_SUCCESS

    constructor(public payload: UserImage) { }
}

export class GetUserImageError implements Action {
    readonly type = GET_USER_IMAGE_ERROR
}

export class GetUserLinks implements Action {
    readonly type = GET_USER_LINKS

    constructor(public payload: number) { }
}

export class GetUserLinksSuccess implements Action {
    readonly type = GET_USER_LINKS_SUCCESS

    constructor(public payload: UserLinks) { }
}

export class GetUserLinksError implements Action {
    readonly type = GET_USER_LINKS_ERROR
}

export class GetListOptionsNoFiltered implements Action {
    readonly type = GET_LIST_OPTIONS_NO_FILTERED
}

export class GetListOptionsNoFilteredSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_NO_FILTERED_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetListOptionsNoFilteredError implements Action {
    readonly type = GET_LIST_OPTIONS_NO_FILTERED_ERROR
}

export type All
    = GetListOptions | GetListOptionsSuccess | GetListOptionsError
    | GetListOptionsAll | GetListOptionsAllSuccess | GetListOptionsAllError
    | GetListOptionsWithEmails | GetListOptionsWithEmailsSuccess | GetListOptionsWithEmailsError
    | GetUserImage | GetUserImageSuccess | GetUserImageError
    | GetUserLinks | GetUserLinksSuccess | GetUserLinksError
    | GetListOptionsNoFiltered | GetListOptionsNoFilteredSuccess | GetListOptionsNoFilteredError

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAuthComponent } from 'app/components/base/base-auth.component';
import { AuthService, ExternalProvider } from 'app/core';
import { ExternalAccountEndpoints } from 'app/core/constants/external-account-endpoints';
import { LoginSuccessAction } from 'app/core/store/actions/auth.actions';
import { State } from 'app/core/store/states';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './external-login.component.html'
})

export class LoginExternalComponent extends BaseAuthComponent implements OnInit {
  constructor(
    public store: Store<State>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) {
    super(store)
  }

  provider: ExternalProvider
  providerName: string

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const code = params['code']
      this.provider = params['state']
      this.providerName = ExternalProvider[this.provider]

      if (code) {
        this.authService.loginWithExternalAccount({ code: code, redirectUri: `${environment.appBaseUrl}${ExternalAccountEndpoints.loginAccount}`, provider: this.providerName })
          .takeWhile(_ => this.isAlive)
          .subscribe(tokens => {
            this.store.dispatch(new LoginSuccessAction(tokens))
          }, null, () => this.router.navigate([`/home`]))
      }
    })
  }
}

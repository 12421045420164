import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { BaseModel } from 'app/core/models'
import { VacationRequestService } from 'app/core/services'
import { State } from 'app/core/store/states'
import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import * as DaysTypeActions from '../../actions/enums/days-type.actions'


@Injectable()
export class DaysTypeEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private vacationRequestService: VacationRequestService
    ) { }

    @Effect()
    GetAll$: Observable<Action> = this.actions$.pipe(
        ofType<DaysTypeActions.GetAll>(DaysTypeActions.GET_ALL),
        mergeMap((action) => {
            return this.vacationRequestService.getDayTypes().map(
                (daysType: BaseModel[]) => {
                    return new DaysTypeActions.GetAllSuccess(daysType)
                }, _ => of(new DaysTypeActions.GetAllError()))
        })
    )
}
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Locales } from 'app/core/constants';

export interface LanguageState {
    language: string
}

export const initialState: LanguageState = {
    language: Locales.bgBG
}

export namespace languageSelectors {
    export const getLanguageState = createFeatureSelector<LanguageState>('language')
    export const getLanguage = createSelector(getLanguageState, state => state.language)
}

import * as PermanentHolidayActions from 'app/core/store/actions/permanent-holiday.actions';
import { initialState } from '../../store/states/permanent-holiday.states';


export type Action = PermanentHolidayActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case PermanentHolidayActions.GET_TOP_5_SUCCESS : {
            return {
                ...state,
                permanentHolidays: action.payload
            }
        }
        default: return state
    }
}

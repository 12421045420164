import { CommonDatePipe } from './common-date.pipe';
import { CommonDateTimePipe } from './common-date-time.pipe';
import { FilterPipe } from './filter.pipe';
import { KeysPipe } from './keys.pipe';
import { OrderBy } from './sort-pipe';

export const sharedPipes = [
    CommonDatePipe,
    CommonDateTimePipe,
    OrderBy,
    KeysPipe,
    FilterPipe,
]

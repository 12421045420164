import { Store } from '@ngrx/store'

import { BaseComponent } from './base.component'

import { UserSettings } from 'app/core/models'

import { State } from 'app/core/store/states'
import { selectors } from 'app/core/store/states/auth.states'
import { settingsSelectors } from 'app/core/store/states/settings.states'

export class BaseAuthComponent extends BaseComponent {

  userRole: string = ''
  workHoursPerDay: number = 0
  userId: number = 0
  userName: string = ''
  userFullName: string = ''
  userTenantId: string = ''
  isLogged: boolean = false
  userSettings: UserSettings = new UserSettings()

  isGlobalAdmin: boolean
  isTenantAdmin: boolean
  isCompanyAdmin: boolean
  isTL: boolean
  isGuest: boolean

  loadedGuestModule: boolean

  constructor(public store: Store<State>) {
    super()

    this.store.select(selectors.getAuthState)
      .takeWhile(_ => this.isAlive)
      .subscribe(auth => {
        this.workHoursPerDay = auth.workHoursPerDay
        this.userId = auth.userId
        this.userName = auth.userName
        this.userFullName = auth.userFullName
        this.isLogged = auth.loggedIn
        this.userRole = auth.role
        this.userTenantId = auth.tenantId

        this.isGlobalAdmin = auth.isGlobalAdmin
        this.isTenantAdmin = auth.isTenantAdmin
        this.isCompanyAdmin = auth.isCompanyAdmin
        this.isTL = auth.isTL
        this.isGuest = auth.isGuest

        this.loadedGuestModule = auth.loadedGuestModule
      })

    this.store.select(settingsSelectors.getUserSettings)
      .takeWhile(_ => this.isAlive)
      .subscribe(userSettings => {
        if (userSettings) {
          this.userSettings.blackCalendarIcon = userSettings.blackCalendarIcon
          this.userSettings.typeaheadNom = userSettings.typeaheadNom
        }
      })
  }
}

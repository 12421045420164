import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MsTeam, MsTeamsMessage, MsUser } from '../models';
import { ApiService } from './common/api.service';


@Injectable()
export class MicrosoftService {

    constructor(
        private httpClient: ApiService
    ) { }

    getMyTeams(): Observable<MsTeam[]> {
        return this.httpClient.get(`microsoft/teams/my`, true, true)
    }

    getTeamMembers(teamId: string): Observable<MsUser[]> {
        return this.httpClient.get(`microsoft/teams/${teamId}/members`, true, true)
    }

    sendTeamsMessage(message: MsTeamsMessage): Observable<void> {
        return this.httpClient.post(`microsoft/teams/message`, message, true, true)
    }
}

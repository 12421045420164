import { Injectable } from '@angular/core'

import { NotificationType } from 'app/core/enums/notification-type.enum'
import { ToastrService as AngularToastrService } from 'ngx-toastr'

import { TranslationService } from './traslation.service'

import { Notification } from 'app/core/models'

@Injectable()

export class ToastrService {
    private options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: true,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
        onclick: null,
        showDuration: 4000,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        enableHtml: true
    }

    private notificationOptions = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: true,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
        onclick: null,
        showDuration: 4000,
        hideDuration: 1000,
        disableTimeOut: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        enableHtml: true
    }

    constructor(private toastr: AngularToastrService,
        private translateService: TranslationService) { }

    success(message: string, title?: string, translate = false): void {
        const msg = translate ? this.translateService.translateMsg(message) : message
        this.toastr.success(msg, title, this.options)
    }

    info(message: string, title?: string, translate = false): void {
        const msg = translate ? this.translateService.translateMsg(message) : message
        this.toastr.info(msg, title, this.options)
    }

    warning(message: string, title?: string, translate = false): void {
        const msg = translate ? this.translateService.translateMsg(message) : message
        this.toastr.warning(msg, title, this.options)
    }

    error(message: string, title?: string, translate = false): void {
        const msg = translate ? this.translateService.translateMsg(message) : message
        this.toastr.error(msg, title, this.options)
    }

    notification(data: Notification, translate = false): void {
        const message = translate ? this.translateService.translateMsg(data.message) : data.message

        switch (data.type) {
            case NotificationType.Success:
                this.toastr.success(message, this.translateService.translateMsg('general.notification'), this.notificationOptions)
                break
            case NotificationType.Warning:
                this.toastr.warning(message, this.translateService.translateMsg('general.notification'), this.notificationOptions)
                break
            case NotificationType.Error:
                this.toastr.error(message, this.translateService.translateMsg('general.notification'), this.notificationOptions)
                break
            case NotificationType.Info:
            default:
                this.toastr.info(message, this.translateService.translateMsg('general.notification'), this.notificationOptions)
        }
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { BaseActionComponent } from './base-action.component'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

@Component({
  selector: 'icon-action',
  templateUrl: './icon-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconActionComponent extends BaseActionComponent {
  @Input() icon = ''
  @Input() title = ''
  @Input() btnClass = ''
  @Input() isConfirm: boolean = false
  @Input() confirmMsg: string = ''

  constructor(
    public el: ElementRef,
    private modal: ModalService,
    private translate: TranslateService
  ) {
    super(el)
  }

  onBtnClick(event) {
    event.stopPropagation()
    if (!this.disabled) {
      if (this.isConfirm) {
        const message = this.translate.instant(this.confirmMsg === '' ? 'messages.areYouSure' : this.confirmMsg)

        this.modal.confirm(ModalTypes.Warning, message).subscribe(state => {
          if (state && !this.disabled) {
            this.onClick.emit(event)
          }
        })
      } else {
        super.onBtnClick(event)
      }
    }
  }
}

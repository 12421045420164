import { animate, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, Component, Input } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import { Store } from '@ngrx/store'

import { SidebarItemNodeModel } from './models/sidebar-item-node.model'

import { State } from 'app/core/store/states'
import { getMenuState } from 'app/core/store/states/sidebar.states'

import { BaseComponent } from 'app/components/base/base.component'


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])]
})
export class SidebarComponent extends BaseComponent implements AfterViewInit {
  private _activeNode: SidebarItemNodeModel = null
  items: SidebarItemNodeModel[] = []

  @Input() show = true

  private set activeNode(value: SidebarItemNodeModel) {
    if (value !== this._activeNode) {
      if (this._activeNode) {
        this._activeNode.isActive = false
      }

      this._activeNode = value

      if (this._activeNode) {
        this._activeNode.isActive = true
      }
    }
  }

  constructor(private router: Router, store: Store<State>) {
    super()

    store.select(getMenuState).subscribe(state => {
      this.items = state
      this.selectActiveNode(this.router.url)
    })
  }

  ngAfterViewInit(): void {
    this.router.events
      .takeWhile(_ => this.isAlive)
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.selectActiveNode(event.url)
        }
      })
  }

  selectActiveNode(route: string): void {
    if (route.startsWith('/')) {
      route = route.substring(1)
    }

    this.activeNode = this.getActiveNode(this.items, route)
  }

  public onNodeClick(node: SidebarItemNodeModel): void {
    if (!node.isLeaf) {
      node.isCollapsed = !node.isCollapsed
    } else if (!node.isActive) {
      this.activeNode = node
      this.router.navigateByUrl(node.route)
    }
  }

  private getActiveNode(nodes: SidebarItemNodeModel[], currentRoute: string): SidebarItemNodeModel {
    let activeNode: SidebarItemNodeModel = null

    nodes.forEach((node: SidebarItemNodeModel) => {
      if (activeNode) {
        return activeNode
      }

      if (!node.isLeaf) {
        activeNode = this.getActiveNode(node.children, currentRoute)
      } else if (node.isLeaf && node.route === currentRoute) {
        activeNode = node
      }
    })

    return activeNode
  }
}

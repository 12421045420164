import { Component, EventEmitter, Input, Output } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

@Component({ selector: 'action', template: '' })
export class ActionComponent {
    @Input() name: string
    @Input() default: boolean

    @Output() click = new EventEmitter()

    constructor(private translateService: TranslateService) {
        this.name = this.translateService.instant('general.select')
    }
}

import { Component, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { BaseActionComponent } from './base-action.component';
import { ModalService } from 'app/dialogs/modal.service';
import { ModalSizeTypes } from '../../../dialogs/interfaces/modal-size-types';

@Component({
  selector: 'print-action',
  templateUrl: './print-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintActionComponent extends BaseActionComponent {

  constructor(public el: ElementRef) {
    super(el)
  }
}

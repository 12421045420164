import { ActiveActionComponent } from './action-buttons/active-action.component'
import { BaseActionComponent } from './action-buttons/base-action.component'
import { CopyActionComponent } from './action-buttons/copy-action.component'
import { DeleteActionComponent } from './action-buttons/delete-action.component'
import { DetailsActionComponent } from './action-buttons/details-action.component'
import { EditActionComponent } from './action-buttons/edit-action.component'
import { IconActionComponent } from './action-buttons/icon-action.component'
import { PrintActionComponent } from './action-buttons/print-action.component'
import { ReorderActionComponent } from './action-buttons/reorder-action.component'
import { AuditComponent } from './audit/audit.component'
import { MDAddButtonComponent } from './buttons/add-btn.component'
import { BackButtonComponent } from './buttons/back-btn.component'
import { MDBaseButtonComponent } from './buttons/base-button.component'
import { MDBoldButtonComponent } from './buttons/bold-btn.component'
import { MDCancelButtonComponent } from './buttons/cancel-btn.component'
import { MDButtonComponent } from './buttons/f-btn.component'
import { MDIconButtonComponent } from './buttons/icon-btn.component'
import { ChartComponent } from './charts/chart.component'
import { FilterItemComponent } from './fitler-item/filter-item.component'
import { FilterComponent } from './fitler-item/filter.component'
import { ActionComponent } from './forms/f-action-select/action.component'
import { FormActionSelectComponent } from './forms/f-action-select/f-action-select.component'
import { FormBaseComponent } from './forms/f-base.component'
import { FormCheckBoxComponent } from './forms/f-checkbox/f-checkbox.component'
import { FormClipboardComponent } from './forms/f-clipboard/f-clipboard.component'
import { FormColorPickerComponent } from './forms/f-color-picker/f-color-picker.component'
import { FormDateRangeComponent } from './forms/f-date-range/f-date-range.component'
import { FormDateTimeComponent } from './forms/f-date-time/f-date-time.component'
import { FormDateComponent } from './forms/f-date/f-date.component'
import { FormDropdownComponent } from './forms/f-dropdown/f-dropdown.component'
import { FormEditorComponent } from './forms/f-editor/f-editor.component'
import { FormErrorComponent } from './forms/f-error/f-error.component'
import { FormIconSelectComponent } from './forms/f-icon-select/f-icon-select.component'
import { FormImageTypeaheadComponent } from './forms/f-image-typeahead/f-image-typeahead.component'
import { FormInputComponent } from './forms/f-input/f-input.component'
import { FormMultiSelectComponent } from './forms/f-multiselect/f-multiselect.component'
import { FormNumberComponent } from './forms/f-number/f-number.component'
import { FormRadioComponent } from './forms/f-radio/f-radio.component'
import { FormSelectComponent } from './forms/f-select/f-select.component'
import { FormSliderComponent } from './forms/f-slider/f-slider.component'
import { FormTextareaComponent } from './forms/f-textarea/f-textarea.component'
import { FormTypeaheadComponent } from './forms/f-typeahead/f-typeahead.component'
import { FormUploadComponent } from './forms/f-upload/f-upload.component'
import { LabelColorComponent } from './label-color/label-color.component'
import { LabelImageComponent } from './label-image/label-image.component'
import { NumberCardComponent } from './number-card/number-card.component'
import { RateComponent } from './rate/rate.component'
import { ReminderListComponent } from './reminder/reminder-list/reminder-list.component'
import { ReminderComponent } from './reminder/reminder.component'
import { FormTimerComponent } from './timer/timer.component'
import { FormTooltipComponent } from './tooltip/tooltip.component'
import { StatusImageComponent } from './user-status/status-image/status-image.component'
import { WysiwygComponent } from './wysiwyg/wysiwyg.component'


export const sharedComponents = [
    MDBaseButtonComponent,
    MDButtonComponent,
    MDIconButtonComponent,
    MDBoldButtonComponent,
    MDAddButtonComponent,
    MDCancelButtonComponent,
    BackButtonComponent,

    FormBaseComponent,
    FormInputComponent,
    FormErrorComponent,
    FormColorPickerComponent,
    FormSelectComponent,
    FormNumberComponent,
    FormTextareaComponent,
    FormCheckBoxComponent,
    FormDateComponent,
    FormDateTimeComponent,
    FormDateRangeComponent,
    FormRadioComponent,
    FormTooltipComponent,
    FormMultiSelectComponent,
    FormTypeaheadComponent,
    FormImageTypeaheadComponent,
    FormDropdownComponent,
    FormSliderComponent,
    FormIconSelectComponent,
    FormClipboardComponent,
    RateComponent,
    FormEditorComponent,

    ActionComponent,
    FormActionSelectComponent,

    BaseActionComponent,
    DeleteActionComponent,
    ActiveActionComponent,
    EditActionComponent,
    PrintActionComponent,
    IconActionComponent,
    CopyActionComponent,
    DetailsActionComponent,
    ReorderActionComponent,

    LabelImageComponent,
    LabelColorComponent,

    StatusImageComponent,

    WysiwygComponent,

    FormUploadComponent,

    AuditComponent,

    ReminderComponent,
    ReminderListComponent,

    FilterComponent,
    FilterItemComponent,

    FormTimerComponent,

    ChartComponent,

    NumberCardComponent,
]

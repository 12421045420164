import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { AuthHelperService } from '../services/common/auth-helper.service'

@Injectable()
export class ModuleAdminGuard implements CanActivate {

    constructor(
        private router: Router,
        private authHelperService: AuthHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const module = route.data['module'] as string

        if (this.authHelperService.moduleAdmins.some(m => m === module)) {
            return true
        }

        return this.router.parseUrl('/not-authorized')
    }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core'

import { Store } from '@ngrx/store'

import { TableFilter } from '../models/table-filter.model'
import { TablePage } from '../models/table-page.model'
import { NgTableService } from '../services/ng-table.service'
import { RowBackgroudService } from '../services/row-backgroud.service'
import { config } from '../smart-table.config'
import { getActivePage, getFilter, getIsFiltering, State } from '../store'
import { ChangeFilterAction, GetActiveItemAction, SetObjectAction } from '../store/smarter-table.actions'
import { STButtonComponent } from './s-t-column.component'
import { SmartTableComponent } from './smart-table.component'

@Component({
  selector: 'smarter-table',
  styleUrls: ['./smart-table.component.scss'],
  templateUrl: './smart-table.component.html'
})
export class SmarterTableComponent extends SmartTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() object: string = ''
  @Input() parentId: number = -1
  @Input() startFiltering = true

  isFiltering = false
  currentFilter: TableFilter = new TableFilter()

  protected isAlive = true

  constructor(private store: Store<State>, public st: NgTableService, public rowBgService: RowBackgroudService) {
    super(st, rowBgService)

    if (config.useStore) {
      store.select(getActivePage).takeWhile(() => this.isAlive)
        .subscribe(data => {
          this.page = Object.assign({}, data)

          const quotient = Math.floor(this.page.totalCount / this.st.rowsOnPage)
          const remainder = this.page.totalCount % this.st.rowsOnPage

          this.st.lastPage = quotient + (remainder > 0 ? 1 : 0)

          this.hideColsIfNeeded()
        })

      store.select(getIsFiltering).takeWhile(() => this.isAlive)
        .subscribe(data => {
          this.isFiltering = data
          this.emitFilterChange()
        })

      store.select(getFilter).takeWhile(() => this.isAlive)
        .subscribe(data => {
          if (data.filter !== this.filterQuery) {
            this.filterQuery = data.filter
          }
        })
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes['parentId'] && changes['parentId'].currentValue && this.parentId) {
      this.filter.parentId = changes['parentId'].currentValue
      this.emitFilterChange()
    }
  }

  ngOnInit() {
    this.page = new TablePage()

    if (!this.pageable) {
      this.filter.rowsOnPage = null
    }

    this.filter.onlyActive = this.isSwitchedOn

    if (config.useStore) {
      this.store.dispatch(new SetObjectAction(this.object))
      if (this.startFiltering) {
        this.currentFilter = Object.assign({}, this.filter)
        this.store.dispatch(new ChangeFilterAction(this.filter))
      }
    }
  }

  compareFilters() {
    return (
      this.currentFilter.filter !== this.filter.filter ||
      this.currentFilter.onlyActive !== this.filter.onlyActive ||
      this.currentFilter.orderBy !== this.filter.orderBy ||
      this.currentFilter.page !== this.filter.page ||
      (this.pageable && this.currentFilter.rowsOnPage !== this.filter.rowsOnPage) ||
      this.currentFilter.parentId != this.filter.parentId
    )
  }

  emitFilterChange() {
    if (!this.isFiltering && this.compareFilters()) {
      this.currentFilter = Object.assign({}, this.filter)

      if (config.useStore) {
        this.store.dispatch(new ChangeFilterAction(this.filter))
      }

      this.filterChange.emit(this.filter)
    }
  }

  rowSelect(entity) {
    this.activeItem = entity

    if (config.useStore) {
      this.store.dispatch(new GetActiveItemAction(entity))
    }

    this.rowSelected.emit(entity)
  }

  btnClick(btn: STButtonComponent): void {
    if (config.useStore) {
      this.store.dispatch(new GetActiveItemAction(null))
    }

    btn.onClick.emit()
  }

  ngOnDestroy() {
    this.isAlive = false
  }
}

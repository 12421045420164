import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BaseModel } from 'app/core/models';
import { LocationService } from 'app/core/services';
import { State } from 'app/core/store/states';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { mergeMap } from 'rxjs/operators';

import * as LocationActions from '../../actions/noms/location.actions';
import { LocationModel } from 'app/core/models/noms/location';


@Injectable()
export class LocationEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private locationService: LocationService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<LocationActions.GetListOptions>(LocationActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.locationService.getListOptions(true).map((locations: BaseModel[]) => {
                return new LocationActions.GetListOptionsSuccess(locations)
            }, _ => of(new LocationActions.GetListOptionsError()))
        })
    )
}

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/withLatestFrom';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MicrosoftService } from 'app/core/services';
import * as MicrosoftActions from 'app/core/store/actions/microsoft.actions';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MicrosoftEffects {
    constructor(
        private actions$: Actions,
        private microsoftService: MicrosoftService,
    ) { }

    @Effect() login$ = this.actions$.pipe(
        ofType<MicrosoftActions.GetMyTeams>(MicrosoftActions.GET_MY_TEAMS),
        mergeMap(action => {
            return this.microsoftService.getMyTeams().map(teams => {
                return new MicrosoftActions.GetMyTeamsSuccess(teams)
            }, _ => new MicrosoftActions.GetMyTeamsError())
        })
    )
}

import { Location } from '@angular/common'
import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'back-btn',
  templateUrl: 'back-btn.component.html',
})
export class BackButtonComponent {
  @Input() cssClass: string
  @Input() text: string = ''
  @Input() route: string = ''

  constructor(private location: Location, private router: Router) { }

  back() {
    if (this.route && this.route !== '') {
      this.router.navigate([this.route])
    } else {
      this.location.back()
    }
  }
}

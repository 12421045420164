import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { State, getTenantName } from 'app/core/store/states'
import { tenantSelectors } from 'app/core/store/states/tenant.states'
import { SelectTenant } from 'app/core/store/actions/tenant.actions'
import { BaseModel } from 'app/core/models'



@Injectable()
export class TenantStoreService {
    constructor(private store$: Store<State>) { }

    public get tenantsListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(tenantSelectors.getTenantListOptions)
    }

    public get enterpriseListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(tenantSelectors.getEnterpriseListOptions)
    }

    public get selectedTenantId(): Observable<string> {
        return this.store$.select(tenantSelectors.getSelectedTenantId)
    }

    public get selectedTenantName(): Observable<string> {
        return this.store$.select(getTenantName)
    }

    public set selectTenantId(tenantId: string) {
        this.store$.dispatch(new SelectTenant(tenantId))
    }
}

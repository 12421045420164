import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'

import * as SettingsActions from '../actions/settings.actions'

import { ToastrService, TimeSheetSettingService } from 'app/core/services'
import { SettingsService } from 'app/core/services/settings.service'

import { UserSettings, TimeSheetSetting } from 'app/core/models'

import { State } from 'app/core/store/states'


@Injectable()
export class SettingsEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private settingsService: SettingsService,
        private timeSheetSettingService: TimeSheetSettingService,
        private toastrService: ToastrService) {
    }

    @Effect()
    GetUserSettings$: Observable<Action> = this.actions$.pipe(
        ofType<SettingsActions.GetUserSettings>(SettingsActions.GET_USER_SETTINGS),
        mergeMap((action) => {
            return this.settingsService.getUserSettings().map((userSettings: UserSettings) => {
                return new SettingsActions.GetUserSettingsSuccess(userSettings)
            }, _ => of(new SettingsActions.GetUserSettingsError()))
        })
    )

    @Effect()
    SaveUserSettings$: Observable<Action> = this.actions$.pipe(
        ofType<SettingsActions.SaveUserSettings>(SettingsActions.SAVE_USER_SETTINGS),
        mergeMap((action) => {
            return this.settingsService.saveUserSettings(action.payload).map(_ => {
                this.store.dispatch(new SettingsActions.GetUserSettings())
                this.toastrService.success('messages.settingsSuccessfullySaved', null, true)
                return new SettingsActions.SaveUserSettingsSuccess()
            }, _ => of(new SettingsActions.SaveUserSettingsError()))
        })
    )

    @Effect()
    GetTimeSheetSettings$: Observable<Action> = this.actions$.pipe(
        ofType<SettingsActions.GetTimeSheetSettings>(SettingsActions.GET_TIMESHEET_SETTINGS),
        mergeMap((action) => {
            return this.timeSheetSettingService.get().map((timeSheetSettings: TimeSheetSetting) => {
                return new SettingsActions.GetTimeSheetSettingsSuccess(timeSheetSettings)
            }, _ => of(new SettingsActions.GetTimeSheetSettingsError()))
        })
    )

    @Effect()
    SaveTimeSheetSettings$: Observable<Action> = this.actions$.pipe(
        ofType<SettingsActions.SaveTimeSheetSettings>(SettingsActions.SAVE_TIMESHEET_SETTINGS),
        mergeMap((action) => {
            return this.timeSheetSettingService.save(action.payload).map(_ => {
                this.store.dispatch(new SettingsActions.GetTimeSheetSettings())
                this.toastrService.success('messages.settingsSuccessfullySaved', null, true)
                return new SettingsActions.SaveTimeSheetSettingsSuccess()
            }, _ => of(new SettingsActions.SaveTimeSheetSettingsError()))
        })
    )

}




import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { Store } from '@ngrx/store'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { ChangePasswordComponent } from 'app/app-modules/shared-components/change-password/change-password.component'
import { ResetUserPasswordComponent } from '../user/reset-user-password/reset-user-password.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { AuthStoreService, PicturesService, ToastrService, UserService } from 'app/core/services'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'


@Component({
    templateUrl: 'admin-mgmt.component.html'
})
export class AdminMgmtComponent extends BaseManagementComponent {

    filter: TableFilter = new TableFilter()

    constructor(
        store: Store<State>,
        private modal: ModalService,
        private router: Router,
        private picturesService: PicturesService,
        private userService: UserService,
        private toastr: ToastrService,
        private authStoreService: AuthStoreService
    ) {
        super(store)
        this.filter.orderBy = 'Fullname asc'
    }

    public add() {
        this.router.navigate(['/admin'])
    }

    openChangePasswordModal(id: number) {
        this.modal.open(ChangePasswordComponent, ModalSizeTypes.Medium, { id })
            .subscribe(result => {
                if (result.isSubmit) {
                    this.userService.changePassword(result.data).first().subscribe(_ => {
                        this.toastr.success('messages.passwordSuccessfullyChanged', null, true)
                    })
                }
            })
    }

    changeActive(id: number) {
        this.userService.changeIsActive(id)
            .first()
            .subscribe(_ => {
                this.filterData()
            })
    }

    filterData() {
        this.store.dispatch(new ChangeFilterAction(this.filter))
    }

    getProfilePicture(userId: number): string {
        return this.picturesService.getProfilePic(userId)
    }

    getCurrentUserStatus() {
        return this.authStoreService.statusId$
            .takeWhile(_ => this.isAlive)
    }

    resetUserPassword(email: string) {
        this.modal.open(ResetUserPasswordComponent, ModalSizeTypes.Medium, { entity: { email } })
            .subscribe(result => {
                if (result.isSubmit) {
                    this.userService.resetPassword(result.data)
                        .first()
                        .subscribe(_ => {
                            this.toastr.success('messages.passwordSuccessfullyGenerated', null, true)
                        })
                }
            })
    }
}

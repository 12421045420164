import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'
import { ChangeIsActiveAction, DeleteAction } from 'app/smart-table/store/smarter-table.actions'

import { BaseAuthComponent } from './base-auth.component'

import { State } from 'app/core/store/states'


@Injectable()
export class BaseManagementComponent extends BaseAuthComponent {
    constructor(public store: Store<State>) {
        super(store)
    }

    deleteEntity() {
        this.store.dispatch(new DeleteAction())
    }

    changeIsActive() {
        this.store.dispatch(new ChangeIsActiveAction())
    }
}

import { Component, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { BaseActionComponent } from './base-action.component';

@Component({
  selector: 'details-action',
  templateUrl: './details-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsActionComponent extends BaseActionComponent {

  constructor(public el: ElementRef) {
    super(el)
  }
}

import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core'
import { NgControl } from '@angular/forms'

import { NgbDatepicker, NgbPopover, NgbTimeStruct, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormBaseComponent } from '../f-base.component'
import { DateTimeModel } from './date-time'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'f-date-time',
  templateUrl: './f-date-time.component.html',
  providers: [MakeValidatorProviders(FormDateTimeComponent), MakeValueAccessorProviders(FormDateTimeComponent)]
})
export class FormDateTimeComponent extends FormBaseComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() dateString: string

  @Input() dateTimeFormat = 'yyyy-MM-dd HH:mm'
  @Input() hourStep = 1
  @Input() minuteStep = 15
  @Input() secondStep = 30
  @Input() seconds = false

  @Input() minDate: Date
  @Input() maxDate: Date

  @Input() initialDate: Date

  @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>()

  min: NgbDateStruct
  max: NgbDateStruct

  oldValue: DateTimeModel

  private showTimePickerToggle = false

  private datetime: DateTimeModel = new DateTimeModel()
  private initialDatetime: DateTimeModel = new DateTimeModel()

  private isValueDifferent: boolean

  @ViewChild(NgbDatepicker, { static: true }) dp: NgbDatepicker

  @ViewChild(NgbPopover, { static: true }) private popover: NgbPopover

  constructor(injector: Injector, private datePipe: DatePipe, private cd: ChangeDetectorRef) {
    super(injector)
  }

  ngOnInit() {
    this.control = this
      .injector
      .get(NgControl)
  }

  ngAfterViewInit() {
    this.popover.hidden.subscribe(_ => {
      this.showTimePickerToggle = false
    })
  }

  ngOnChanges() {
    if (this.initialDate) {
      const dateString = this.initialDate.toString()
      this.initialDatetime = DateTimeModel.fromLocalString(dateString)
    } else {
      this.initialDatetime = new DateTimeModel()
    }

    this.min = this.dateToValue(this.minDate)
    this.max = this.dateToValue(this.maxDate)
  }

  onClickedOutside() {
    const isOpen = this.popover.isOpen()
    this.isValueDifferent = this.initialDatetime.toString() !== this.datetime.toString()
    if (isOpen || this.showTimePickerToggle) {
      this.popover.close()
    }
  }

  save() {
    this.isValueDifferent = this.initialDatetime.toString() !== this.datetime.toString()
    this.update.emit(this.isValueDifferent)
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel))
      this.dateString = newModel
      this.setDateStringModel()
    } else {
      this.datetime = new DateTimeModel()
    }
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle
    $event.stopPropagation()
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  onInputChange($event: any) {
    const value = $event.target.value
    const dt = DateTimeModel.fromLocalString(value)
    const date = dt ? this.getNewDateFromDate(new Date(dt.year, dt.month - 1, dt.day)) : null
    const dateStruct = this.dateToValue(this.getValidDate(date))

    if (dateStruct) {
      dt.day = dateStruct.day
      dt.month = dateStruct.month
      dt.year = dateStruct.year

      this.datetime = dt
      this.oldValue = this.datetime
      this.setDateStringModel()
      this.save()
    } else if (!value || value.trim() === '' || !this.oldValue) {
      this.datetime = new DateTimeModel()
      this.dateString = ''
      this.value = this.dateString
      this.save()
    } else if (this.oldValue) {
      this.datetime = this.oldValue
      this.setDateStringModel()
      this.save()
    }
  }

  onDateChange($event: any) {
    if ($event && $event.year) {
      $event = this.getDateTimeStrng($event.year, $event.month, $event.day, this.datetime.hour, this.datetime.minute, this.datetime.second)
    }

    const date = DateTimeModel.fromLocalString($event.toString())

    if (!date) {
      this.dateString = this.dateString
      return
    }

    this.datetime = date
    this.oldValue = this.datetime

    this.setDateStringModel()
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour
    this.datetime.minute = event.minute
    this.datetime.second = event.second

    this.setDateStringModel()
  }

  setDateStringModel() {
    const date = new Date(this.datetime.toString())
    this.dateString = this.datePipe.transform(date, this.dateTimeFormat)
    this.value = this.datetime.toUtcString()
    this.cd.detectChanges()
  }

  getValidDate(value: Date) {
    const minDate = this.getNewDateFromDate(this.minDate)
    const maxDate = this.getNewDateFromDate(this.maxDate)

    value = value ? new Date(value) : null

    this.min = this.dateToValue(minDate)
    this.max = this.dateToValue(maxDate)

    if (value && value < minDate) {
      return minDate
    } else if (value && value > maxDate) {
      return maxDate
    }

    return value
  }

  dateToValue(value: Date): NgbDateStruct {
    if (value) {
      const date = this.getNewDateFromDate(value)

      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }

    return null
  }

  getNewDateFromDate(date: Date) {
    const newDate = new Date(date)
    return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
  }

  getDateTimeStrng(year: number, month: number, date: number, hour: number, minute: number, second: number): string {
    year = year || 1
    month = month || 1
    date = date || 1
    hour = hour || 0
    minute = minute || 0
    second = second || 0

    return `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
  }
}

import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { AppModules, LanguageStoreService, LoadingStoreService, Locales } from 'app/core'

import { BaseAuthComponent } from '../base/base-auth.component'

import * as AppActions from 'app/core/store/actions/app.actions'
import * as AuthActions from 'app/core/store/actions/auth.actions'
import * as DayTypeActions from 'app/core/store/actions/enums/days-type.actions'
import * as RequestStatusTypeActions from 'app/core/store/actions/enums/request-status-type.actions'
import * as LeaveTypeActions from 'app/core/store/actions/leave-types.actions'
import * as CompanyActions from 'app/core/store/actions/noms/company.actions'
import * as LocationActions from 'app/core/store/actions/noms/location.actions'
import * as PositionActions from 'app/core/store/actions/noms/position.actions'
import * as ProjectActions from 'app/core/store/actions/noms/project.actions'
import * as PermanentHolidayActions from 'app/core/store/actions/permanent-holiday.actions'
import * as SettingsActions from 'app/core/store/actions/settings.actions'
import * as TenantActions from 'app/core/store/actions/tenant.actions'
import * as TimeSheetActions from 'app/core/store/actions/time-sheet.actions'
import * as UserActions from 'app/core/store/actions/user.actions'
import * as WorkTimeTypeActions from 'app/core/store/actions/work-time-type.actions'
import { State } from 'app/core/store/states'
import { selectors } from 'app/core/store/states/auth.states'

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends BaseAuthComponent implements OnInit {
  currentYear: number = new Date().getFullYear()
  loading$: Observable<boolean>
  firstTime = true
  showSidebar = true

  constructor(store: Store<State>, private router: Router,
    private loadingService: LoadingStoreService,
    private translate: TranslateService,
    private languageStoreService: LanguageStoreService) {
    super(store)

    this.loading$ = this.loadingService.isLoading$
      .takeWhile(_ => this.isAlive)

    this.store.select(selectors.getAuthLoggedIn)
      .takeWhile(_ => this.isAlive)
      .subscribe(data => {
        this.init(data)
      })

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.translate.setTranslation(Locales.enUS, require('assets/i18n/en.json'))
    this.translate.setTranslation(Locales.bgBG, require('assets/i18n/bg.json'))
  }

  ngOnInit(): void {
    this.languageStoreService.language$
      .takeWhile(_ => this.isAlive)
      .subscribe(language => {
        this.translate.use(language)

        if (!this.firstTime) {
          this.store.dispatch(new WorkTimeTypeActions.GetListOptions())
          this.router.navigated = false;
          this.router.navigate([this.router.url])
        }
        this.firstTime = false
      })

    this.translate.onLangChange
      .subscribe(_ => {
        this.store.dispatch(new DayTypeActions.GetAll())
        this.store.dispatch(new RequestStatusTypeActions.GetAll())
        this.store.dispatch(new AppActions.GetModules())
        this.store.dispatch(new LeaveTypeActions.GetListOptions())
        this.store.dispatch(new TenantActions.GetEnterpriseListOptions())
      })
  }

  init(isLogged) {
    if (isLogged) {
      this.store.dispatch(new ProjectActions.GetListOptions())
      this.store.dispatch(new LocationActions.GetListOptions())
      this.store.dispatch(new CompanyActions.GetListOptions())
      this.store.dispatch(new PositionActions.GetListOptions())
      this.store.dispatch(new SettingsActions.GetUserSettings())
      this.store.dispatch(new PermanentHolidayActions.GetTop5())
      this.store.dispatch(new TenantActions.GetListOptions())
      this.store.dispatch(new TenantActions.GetEnterpriseListOptions())
      this.store.dispatch(new DayTypeActions.GetAll())
      this.store.dispatch(new RequestStatusTypeActions.GetAll())
      this.store.dispatch(new LeaveTypeActions.GetListOptions())
      this.store.dispatch(new AppActions.GetModules())
      this.store.dispatch(new AuthActions.GetExternalLinksAction())
      this.store.dispatch(new UserActions.GetListOptions())
      this.store.dispatch(new UserActions.GetListOptionsAll())
      this.store.dispatch(new UserActions.GetListOptionsNoFiltered())
      this.store.dispatch(new UserActions.GetListOptionsWithEmails())

      if (!this.isCompanyAdmin) {
        this.store.dispatch(new SettingsActions.GetTimeSheetSettings())
      }

      this.store.select(selectors.getTenantModules)
        .first(_ => this.isAlive)
        .subscribe(modules => {
          if (modules.includes(AppModules.TimeSheet)) {
            this.store.dispatch(new WorkTimeTypeActions.GetListOptions())
            this.store.dispatch(new TimeSheetActions.GetWorkDays())
            this.store.dispatch(new TimeSheetActions.GetCurrentWorkDays())
            this.store.dispatch(new TimeSheetActions.GetEnteredHours())
          }
        })

      // if (this.pushServce.hubConnection) {
      //   this.pushServce.hubConnection.on('Message', (data: any) => {
      //     this.toastrService.notification(data)
      //   })
      // }
    } else {
      // if (this.pushServce.hubConnection) {
      //   this.pushServce.hubConnection.off('Message', (data: any) => { })
      // }
    }
  }
}

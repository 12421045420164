import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MsTeam } from 'app/core/models';
import { microsoftSelectors, MicrosoftState } from 'app/core/store/states/microsoft.states';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class MicrosoftStoreService {

    constructor(private store$: Store<MicrosoftState>) { }

    public get myTeams$(): Observable<MsTeam[]> {
        return this.store$.select(microsoftSelectors.getMyTeams)
    }
}


import { createFeatureSelector, createSelector } from '@ngrx/store'

import * as SmarterTable from '../../../smart-table/store/smarter-table.reducers'
import * as App from './app.states'
import * as AuthState from './auth.states'
import * as Day from './enums/days-types.states'
import * as RequestStatus from './enums/request-status-type.states'
import * as UserStatus from './enums/user-status-type.states'
import * as Language from './language.state'
import * as LeaveTypes from './leave-types.states'
import * as LoaderState from './loading-mask.states'
import * as Company from './noms/company.states'
import * as Location from './noms/location.states'
import * as Position from './noms/position.states'
import * as Project from './noms/project.states'
import * as PermanentHolidays from './permanent-holiday.states'
import * as Settings from './settings.states'
import * as TimeSheet from './time-sheet.states'
import * as Tenant from './tenant.states'
import * as User from './user.states'
import * as WorkTimeType from './work-time-type.states'
import * as Microsoft from './microsoft.states'

export interface State {
  auth: AuthState.AuthState,
  loader: LoaderState.LoadingState,
  smarterTable: SmarterTable.State,
  project: Project.ProjectState,
  workTimeType: WorkTimeType.WorkTimeTypeState,
  company: Company.CompanyState,
  location: Location.LocationState,
  position: Position.PositionState,
  settings: Settings.State,
  timeSheet: TimeSheet.State,
  permanentHoliday: PermanentHolidays.State,
  language: Language.LanguageState,
  tenant: Tenant.TenantState,
  user: User.State,
  daysType: Day.DayTypeState,
  requestStatus: RequestStatus.RequestStatusTypeState,
  userStatusTypes: UserStatus.UserStatusTypeState,
  leaveTypes: LeaveTypes.LeaveTypesState,
  app: App.AppState,
  microsoft: Microsoft.MicrosoftState,
}

export const getSmarterTableState = createFeatureSelector<SmarterTable.State>('smarterTable')
export const getSmarterTablePage = createSelector(getSmarterTableState, state => state.activePage)
export const getSmarterTableRowsCount = createSelector(getSmarterTableState, state => state.activePage.totalCount)
export const getSmarterTableFilter = createSelector(getSmarterTableState, state => state.filter)
export const getSmarterTableIsFiltering = createSelector(getSmarterTableState, state => state.isFiltering)

export const getTenantName = createSelector(AuthState.selectors.getTenantId, Tenant.tenantSelectors.getTenantListOptions, (tenantId, tenantList) => {
  if (tenantId && tenantList && tenantList.length > 0) {
    const currentTenant = tenantList.find(tl => tl.id === tenantId)
    if (currentTenant) {
      return currentTenant.name
    }
  }

  return ''
})


import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from '../common/api.service'

import { BaseModel, TSAdvanceReportSetting } from 'app/core/models'

@Injectable()

export class TimeSheetAdvanceReportSettingService {

    constructor(private httpClient: ApiService) {
    }

    getById(id): Observable<TSAdvanceReportSetting> {
        return this.httpClient.get('ts-report-setting/' + id)
    }

    save(model): Observable<any> {
        return this.httpClient.post('ts-report-setting/save', model)
    }

    delete(id: number, userId: number): Observable<any> {
        const params = `?userId=${userId}`
        return this.httpClient.delete('ts-report-setting/delete/' + id + params, null)
    }

    search(term: string, userId: number): Observable<BaseModel[]> {
        return this.httpClient.get(`ts-report-setting/search/${userId}?${term}`)
    }
}

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'


export const minDateFn = (minDate: any): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!isPresent(minDate)) { return null }
    if (isPresent(Validators.required(control))) { return null }
    if (control.value === {}) {
      return null
    }
    let v: any = new Date(control.value)

    v = Date.parse(v);
    const minDateValue = Date.parse(minDate)
    if (isNaN(v)) {
      return { dateInvalid: true }
    } else {
      return v >= minDateValue ? null : { actualValue: v, requiredValue: minDate, minDate: true };
    }
  }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from '../common/api.service'

import { VacationApproveInputModel, VacationApproveViewModel } from 'app/core/models'

@Injectable()

export class VacationApproveService {
  constructor(private httpClient: ApiService) {
  }

  getApproveModel(requestId: number): Observable<VacationApproveViewModel> {
    return this.httpClient.get(`request/${requestId}/approve-model`)
  }

  vacationApprove(model: VacationApproveInputModel[]): Observable<any> {
    return this.httpClient.post('request/vacation-approve', model)
  }
}

import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'


@Component({
  selector: 'f-checkbox',
  templateUrl: './f-checkbox.component.html',
  styleUrls: [
    './f-checkbox.component.scss',
  ],
  providers: [
    MakeValidatorProviders(FormCheckBoxComponent),
    MakeValueAccessorProviders(FormCheckBoxComponent)
  ]
})
export class FormCheckBoxComponent extends FormBaseComponent {
  @Input() isChecked = false
}

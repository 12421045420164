import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { HotkeyModule } from 'angular2-hotkeys'
import { CoreModule } from 'app/core'
import { SharedModule } from 'app/shared'
import { SmartTableModule } from 'app/smart-table/smart-table.module'
import { MultiselectDropdownModule } from 'ng2-multiselect'

import { SharedComponentsModule } from '../shared-components/shared-components.module'
import { AdvReportTSSettingsComponent } from './adv-report-settings/adv-report-settings.component'
import { MonthReportComponent } from './month-report/month-report.component'
import { TimeSheetSettingsComponent } from './settings/settings.component'
import { TimeSheetReportComponent } from './time-sheet-report/time-sheet-report.component'
import { TimeSheetRoutingModule } from './time-sheet.routing'
import { TimeSheetMgmtComponent } from './time-sheet/time-sheet-mgmt.component'
import { TimeSheetComponent } from './time-sheet/time-sheet.component'

import { DialogsModule } from 'app/dialogs/dialogs.module'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MultiselectDropdownModule,
    SmartTableModule,
    SharedModule,
    CoreModule,
    DialogsModule,
    TimeSheetRoutingModule,
    SharedComponentsModule,
    HotkeyModule,
  ],
  declarations: [
    TimeSheetComponent,
    TimeSheetReportComponent,
    TimeSheetMgmtComponent,
    TimeSheetSettingsComponent,
    AdvReportTSSettingsComponent,
    MonthReportComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [],
  entryComponents: [
    TimeSheetComponent,
    TimeSheetMgmtComponent,
    TimeSheetReportComponent
  ]

})
export class TimeSheetModule {
  constructor() { }
}

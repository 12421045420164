export class TimeSheet {
  id: number
  date: Date
  toDate: Date
  spentTime: number
  task: string
  description: string
  project: string
  projectId: number
  workTimeType: string
  workTimeTypeId: number
  vacationUserId: number
  vacationUser: string
}

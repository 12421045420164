import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AuthService, ToastrService, UserService } from 'app/core/services'
import * as CompanyActions from 'app/core/store/actions/noms/company.actions'
import { Observable } from 'rxjs'
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators'

import {
  ActionTypes,
  GetExternalLinksAction,
  GetExternalLinksFailedAction,
  GetExternalLinksSuccessAction,
  LoginAction,
  LoginFailedAction,
  LoginSuccessAction,
  SetTenantAction,
  SetTenantFailedAction,
  SetTenantSuccessAction,
} from '../actions/auth.actions'
import * as MicrosoftActions from '../actions/microsoft.actions'
import * as UserActions from 'app/core/store/actions/user.actions'

import { State } from '../states'
import { selectors } from '../states/auth.states'

@Injectable()
export class AuthEffects {

  @Effect() login$ = this.actions$.pipe(
    ofType<LoginAction>(ActionTypes.LOGIN),
    mergeMap(action => {
      return this.authService.login(action.payload).map(
        res => {
          if (res) {
            res.returnUrl = action.payload.returnUrl
            return new LoginSuccessAction(res)
          } else {
            return new LoginFailedAction(res)
          }
        },
        err => new LoginFailedAction(err)
      )
    })
  )

  @Effect() setTenant$ = this.actions$.pipe(
    ofType<SetTenantAction>(ActionTypes.SET_TENANT),
    mergeMap(action => {
      return this.userService.setTenant(action.payload).map(
        _ => {

          this.store.dispatch(new UserActions.GetListOptions())
          this.store.dispatch(new UserActions.GetListOptionsAll())

          return new SetTenantSuccessAction(action.payload)
        }, _ => new SetTenantFailedAction()
      )
    })
  )

  @Effect() setTenantSuccess$ = this.actions$.pipe(
    ofType<SetTenantSuccessAction>(ActionTypes.SET_TENANT_SUCCESS),
    mergeMap(action => {
      this.store.dispatch(new CompanyActions.GetListOptions())

      this.toastrService.success('messages.tenantSelectedSuccessfully', null, true)
      return Observable.empty()
    })
  )

  @Effect() getExternalLinks$ = this.actions$.pipe(
    ofType<GetExternalLinksAction>(ActionTypes.GET_EXTERNAL_LINKS),
    mergeMap(action => {
      return this.authService.getExternalAccountLinksWithEmails().map(
        links => new GetExternalLinksSuccessAction(links),
        _ => new GetExternalLinksFailedAction()
      )
    }),
  )

  @Effect({ dispatch: false }) getExternalLinksSuccess$ = this.actions$.pipe(
    ofType<GetExternalLinksSuccessAction>(ActionTypes.GET_EXTERNAL_LINKS_SUCCESS),
    withLatestFrom(this.store.select(selectors.hasMicrosoftAccount)),
    tap(([action, hasMicrosoftAccount]) => {
      if (hasMicrosoftAccount) {
        this.store.dispatch(new MicrosoftActions.GetMyTeams())
      }
    })
  )

  @Effect({ dispatch: false }) successLogin$ = this.actions$.pipe(
    ofType<LoginSuccessAction>(ActionTypes.LOGIN_SUCCESS),
    tap(action => this.router.navigate([action.payload.returnUrl]))
  )

  @Effect({ dispatch: false }) logActions$ = this.actions$.do
    (action => console.log(action))

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private toastrService: ToastrService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) { }
}

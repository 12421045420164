import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { Months, NotificationService, TranslationService } from 'app/core'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'
import * as moment from 'moment'

import { BaseModel } from 'app/core/models'
import { SelectOption } from 'app/core/models/common/select-option'
import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component';


@Component({
  templateUrl: './month-report.component.html',
})
export class MonthReportComponent extends BaseAuthComponent implements OnInit {
  filter: TableFilter = new TableFilter()

  months: any[] = []

  years: BaseModel[] = [
    { id: moment().year(), name: String(moment().year()) },
    { id: moment().year() - 1, name: String(moment().year() - 1) },
    { id: moment().year() - 2, name: String(moment().year() - 2) }
  ]

  constructor(
    private translateService: TranslationService,
    private notificationService: NotificationService,
    public store: Store<State>
  ) {
    super(store)
    this.months = Months.map(m => new SelectOption(m.id, this.translateService.translateMsg(m.name)))
  }

  ngOnInit() {
    this.filter.customFilter.month = moment().month() + 1
    this.filter.customFilter.year = moment().year()
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  sendNotification(id: number, type: number) {
    this.notificationService.sendNotification(id, type)
      .first()
      .subscribe()
  }

}

export class VacationRequestReport {
  id: number
  fromDate: Date
  toDate: Date
  description: string
  leaveType: string
  leaveTypeId: number
  status: string
  statusId: number
  userId: number
  userName: string
  totalDays: number
}

export class VacationRequestReportWithCount {
  count: number
  records: VacationRequestReport[]
}

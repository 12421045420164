import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { Roles } from 'app/core'
import { AuthGuard, RoleGuard } from 'app/core/guards'

import { AddVacationDaysComponent } from './add-vacation-days/add-vacation-days.component'
import { AdminMgmtComponent } from './admin/admin-mgmt.component'
import { AdminComponent } from './admin/admin.component'
import { CompensationComponent } from './compensations/compensation.component'
import { CompanyMgmtComponent } from './noms/company/company-mgmt.component'
import { LocationMgmtComponent } from './noms/location/location-mgmt.component'
import { PositionMgmtComponent } from './noms/position/position-mgmt.component'
import { ProjectMgmtComponent } from './noms/project/project-mgmt.component'
import { TeanantMgmtComponent } from './noms/tenant/tenant-mgmt.component'
import { ProjectToTeamLeadComponent } from './project-to-teamlead/project-to-teamlead.component'
import { UserMgmtComponent } from './user/users/user-mgmt.component'
import { UserComponent } from './user/users/user.component'
import { WorkScheduleChangeMgmtComponent } from './work-schedule-change/work-schedule-change-mgmt.component'

const administrationRoutes: Routes = [
  {
    path: 'users',
    component: UserMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/:id',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admins',
    component: AdminMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'admin/:id',
    component: AdminComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'change-day',
    component: WorkScheduleChangeMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.globalAdminRole] }
  }, {
    path: 'projects',
    component: ProjectMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'positions',
    component: PositionMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'locations',
    component: LocationMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'add-vacation-days',
    component: AddVacationDaysComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'project-to-team-lead',
    component: ProjectToTeamLeadComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'companies',
    component: CompanyMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'tenants',
    component: TeanantMgmtComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }, {
    path: 'compensation',
    component: CompensationComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(administrationRoutes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }

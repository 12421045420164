import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { UserEventNotificationModel } from '../models/user/user-event-notification.model';
import { ApiService } from './common/api.service';

@Injectable()
export class UserEventNotificationService {
    constructor(private httpClient: ApiService) { }

    save(userEventNotification: UserEventNotificationModel[]) {
        return this.httpClient.post('user-event-notification/save', userEventNotification)
    }

    getSettings(): Observable<UserEventNotificationModel[]> {
        return this.httpClient.get('user-event-notification', true)
    }
}
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { HttpObjectService } from '../common'
import { ApiService } from '../common/api.service'

import {
  BaseModel,
  CalendarEventView,
  GetDefaultSettingDateViewModel,
  UserAddDays,
  VacationRequest,
  VacationRequestInput,
  VacationRequestReport,
} from 'app/core/models'

@Injectable()

export class VacationRequestService extends HttpObjectService {
  object = 'request'

  constructor(private httpClient: ApiService) {
    super(httpClient)
  }

  getAllRequests(): Observable<VacationRequest[]> {
    return this.httpClient.get(this.object)
  }

  getCompensationDaysByUserId(userId: number, leaveTypeId: number): Observable<number> {
    return this.httpClient.get(`${this.object}/compensation-days/${userId}/${leaveTypeId}`)
  }

  getDefaultDays(): Observable<GetDefaultSettingDateViewModel> {
    return this.httpClient.get(`tenant-settings/vacation-days`)
  }

  getDayTypes(): Observable<BaseModel[]> {
    return this.httpClient.get(`${this.object}/day-types`, true, true)
  }

  addDays(addDays: UserAddDays[]): Observable<void> {
    return this.httpClient.post(`users/add-days/`, addDays)
  }

  getTeamRequests(teamLeadId: number): Observable<VacationRequest[]> {
    return this.httpClient.get(`${this.object}/team-request/` + teamLeadId)
  }

  exportReportsByPeriod(model): Observable<any> {
    return this.httpClient.postExport(`export/vacation-request`, model)
  }

  getAllRequestsPageByUserId(id: number, page: number, itemsPerPage: number): Observable<VacationRequest[]> {
    const params = `?page=${page}&itemsPerPage=${itemsPerPage}`

    return this.httpClient.get(`${this.object}/user/` + id + params)
  }

  print(id: number) {
    return this.httpClient.getFile(`${this.object}/print/` + id)
  }

  getListOptions(): Observable<BaseModel[]> {
    return this.httpClient.get(`${this.object}/leave-types`)
  }

  getReport(model): Observable<VacationRequestReport[]> {
    return this.httpClient.post(`${this.object}/report`, model)
  }

  getAllStatus(): Observable<BaseModel[]> {
    return this.httpClient.get(`${this.object}/status-types`, true, true)
  }

  addRequest(request: VacationRequestInput): Observable<any> {
    return this.httpClient.post(`${this.object}/add-request`, request)
  }

  cancelRequest(requestId: number, userId: number): Observable<number> {
    return this.httpClient.get(`${this.object}/cancel/${requestId}/${userId}`)
  }

  getCalendarEvents(year: number, month: number): Observable<CalendarEventView[]> {
    return this.httpClient.get(`${this.object}/calendar-events/${year}/${month}`)
  }

  getBusinessDaysCount(fromDate, toDate) {
    const params = `?fromDate=${fromDate}&toDate=${toDate}`
    return this.httpClient.get(`${this.object}/business-days-count` + params)
  }
}

import * as TimeSheetActions from 'app/core/store/actions/time-sheet.actions'

import { initialState } from '../states/time-sheet.states'


export type Action = TimeSheetActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case TimeSheetActions.GET_WORKDAYS_SUCCESS: {
            return {
                ...state,
                workDays: action.payload
            }
        }
        case TimeSheetActions.GET_CURRENT_WORK_DAYS_SUCCESS: {
            return {
                ...state,
                currentWorkDays: action.payload
            }
        }
        case TimeSheetActions.GET_ENTERED_HOURS_SUCCESS: {
            return {
                ...state,
                enteredHours: action.payload
            }
        }

        default: return state
    }
}

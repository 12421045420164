import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { LocationComponent } from './location.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { LocationService } from 'app/core/services'

import * as LocationActions from 'app/core/store/actions/noms/location.actions'
import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'
import { LocationModel } from 'app/core/models/noms/location'


@Component({
  templateUrl: './location-mgmt.component.html'
})
export class LocationMgmtComponent extends BaseManagementComponent {

  constructor(
    public store: Store<State>,
    private modal: ModalService,
    private locationService: LocationService) {
    super(store)
  }

  public add() {
    const item: LocationModel = { id: 0, tenantId: '', name: '', isActive: true }
    this.openModal(item)
  }

  public edit(item) {
    this.openModal(item)
  }

  openModal(item) {
    this.modal.open(LocationComponent, ModalSizeTypes.Small, { entity: item })
      .subscribe(result => {
        if (result.isSubmit) {
          this.locationService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
            this.store.dispatch(new LocationActions.GetListOptions())
          })
        }
      })
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { LoadingHttpParams } from 'app/core/helpers/common/loading-http-params'
import { LoadAction, LoadDoneAction } from 'app/core/store/actions/loading-mask.actions'
import { LoadingState } from 'app/core/store/states/loading-mask.states'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

@Injectable()
export class LoadingInterceptorService implements HttpInterceptor {
    constructor(private store: Store<LoadingState>) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isBackground: boolean = req.params instanceof LoadingHttpParams && req.params.isBackground

        if (!isBackground) {
            this.store.dispatch(new LoadAction())
        }

        return next.handle(req).pipe(
            finalize(() => {
                if (!isBackground) {
                    this.store.dispatch(new LoadDoneAction())
                }
            })
        )
    }
}

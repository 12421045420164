import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Operation } from 'app/core/enums/common/operation.enum'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import {
  NomsStoreService,
  SettingsStoreService,
  TimeSheetService,
  TimeSheetStoreService,
  TranslationService,
} from 'app/core/services'

import { BaseActiveModel, BaseModel, TimeSheet } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  selector: 'time-sheet',
  templateUrl: './time-sheet.component.html'
})

export class TimeSheetComponent extends BaseAuthComponent implements OnInit {

  entity: TimeSheet = new TimeSheet()

  users$: Observable<BaseActiveModel[]>
  projectOptions$: Observable<BaseModel[]>
  workTimeTypeOptions$: Observable<BaseModel[]>

  minDate = new Date()
  isSeries = false
  fromDate: string
  date: string
  isTeams: boolean

  operation: Operation
  closeAfterNew: boolean

  divisibleBy = 0.5

  constructor(
    store: Store<State>,
    private translationService: TranslationService,
    private timeSheetService: TimeSheetService,
    private timeSheetStoreService: TimeSheetStoreService,
    private settingsStoreService: SettingsStoreService,
    private nomsStoreService: NomsStoreService) {
    super(store)
  }

  ngOnInit() {

    this.workTimeTypeOptions$ = this.nomsStoreService.workTimeTypes$
      .takeWhile(_ => this.isAlive)

    this.projectOptions$ = this.nomsStoreService.projectListOptions$
      .takeWhile(_ => this.isAlive)

    this.settingsStoreService.getTimesheetSettings$
      .takeWhile(_ => this.isAlive)
      .subscribe(data => {
        this.closeAfterNew = data.closeAfterNew
      })

    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)

    this.fromDate = this.translationService.translateMsg('general.fromDate')
    this.date = this.translationService.translateMsg('general.date')

    if (this.operation === Operation.Insert) {
      this.prepareRecord(this.initValues(this.entity))
    }

    if (this.operation === Operation.Copy) {
      this.prepareRecord(this.entity)
    }
  }

  onSubmit(dialog: NgbActiveModal) {
    this.timeSheetService.save(this.entity)
      .first()
      .subscribe(_ => {
        this.refreshData()

        if (this.operation === Operation.Insert && !this.closeAfterNew) {
          this.prepareRecord(this.initValues(new TimeSheet()))
        } else {
          dialog.close()
        }
      })
  }

  refreshData() {
    this.store.dispatch(new FilterAction())
    if (!this.isCompanyAdmin && !this.isTeams) {
      this.timeSheetStoreService.resetMonthlyEnteredHours()
    }
  }

  prepareRecord(item: TimeSheet) {
    this.timeSheetService.prepareRecord(item, this.workHoursPerDay, this.operation === Operation.Insert)
      .first()
      .subscribe((result) => {
        if (result.projectId === 0) {
          result.projectId = undefined
        }
        this.entity = Object.assign({}, result)
      })
  }

  initValues(item: TimeSheet): TimeSheet {
    item.vacationUserId = item.vacationUserId || this.userId
    item.date = item.date || new Date()
    item.workTimeTypeId = 1

    return item
  }
}

import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { AppModules, NotificationEvent, NotificationType } from 'app/core'
import { enumToArray } from 'app/core/utils/map'

import { AuthStoreService, ToastrService, TranslationService } from 'app/core/services'
import { UserEventNotificationService } from 'app/core/services/user-event-notification.service'

import { BaseModel } from 'app/core/models'
import { UserEventNotificationModel } from 'app/core/models/user/user-event-notification.model'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
    templateUrl: './user-event-notification.component.html'
})

export class UserEventNotificationComponent extends BaseAuthComponent implements OnInit {
    entity: any = {}

    notificationTypes: BaseModel[] = enumToArray(NotificationType, this.translationService)
    notificationEvents: BaseModel[] = enumToArray(NotificationEvent, null)
    
    testsModuleAvailable = false

    constructor(
        public store: Store<State>,
        private translationService: TranslationService,
        private toastrService: ToastrService,
        private authStoreService: AuthStoreService,
        private userEventNotificationService: UserEventNotificationService
    ) {
        super(store)

        this.authStoreService.tenantModules$
            .takeWhile(_ => this.isAlive)
            .subscribe(tm => {
                this.testsModuleAvailable = tm.some(m => m === AppModules.Tests)
            })
    }

    ngOnInit() {
        this.userEventNotificationService.getSettings()
            .first()
            .subscribe(data => this.init(data))
    }

    init(data: UserEventNotificationModel[]) {
        Object.keys(NotificationEvent)
            .filter((item) => isNaN(Number(item)) && item !== 'null')
            .forEach(k => {
                const propName = k.charAt(0).toLowerCase() + k.slice(1)
                const property = data.find(d => d.type === NotificationEvent[k])

                if (property) {
                    this.entity[propName] = property.value
                } else {
                    this.entity[propName] = NotificationType.Email
                }
            })
    }

    save() {
        const keys = Object.keys(this.entity)

        const userEventNotification = keys.map(k => {
            const propName = k.charAt(0).toUpperCase() + k.slice(1)
            return { type: propName, value: this.entity[k] }
        })

        this.userEventNotificationService.save(userEventNotification)
            .first()
            .subscribe(() => this.toastrService.success('messages.settingsSuccessfullySaved', null, true))
    }
}

import { Action } from '@ngrx/store'
import { TimeSheetSetting, UserSettings } from 'app/core/models'

import { type } from '../../util'


export const GET_USER_SETTINGS = type('[USER_SETTINGS] GET')
export const GET_USER_SETTINGS_SUCCESS = type('[USER_SETTINGS] GET_SUCCESS')
export const GET_USER_SETTINGS_ERROR = type('[USER_SETTINGS] GET_ERROR')

export const SAVE_USER_SETTINGS = type('[USER_SETTINGS] SAVE')
export const SAVE_USER_SETTINGS_SUCCESS = type('[USER_SETTINGS] SAVE_SUCCESS')
export const SAVE_USER_SETTINGS_ERROR = type('[USER_SETTINGS] SAVE_ERROR')

export const GET_TIMESHEET_SETTINGS = type('[TIMESHEET_SETTINGS] GET')
export const GET_TIMESHEET_SETTINGS_SUCCESS = type('[TIMESHEET_SETTINGS] GET_SUCCESS')
export const GET_TIMESHEET_SETTINGS_ERROR = type('[TIMESHEET_SETTINGS] GET_ERROR')

export const SAVE_TIMESHEET_SETTINGS = type('[TIMESHEET_SETTINGS] SAVE')
export const SAVE_TIMESHEET_SETTINGS_SUCCESS = type('[TIMESHEET_SETTINGS] SAVE_SUCCESS')
export const SAVE_TIMESHEET_SETTINGS_ERROR = type('[TIMESHEET_SETTINGS] SAVE_ERROR')


export class GetUserSettings implements Action {
    readonly type = GET_USER_SETTINGS
}

export class GetUserSettingsSuccess implements Action {
    readonly type = GET_USER_SETTINGS_SUCCESS

    constructor(public payload: UserSettings) { }
}

export class GetUserSettingsError implements Action {
    readonly type = GET_USER_SETTINGS_ERROR
}

export class SaveUserSettings implements Action {
    readonly type = SAVE_USER_SETTINGS

    constructor(public payload: UserSettings) { }
}

export class SaveUserSettingsSuccess implements Action {
    readonly type = SAVE_USER_SETTINGS_SUCCESS
}

export class SaveUserSettingsError implements Action {
    readonly type = SAVE_USER_SETTINGS_ERROR
}

export class GetTimeSheetSettings implements Action {
    readonly type = GET_TIMESHEET_SETTINGS
}

export class GetTimeSheetSettingsSuccess implements Action {
    readonly type = GET_TIMESHEET_SETTINGS_SUCCESS

    constructor(public payload: TimeSheetSetting) { }
}

export class GetTimeSheetSettingsError implements Action {
    readonly type = GET_TIMESHEET_SETTINGS_ERROR
}

export class SaveTimeSheetSettings implements Action {
    readonly type = SAVE_TIMESHEET_SETTINGS

    constructor(public payload: TimeSheetSetting) { }
}

export class SaveTimeSheetSettingsSuccess implements Action {
    readonly type = SAVE_TIMESHEET_SETTINGS_SUCCESS
}

export class SaveTimeSheetSettingsError implements Action {
    readonly type = SAVE_TIMESHEET_SETTINGS_ERROR
}


export type All =
    GetUserSettings | GetUserSettingsSuccess | GetUserSettingsError
    | SaveUserSettings | SaveUserSettingsSuccess | SaveUserSettingsError
    | GetTimeSheetSettings | GetTimeSheetSettingsSuccess | GetTimeSheetSettingsError
    | SaveTimeSheetSettings | SaveTimeSheetSettingsSuccess | SaveTimeSheetSettingsError

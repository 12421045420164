import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

import { LocationService } from 'app/core/services'

import { BaseModel, RoleWithUsersSimpleInfo, UserSimpleInfo } from 'app/core/models'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  selector: 'multi-select-user-list',
  templateUrl: './multi-select-user-list.html'
})
export class MultiSelectUserListComponent extends BaseComponent implements OnInit, OnChanges {
  _allUsersSimpleInfo: UserSimpleInfo[] = []
  get allUsersSimpleInfo(): UserSimpleInfo[] {
    return this._allUsersSimpleInfo
  }

  @Input('allUsersSimpleInfo')
  set allUsersSimpleInfo(value: UserSimpleInfo[]) {
    this._allUsersSimpleInfo = value.map(v => {
      return Object.assign({}, v)
    });

    this._addUsersToRole(0)
  }

  @Output() allSelectedUsers: EventEmitter<number[]> = new EventEmitter<number[]>()

  locations: BaseModel[] = []
  allUsersSelected = false;
  usersByRole: RoleWithUsersSimpleInfo[] = [];

  constructor(private locationService: LocationService, private modalService: ModalService) {
    super()
  }

  ngOnInit() {
    this.getLocations()
  }

  ngOnChanges() {
  }

  private _addUsersToRole(location) {
    this.usersByRole = []

    this._allUsersSimpleInfo.forEach(user => {
      if (Number(location) === 0 || user.locationId === Number(location)) {
        if (!this.usersByRole.some(r => r.role === user.role)) {
          this.usersByRole.push(new RoleWithUsersSimpleInfo(user.role, false))
        }

        const userRole = this.usersByRole.find(role => role.role === user.role)
        userRole.users.push(user)
      }
    })

    this.usersByRole.forEach(r => r.isChecked = r.users.every(u => u.hasBeenMarked))
    this.allUsersSelected = this.usersByRole.every(r => r.isChecked)
  }

  private _getCheckedUsers() {
    return this.usersByRole.reduce((r, a) => r.concat(a.users.filter(u => u.hasBeenMarked).map(u => u.id)), [])
  }

  getLocations() {
    this.locationService.getListOptions()
      .first()
      .subscribe(
        l => this.locations = l,
        error => this.modalService.info(ModalTypes.Alert, error))

  }

  changeAllByRole(role) {
    role.isChecked = !role.isChecked

    role.users.forEach(user => {
      user.hasBeenMarked = role.isChecked
    })

    // if(!role.isChecked){

    this.allUsersSelected = this.usersByRole.every(r => r.isChecked)

    // }
    this.allSelectedUsers.next(this._getCheckedUsers())
  }

  changeLocation(location) {
    this._addUsersToRole(location)

    this.allSelectedUsers.next(this._getCheckedUsers())
  }

  selectAllUsers() {
    this.allUsersSelected = !this.allUsersSelected;

    const tempAllUsersSelected = this.allUsersSelected;
    this.usersByRole.forEach(r => {
      if (r.isChecked !== tempAllUsersSelected) {
        this.changeAllByRole(r)
      }
    })

    this.allSelectedUsers.next(this._getCheckedUsers())
  }

  onSelectUser(role, user) {
    user.hasBeenMarked = !user.hasBeenMarked

    if (!user.hasBeenMarked) {
      role.isChecked = false
    } else if (role.users.every(u => u.hasBeenMarked)) {
      role.isChecked = true
    }

    this.allUsersSelected = this.usersByRole.every(r => r.isChecked)

    this.allSelectedUsers.next(this._getCheckedUsers())
  }
}

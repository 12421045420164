export class RecomBook {
  id: number
  recordDate: Date
  title: string
  text: string
  userId?: number
  userName?: string
  recommendationType: string
  recommendationTypeId: number 
  isActive: boolean = false
  isAnswered: boolean = false
}
export class RecomBookWithCount {
  count: number;
  records: RecomBook[]
}
import { Component, OnInit } from '@angular/core'

import { Resources } from 'app/core/constants'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

import { UserService } from 'app/core/services'
import { ProjectService } from 'app/core/services/noms/project.service'

import { BaseModel } from 'app/core/models'
import { ProjectByTeamLeadView } from 'app/core/models/project-teamlead-view'
import { TeamLeadProjectInput } from 'app/core/models/teamlead-project-input'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  selector: 'project-to-teamlead',
  templateUrl: './project-to-teamlead.component.html'
})

export class ProjectToTeamLeadComponent extends BaseComponent implements OnInit {

  teamLeads: BaseModel[] = []
  subordinates: BaseModel[] = []
  activeProjects: BaseModel[] = []
  teamLeadProjectInput: TeamLeadProjectInput = new TeamLeadProjectInput()
  projectsByTLAndSubordinate: ProjectByTeamLeadView[] = []
  allSelected: boolean
  oldSelectedProjectsIds: number[] = []
  test: boolean = false

  constructor(private service: ProjectService,
    private modalService: ModalService,
    private userService: UserService) {
    super()
  }

  ngOnInit() {
    this.getActiveProjects()
    this.getTeamLeads()
  }

  getActiveProjects() {
    this.service.getListOptions()
      .first()
      .subscribe(
        p => this.activeProjects = p,
        error => this.modalService.info(ModalTypes.Alert, error),
        () => {
          this.allSelected = true
        }
      )
  }

  getTeamLeads() {
    this.userService.getAllCurrentTeamLeads()
      .first()
      .subscribe(
        tl => tl.forEach(el => {
          this.teamLeads = [...this.teamLeads, el]
        }),
        error => this.modalService.info(ModalTypes.Alert, error)
      )
  }

  getSubordinates(event) {
    this.userService.getAllSubordinatesNames(event.target.value)
      .first()
      .subscribe(
        s => this.subordinates = s,
        error => this.modalService.info(ModalTypes.Alert, error),
        () => {
          let subordinatesIds = this.subordinates.map(s => s.id)
          let isThereSuchSubordinate = subordinatesIds.find(id => id === this.teamLeadProjectInput.userId) !== 0
            && subordinatesIds.find(id => id === this.teamLeadProjectInput.userId) !== undefined

          if (!isThereSuchSubordinate) {
            this.allSelected = true
            this.teamLeadProjectInput.userId = 0
          }

          if (this.teamLeadProjectInput.userId !== 0) {
            this.getProjectsByTlAndSubordinate()
          }
        }
      )
  }

  updateProjectToTeamLead() {
    this.oldSelectedProjectsIds = []
    let chosenIds = undefined

    if (this.allSelected) {
      chosenIds = []
    } else {
      chosenIds = this.projectsByTLAndSubordinate.filter(p => p.hasBeenChosen === true).map(p => p.id)
    }

    this.teamLeadProjectInput.projectsIds = chosenIds

    this.service.updateProjectToTeamLead(this.teamLeadProjectInput)
      .first()
      .subscribe(
        p => {
          this.modalService.info(ModalTypes.Success, Resources.successUpdated)
        },
        error => this.modalService.info(ModalTypes.Alert, error)
      )
  }

  getProjectsByChosenCriteria(event) {
    this.teamLeadProjectInput.userId = event.target.value
    if (this.teamLeadProjectInput.userId === 0) {
      this.getActiveProjects()
    }
    this.getProjectsByTlAndSubordinate()
  }

  selectAllProjects(event) {
    this.allSelected = !this.allSelected

    if (this.allSelected === true) {
      this.oldSelectedProjectsIds = this.projectsByTLAndSubordinate.filter(p => p.hasBeenChosen === true).map(p => p.id)
      this.projectsByTLAndSubordinate.map(p => p.hasBeenChosen = false)
    } else {
      this.oldSelectedProjectsIds.forEach(id => {
        this.projectsByTLAndSubordinate.filter(p => p.id === id).map(p => p.hasBeenChosen = true)
      })
      this.oldSelectedProjectsIds = []
    }
  }

  getProjectsByTlAndSubordinate() {
    this.projectsByTLAndSubordinate = []
    this.allSelected = false

    this.service.getAllProjectsByTeamLead(this.teamLeadProjectInput)
      .first()
      .subscribe(
        p => p.forEach(
          el => {
            this.projectsByTLAndSubordinate = [...this.projectsByTLAndSubordinate, el]
          }),
        error => this.modalService.info(ModalTypes.Alert, error),
        () => {
          if (this.projectsByTLAndSubordinate.every(p => p.hasBeenChosen === true)) {
            this.projectsByTLAndSubordinate.map(p => p.hasBeenChosen = false)
            this.allSelected = true
          }
        }
      )
  }
}

import { Action } from '@ngrx/store'

import { type } from '../../util'

import { UserTokens } from 'app/core/models/auth/user-tokens'

export const ActionTypes = {
  LOGIN: type('[Auth] Login'),
  LOGIN_SUCCESS: type('[Auth] Login Success'),
  LOGIN_FAILED: type('[Auth] Login Failed'),
  LOGOUT: type('[Auth] Logout'),

  REFRESH_SUCCESS: type('[AUTH] Refresh Success'),

  SET_IS_LOGGEDIN: type('[Auth] Set isLogin'),
  SET_READ_HANDBOOK: type('[Auth] Set readHandbook'),

  SET_STATUS: type('[AUTH] Set Status'),

  SET_TENANT: type('[AUTH] Set Tenant'),
  SET_TENANT_SUCCESS: type('[AUTH] Set Tenant Success'),
  SET_TENANT_FAILED: type('[AUTH] Set Tenant Failed'),

  GET_EXTERNAL_LINKS: type('[AUTH] Get External Links'),
  GET_EXTERNAL_LINKS_SUCCESS: type('[AUTH] Get External Links Success'),
  GET_EXTERNAL_LINKS_FAILED: type('[AUTH] Get External Links Failed'),

  LOADED_GUEST_MODULE: type('[AUTH] Loaded Guest Module'),
};

export class LoginAction implements Action {
  type = ActionTypes.LOGIN

  constructor(public payload: any) { }
}

export class LoginSuccessAction implements Action {
  type = ActionTypes.LOGIN_SUCCESS

  constructor(public payload: UserTokens) { }
}

export class LoginFailedAction implements Action {
  type = ActionTypes.LOGIN_FAILED;

  constructor(public payload: string) { }
}

export class RefreshSuccessAction implements Action {
  type = ActionTypes.REFRESH_SUCCESS

  constructor(public payload: UserTokens) { }
}

export class LogoutAction implements Action {
  type = ActionTypes.LOGOUT;

  constructor(public payload = '') { }
}

export class SetIsLoggedInAction implements Action {
  type = ActionTypes.SET_IS_LOGGEDIN;

  constructor(public payload: boolean) { }
}

export class SetreadHandbookAction implements Action {
  type = ActionTypes.SET_READ_HANDBOOK;

  constructor(public payload: boolean) { }
}

export class SetStatusAction implements Action {
  type = ActionTypes.SET_STATUS;

  constructor(public payload: number) { }
}

export class SetTenantAction implements Action {
  type = ActionTypes.SET_TENANT;

  constructor(public payload: string) { }
}

export class SetTenantSuccessAction implements Action {
  type = ActionTypes.SET_TENANT_SUCCESS;

  constructor(public payload: string) { }
}

export class SetTenantFailedAction implements Action {
  type = ActionTypes.SET_TENANT_FAILED;
}

export class GetExternalLinksAction implements Action {
  type = ActionTypes.GET_EXTERNAL_LINKS;
}

export class GetExternalLinksSuccessAction implements Action {
  type = ActionTypes.GET_EXTERNAL_LINKS_SUCCESS;

  constructor(public payload: string[]) { }
}

export class GetExternalLinksFailedAction implements Action {
  type = ActionTypes.GET_EXTERNAL_LINKS_FAILED;
}

export class LoadedGuestModule implements Action {
  type = ActionTypes.LOADED_GUEST_MODULE;
}

export type Actions
  = LoginAction
  | LoginSuccessAction
  | LoginFailedAction
  | LogoutAction
  | SetIsLoggedInAction
  | SetreadHandbookAction
  | RefreshSuccessAction
  | SetStatusAction
  | SetTenantAction
  | SetTenantSuccessAction
  | SetTenantFailedAction
  | GetExternalLinksAction
  | GetExternalLinksSuccessAction
  | GetExternalLinksFailedAction
  | LoadedGuestModule

import { Action } from '@ngrx/store'

import { type } from '../../util'

export const SET_LANGUAGE = type('[LANGUAGE] SET_LANGUAGE')

export class SetLanguage implements Action {
    readonly type = SET_LANGUAGE

    constructor(public payload: string) { }
}

export type All = SetLanguage

import 'rxjs/add/operator/let'
import 'rxjs/add/operator/map'

import { JwtHelperService } from '@auth0/angular-jwt'
import { Roles } from 'app/core/constants'
import { hasRole } from 'app/core/helpers/common/auth-helper'

import * as auth from '../actions/auth.actions'
import { AuthState, initialState } from '../states/auth.states'


export function reducer(state = initialState, action: auth.Actions): AuthState {
  switch (action.type) {
    case auth.ActionTypes.LOGIN_SUCCESS: {
      // todo: show token expiratin time
      return Object.assign({}, state, getNewAuthData(action.payload))
    }

    case auth.ActionTypes.REFRESH_SUCCESS: {
      // todo: show token expiratin time
      return Object.assign({}, state, getNewAuthData(action.payload))
    }

    case auth.ActionTypes.SET_IS_LOGGEDIN: {
      return Object.assign({}, state, {
        loggedIn: action.payload
      })
    }

    case auth.ActionTypes.SET_READ_HANDBOOK: {
      return Object.assign({}, state, {
        readHandbook: action.payload
      })
    }

    case auth.ActionTypes.SET_STATUS: {
      return Object.assign({}, state, {
        statusId: action.payload
      })
    }

    case auth.ActionTypes.SET_TENANT_SUCCESS: {
      return Object.assign({}, state, {
        tenantId: action.payload
      })
    }

    case auth.ActionTypes.GET_EXTERNAL_LINKS_SUCCESS: {
      return Object.assign({}, state, {
        externalLinks: action.payload || []
      })
    }

    case auth.ActionTypes.LOGIN_FAILED: {
      return Object.assign({}, state, {
        error: action.payload
      })
    }

    case auth.ActionTypes.LOADED_GUEST_MODULE: {
      // todo: show token expiratin time
      return Object.assign({}, state, {
        loadedGuestModule: true
      })
    }

    default: {
      return state
    }
  }
}

function getNewAuthData(tokens) {
  const jwtHelper = new JwtHelperService()
  const authData = jwtHelper.decodeToken(tokens.accessToken)
  const expirationTime = new Date(0)
  expirationTime.setUTCSeconds(authData.exp)
  // todo: show token expiratin time
  return {
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    userName: authData.userName,
    userFullName: authData.userFullName,
    userId: parseInt(authData.id, 10),
    role: authData.role,
    readHandbook: authData.readHandBook === 'True',
    workHoursPerDay: authData.workHoursPerDay,
    guid: authData.guid,
    tenantId: authData.tenant,
    isSupressSmtpWarning: authData.isSupressSmtpWarning === 'True',
    exp: expirationTime,
    isGlobalAdmin: hasRole(authData.role, Roles.globalAdminRole),
    isTenantAdmin: hasRole(authData.role, Roles.tenantAdminRole),
    isCompanyAdmin: hasRole(authData.role, Roles.companyAdminRole),
    isTL: hasRole(authData.role, Roles.teamLeadRole),
    isGuest: hasRole(authData.role, Roles.ticketGuest),
    loggedIn: true,
    statusId: authData.statusId,
    moduleAdmins: Array.isArray(authData.ModuleAdmin) ? [...authData.ModuleAdmin] : [authData.ModuleAdmin],
    tenantModules: Array.isArray(authData.TenantModules) ? [...authData.TenantModules] : [authData.TenantModules]
  }
}

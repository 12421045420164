import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { BaseModel } from 'app/core/models'
import { VacationRequestService } from 'app/core/services'
import { State } from 'app/core/store/states'
import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import * as LeaveTypesActions from '../actions/leave-types.actions'


@Injectable()
export class LeaveTypeEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private vacationRequestService: VacationRequestService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<LeaveTypesActions.GetListOptions>(LeaveTypesActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.vacationRequestService.getListOptions().map(
                (leaveTypes: BaseModel[]) => {
                    return new LeaveTypesActions.GetListOptionsSuccess
                    (leaveTypes)
                }, _ => of(new LeaveTypesActions.GetListOptionsError()))  
        })
    )
}
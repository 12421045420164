import { CalendarEventType } from '../enums/calendar-event-type.enum'
import { CalendarColors } from './calendar-colors'

export const CalendarLegend = {
    vacation: {
        type: CalendarEventType.Vacation,
        icon: 'fas fa-plane-departure',
        title: 'general.vacation',
        color: CalendarColors.orange.primary,
        checked: true
    },
    sickLeave: {
        type: CalendarEventType.SickLeave,
        icon: 'fas fa-thermometer',
        title: 'general.sickLeave',
        color: CalendarColors.darkRed.primary,
        checked: true
    },
    homeOffice: {
        type: CalendarEventType.HomeOffice,
        icon: 'fas fa-couch',
        title: 'general.homeOffice',
        color: CalendarColors.darkGreen.primary,
        checked: true
    },
    birthDay: {
        type: CalendarEventType.BirthDate,
        icon: 'fas fa-birthday-cake',
        title: 'general.birthDay',
        color: CalendarColors.purpul.primary,
        checked: true
    },
    workAnniversary: {
        type: CalendarEventType.EmploymentDate,
        icon: 'fas fa-briefcase',
        title: 'general.employmentDate',
        color: CalendarColors.darkBlue.primary,
        checked: true
    },
    note: {
        type: CalendarEventType.Note,
        icon: 'fas fa-clipboard',
        title: 'general.note',
        color: CalendarColors.gray.primary,
        checked: true
    },
    task: {
        type: CalendarEventType.Task,
        icon: 'fas fa-tasks',
        title: 'general.task',
        color: CalendarColors.gray.primary,
        checked: true
    },
    appointment: {
        type: CalendarEventType.Appointment,
        icon: 'fas fa-calendar-week',
        title: 'general.appointment',
        color: CalendarColors.gray.primary,
        checked: true
    },
    meeting: {
        type: CalendarEventType.Meeting,
        icon: 'fas fa-handshake',
        title: 'general.meeting',
        color: CalendarColors.gray.primary,
        checked: true
    },
    event: {
        type: CalendarEventType.Event,
        icon: 'fas fa-calendar-check',
        title: 'general.event',
        color: CalendarColors.gray.primary,
        checked: true
    },
}


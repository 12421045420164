import { Component } from '@angular/core'

import { ApiService } from 'app/core/services/common/api.service'

import { User } from 'app/core/models'

@Component({
  selector: 'home',
  template: `
    <mwl-demo-app></mwl-demo-app>`,
  styleUrls: ['home-styles.scss']
})

export class HomeComponent {
  currentUser: User
  users: User[] = []
  values: string[] = []

  constructor(private ApiService: ApiService) {
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface LeaveTypesState {
    listOptions: BaseModel[]
}

export const initialState: LeaveTypesState = {
    listOptions: []
}

export namespace leaveTypesSelector {
    export const getLeaveTypesState = createFeatureSelector<LeaveTypesState>('leaveTypes')
    export const getLeaveTypesAll = createSelector(getLeaveTypesState, state => state.listOptions)
}

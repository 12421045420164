import { initialState } from '../../states/enums/request-status-type.states'

import * as RequestStatusTypeActions from 'app/core/store/actions/enums/request-status-type.actions'


export type Action = RequestStatusTypeActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case RequestStatusTypeActions.GET_ALL_SUCCESS: {
            return {
                ...state,
                listOptions: action.payload
            }
        }
        default: return state
    }
}

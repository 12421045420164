import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { Observable } from 'rxjs/Observable'
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'

import { SmartTableService } from '../services/smart-table.service'
import {
  ActionTypes,
  ChangeFilterAction,
  ChangeIsActiveAction,
  DeleteAction,
  DeleteFailAction,
  FilterAction,
  FilterFailAction,
  FilteringAction,
  FilterSuccesAction,
  SaveAction,
  SaveSuccessAction,
  UpdateAction,
  UpdateSuccessAction,
} from './smarter-table.actions'

import { State } from 'app/core/store/states'

@Injectable()
export class SmarterTableEffects {

  @Effect() changeFilterAction$ = this.actions$.pipe(
    ofType<ChangeFilterAction>(ActionTypes.CHANGE_FILTER),
    map(action => action.payload),
    map(action => new FilterAction())
  )

  @Effect() filter$ = this.actions$.pipe(
    ofType<FilterAction>(ActionTypes.FILTER),
    withLatestFrom(this.store.select(s => s.smarterTable)),
    switchMap(([action, st]) => {
      let observable: Observable<any> = Observable.empty()

      if (st.object !== '') {
        this.stService.object = st.object
        this.store.dispatch(new FilteringAction())
        observable = this.stService.getPage(st.filter, action.payload)
      }

      return observable
        .map(data => new FilterSuccesAction(data))
        .catch((err) => Observable.of(new FilterFailAction(err)))
    })
  )

  @Effect() save$ = this.actions$.pipe(
    ofType<SaveAction>(ActionTypes.SAVE),
    withLatestFrom(this.store.select(s => s.smarterTable)),
    switchMap(([action, st]) => {
      let observable: Observable<any> = Observable.empty()

      if (st.object !== '') {
        this.stService.object = st.object
        observable = this.stService.save(action.payload)
      }

      return observable
        .map(data => new SaveSuccessAction(data))
        .catch((err) => Observable.of(new FilterFailAction(err)))
    })
  )

  @Effect({ dispatch: false }) saveSuccess$ = this.actions$.pipe(
    ofType<SaveSuccessAction>(ActionTypes.SAVE_SUCCESS),
    tap(() => this.store.dispatch(new FilterAction()))
  )

  @Effect() update$ = this.actions$.pipe(
    ofType<UpdateAction>(ActionTypes.UPDATE),
    withLatestFrom(this.store.select(s => s.smarterTable)),
    switchMap(([action, st]) => {
      let observable: Observable<any> = Observable.empty()

      if (st.object !== '') {
        this.stService.object = st.object
        observable = this.stService.update(action.payload)
      }

      return observable
        .map(data => new UpdateSuccessAction(data))
        .catch((err) => Observable.of(new FilterFailAction(err)))
    })
  )

  @Effect({ dispatch: false }) updateSuccess$ = this.actions$.pipe(
    ofType<UpdateSuccessAction>(ActionTypes.UPDATE_SUCCESS),
    tap(() => this.store.dispatch(new FilterAction())),
  )

  @Effect() delete$ = this.actions$.pipe(
    ofType<DeleteAction>(ActionTypes.DELETE),
    withLatestFrom(this.store.select(s => s.smarterTable)),
    switchMap(([action, st]) => {
      this.stService.object = st.object
      return this.stService.delete(st.selectedItem.id)

        .map(() => new FilterAction())
        .catch((err) => Observable.of(new DeleteFailAction(err)))
    }
    )
  )

  @Effect() changeIsActive$ = this.actions$.pipe(
    ofType<ChangeIsActiveAction>(ActionTypes.CHANGE_IS_ACTIVE),
    withLatestFrom(this.store.select(s => s.smarterTable)),
    switchMap(([action, st]) => {
      this.stService.object = st.object
      return this.stService.changeIsActive(st.selectedItem.id)

        .map(() => new FilterAction())
        .catch((err) => Observable.of(new DeleteFailAction(err)))
    }
    )
  )

  constructor(
    private stService: SmartTableService,

    private router: Router,
    private actions$: Actions,
    private store: Store<State>) { }
}

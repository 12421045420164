import { Component, OnInit } from '@angular/core'

import { ChangePasswordModel } from 'app/core/models'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  templateUrl: './change-password.component.html',
})

export class ChangePasswordComponent extends BaseComponent implements OnInit {

  entity: ChangePasswordModel = new ChangePasswordModel()
  id: number
  constructor() {
    super()
  }

  ngOnInit() {
    this.entity.id = this.id
  }

  validatePassword(pass) {
    const newPassword = this.entity.newPassword
    const confirmPassword = pass

    if (newPassword !== confirmPassword) {
      return false
    }
    return true
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

import { Component } from '@angular/core'

import { PositionModel } from 'app/core/models'

@Component({
  templateUrl: './position.component.html',
})

export class PositionComponent {
  entity: PositionModel = { id: 0, tenantId: '', name: '', isActive: true, hierarchyLevel: 0, officialPositionName: '' }

  hierarchyLevels = [{ id: 0, name: '0' }, { id: 1, name: '1' }, { id: 2, name: '2' },
  { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }]

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

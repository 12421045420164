import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core'

@Component({ selector: 'filter-item', template: '' })
export class FilterItemComponent {
    @Input() title: string
    @Input() source: any = []
    @Input() listMaxHeight: number

    @Output() changed = new EventEmitter()

    hidden: boolean = false

    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BaseModel } from 'app/core/models';
import { ProjectService } from 'app/core/services';
import { State } from 'app/core/store/states';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { mergeMap } from 'rxjs/operators';

import * as ProjectActions from '../../actions/noms/project.actions';
import { ProjectModel } from 'app/core/models/noms/project';


@Injectable()
export class ProjectEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private projectService: ProjectService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<ProjectActions.GetListOptions>(ProjectActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.projectService.getListOptions(true).map((projects: BaseModel[]) => {
                return new ProjectActions.GetListOptionsSuccess(projects)
            }, _ => of(new ProjectActions.GetListOptionsError()))
        })
    )
}

import { Component, HostListener, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core'
import { Store } from '@ngrx/store'
import { BaseAuthComponent } from 'app/components/base/base-auth.component'
import { PicturesService } from 'app/core'
import { State } from 'app/core/store/states'
import { Comment } from '../../models/comment'

export class AbstractCommentComponent extends BaseAuthComponent {

    @Output() onSave = new EventEmitter<Comment>()

    inTypingMode: boolean = false

    constructor(
        store: Store<State>,
        private picturesService: PicturesService,
        private cd: ChangeDetectorRef,
    ) {
        super(store)
    }

    @ViewChild('content', { static: true }) content: ElementRef

    editorOpeningElementClass: string = 'editor-opening-element'

    toggleTypingMode(inTypingMode) {
        this.inTypingMode = inTypingMode

        if (this.inTypingMode) {
            setTimeout(() => {
                const editor = this.getEditorNode() // get the editor rich text area
                editor.focus()

                if (editor.lastChild) { // if the editor has content in it, traverse the DOM until you get the last element
                    let currentNode = editor.lastChild

                    while (currentNode.nodeType !== 3) { // text node
                        currentNode = currentNode.lastChild
                    }

                    this.setCaretPosition(currentNode, currentNode['length'])
                } else { // if the editor is empty
                    editor.appendChild(document.createTextNode(''))
                    this.setCaretPosition(editor.lastChild, 0)
                }
            })
        }
    }

    cancel() {
        this.toggleTypingMode(false)
    }

    getUserImage(userId) {
        return this.picturesService.getProfilePic(userId)
    }

    @HostListener('document:click', ['$event'])
    public closeOnOutsideEvent($event: any) {
        if (this.inTypingMode && $event.target.classList.value.includes(this.editorOpeningElementClass) && !this.content.nativeElement.contains($event.target)) {
            this.toggleTypingMode(false)
            this.cd.detectChanges()
        }
    }

    getEditorNode(): any {
        const nativeElement = this.content.nativeElement as HTMLElement
        const editor = nativeElement.getElementsByClassName('angular-editor-textarea')[0] // get the textarea inside our view child content
        return editor
    }

    setCaretPosition(node: Node, offset: number) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.setStart(node, offset) // create an empty range in the right spot
        range.collapse(true) // collapses the range to a single position

        selection.removeAllRanges() // removes concurrent ranges
        selection.addRange(range) // adds the selection range, which placed the caret on the right position
    }
}

import { initialState } from '../../states/enums/days-types.states'

import * as DayTypeActions from 'app/core/store/actions/enums/days-type.actions'


export type Action = DayTypeActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case DayTypeActions.GET_ALL_SUCCESS: {
            return {
                ...state,
                listOptions: action.payload
            }
        }
        default: return state
    }
}

import { Injectable } from '@angular/core'

@Injectable()
export class RowBackgroudService {

    previousValue: any = null
    previousClass: string = ''

    oddClass: string = ''
    evenClass: string = 'even-background'

    constructor() {
        this.previousClass = this.oddClass
    }

    getClass(rowData: any, sortCol: string, index: number) {
        if (index === 0 || this.previousValue === rowData[sortCol]) {
            this.previousClass = index === 0 ? this.oddClass : this.previousClass
        } else {
            if (this.previousValue && rowData[sortCol]) {
                this.previousClass = (this.previousClass === this.oddClass ? this.evenClass : this.oddClass)
            }
        }

        this.previousValue = rowData[sortCol]

        return this.previousClass
    }
}

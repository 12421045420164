import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { TranslateService } from '@ngx-translate/core'
import { angularEditorConfig } from 'app/shared/configs/angular-editor-config'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormBaseComponent } from '../f-base.component'

@Component({
  selector: 'f-editor',
  templateUrl: './f-editor.component.html',
  styleUrls: [
    './f-editor.component.css',
  ],
  providers: [
    MakeValidatorProviders(FormEditorComponent),
    MakeValueAccessorProviders(FormEditorComponent)
  ],
})
export class FormEditorComponent extends FormBaseComponent implements OnInit, OnChanges {
  @Input() maxLength?: number
  @Input() minLength?: number
  @Input() disabled: boolean = false
  @Input() visibleToolbar: boolean = false

  @Input() editorConfig: AngularEditorConfig = Object.assign({}, angularEditorConfig)

  constructor(injector: Injector, private translateService: TranslateService) {
    super(injector)
  }

  ngOnInit() {
    super.ngOnInit()
    this.editorConfig.placeholder = this.translateService.instant('general.enterTextHere')
    this.visibleToolbar = false
  }

  ngOnChanges() {
    this.editorConfig.editable = !this.disabled
  }

  showToolbar() {
    if (!this.disabled) {
      this.visibleToolbar = true
    }
  }

  hideToolbar() {
    this.visibleToolbar = false
  }
}

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'reorder-action',
  templateUrl: './reorder-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReorderActionComponent {
  @Input() upTitle = ''
  @Input() downTitle = ''

  @Input() upDisabled = false
  @Input() downDisabled = false

  @Output() upClick = new EventEmitter<any>()
  @Output() downClick = new EventEmitter<any>()

  constructor(public el: ElementRef) {

  }

  upBtnClick(event) {
    event.stopPropagation()

    if (!this.upDisabled) {
      this.upClick.emit(event)
    }
  }

  downBtnClick(event) {
    event.stopPropagation()

    if (!this.downDisabled) {
      this.downClick.emit(event)
    }
  }
}

import { Injectable } from '@angular/core'

import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/withLatestFrom'
import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { UserService } from 'app/core'
import { ExternalProvider } from 'app/core/enums'

import { UserImage } from '../../models/user/user-image'
import { State } from '../states'

import { AuthService } from 'app/core/services'

import { UserBasicInfo } from 'app/core/models'
import { BaseModel } from 'app/core/models/common/base-model'

import * as UserActions from 'app/core/store/actions/user.actions'

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private userService: UserService,
        private authService: AuthService,
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetListOptions>(UserActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.userService.getListOptions(true).map((users: BaseModel[]) => {
                return new UserActions.GetListOptionsSuccess(users)
            }, _ => of(new UserActions.GetListOptionsError()))
        })
    )

    @Effect()
    GetListOptionsNoFiltered$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetListOptions>(UserActions.GET_LIST_OPTIONS_NO_FILTERED),
        mergeMap((action) => {
            return this.userService.getListOptionsNoFiltered(true).map((users: BaseModel[]) => {
                return new UserActions.GetListOptionsNoFilteredSuccess(users)
            }, _ => of(new UserActions.GetListOptionsNoFilteredError()))
        })
    )

    @Effect()
    GetListOptionAll$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetListOptionsAll>(UserActions.GET_LIST_OPTIONS_ALL),
        mergeMap((action) => {
            return this.userService.getListOptions(true, true).map((users: BaseModel[]) => {
                return new UserActions.GetListOptionsAllSuccess(users)
            }, _ => of(new UserActions.GetListOptionsAllError()))
        })
    )


    @Effect()
    GetListOptionsWithEmails$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetListOptionsWithEmails>(UserActions.GET_LIST_OPTIONS_WITH_EMAILS),
        mergeMap((action) => {
            return this.userService.getListOptionsWithEmails(true).map((users: UserBasicInfo[]) => {
                return new UserActions.GetListOptionsWithEmailsSuccess(users)
            }, _ => of(new UserActions.GetListOptionsWithEmailsError()))
        })
    )

    @Effect()
    GetUserImage$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetUserImage>(UserActions.GET_USER_IMAGE),
        mergeMap((action) => {
            return this.userService.getAvatar(action.payload).map((image: string) => {
                return new UserActions.GetUserImageSuccess(new UserImage(action.payload, image))
            }, _ => of(new UserActions.GetUserImageError()))
        })
    )

    @Effect()
    GetUserLinks$: Observable<Action> = this.actions$.pipe(
        ofType<UserActions.GetUserLinks>(UserActions.GET_USER_LINKS),
        mergeMap((action) => {
            return this.authService.getExternalAccountLinks(action.payload).map((links: ExternalProvider[]) => {
                return new UserActions.GetUserLinksSuccess({ userId: action.payload, providers: links })
            }, _ => of(new UserActions.GetUserLinksError()))
        })
    )
}

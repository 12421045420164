import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ComponentRef, Type, ComponentFactoryResolver, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalMenuItem } from '../../interfaces/modal-menu-item';
import { InsertionDirective } from 'app/shared/directives/insertion/insertion.directive';

@Component({
  selector: 'menu-dialog',
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.scss']
})
export class MenuDialogComponent implements OnInit, OnDestroy {

  @Input() title: string
  @Input() items: ModalMenuItem[]

  @Output() dialogClose = new EventEmitter<any>()

  selectedItemId: number = null
  componentRef: ComponentRef<any>

  // add this:
  @ViewChild(InsertionDirective, {static: true})
  insertionPoint: InsertionDirective

  constructor(
    public activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.items = this.items.map((i, index) => { i.id = index; return i })

    if (this.items.length > 0) {
      this.selectItem(this.items[0])
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  selectItem(item: ModalMenuItem) {
    if (item.id === this.selectedItemId) {
      return
    }

    this.loadChildComponent(item.component)
    this.selectedItemId = item.id
    this.cd.detectChanges()
  }

  onClose() {
    this.activeModal.close(false)
    this.dialogClose.emit()
  }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }
}

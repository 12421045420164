import { Action } from '@ngrx/store'
import { BaseModel } from 'app/core/models'

import { type } from '../../util'

export const GET_LIST_OPTIONS = type('[TENANT] GET_LIST_OPTIONS')
export const GET_LIST_OPTIONS_SUCCESS = type('[TENANT] GET_LIST_OPTIONS_SUCCESS')
export const GET_LIST_OPTIONS_ERROR = type('[TENANT] GET_LIST_OPTIONS_ERROR')

export const GET_ENTERPRISE_LIST_OPTIONS = type('[TENANT] GET_ENTERPRISE_LIST_OPTIONS')
export const GET_ENTERPRISE_LIST_OPTIONS_SUCCESS = type('[TENANT] GET_ENTERPRISE_LIST_OPTIONS_SUCCESS')
export const GET_ENTERPRISE_LIST_OPTIONS_ERROR = type('[TENANT] GET_ENTERPRISE_LIST_OPTIONS_ERROR')

export const SELECT_TENANT = type('[TENANT] SELECT_TENANT')

export class GetListOptions implements Action {
    readonly type = GET_LIST_OPTIONS
}

export class GetListOptionsSuccess implements Action {
    readonly type = GET_LIST_OPTIONS_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetListOptionsError implements Action {
    readonly type = GET_LIST_OPTIONS_ERROR
}

export class GetEnterpriseListOptions implements Action {
    readonly type = GET_ENTERPRISE_LIST_OPTIONS
}

export class GetEnterpriseListOptionsSuccess implements Action {
    readonly type = GET_ENTERPRISE_LIST_OPTIONS_SUCCESS

    constructor(public payload: BaseModel[]) { }
}

export class GetEnterpriseListOptionsError implements Action {
    readonly type = GET_ENTERPRISE_LIST_OPTIONS_ERROR
}

export class SelectTenant implements Action {
    readonly type = SELECT_TENANT

    constructor(public payload: string) { }
}

export type All =
    GetListOptions | GetListOptionsSuccess | GetListOptionsError
    | GetEnterpriseListOptions | GetEnterpriseListOptionsSuccess | GetEnterpriseListOptionsError
    | SelectTenant

import { Action } from '@ngrx/store'

import { type } from './util'

export const ActionTypes = {
  SET_OBJECT: type('SET_OBJECT'),
  CHANGE_FILTER: type('CHANGE_FILTER'),
  FILTER: type('FILTER'),
  FILTER_SUCCESS: type('FILTER_SUCCESS'),
  FILTER_FAIL: type('FILTER_FAIL'),
  GET_ACTIVE_ITEM: type('GET_ACTIVE_ITEM'),
  SET_FILTERING: type('SET_FILTERING'),

  COPY: type('COPY'),

  SAVE: type('SAVE'),
  SAVE_SUCCESS: type('SAVE_SUCCESS'),
  SAVE_FAIL: type('SAVE_FAIL'),
  UPDATE: type('UPDATE'),
  UPDATE_SUCCESS: type('UPDATE_SUCCESS'),
  UPDATE_FAIL: type('UPDATE_FAIL'),
  DELETE: type('DELETE'),
  DELETE_FAIL: type('DELETE_FAIL'),
  CHANGE_IS_ACTIVE: type('CHANGE_IS_ACTIVE'),
  CHANGE_IS_ACTIVE_FAIL: type('CHANGE_IS_ACTIVE_FAIL'),
}

export class SetObjectAction implements Action {
  type = ActionTypes.SET_OBJECT
  constructor(public payload: string) { }
}

export class ChangeFilterAction implements Action {
  type = ActionTypes.CHANGE_FILTER

  constructor(public payload: any) { }
}

export class FilterAction implements Action {
  type = ActionTypes.FILTER

  constructor(public payload: boolean = false) { }
}

export class FilterSuccesAction implements Action {
  type = ActionTypes.FILTER_SUCCESS

  constructor(public payload: any) { }
}

export class FilterFailAction implements Action {
  type = ActionTypes.FILTER_FAIL

  constructor(public payload: any) { }
}

export class GetActiveItemAction implements Action {
  type = ActionTypes.GET_ACTIVE_ITEM

  constructor(public payload: any) { }
}

export class SaveAction implements Action {
  type = ActionTypes.SAVE

  constructor(public payload: any) { }
}

export class SaveFailAction implements Action {
  type = ActionTypes.SAVE_FAIL
  constructor(public payload: any) { }
}

export class SaveSuccessAction implements Action {
  type = ActionTypes.SAVE_SUCCESS
  constructor(public payload: any = null) { }
}

export class UpdateAction implements Action {
  type = ActionTypes.UPDATE

  constructor(public payload: any) { }
}

export class UpdateSuccessAction implements Action {
  type = ActionTypes.UPDATE_SUCCESS
  constructor(public payload: any) { }
}

export class UpdateFailAction implements Action {
  type = ActionTypes.UPDATE_FAIL
  constructor(public payload: any = null) { }
}

export class DeleteAction implements Action {
  type = ActionTypes.DELETE

  constructor(public payload: any = null) { }
}

export class DeleteFailAction implements Action {
  type = ActionTypes.DELETE_FAIL

  constructor(public payload: any) { }
}

export class ChangeIsActiveAction implements Action {
  type = ActionTypes.CHANGE_IS_ACTIVE

  constructor(public payload: any = null) { }
}

export class ChangeIsActiveFailAction implements Action {
  type = ActionTypes.CHANGE_IS_ACTIVE_FAIL

  constructor(public payload: any) { }
}

export class FilteringAction implements Action {
  type = ActionTypes.SET_FILTERING

  constructor(public payload: boolean = true) { }
}

export class CopyAction implements Action {
  type = ActionTypes.COPY

  constructor(public payload: any = null) { }
}


export type Actions
  = ChangeFilterAction
  | FilterAction
  | FilterFailAction
  | FilterSuccesAction
  | GetActiveItemAction
  | SetObjectAction

  | SaveAction
  | SaveFailAction
  | SaveSuccessAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateFailAction
  | DeleteAction
  | DeleteFailAction
  | ChangeIsActiveAction
  | ChangeIsActiveFailAction

  | FilteringAction

  | CopyAction

import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { BaseAuthComponent } from 'app/components/base/base-auth.component'
import { RequestStatus } from 'app/core/enums/request-status.enum'
import { VacationApproveInputModel, VacationRequest } from 'app/core/models'
import { ToastrService, TranslationService, VacationApproveService, VacationRequestService } from 'app/core/services'
import { State } from 'app/core/store/states'
import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'
import { getActivePage } from 'app/smart-table/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

@Component({
  templateUrl: './team-requests.component.html'
})

export class TeamRequestsComponent extends BaseAuthComponent {

  vacationRequests: VacationRequest[]

  constructor(
    private approveService: VacationApproveService,
    store: Store<State>,
    private toastrService: ToastrService,
    private modal: ModalService,
    private translationService: TranslationService
  ) {
    super(store)

    store.select(getActivePage)
      .takeWhile(() => this.isAlive)
      .subscribe(state => {
        this.vacationRequests = Object.assign([], state.data)
      })
  }

  get btnDisabled() {
    return !this.vacationRequests.some(e => e.selected)
  }

  checkAll(isChecked) {
    this.vacationRequests
      .filter(g => g.selected !== isChecked)
      .forEach(g => g.selected = isChecked)
  }

  private submitApproves(approves: VacationApproveInputModel[]) {

    this.approveService.vacationApprove(approves)
      .first()
      .subscribe(app => {
        this.store.dispatch(new FilterAction())

        const ifPlural = app.count > 1 ? 's' : ''
        const requestStatus = RequestStatus[app.status]
        const messageInfo = `messages.request${ifPlural}${requestStatus}`

        this.toastrService.info(`${app.count} ${this.translationService.translateMsg(messageInfo)}!`)
      })
  }

  confirm(approved: boolean) {
    const message = approved ?
      this.translationService.translateMsg('messages.confirmApprovePlural') :
      this.translationService.translateMsg('messages.confirmRejectPlural')

    this.modal.confirm(ModalTypes.Warning, message)
      .subscribe((confirmed) => {
        if (confirmed) {

          const approves = this.vacationRequests
            .filter(r => r['selected'])
            .map(a => new VacationApproveInputModel(a.id, this.userId, approved))

          this.submitApproves(approves)
        }
      })
  }
}

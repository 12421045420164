export class ChatMessageMention {
    id: number
    mentionText: string
    mentioned: any
}

export class MsTeamsMessage {
    teamId: string
    message: string
    mentions: ChatMessageMention[] = []
}

export class MsTeamsMentionOption {
    id: string
    name: string
    isConversation: boolean = false
    type: 'team' | 'channel' = null

    constructor(id: string, name: string, isConversation: boolean = false, conversationIdentityType: 'team' | 'channel' = null) {
        this.id = id
        this.name = name
        this.isConversation = isConversation
        this.type = conversationIdentityType
    }
}

export class TSAdvanceReportSetting {
    id: number
    name: string
    groupByUser: boolean
    groupByProject: boolean
    groupByWorkTimeType: boolean
    groupByTask: boolean
    groupByDate: boolean
    userId: number
    visibleColumns: string[] = []
}

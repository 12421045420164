import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'

export const minLengthFn = (minLength: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!isPresent(minLength)) { return null }
        if (isPresent(Validators.required(control))) { return null }
        const v: number = +control.value.length
        return v >= +minLength ? null : { actualValue: v, requiredValue: +minLength, minlength: true }
    }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'

import * as WorkTimeTypeActions from '../actions/work-time-type.actions'

import { TimeSheetService } from 'app/core/services'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'


@Injectable()
export class WorkTimeTypeEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private timeSheetService: TimeSheetService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<WorkTimeTypeActions.GetListOptions>(WorkTimeTypeActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.timeSheetService.getWorkTimeTypes().map((workTimeTypes: BaseModel[]) => {
                return new WorkTimeTypeActions.GetListOptionsSuccess(workTimeTypes)
            }, _ => of(new WorkTimeTypeActions.GetListOptionsError()))
        })
    )
}

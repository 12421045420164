import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'


export interface DayTypeState {
    listOptions: BaseModel[]
}

export const initialState: DayTypeState = {
    listOptions: []
}

export namespace dayTypeSelector {
    export const getDaysTypeState = createFeatureSelector<DayTypeState>('daysType')
    export const getDayTypesAll = createSelector(getDaysTypeState, state => state.listOptions)
}

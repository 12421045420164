import { initialState } from '../states/app.states'

import * as AppActions from 'app/core/store/actions/app.actions'


export type Action = AppActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case AppActions.GET_MODULES_SUCCESS : {
            return {
                ...state,
                modules: action.payload
            }
        }
        default: return state
    }
}

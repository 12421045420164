import { Component } from '@angular/core'

import { MDBaseButtonComponent } from './base-button.component'

@Component({
  selector: 'cancel-btn',
  templateUrl: 'cancel-btn.component.html',
})
export class MDCancelButtonComponent extends MDBaseButtonComponent {
  constructor() {
    super()

    this.cssClass = 'btn-outline-secondary'
  }

}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface WorkTimeTypeState {
    listOptions: BaseModel[]
}

export const initialState: WorkTimeTypeState = {
    listOptions: []
}

export namespace wttSelectors {
    export const getWorkTimeTypeState = createFeatureSelector<WorkTimeTypeState>('workTimeType')
    export const getWorkTimeTypesAll = createSelector(getWorkTimeTypeState, state => state.listOptions)
}

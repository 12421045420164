import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent {

  constructor(private router: Router) { }

  navigateToHome() {
    this.router.navigateByUrl('/home')
  }
}

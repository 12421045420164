import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'


export const maxFn = (max: number): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: any} => {
    if (!isPresent(max)) {return null}
    if (isPresent(Validators.required(control))) { return null }

    const v: number = +control.value
    return v <= +max ? null : {actualValue: v, requiredValue: +max, max: true}
  }
}

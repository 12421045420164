export class VacationRequest {
  id: number
  fromDate: Date
  toDate: Date
  leaveTypeName: string
  description: string
  fullName: string
  status: number
  statusName: string
  actionDate: Date
  totalDays: number
  shouldBeExportedToTimeSheet: boolean
  missingPeopleMaxCount: number
  selected: boolean
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commonDateTime' })
export class CommonDateTimePipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    transform(value: any): any {
        const storedLanguage = localStorage.getItem('language')
        const locale = !!storedLanguage ? JSON.parse(storedLanguage).language : 'bg-BG'

        const dateFormat = 'mediumDate'
        const timeFormat = 'HH:mm'

        return value
            ? `${this.datePipe.transform(new Date(value), dateFormat, undefined, locale)} ${this.datePipe.transform(new Date(value), timeFormat)}`
            : null
    }
}

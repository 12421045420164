import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core'

import { SummernoteElementService } from './summernote-element.service'
import { debounce } from './debounce'

export interface IWysiwygElementService {
  init(rootElement: ElementRef): void
  setContent(content: string): void
  getContent(): string
  getContentElement(): Node
}

@Component({
  selector: 'wysiwyg',
  template: '<div #root></div>'
})
export class WysiwygComponent implements AfterViewInit, OnDestroy {
  private wysiwygElementService: IWysiwygElementService
  @ViewChild('root', {static: true}) rootElement: ElementRef
  private observer: MutationObserver
  @Input() content: string
  @Output() contentChange: EventEmitter<string> = new EventEmitter<string>()

  constructor(wysiwygElementService: SummernoteElementService) {
    this.wysiwygElementService = wysiwygElementService
  }

  ngAfterViewInit() {
    this.wysiwygElementService.init(this.rootElement)

    if (this.content) {
      this.wysiwygElementService.setContent(this.content)
    }
    
    this.setupObserver()
  }

  ngOnDestroy(): void {
    this.destroyObserver()
  }

  // https://developer.mozilla.org/en/docs/Web/API/MutationObserver
  private setupObserver(): void {
    const mutationCallback: MutationCallback = debounce(this.contentChangeHandler.bind(this), 500)

    this.observer = new MutationObserver(mutationCallback)

    const config: MutationObserverInit = {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    }

    this.observer.observe(this.wysiwygElementService.getContentElement(), config)
  }

  private contentChangeHandler(): void {
    this.contentChange.emit(this.wysiwygElementService.getContent())
  }

  private destroyObserver(): void {
    this.observer.disconnect()
  }
}
import { Component } from '@angular/core';
import { ResetUserPasswordModel } from 'app/core/models/user/reset-user-password';

@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html'
})
export class ResetUserPasswordComponent {

  entity: ResetUserPasswordModel = {
    email: ''
  }

  generatePassword: boolean = true

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }

  setGeneratePassword() {
    this.entity.password = ''
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface LocationState {
    listOptions: BaseModel[]

}

export const initialState: LocationState = {
    listOptions: []
}

export namespace locationSelectors {
    export const getLocationState = createFeatureSelector<LocationState>('location')
    export const getLocationListOptions = createSelector(getLocationState, state => state.listOptions)

}

import { Component, ContentChildren, QueryList } from '@angular/core'

import { FilterItemComponent } from './filter-item.component'

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter-item.scss']
})

export class FilterComponent {
    @ContentChildren(FilterItemComponent) filters: QueryList<FilterItemComponent>

    change(filter: FilterItemComponent, item: any): void {
        item.checked = !item.checked
        filter.changed.emit();
    }
}

import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core'

import {
    AuthStoreService,
    CalendarEventCreatedService,
    MicrosoftService,
    MicrosoftStoreService,
    ToastrService,
} from 'app/core'
import { Guid } from 'guid-typescript'

import { BaseModel, ChatMessageMention, MsTeam, MsTeamsMentionOption, MsTeamsMessage, MsUser } from 'app/core/models'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
    selector: 'app-teams-message',
    templateUrl: './teams-message.component.html',
    styleUrls: [
        './teams-message.component.scss'
    ]
})
export class TeamsMessageComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() eventId: Guid
    @Input() mentionTriggerKey: string = '@'
    @Input() defaultMessage: string = ''

    @ViewChild('mentionPicker', { static: false }) mentionPicker: ElementRef

    teams: MsTeam[] = []
    teamMembers: MsUser[] = []
    teamsMessage: MsTeamsMessage = new MsTeamsMessage()

    hasLinkedMicrosoftAccount: boolean = false
    inMentionMode: boolean = false
    operationCompleted: boolean = false

    mentionTriggered: boolean = false

    mentionPhrase: string = ''
    mentionPosition: number = 0
    mentionOptions: MsTeamsMentionOption[] = []

    mentionOptionFocused: number = null

    editorClass: string = 'angular-editor-textarea'

    constructor(
        private microsoftService: MicrosoftService,
        private microsoftStoreService: MicrosoftStoreService,
        private authStoreService: AuthStoreService,
        private calendarEventCreatedService: CalendarEventCreatedService,
        private toastrService: ToastrService,
    ) {
        super()
    }

    ngOnInit() {
        this.microsoftStoreService
            .myTeams$
            .takeWhile(_ => this.isAlive)
            .subscribe(teams => {
                this.teams = teams
            })

        this.calendarEventCreatedService
            .objectCreated
            .takeWhile(_ => !this.operationCompleted)
            .subscribe(eventId => {
                if (this.eventId.equals(eventId)) {
                    this.sendMessage()
                }
            })

        this.authStoreService
            .hasMicrosoftAccount$
            .takeWhile(_ => this.isAlive)
            .subscribe(hasLinkedMicrosoftAccount => {
                this.hasLinkedMicrosoftAccount = hasLinkedMicrosoftAccount
            })
    }

    ngOnChanges() {
        if (this.teamsMessage.teamId) {
            this.displayDefaultMessage(this.teams.find(t => t.id === this.teamsMessage.teamId))
        }
    }

    ngAfterViewInit() {
        const dropdown = document.getElementById('dropdown')
        document.body.appendChild(dropdown)
    }

    ngOnDestroy() {
        const dropdown = document.getElementById('dropdown')
        document.body.removeChild(dropdown)
    }

    get searchMentionOptions(): BaseModel[] {
        return this.mentionOptions.filter(o => o.name.toLocaleLowerCase().includes(this.mentionPhrase.toLocaleLowerCase()))
    }

    sendMessage(): void {
        if (this.teamsMessage.teamId && this.teamsMessage.message) {
            this.microsoftService
                .sendTeamsMessage(this.teamsMessage)
                .takeWhile(_ => !this.operationCompleted)
                .subscribe(_ => {
                    this.toastrService.success('messages.teamsMessageSuccessfullySent', null, true)
                    this.operationCompleted = true
                })
        } else {
            this.operationCompleted = true
        }
    }

    onTeamChosen(team: MsTeam): void {
        this.mentionOptions = [new MsTeamsMentionOption(team.id, team.displayName, true, 'team')]
        this.getTeamMembers()
        this.displayDefaultMessage(team)
    }

    getTeamMembers(): void {
        this.microsoftService
            .getTeamMembers(this.teamsMessage.teamId)
            .first()
            .subscribe(members => {
                this.teamMembers = members
                this.mentionOptions.push(...this.teamMembers.map(m => new MsTeamsMentionOption(m.id, m.displayName)))
            })
    }

    displayDefaultMessage(team: MsTeam) {
        this.teamsMessage.message = ''
        this.teamsMessage.mentions = []

        this.mentionPosition = 0
        this.teamsMessage.message = ''
        this.mention(new MsTeamsMentionOption(team.id, team.displayName, true, 'team'), true)
        this.teamsMessage.message += this.defaultMessage
    }

    mention(mentionOption: MsTeamsMentionOption, calledFromCode: boolean = false): void {
        if (!(this.inMentionMode || calledFromCode)) { // unpredicted double event trigger protection
            return
        }

        const mentionId = this.teamsMessage.mentions.length

        this.teamsMessage.message = this.teamsMessage.message.substring(0, this.mentionPosition - 1)
            + `<at id="${mentionId}">${mentionOption.name}</at>&nbsp;`
            + this.teamsMessage.message.substring(this.mentionPosition + this.mentionPhrase.length)

        const mentioned = mentionOption.isConversation
            ? {
                conversation: {
                    id: mentionOption.id,
                    displayName: mentionOption.name,
                    conversationIdentityType: mentionOption.type,
                }
            }
            : {
                user: {
                    id: mentionOption.id,
                    displayName: mentionOption.name,
                }
            }

        const mention: ChatMessageMention = {
            id: mentionId,
            mentionText: mentionOption.name,
            mentioned: mentioned
        }

        this.teamsMessage.mentions.push(mention)
        this.resetMentionMode()
    }

    resetMentionMode(): void {
        this.mentionPhrase = ''
        this.inMentionMode = false
        this.mentionOptionFocused = null
    }

    displayMentionOptions() {
        const selection = window.getSelection()
        const baseOffset = selection['baseOffset']
        const currentPosition = this.getCurrentPosition()
        const length = selection.focusNode['length']
        const node = document.getElementsByClassName(this.editorClass)[0]

        const topOffset: number = 5
        const leftOffset: number = -30

        // Get the text before and after the caret
        const textBefore = node['innerHTML'].substr(0, currentPosition)
        const textAfter = (baseOffset !== length) ? node['innerHTML'].substr(currentPosition) : ''

        node['innerHTML'] = textBefore + '<div id="dummy" style="display: inline-block"></div>' + textAfter

        const dropdown = document.getElementById('dropdown')
        const dummy = document.getElementById('dummy')

        const dummyRect = dummy.getBoundingClientRect();

        dropdown.style.top = (dummyRect.top + topOffset - dummy.scrollTop).toString() + 'px'
        dropdown.style.left = (dummyRect.left + leftOffset - dummy.scrollLeft).toString() + 'px'

        this.setCaretPosition(selection, dummy.previousSibling, baseOffset)
        dummy.remove()

        this.inMentionMode = true
    }

    setCaretPosition(selection, node: Node, offset: number) {
        const range = document.createRange()
        range.setStart(node, offset)
        range.collapse(true)

        selection.removeAllRanges()
        selection.addRange(range)
    }

    keydown(event): void {
        if (event.key === this.mentionTriggerKey) {
            this.mentionTriggered = true
        }

        const selection = window.getSelection()
        let currentNode = selection.focusNode

        if (event.key.match(/^[\d\w]$/i)) {
            while (currentNode && currentNode['id'] !== 'editor') {
                const tag: string = currentNode['tagName']

                if (tag && tag.toLowerCase() === 'at') {
                    event.preventDefault()
                    event.stopPropagation()

                    if (currentNode.nextSibling) {
                        currentNode.nextSibling.textContent = event.key + currentNode.nextSibling.textContent
                        this.setCaretPosition(selection, currentNode.nextSibling, 1)
                    } else {
                        currentNode.parentNode.appendChild(document.createTextNode(event.key))
                        this.setCaretPosition(selection, currentNode.parentNode.lastChild, 1)
                    }

                    break
                }

                currentNode = currentNode.parentElement
            }
        }
    }

    keyup(event): void {
        if (this.mentionTriggered && !this.inMentionMode) {
            this.mentionTriggered = false
            this.mentionPosition = this.getCurrentPosition()
            this.displayMentionOptions()
            return
        }

        if (this.inMentionMode && (event.keyCode <= 90 && event.keyCode >= 58)) {
            const currentPosition = this.getCurrentPosition()

            if (currentPosition >= this.mentionPosition && currentPosition <= this.mentionPosition + this.mentionPhrase.length + 1) {
                const addedSymbolIndex = currentPosition - this.mentionPosition
                this.mentionPhrase = this.mentionPhrase.substring(0, addedSymbolIndex) + event.key + this.mentionPhrase.substring(addedSymbolIndex + 1)
            }

            return
        }

        if (event.keyCode === 8 || event.keyCode === 46) { // Backspace and Delete
            const toRemove: ChatMessageMention[] = []

            this.teamsMessage.mentions.forEach(m => {
                if (!this.teamsMessage.message.includes(`<at id="${m.id}">`)) {
                    toRemove.push(m)
                }
            })

            toRemove.forEach(r => {
                this.teamsMessage.mentions.splice(this.teamsMessage.mentions.findIndex(m => m.id === r.id), 1)
            })

            if (this.inMentionMode) {
                const currentPosition = this.getCurrentPosition()

                if (currentPosition >= this.mentionPosition && currentPosition <= this.mentionPosition + this.mentionPhrase.length) {
                    const deletedSymbolIndex = currentPosition - this.mentionPosition
                    this.mentionPhrase = this.mentionPhrase.substring(0, deletedSymbolIndex) + this.mentionPhrase.substring(deletedSymbolIndex + 1)
                }

                if (this.teamsMessage.message[this.mentionPosition - 1] !== this.mentionTriggerKey) {
                    this.resetMentionMode()
                }
            }
        }
    }

    arrowUp(event) {
        if (this.inMentionMode) {
            event.preventDefault()

            const dropdownMenu = document.getElementById('dropdownMenu')
            const items = dropdownMenu.children

            if (items.length > 0) {
                if (!this.mentionOptionFocused) {
                    this.mentionOptionFocused = items.length - 1
                } else {
                    this.mentionOptionFocused -= this.mentionOptionFocused > 0 ? 1 : 0
                }

                items[this.mentionOptionFocused]['focus']()
            }
        }
    }

    arrowDown() {
        if (this.inMentionMode) {
            event.preventDefault()

            const dropdownMenu = document.getElementById('dropdownMenu')
            const items = dropdownMenu.children

            if (items.length > 0) {
                if (!this.mentionOptionFocused) {
                    this.mentionOptionFocused = 0
                } else {
                    this.mentionOptionFocused += this.mentionOptionFocused < items.length - 1 ? 1 : 0
                }

                items[this.mentionOptionFocused]['focus']()
            }
        }
    }

    getNodeLength(node: Node): number {
        let text: string = ''

        if (node.nodeType === 3) { // text node
            text = node.textContent
        } else {
            text = node['outerHTML']
        }

        return this.getHtmlTextLength(text);
    }

    getCurrentPosition(): number {
        let index: number = 0

        const selection = window.getSelection()
        let currentNode = selection.focusNode

        while (currentNode && (!currentNode['className'] || !currentNode['className'].includes(this.editorClass))) {
            if (currentNode['tagName']) {
                index += currentNode['tagName'].length + 2 // name + <>

                const attributes = currentNode['attributes']

                for (let i = 0; i < attributes.length; i++) {
                    const element = attributes[i]
                    index += element.name.length + element.value.length + 4 // = and ""
                }
            }

            if (currentNode.nodeType === 3) { // text node
                index += this.getHtmlTextLength(currentNode.textContent.substr(0, selection.focusOffset))
            }

            let previousSibling = currentNode.previousSibling

            while (previousSibling) {
                index += this.getNodeLength(previousSibling)
                previousSibling = previousSibling.previousSibling
            }

            currentNode = currentNode.parentNode
        }

        return index
    }

    getHtmlTextLength(text: string): number {
        let length: number = text.length

        for (let i = 0; i < text.length; i++) {
            if (text.charCodeAt(i) === 160) {
                length += 5
            }
        }

        return length
    }
}

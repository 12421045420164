import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'
import { companySelectors } from 'app/core/store/states/noms/company.states'
import { locationSelectors } from 'app/core/store/states/noms/location.states'
import { positionSelectors } from 'app/core/store/states/noms/position.states'
import { projectSelectors } from 'app/core/store/states/noms/project.states'
import { wttSelectors } from 'app/core/store/states/work-time-type.states'

@Injectable()

export class NomsStoreService {

    constructor(private store$: Store<State>) { }

    public get companyListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(companySelectors.getCompanyListOptions)
    }

    public get locationListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(locationSelectors.getLocationListOptions)
    }

    public get workTimeTypes$(): Observable<BaseModel[]> {
        return this.store$.select(wttSelectors.getWorkTimeTypesAll)
    }

    public get projectListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(projectSelectors.getProjectListOptions)
    }

    public get positionListOptions$(): Observable<BaseModel[]> {
        return this.store$.select(positionSelectors.getPositionListOptions)
    }
}

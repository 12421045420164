import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from './common/api.service'

import { PermanentlyHolidayDate } from 'app/core/models/permanently-holiday-date'

@Injectable()

export class PermanentlyHolidayDateService {
  constructor(private httpClient: ApiService) {
  }

  takeTop5(): Observable<PermanentlyHolidayDate[]> {
    return this.httpClient.get('permanentlyHolidayDate/take-five', true, true)
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserAction from 'app/core/store/actions/user.actions';
import { State } from 'app/core/store/states';
import { userSelectors } from 'app/core/store/states/user.states';

import { ExternalProvider } from '../enums';
import { UserLinks } from '../models';

@Injectable()
export class ExternalLinksService {
    externalLinks: UserLinks[] = []
    requestedExternalLinks: number[] = []

    constructor(private store: Store<State>) {
        this
            .store
            .select(userSelectors.getExternalLinks)
            .subscribe(links => {
                this.externalLinks = links
            })
    }

    getExternalLinks(userId: number): ExternalProvider[] {
        const userLinks = this.externalLinks.find(i => i.userId === userId)

        if (userLinks) {
            return userLinks.providers
        } else {
            if (this.requestedExternalLinks.indexOf(userId) < 0 && userId) {
                this.requestedExternalLinks.push(userId)
                this.store.dispatch(new UserAction.GetUserLinks(userId))
            }
        }

        return []
    }
}

import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CoreModule } from 'app/core'
import { SharedModule } from 'app/shared'
import { SmartTableModule } from 'app/smart-table/smart-table.module'
import { MultiselectDropdownModule } from 'ng2-multiselect'

import { SharedComponentsModule } from '../shared-components/shared-components.module'
import { AnnualTeamReportComponent } from './annual-team-request/аnnual-team-report.component'
import { MyRequestsComponent } from './my-requests/my-requests.component'
import { VacationReportByYearComponent } from './report-by-year/report-by-year.component'
import { TeamRequestsComponent } from './team-requests/team-requests.component'
import { VacationApproveComponent } from './vacation-approve/vacation-approve.component'
import { VacationReportComponent } from './vacation-request-report/vacation-report.component'
import { VacationRequestRoutingModule } from './vacation-request.routing'
import { VacationRequestComponent } from './vacation-request/vacation-request.component'

import { DialogsModule } from 'app/dialogs/dialogs.module'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    SmartTableModule,
    DialogsModule,
    SharedComponentsModule,
    MultiselectDropdownModule,
    VacationRequestRoutingModule
  ],
  declarations: [
    MyRequestsComponent,
    AnnualTeamReportComponent,
    TeamRequestsComponent,
    VacationApproveComponent,
    VacationRequestComponent,
    VacationReportComponent,
    VacationReportByYearComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [
    VacationRequestComponent
  ],
  providers: [],

})
export class VacationRequestModule {
  constructor() { }
}

import { Component, ElementRef, Input } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { BaseActionComponent } from './base-action.component'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

@Component({
  selector: 'active-action',
  templateUrl: './active-action.component.html'
})
export class ActiveActionComponent extends BaseActionComponent {
  @Input() activate = false

  constructor(public el: ElementRef, private modal: ModalService, private translate: TranslateService) {
    super(el)
  }

  onBtnClick(event) {
    event.stopPropagation()
    const message = this.translate.instant('messages.areYouSure')

    this.modal.confirm(ModalTypes.Warning, message).subscribe(state => {
      if (state && !this.disabled) {
        this.onClick.emit(event)
      }
    })
  }
}

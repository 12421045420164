export const Roles = {
    globalAdminRole: 'Global Administrator',
    tenantAdminRole: 'Tenant Administrator',
    companyAdminRole: 'Company Administrator',
    teamLeadRole: 'TeamLead',
    ticketGuest: "Ticket Guest",
}

export const RolesLocalizeStrings = {
    globalAdminRole: 'globalAdminrole',
    tenantAdminRole: 'tenantAdminRole',
    companyAdminRole: 'companyAdminRole',
    teamLeadRole: 'teamLeadRole',
    none: 'none'
}

export const RoleIds = {
    globalAdminRole: '89dfcf49-8322-4620-8f1a-f560fe525d79',
    tenantAdminRole: 'c5a90709-70f5-439d-aa50-af8058b300ce',
    companyAdminRole: 'fff562f2-29d2-4433-b597-7d7c45dc2d6c',
    teamLeadRole: '6bff149e-59cf-4101-9a4a-9da9deb5b28f',
    ticketGuest: "B763E560-95FF-48EC-AAC9-F5DB090F5ABB",
}

import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core'
import { NgControl } from '@angular/forms'

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'

import { FormBaseComponent } from '../f-base.component'

@Component({
  selector: 'f-date',
  templateUrl: './f-date.component.html',
  providers: [MakeValidatorProviders(FormDateComponent), MakeValueAccessorProviders(FormDateComponent)]
})
export class FormDateComponent extends FormBaseComponent implements OnInit, OnChanges {
  @ViewChild('d', { static: true }) datepicker

  date: any = null

  @Input() minDate: Date
  @Input() maxDate: Date
  min: NgbDateStruct
  max: NgbDateStruct

  @Output() change: EventEmitter<any> = new EventEmitter<any>()

  oldDate: Date = new Date()

  constructor(
    injector: Injector,
    private dateParser: NgbDateParserFormatter,
  ) {
    super(injector)
  }

  ngOnInit() {
    this.control = this
      .injector
      .get(NgControl)
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes['minDate'] && this.minDate) {
      this.min = this.dateToValue(this.minDate)
    }
    if (changes['maxDate'] && this.maxDate) {
      this.max = this.dateToValue(this.maxDate)
    }
  }

  writeValue(value: any) {
    const validDate = this.getValidDate(value)
    this.date = this.dateToValue(validDate)

    if (validDate) {
      this.oldDate = validDate
    }

    this.value = validDate ? validDate.toDateString() : undefined
    super.writeValue(this.getUtcDate(validDate))

    this.change.emit()
  }

  getValidDate(value: Date) {
    const minDate = this.getNewDateFromDate(this.minDate)
    const maxDate = this.getNewDateFromDate(this.maxDate)

    value = !!value ? new Date(value) : null

    this.min = this.dateToValue(minDate)
    this.max = this.dateToValue(maxDate)

    if (value && value < minDate) {
      return minDate
    } else if (value && value > maxDate) {
      return maxDate
    }

    return value
  }

  keyboardChange(dateString: string) {
    let date: NgbDateStruct = null

    if (dateString) {
      date = this.dateParser.parse(dateString)
    } else {
      this.writeValue(null)
      return
    }

    this.updateDate(date)
  }

  updateDate(value: any) {
    if (value) {
      const _date = new Date(value.year, value.month - 1, value.day)

      if (!isNaN(_date.getTime())) {
        this.oldDate = _date
        this.value = _date.toDateString()
        this.writeValue(this.value)

        return
      }
    }

    this.value = !!value ? this.oldDate : this.getValidDate(new Date())
    this.writeValue(this.value)
  }

  dateToValue(value: Date) {
    if (value) {
      const date = this.getNewDateFromDate(value)

      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }

    return null
  }

  getNewDateFromDate(date: Date) {
    const newDate = new Date(date)
    return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
  }

  getUtcDate(date: Date) {
    const newDate = new Date(date)
    return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()))
  }
}

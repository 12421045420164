import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { PositionComponent } from './position.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { PositionService } from 'app/core/services'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

import * as PositionActions from 'app/core/store/actions/noms/position.actions'
import { PositionModel } from 'app/core/models'


@Component({
  templateUrl: './position-mgmt.component.html'
})
export class PositionMgmtComponent extends BaseManagementComponent {

  constructor(
    public store: Store<State>,
    private modal: ModalService,
    private positionService: PositionService) {
    super(store)
  }


  public add() {
    const item: PositionModel = { id: 0, tenantId: '', name: '', isActive: true, hierarchyLevel: 0, officialPositionName: '' }
    this.openModal(item, false)
  }

  public edit(item) {
    this.openModal(item, true)
  }

  openModal(item, isEdit) {
    this.modal.open(PositionComponent, ModalSizeTypes.Medium, { entity: item, isEdit })
      .subscribe(result => {
        if (result.isSubmit) {
          this.positionService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
            this.store.dispatch(new PositionActions.GetListOptions())
          })
        }
      })
  }
}

import { BaseModel } from '../common/base-model'
import { UserSimpleInfo } from '../user/user-simple-info'


export class EditRecomBookViewModel{
    id: number
    title: string
    text: string
    recordDate: Date
    recommendationTypeId: number
    recommendationType: string
    isActive: boolean
    anonymousSender: boolean
    anonymousResponse: boolean
    user: BaseModel
    responseUsers: Array<UserSimpleInfo>
    resultUsers: Array<UserSimpleInfo>
    isAnswered: boolean
}
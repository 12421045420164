import { OnDestroy } from '@angular/core'

import { Guid } from 'guid-typescript'

export class BaseComponent implements OnDestroy {
  protected isAlive = true

  public componentId: Guid = Guid.create()

  constructor() { }

  ngOnDestroy() {
    this.isAlive = false
  }
}



export class WorkScheduleChange  {
  date: string
  isWorkday: boolean
  comment: string
  tenantId: string

  GetDate(): Date {
    return new Date(this.date)
  }

  GetDayType(): boolean {
    return this.isWorkday
  }

  GetComment(): string {
    return this.comment
  }
}

export class AllRecommendations {
  id: number
  title: string
  text: string
  recordDate: Date
  recommendationType: string
  recommendationTypeId: number
  isActive: boolean
  userId: number
  userName: string
  AnonymousSender: boolean
  AnonymousResponse: boolean
  isResponseUser: boolean
  isResultUser: boolean
  responseUserIds: Array<number>
  resultUserIds: Array<number>
  isAnswered: boolean
}

export class AllRecommendationsWithCount {
  count: number
  extendedRecords: AllRecommendations[]
  currentUserAnsweredRecommendationBooks: number[]
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { LoadingHttpParams } from 'app/core/helpers/common/loading-http-params'
import { UserTokens } from 'app/core/models/auth/user-tokens'
import * as AuthAction from 'app/core/store/actions/auth.actions'
import { State } from 'app/core/store/states'
import { selectors } from 'app/core/store/states/auth.states'
import { ModalService } from 'app/dialogs/modal.service'
import { environment } from 'environments/environment'
import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'

import { LanguageStoreService } from '../store'
import { ToastrService } from './toaster.service'
import { TranslationService } from './traslation.service'

const responseMapper = (response: Response) => response

@Injectable()
export class ApiService {

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  })

  api_url: string = environment.apiBaseUrl
  isLoggedIn: boolean
  tokenExpires: Date
  refreshToken: string
  refreshing: boolean = false
  locale: string
  language: string

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private store: Store<State>,
    private router: Router,
    private translationService: TranslationService,
    private languageStoreService: LanguageStoreService,
    private modalService: ModalService,
  ) {

    store.select(selectors.getAuthState).subscribe(auth => {
      this.isLoggedIn = auth.loggedIn
      this.tokenExpires = auth.exp
      this.refreshToken = auth.refreshToken
      this.headers = this.headers.set('Authorization', `Bearer ${auth.accessToken}`)
      this.headers = this.headers.set('TenantId', `${auth.tenantId}`)
    })

    this.languageStoreService.language$.subscribe(lang => {
      this.language = lang
      this.headers = this.headers.set('Accept-Language', this.language)
    })
  }

  get(path: string, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('get', path, null, isBackground, isAuthorized)
  }

  getFile(path: string, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('get', path, null, isBackground, isAuthorized, false, {
      headers: this.headers,
      responseType: 'arraybuffer'
    })
  }

  postExport(path: string, body, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('post', path, body, isBackground, isAuthorized, false, {
      headers: this.headers,
      responseType: 'arraybuffer'
    })
  }

  post(path: string, body, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('post', path, body, isBackground, isAuthorized)
  }

  put(path: string, body, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('put', path, body, isBackground, isAuthorized)
  }

  delete(path, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('delete', path, null, isBackground, isAuthorized)
  }

  patch(path, body = {}, isAuthorized = true, isBackground = false): Observable<any> {
    return this.executeHttpMethod('patch', path, body, isBackground, isAuthorized)
  }

  private executeHttpMethod(httpMethod: string, url: string, data?: { [key: string]: any },
    isBackground = false, isAuthorized = true, isRefreshRequest: boolean = false, options = null): Observable<any> {
    return Observable.defer(async () => {
      await this.authorize(isAuthorized)
      options = options || { headers: this.headers }

      options = {
        ...options,
        params: new LoadingHttpParams(isBackground)
      }

      if (isAuthorized && !this.isLoggedIn) {
        return of(null)
      }

      const httpMethodName: string = httpMethod.toLowerCase()

      const req$: Observable<Response> = data ?
        this.http[httpMethodName](`${this.api_url}${url}`, data, options) :
        this.http[httpMethodName](`${this.api_url}${url}`, options)

      return req$
    }).mergeMap(obs => obs.do
      (() => { })
      .map(responseMapper)
      .catch(isRefreshRequest ? this.handleRefreshExpired.bind(this) : this.handleError.bind(this))
    )
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 400) {
      const messages = err.error.messages

      const resultMessage: string = messages

      this.toastrService.error(resultMessage, this.translationService.translateMsg('messages.errorOccurredTitle'))
    } else if (err.status === 401) {
      this.toastrService.error(this.translationService.translateMsg('messages.unauthorizedError'),
        this.translationService.translateMsg('messages.unauthorizedErrorTitle'))
    } else if (err.status === 403) {
      this.toastrService.error(this.translationService.translateMsg('messages.unauthorizedError'),
        this.translationService.translateMsg('messages.unauthorizedErrorTitle'))
    } else if (err.status === 404) {
      this.toastrService.error(this.translationService.translateMsg('messages.notFoundError'),
        this.translationService.translateMsg('messages.notFoundErrorTitle'))
    } else if (err.status === 500) {
      this.toastrService.error(
        this.translationService.translateMsg('messages.serverError'),
        this.translationService.translateMsg('messages.errorOccurredTitle')
      )
    } else if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.log('An error occurred:', err.error.message)
      console.log('An unexpected error occurred')
      console.log(err)
    } else {
      // The backend returned an unsuccessful response code. The response body may
      // contain clues as to what went wrong, console.log(`Backend returned code
      // ${err.status}, body was: ${err.error}`)
      console.log(err)
    }

    return Observable.empty() // throw(err)
  }

  private handleRefreshExpired(): Observable<any> {
    this.modalService.dismissAll()

    this.refreshing = false
    this.isLoggedIn = false

    this.toastrService.error(
      this.translationService.translateMsg('messages.loginRequired'),
      this.translationService.translateMsg('messages.sessionExpired'))

    this.store.dispatch(new AuthAction.LogoutAction())
    this.router.navigate(['login'])
    return of(null)
  }

  private async authorize(shouldAuthorize) {
    if (shouldAuthorize) {
      while (this.refreshing) {
        await this.resolveAfterSeconds(2000)
      }
    }

    if (!shouldAuthorize || !this.tokenHasExpired()) {
      return this.resolveAfterSeconds(0)
    } else if (this.tokenHasExpired() && !this.refreshing) {

      this.refreshing = true

      return this.refreshTokens(this.refreshToken)
        .do((tokens: UserTokens) => {
          if (tokens) {
            this.store.dispatch(new AuthAction.RefreshSuccessAction(tokens))
          }

          this.refreshing = false
        })
        .toPromise()
    }
  }

  private resolveAfterSeconds(sec = 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, sec)
    })
  }

  private refreshTokens(refreshToken: string): Observable<any> {
    return this.executeHttpMethod('post', `account/refresh`, { 'refreshToken': refreshToken }, false, false, true)
  }

  private tokenHasExpired(): boolean {
    if (this.tokenExpires) {
      const now: Date = new Date()
      return now > this.tokenExpires
    }

    return false
  }

}

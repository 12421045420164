import * as cloneDeep from 'lodash.clonedeep'

import { initialState } from '../states/user.states'

import { UserLinks } from 'app/core/models'
import { UserImage } from 'app/core/models/user/user-image'

import * as UserActions from 'app/core/store/actions/user.actions'

export type Action = UserActions.All

export function reducer(state = initialState, action: Action) {

    switch (action.type) {
        case UserActions.GET_LIST_OPTIONS_SUCCESS: {
            return {
                ...state,
                listOptions: action.payload
            }
        }
        case UserActions.GET_LIST_OPTIONS_NO_FILTERED_SUCCESS: {
            return {
                ...state,
                listOptionsNoFiltered: action.payload
            }
        }
        case UserActions.GET_LIST_OPTIONS_ALL_SUCCESS: {
            return {
                ...state,
                listOptionsAll: action.payload
            }
        }

        case UserActions.GET_LIST_OPTIONS_WITH_EMAILS_SUCCESS: {
            return {
                ...state,
                listOptionsWithEmails: action.payload
            }
        }

        case UserActions.GET_USER_IMAGE_SUCCESS: {
            const images: UserImage[] = cloneDeep(state.profileImages)
            const userImage = images.find(i => i.userId === action.payload.userId)
            if (userImage) {
                userImage.base64Image = action.payload.base64Image
            } else {
                images.push(action.payload)
            }

            return {
                ...state,
                profileImages: images
            }
        }

        case UserActions.GET_USER_LINKS_SUCCESS: {
            const links: UserLinks[] = cloneDeep(state.externalLinks)
            const userLinks = links.find(i => i.userId === action.payload.userId)

            if (userLinks) {
                userLinks.providers = action.payload.providers
            } else {
                links.push(action.payload)
            }

            return {
                ...state,
                externalLinks: links
            }
        }

        default: {
            return state
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

import { Store } from '@ngrx/store'
import { TimeSheetSettingsComponent } from 'app/app-modules/time-sheet/settings/settings.component'
import {
    UserEventNotificationComponent,
} from 'app/app-modules/user-settings/user-event-notification-settings/user-event-notification.component'
import { UserSettingsComponent } from 'app/app-modules/user-settings/user-settings/user-settings.component'
import { AuthStoreService, UserService } from 'app/core'
import { userStatuses } from 'app/shared/components/user-status/resources/user-statuses'

import { KeyBindingsComponent } from '../key-bindings/key-bindings.component'

import { ModalMenuItem } from 'app/dialogs/interfaces/modal-menu-item'
import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { TenantStoreService } from 'app/core/services'
import { PicturesService } from 'app/core/services/common/pictures.service'

import * as AuthActions from 'app/core/store/actions/auth.actions'
import { State } from 'app/core/store/states'
import { selectors } from 'app/core/store/states/auth.states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends BaseAuthComponent implements OnInit {
    @Input() userEmail: string

    @Output() logOut = new EventEmitter<any>()
    @Output() toggle = new EventEmitter<any>()

    statusId: number = userStatuses[0].id
    statuses: any[] = userStatuses
    tenantName: string
    profileLink: string = ''

    logoLink = '/home'

    constructor(
        private router: Router,
        private authStoreService: AuthStoreService,
        store: Store<State>,
        private picturesService: PicturesService,
        private userService: UserService,
        private modalService: ModalService,
        private tenantStoreService: TenantStoreService
    ) {
        super(store)
        this.store.select(selectors.getUserId)
            .takeWhile(_ => this.isAlive)
            .subscribe(userId => {
                if (this.isCompanyAdmin) {
                    this.profileLink = `/profile/admin/${this.userId}`
                } else {
                    this.profileLink = `/profile/${this.userId}`
                }
            })
    }

    toggleClick() {
        if (this.isLogged && !this.isGuest) {
            this.toggle.emit()
        }
    }

    onLogOut() {
        this.store.dispatch(new AuthActions.LogoutAction())

        this.router.navigate(['login'])
    }

    ngOnInit(): void {
        this.authStoreService.statusId$
            .takeWhile(_ => this.isAlive)
            .subscribe(statusId => {
                this.statusId = +statusId
            })
        
        this.tenantStoreService
            .selectedTenantName
            .takeWhile(_ => this.isAlive)
            .subscribe(tenantName => this.tenantName = tenantName)

        if (window.location.pathname.startsWith('/wizard')) {
            this.logoLink = window.location.pathname
        }
    }

    getProfilePicture(): string {
        return this.picturesService.getProfilePic(this.userId)
    }

    selectStatus(statusId: number) {
        this.userService.saveStatus(statusId)
            .first()
            .subscribe(() => this.authStoreService.statusId = statusId)
    }

    openSettings() {
        const items = [new ModalMenuItem(UserSettingsComponent, 'general.general', 'fas fa-cog')]

        if (!this.isCompanyAdmin) {
            items.push(new ModalMenuItem(TimeSheetSettingsComponent, 'general.timeSheet', 'fas fa-business-time'))
            items.push(new ModalMenuItem(UserEventNotificationComponent, 'general.notifications', 'fas fa-bell'))
        }
        this.modalService.menu('general.settings', items, ModalSizeTypes.Large)
            .takeWhile(_ => this.isAlive).subscribe(_ => _)
    }

    openKeyBindings() {
        this.modalService.open(KeyBindingsComponent, ModalSizeTypes.Medium)
            .first()
            .subscribe(_ => _)
    }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { HomeOfficeInput } from '../models/home-office/home-office-input'
import { ApiService } from './common/api.service'

@Injectable()
export class HomeOfficeService {

  constructor(private httpClient: ApiService) {
  }

  public save(model: HomeOfficeInput): Observable<any> {
    return this.httpClient.post('home-office/save', model)
  }

}

import { CommonModule, DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularEditorModule } from '@kolkov/angular-editor'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { ClickOutsideModule } from 'ng-click-outside'
import { ChartsModule } from 'ng2-charts'
import { MultiselectDropdownModule } from 'ng2-multiselect'
import { ColorPickerModule } from 'ngx-color-picker'
import { NgxPaginationModule } from 'ngx-pagination'

import { sharedComponents } from './components'
import { DetailsActionComponent } from './components/action-buttons/details-action.component'
import { ChartComponent } from './components/charts/chart.component'
import { CollectionManipulator } from './components/collection-manipulator'
import { NumberCardComponent } from './components/number-card/number-card.component'
import { ReminderComponent } from './components/reminder/reminder.component'
import { SummernoteElementService } from './components/wysiwyg/summernote-element.service'
import { sharedDirectives } from './directives'
import { sharedPipes } from './pipes'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxPaginationModule,
    MultiselectDropdownModule,
    ColorPickerModule,
    NgbModule,
    TranslateModule,
    ClickOutsideModule,
    AngularEditorModule,
    ChartsModule,
  ],
  declarations: [DetailsActionComponent, ...sharedComponents, ...sharedDirectives, ...sharedPipes],
  exports: [
    CommonModule, HttpClientModule, ReactiveFormsModule, FormsModule, TranslateModule, ...sharedComponents, ...sharedDirectives, ...sharedPipes
  ],
  providers: [SummernoteElementService, CollectionManipulator, DatePipe],
  entryComponents: [ ReminderComponent, ChartComponent, NumberCardComponent, ]

})
export class SharedModule {
  constructor() { }
}

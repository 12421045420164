import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface TenantState {
    listOptions: BaseModel[]
    enterpriseListOptions: BaseModel[]
    tenantId: string
}

export const initialState: TenantState = {
    listOptions: [],
    enterpriseListOptions: [],
    tenantId: null
}

export namespace tenantSelectors {
    export const getTenantState = createFeatureSelector<TenantState>('tenant')
    export const getTenantListOptions = createSelector(getTenantState, state => state.listOptions)
    export const getSelectedTenantId = createSelector(getTenantState, state => state.tenantId)
    export const getEnterpriseListOptions = createSelector(getTenantState, state => state.enterpriseListOptions)
}

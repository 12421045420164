import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Observable } from 'rxjs'

import * as moment from 'moment'

import { BaseComponent } from 'app/components/base/base.component'
import { ConstantPool } from '@angular/compiler'

@Component(
    {
        selector: 'f-timer',
        template: `<span [class]="class"> {{message}} </span>`
    })

export class FormTimerComponent extends BaseComponent implements OnInit {
    @Input() duration: number = 0
    @Input() class: string = ''

    @Output() timeEnded: EventEmitter<void> = new EventEmitter<void>()
    @Output() onChange: EventEmitter<number> = new EventEmitter<number>()

    message = ''
    diff = 0
    finished = false

    constructor() {
        super()
    }

    ngOnInit() {
        this.diff = this.duration ? this.duration * 60 * 1000 : 0
        Observable.interval(1000)
            .takeWhile(_ => this.isAlive && !this.finished)
            .subscribe(_ => {
                this.diff = this.diff - 1000

                if (this.diff <= 0) {
                    this.diff = 0
                    this.finished = true
                    this.timeEnded.emit()
                }

                this.message = moment.utc(this.diff).format('HH:mm:ss')
                this.onChange.emit(this.diff)
            })
    }

}

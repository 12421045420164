import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { HotkeyModule } from 'angular2-hotkeys'
import { CoreModule } from 'app/core'
import { SharedModule } from 'app/shared'
import { SmartTableModule } from 'app/smart-table/smart-table.module'
import { MultiselectDropdownModule } from 'ng2-multiselect'

import { SharedComponentsModule } from '../shared-components/shared-components.module'
import { UserEventNotificationComponent } from './user-event-notification-settings/user-event-notification.component'
import { UserSettingsComponent } from './user-settings/user-settings.component'

import { DialogsModule } from 'app/dialogs/dialogs.module'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SmartTableModule,
    SharedModule,
    CoreModule,
    DialogsModule,
    SharedComponentsModule,
    HotkeyModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MultiselectDropdownModule,
    SmartTableModule,
    SharedModule,
    CoreModule,
    DialogsModule,
    SharedComponentsModule,
    HotkeyModule,
  ],
  declarations: [
    UserSettingsComponent,
    UserEventNotificationComponent,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [],
  entryComponents: [
    UserSettingsComponent,
    UserEventNotificationComponent,
  ]

})
export class UserSettingsModule {
  constructor() { }
}

import { Component, Input } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { MDBaseButtonComponent } from './base-button.component'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

@Component({
  selector: 'icon-btn',
  templateUrl: 'icon-btn.component.html',
})
export class MDIconButtonComponent extends MDBaseButtonComponent {
  @Input() icon: string

  @Input() isConfirm: boolean = false
  @Input() confirmMsg: string = ''

  constructor(private modal: ModalService, private translate: TranslateService) {
    super()
  }

  onBtnClick(event) {
    if (!this.disabled) {
      if (this.isConfirm) {
        const message = this.translate.instant(this.confirmMsg === '' ? 'messages.areYouSure' : this.confirmMsg)

        this.modal.confirm(ModalTypes.Warning, message).subscribe(state => {
          if (state && !this.disabled) {
            this.onClick.emit(event)
          }
        })
      } else {
        this.onClick.emit(event)
      }
    }
  }
}

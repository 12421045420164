import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { TableFilter } from 'app/smart-table/models/table-filter.model'

import { ApiService } from './common/api.service'


@Injectable()

export class ExportService {
  constructor(private httpClient: ApiService) {

  }

  public annualVacationReport(filter: TableFilter): Observable<any> {
    return this.httpClient.postExport('export/annual-team-report', filter)
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserSettings } from '../models';
import { ApiService } from './common/api.service';

@Injectable()
export class SettingsService {

  constructor(private httpClient: ApiService) { }

  getUserSettings(): Observable<UserSettings> {
    return this.httpClient.get('userSettings', true, true)
  }

  saveUserSettings(settings: UserSettings): Observable<any> {
    return this.httpClient.post('userSettings', settings)
  }
}

import { Roles } from 'app/core/constants/roles'

export function hasRole(userRole: string, role: string) {
    switch (role) {
        case Roles.globalAdminRole:
            return userRole === Roles.globalAdminRole
        case Roles.tenantAdminRole:
            return userRole === Roles.globalAdminRole || userRole === Roles.tenantAdminRole
        case Roles.companyAdminRole:
            return userRole === Roles.globalAdminRole || userRole === Roles.tenantAdminRole || userRole === Roles.companyAdminRole
        default:
            return userRole === role
    }
}

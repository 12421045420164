import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import {
    CalendarEventCreatedService,
    HomeOfficeService,
    ToastrService,
    TranslationService,
    UserStoreService,
} from 'app/core'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'
import { Guid } from 'guid-typescript'

import { HomeOfficeComponent } from '../home-office.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  templateUrl: './home-office-report.component.html'
})
export class HomeOfficeReportComponent extends BaseAuthComponent implements OnInit {

  filter: TableFilter = new TableFilter()
  userOptions$: Observable<BaseModel[]>

  constructor(
    store: Store<State>,
    private modal: ModalService,
    private homeOfficeService: HomeOfficeService,
    private toastrService: ToastrService,
    private translationService: TranslationService,
    private userStoreService: UserStoreService,
    private calendarEventCreatedService: CalendarEventCreatedService,
  ) {
    super(store)
  }

  ngOnInit() {
    if (this.isTL || this.isCompanyAdmin) {
      this.getUsers()
    }
  }

  getUsers() {
    this.userOptions$ = this.userStoreService.listOptions$
      .takeWhile(_ => this.isAlive)
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  public add() {
    const eventId: Guid = Guid.create()

    this.modal.open(HomeOfficeComponent, ModalSizeTypes.Medium, {
      entity:
      {
        vacationUserId: this.isTL ? this.userId : undefined,
        date: new Date()
      },
      eventId: eventId,
    })
      .subscribe(result => {
        if (result.isSubmit) {
          this.homeOfficeService.save(result.data).first()
            .subscribe(() => {
              this.calendarEventCreatedService.subject.next(eventId)
              this.toastrService.success(this.translationService.translateMsg('messages.actionSuccessfullyCompleted'))
            })
        }
      })
  }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from './common/api.service'

import { UserWithCount } from 'app/core/models'
import { CompensationDateInputModel } from 'app/core/models/compensations/compensation-date.input-model'
import { CompensationPackedInputModel } from 'app/core/models/compensations/compensation-packed.input-model'

@Injectable()

export class CompensationService {
  constructor(private httpClient: ApiService) {
  }

  public getAllUsersWithCompensations(compensationModel: CompensationDateInputModel): Observable<UserWithCount> {

    return this.httpClient.post('compensation/all-users-with-compensation', compensationModel)
  }

  public transferDays(model: CompensationPackedInputModel): Observable<number> {

    return this.httpClient.post('compensation/transfer-days/', model)
  }

  public getPossibleDateRange(): Observable<CompensationDateInputModel> {
    return this.httpClient.get('compensation/get-posible-date-range')
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

export interface LoadingState {
  loadingActionsCount: number
  text: string
}

export const initialState: LoadingState = {
  loadingActionsCount: 0,
  text: ''
}

export namespace loadingSelectors {
  export const getLoadingMaskState = createFeatureSelector<LoadingState>('loader')
  export const getLoaderStatus = createSelector(getLoadingMaskState, state => state.loadingActionsCount > 0)
  export const getLoaderText = createSelector(getLoadingMaskState, state => state.text)
}

import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChange } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'

@Component({
    selector: 'f-select',
    templateUrl: './f-select.component.html',
    providers: [
        MakeValidatorProviders(FormSelectComponent),
        MakeValueAccessorProviders(FormSelectComponent)
    ]
})
export class FormSelectComponent extends FormBaseComponent implements OnChanges {
    @Input() source: any[] = []
    @Input() img?: string
    @Input() valueProp = 'id'
    @Input() displayProp = 'name'
    @Input() hasNull = false

    @Input() withAction: boolean = false
    @Input() actionOn: boolean = false
    @Input() actionDisabled: boolean = false

    @Input() actionOffTitle: string = 'general.all'
    @Input() actionOnTitle: string = 'general.activePlural'

    @Input() onIconClass = 'fas fa-filter'
    @Input() offIconClass = 'fas fa-check-double'

    @Output() onChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() inputValueChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() actionChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    emptyObject: any = true
    selectedItem: any = {}
    dropdownTouched = false

    nullItem = { id: null }

    constructor(public injector: Injector, private translateService: TranslateService) {
        super(injector)

        this.nullItem[this.displayProp] = this.translateService.instant('general.select')
        this.placeholder = this.translateService.instant('general.select')
    }

    get isValid() {
        return this.control.errors === null || this.control.pristine
    }

    writeValue(value: any) {
        super.writeValue(value)
        this.changeValue(value, this.source)
    }

    changeItem(item) {
        this.selectedItem = item
        this.emptyObject = this.isEmpty(this.selectedItem)
        this.value = item[this.valueProp]
        this.inputValueChange.emit(this.value)

        this.onChange.emit(item)
    }

    changeValue(value, source) {
        const item = source ? source.find(c => c[this.valueProp] === value) : undefined
        this.emptyObject = this.isEmpty(this.selectedItem)

        if (item && (this.selectedItem[this.valueProp] !== value)) {
            this.changeItem(item)
        } else if (!item) {
            this.selectedItem = {}
            this.emptyObject = true
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['source'] && this.source) {
            this.changeValue(this.value, this.source)
        }
    }

    private isEmpty(item) {
        return item && Object.keys(item).length === 0 && item.constructor === Object
    }

    showHideDropdown() {
        this.dropdownTouched = true;
    }

    onBlur() {
        super.onBlur();
        this.dropdownTouched = true;
    }

    arrowup() {
        if (this.source) {
            const index = this.source.findIndex(c => c[this.valueProp] === this.value)
            const nextIndex = index === 0 ? 0 : index - 1
            const item = this.source[nextIndex]
            if (item) {
                this.changeItem(item)
            }
        }
    }

    changeAction(event) {
        this.actionChange.emit(event)
    }

    arrowdown() {
        if (this.source) {
            const index = this.source.findIndex(c => c[this.valueProp] === this.value)
            const nextIndex = index + 1 > this.source.length - 1 ? index : index + 1
            const item = this.source[nextIndex]
            if (item) {
                this.changeItem(item)
            }
        }
    }
}

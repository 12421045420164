import { Component } from '@angular/core';
import { ResetUserPasswordModel } from 'app/core/models/user/reset-user-password';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {

  entity: ResetUserPasswordModel = {
    email: ''
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }

}

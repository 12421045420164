import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from 'app/core';
import { DialogsModule } from 'app/dialogs/dialogs.module';
import { SharedModule } from 'app/shared';
import { SmartTableModule } from 'app/smart-table/smart-table.module';

import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { HomeOfficeMgmtComponent } from './home-office-mgmt.component';
import { HomeOfficeReportComponent } from './home-office-report/home-office-report.component';
import { HomeOfficeComponent } from './home-office.component';
import { HomeOfficeRoutingModule } from './home-office.routing';
import { CommonDatePipe } from 'app/shared/pipes/common-date.pipe';

@NgModule({
  imports: [
    CommonModule,
    HomeOfficeRoutingModule,
    SmartTableModule,
    SharedModule,
    SharedComponentsModule,
    CoreModule,
    DialogsModule,
  ],
  declarations: [
    HomeOfficeMgmtComponent,
    HomeOfficeComponent,
    HomeOfficeReportComponent
  ],
  entryComponents: [
    HomeOfficeComponent
  ],
  providers: [
    CommonDatePipe,
  ]
})
export class HomeOfficeModule { }

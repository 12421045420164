import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service'

import { Admin } from '../models';
import { Observable } from 'rxjs';


@Injectable()
export class AdminService {
    private object = 'admins'

    constructor(private httpClient: ApiService) { }

    getAdmin(id: number): Observable<Admin> {
        return this.httpClient.get(`${this.object}/${id}`)
    }

    saveAdmin(admin: Admin) {
        return this.httpClient.post(`${this.object}/save`, admin)
    }
}

import { Injectable } from '@angular/core'

import { ApiService } from './common/api.service'
import { HttpObjectService } from './common/http-object.service'

@Injectable()
export class WorkScheduleChangeService extends HttpObjectService {
  object = 'work-schedule-change'

  constructor(public apiService: ApiService) {
    super(apiService)
  }

  loadPermanentHolidays(year: number) {
    return this.apiService.post(`${this.object}/permanent-holidays/${year}`, {})
  }
}

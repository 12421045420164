import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'
import { FileType } from 'app/core'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { VacationRequestComponent } from '../vacation-request/vacation-request.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

import {
    DownloaderService,
    EnumStoreService,
    ToastrService,
    UserStoreService,
    VacationRequestService,
} from 'app/core/services'

import { BaseModel, VacationRequestReport } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({ templateUrl: './vacation-report.component.html' })

export class VacationReportComponent extends BaseAuthComponent implements OnInit {
  vacationRequestReports: VacationRequestReport[] = []

  leaveTypes: BaseModel[]
  statusTypes$: Observable<BaseModel[]>

  userOptions$: Observable<BaseModel[]>
  selectedUsers: any[] = []

  filter: TableFilter = new TableFilter()

  enableExportButton: boolean

  constructor(store: Store<State>,
    private reportDownloaderService: DownloaderService,
    private requestService: VacationRequestService,
    private modal: ModalService,
    private downloaderService: DownloaderService,
    private enumStoreService: EnumStoreService,
    private toastrService: ToastrService,
    private userStoreService: UserStoreService
  ) {
    super(store)
  }

  ngOnInit() {
    if (this.isTL || this.isCompanyAdmin) {
      this.getUsers()
    }

    this.enumStoreService.leaveTypes$
      .takeWhile(_ => this.isAlive)
      .subscribe(leaveTypes => {
        this.leaveTypes = leaveTypes
      })

    this.statusTypes$ = this.enumStoreService.requestStatusTypes$
      .takeWhile(_ => this.isAlive)
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  exportReports() {
    this.requestService.exportReportsByPeriod(this.filter.customFilter)
      .first()
      .subscribe(response => {
        this
          .reportDownloaderService
          .downloadFile(response, 'vacations-report', FileType.Excel)
      }, error => this.modal.info(ModalTypes.Alert, error), () => this.enableExportButton = false)
  }

  getUsers() {
    this.userOptions$ = this.userStoreService.listOptions$
    .takeWhile(_ => this.isAlive)
  }

  print(id: number) {
    this
      .requestService
      .print(id)
      .first()
      .subscribe(response => {
        this.downloaderService.downloadFile(response, 'vacation-request', FileType.Pdf)
      })
  }

  add() {
    this.modal.open(VacationRequestComponent, ModalSizeTypes.Medium,
      {
        entity:
        {
          vacationUserId: this.isTL ? this.userId : undefined
        }
      })
      .subscribe(result => {
        if (result.isSubmit) {
          this.requestService.addRequest(result.data).first()
            .subscribe(() => this.toastrService.success('messages.actionSuccessfullyCompleted', null, true))
        }
      })
  }
}

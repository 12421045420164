import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
} from '@angular/core'

import { TablePage } from 'app/smart-table/models/table-page.model'

import { TableFilter } from '../models/table-filter.model'
import { NgTableService } from '../services/ng-table.service'
import { RowBackgroudService } from '../services/row-backgroud.service'
import { STButtonComponent, STColumnComponent, STFilterComponent } from './s-t-column.component'
import { STRowComponent } from './s-t-row.component'

@Component({
  selector: 'smart-table',
  styleUrls: ['./smart-table.component.scss'],
  templateUrl: './smart-table.component.html'
})
export class SmartTableComponent implements OnChanges, AfterViewInit {
  @ContentChildren(STColumnComponent) columns: QueryList<STColumnComponent>
  @ContentChildren(STRowComponent) rows: QueryList<STRowComponent>
  @ContentChildren(STButtonComponent) buttons: QueryList<STButtonComponent>
  @ContentChild(STFilterComponent, { static: true }) customFilter: STFilterComponent

  @Input() title: string
  @Input() hasFilter = true

  @Input() hasIsActiveFilter = false
  @Input() activeKey = 'general.active'

  @Input() hasGrouping: boolean = false
  @Input() isSwitchedOn = true

  @Input() page: TablePage = new TablePage()
  @Input() filter: TableFilter = new TableFilter()

  @Input() pageable = true
  @Input() sortable = true

  @Input() clickableRows: boolean = false

  @Output() rowSelected = new EventEmitter<any>()
  @Output() filterChange = new EventEmitter<any>()

  @Output() activeItem: any = null

  sorters: {}
  filterQuery = ''

  prevFilterStr: string

  constructor(public st: NgTableService, public rowBgService: RowBackgroudService) {
    this.st.activePage = 1
    this.st.rowsOnPage = 10

    if (!this.pageable) {
      this.st.rowsOnPage = null
      this.filter.rowsOnPage = null
    }
  }

  ngOnChanges(changes: any): any {
    if (changes.page) {
      const quotient = Math.floor(this.page.totalCount / this.st.rowsOnPage)
      const remainder = this.page.totalCount % this.st.rowsOnPage

      this.st.lastPage = quotient + (remainder > 0 ? 1 : 0)
      this.hideColsIfNeeded()
    }
  }

  hideColsIfNeeded() {
    if (!this.hasGrouping && this.columns && this.page.data.length > 0 && this.rows) {
      this.columns.forEach(col => {
        if (col.name) {
          col.visible = !(col.hideIfAllEmpty && this.page.data.every(row => !row[col.name]))
        }
      })
    }
  }

  get groupColumns() {
    return this.columns.filter(c => c.groupTitle !== '')
  }

  get columnHeaders() {
    return this.columns.filter(c => !this.hasGrouping || c.rowspan === 1)
  }

  onSliderClick() {
    this.filter.onlyActive = this.isSwitchedOn
    this.emitFilterChange()
  }

  ngAfterViewInit() {
    const children = this.columns['_results'].filter(c => c.checkAll.observers.length === 0)
    this.sorters = {}
    this.sorters = this.st.prepareSorters(children, this.sorters)

    if (this.sortable) {
      this.st.initSorter(this.filter.orderBy, this.sorters)
    }

    this.filterChanged('')
  }

  emitFilterChange() {
    this.filterChange.emit(this.filter)
  }

  filterChanged(filterStr) {
    this.filter.filter = filterStr

    if (filterStr === '') {
      this.prevFilterStr = undefined
    }

    if (
      (filterStr !== '' && this.prevFilterStr === undefined) ||
      (this.prevFilterStr !== undefined && this.prevFilterStr !== filterStr)
    ) {
      this.st.activePage = 1
      this.prevFilterStr = filterStr
    }

    this.filter.page = this.st.activePage
    this.filter.rowsOnPage = this.pageable ? this.st.rowsOnPage : null

    const sortCol = Object.keys(this.sorters).find(
      k => this.sorters[k].asc || this.sorters[k].desc
    )

    if (sortCol !== undefined) {
      this.filter.orderBy = `${sortCol
        .substring(0, 1)
        .toUpperCase()}${sortCol.substring(1)} ${
        this.sorters[sortCol].asc ? 'asc' : 'desc'
        }`
    }

    this.emitFilterChange()
  }

  setPage(targetPage) {
    this.st.activePage = targetPage
    this.filterChanged(this.filterQuery)
  }

  setRowsOnPage(rows) {
    this.st.rowsOnPage = rows
    this.st.activePage = 1

    this.filterChanged(this.filterQuery)
  }

  clickSorter(colName) {
    if (this.sortable) {
      this.st.clickSorter(colName, this.sorters)

      this.filterChanged(this.filterQuery)
    }
  }

  rowSelect(entity) {
    this.rowSelected.emit(entity)
  }

  onCheckAll(event, col: STColumnComponent) {
    col.checkAll.emit(event.target.checked);
  }

  colClass(col: STColumnComponent, checkRowspan: boolean): string {
    let colClass = col.hClass

    let draw = this.sortable && col.name && this.sorters && this.sorters[col.name]

    if (checkRowspan) {
      draw = draw && col.rowspan > 1
    }

    if (draw) {
      colClass = `${colClass} sort`

      if (this.sorters[col.name].asc) {
        colClass = `${colClass} asc`
      } else if (this.sorters[col.name].desc) {
        colClass = `${colClass} desc`
      }
    }

    if (col.static) {
      colClass += " fixed"
    }

    return colClass
  }

  btnClick(btn: STButtonComponent): void {
    btn.onClick.emit()
  }

  rowClass(rowData: any, index: number) {
    if (this.sorters) {
      const sortCol = Object.keys(this.sorters).find(
        k => this.sorters[k].asc || this.sorters[k].desc
      )

      return this.rowBgService.getClass(rowData, sortCol, index)
    } else {
      return ''
    }
  }
}

import { Action } from '@ngrx/store'

import { type } from '../../util'

export const GET_WORKDAYS = type('[WORKDAYS] GET')
export const GET_WORKDAYS_SUCCESS = type('[WORKDAYS] GET_SUCCESS')
export const GET_WORKDAYS_ERROR = type('[WORKDAYS] GET_ERROR')

export const GET_ENTERED_HOURS = type('[ENTERED_HOURS] GET')
export const GET_ENTERED_HOURS_SUCCESS = type('[ENTERED_HOURS] GET_SUCCESS')
export const GET_ENTERED_HOURS_ERROR = type('[ENTERED_HOURS] GET_ERROR')

export const GET_CURRENT_WORK_DAYS = type('[CURRENT_WORK_DAYS] GET')
export const GET_CURRENT_WORK_DAYS_SUCCESS = type('[CURRENT_WORK_DAYS] GET_SUCCESS')
export const GET_CURRENT_WORK_DAYS_ERROR = type('[CURRENT_WORK_DAYS] GET_ERROR')


export class GetWorkDays implements Action {
    readonly type = GET_WORKDAYS
}

export class GetWorkDaysSuccess implements Action {
    readonly type = GET_WORKDAYS_SUCCESS

    constructor(public payload: number) { }
}

export class GetWorkDaysError implements Action {
    readonly type = GET_WORKDAYS_ERROR
}

export class GetEnteredHours implements Action {
    readonly type = GET_ENTERED_HOURS
}

export class GetEnteredHoursSuccess implements Action {
    readonly type = GET_ENTERED_HOURS_SUCCESS

    constructor(public payload: number) { }
}

export class GetEnteredHoursError implements Action {
    readonly type = GET_ENTERED_HOURS_ERROR
}

export class GetCurrentWorkDays implements Action {
    readonly type = GET_CURRENT_WORK_DAYS
}

export class GetCurrentWorkDaysSuccess implements Action {
    readonly type = GET_CURRENT_WORK_DAYS_SUCCESS

    constructor(public payload: number) { }
}

export class GetCurrentWorkDaysError implements Action {
    readonly type = GET_CURRENT_WORK_DAYS_ERROR
}


export type All =
    GetWorkDays | GetWorkDaysSuccess | GetWorkDaysError
    | GetEnteredHours | GetEnteredHoursSuccess | GetEnteredHoursError
    | GetCurrentWorkDays | GetCurrentWorkDaysSuccess | GetCurrentWorkDaysError

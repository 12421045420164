import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { BaseModel } from '../models/common/base-model'
import { ApiService } from './common/api.service'
import { HttpObjectService } from './common/http-object.service'

@Injectable()
export class AppService extends HttpObjectService {
  object = 'app'

  constructor(public apiService: ApiService) {
    super(apiService)
  }

  getModules(): Observable<BaseModel[]> {
    return this.apiService.get(`${this.object}/modules`, true, true)
  }
}

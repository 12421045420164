export class RecommendationBookInput{
    id: number
    userId: number
    title: string
    text: string
    recommendationTypeId: number
    isActive: boolean = false
    anonymousSender: boolean = false
    anonymousResponse: boolean = false
    shouldNotify: boolean = false
    responseUserIds: Array<number>
    resultUserIds: Array<number>
}
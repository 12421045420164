import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'

@Component({
  selector: 'f-input',
  templateUrl: 'f-input.component.html',
  providers: [
    MakeValidatorProviders(FormInputComponent),
    MakeValueAccessorProviders(FormInputComponent)
  ]
})
export class FormInputComponent extends FormBaseComponent {
  @Input() inputType = 'text'
  @Input() maxLength?: number
  @Input() minLength?: number
}

/// STATUS CODES
///
/// 100 - 199(inc) - SUCCESS
/// 200 - 299(inc) - WARNING
/// 300 - 399(inc) - ALERT

export const VacationMessages = {}
// SUCCESS
VacationMessages[100] = 'You have successfully requested a vacation.'
VacationMessages[101] = 'You have successfully canceled your request.'
VacationMessages[102] = 'You have successfully added vacation days to all employees for this year.'

// WARNING
VacationMessages[200] = 'You do not have enough days.'
VacationMessages[201] = 'Your request has changed.'
VacationMessages[202] = 'Duplicated vacation days.'

// ERROR
VacationMessages[300] = 'Invalid vacation days!'
VacationMessages[301] = 'Invalid user!'
VacationMessages[302] = 'Invalid date range!'

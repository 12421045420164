import * as loadingMask from '../actions/loading-mask.actions'
import { initialState, LoadingState } from '../states/loading-mask.states'


export function reducer(state = initialState, action: loadingMask.Actions): LoadingState {
  switch (action.type) {

    case loadingMask.ActionTypes.LOAD: {
      return Object.assign({}, state, {
        loadingActionsCount: state.loadingActionsCount + 1
      })
    }
    case loadingMask.ActionTypes.LOAD_DONE: {
      return Object.assign({}, state, {
        loadingActionsCount: Math.max(state.loadingActionsCount - 1, 0)
      })
    }
    case loadingMask.ActionTypes.SET_LOADING_TEXT: {
      return Object.assign({}, state, {
        text: action.payload
      })
    }

    default: {
      return state
    }
  }
}

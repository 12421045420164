import * as TenantActions from 'app/core/store/actions/tenant.actions'
import { initialState } from '../states/tenant.states'


export type Action = TenantActions.All

export function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case TenantActions.GET_LIST_OPTIONS_SUCCESS : {
            return {
                ...state,
                listOptions: action.payload
            }
        }

        case TenantActions.GET_ENTERPRISE_LIST_OPTIONS_SUCCESS : {
            return {
                ...state,
                enterpriseListOptions: action.payload
            }
        }

        case TenantActions.SELECT_TENANT: {
            return {
                ...state,
                tenantId: action.payload
            }
        }

        default: return state
    }
}

import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import { FileType } from 'app/core'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'
import { Guid } from 'guid-typescript'

import { SickLeaveComponent } from '../sick-leave.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import {
    CalendarEventCreatedService,
    DownloaderService,
    SickLeaveService,
    ToastrService,
    UserStoreService,
} from 'app/core/services'

import { BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  selector: 'sick-leave-report',
  templateUrl: './sick-leave-report.component.html'
})
export class SickLeaveReportComponent extends BaseAuthComponent implements OnInit {

  filter: TableFilter = new TableFilter()
  userOptions$: Observable<BaseModel[]>

  fromDate = null
  toDate = null

  dates: Date[] = [new Date()]

  constructor(store: Store<State>,
    private downloaderService: DownloaderService,
    private sickLeaveService: SickLeaveService,
    private modal: ModalService,
    private toastrService: ToastrService,
    private userStoreService: UserStoreService,
    private calendarEventCreatedService: CalendarEventCreatedService,
  ) {
    super(store)
  }

  ngOnInit() {
    if (this.isTL || this.isCompanyAdmin) {
      this.getUsers()
    }
  }

  getUsers() {
    this.userOptions$ = this.userStoreService.listOptions$
      .takeWhile(_ => this.isAlive)
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
  }

  public exportReports() {
    this.sickLeaveService.export(this.filter.customFilter)
      .first()
      .subscribe(response => {
        this.downloaderService
          .downloadFile(response, 'sick-leave-report', FileType.Excel)
      })
  }

  public add() {
    const eventId: Guid = Guid.create()

    this.modal.open(SickLeaveComponent, ModalSizeTypes.Medium, {
      entity: {
        vacationUserId: this.isTL ? this.userId : undefined
      },
      eventId: eventId,
    })
      .subscribe(result => {
        if (result.isSubmit) {
          this.sickLeaveService.addSickLeave(result.data).first().subscribe(() => {
            this.calendarEventCreatedService.subject.next(eventId)
            this.toastrService.success('messages.actionSuccessfullyCompleted', null, true)
          })
        }
      })
  }
}

import { Component, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { BaseActionComponent } from './base-action.component'

@Component({
  selector: 'edit-action',
  templateUrl: './edit-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditActionComponent extends BaseActionComponent {

  constructor(public el: ElementRef) {
    super(el)
  }
}

import { Component, Input } from '@angular/core'

@Component({
  selector: 'f-error',
  templateUrl: 'f-error.component.html'
})
export class FormErrorComponent {
  @Input() inputLabel = ''
  @Input() model: any
  @Input() maxLength?: number
  @Input() minLength?: number

  @Input() max?: number
  @Input() min?: number
  @Input() divisibleBy?: number

  @Input() maxDate?: any
  @Input() minDate?: any
  // @Input() minDateInvalid?: any

  displayShort(minDate) {
    const x = minDate.toString().substring(4, 15)
    return x;
  }
}

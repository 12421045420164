import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core'

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BaseActionComponent implements OnChanges {
  @Input() disabled = false
  @Output() onClick = new EventEmitter<any>()

  constructor(protected el: ElementRef) { }

  ngOnChanges() {
    if (this.disabled) {
      this.el.nativeElement.children[0].setAttribute('disabled', 'true')
    }
  }

  onBtnClick(event) {
    event.stopPropagation()

    if (!this.disabled) {
      this.onClick.emit(event)
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Observable } from 'rxjs'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'

@Component({
    selector: 'f-dropdown',
    templateUrl: './f-dropdown.component.html',
    providers: [
        MakeValidatorProviders(FormDropdownComponent),
        MakeValueAccessorProviders(FormDropdownComponent)
    ]
})
export class FormDropdownComponent extends FormBaseComponent {
    @Input() useTypeahead: boolean
    @Input() title: string = 'name'

    @Input() source: any[] = []
    @Input() hasNull: boolean = false
    @Input() search: (term: string) => Observable<any[]>

    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>()

    onSelectedChanged(event) {
        this.selectionChange.emit(event)
    }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { BaseModel, UserBasicInfo } from 'app/core/models'

import { State } from 'app/core/store/states/permanent-holiday.states'
import { userSelectors } from 'app/core/store/states/user.states'

@Injectable()

export class UserStoreService {

    constructor(private store$: Store<State>) { }

    public get listOptions$(): Observable<BaseModel[]> {
        return this.store$.select(userSelectors.getUserListOptions)
    }

    public get listOptionsNoFiltered$(): Observable<BaseModel[]> {
        return this.store$.select(userSelectors.getUserListOptionsNoFiltered)
    }

    public get listOptionsAll$(): Observable<BaseModel[]> {
        return this.store$.select(userSelectors.getUserListOptionsAll)
    }

    public get getOptionsWithEmails$(): Observable<UserBasicInfo[]> {
        return this.store$.select(userSelectors.getUserListOptionsWithEmails)
    }
}

import { Injectable, OnDestroy } from '@angular/core'

import { HubConnection } from '@aspnet/signalr'

import { AuthStoreService } from './store/auth-store.service'

@Injectable()
export class PushService implements OnDestroy {

  hubConnection: HubConnection
  accessToken = ''

  constructor(private authStoreService: AuthStoreService) {
    this.authStoreService.accessToken$
      .subscribe(accessToken => {
        this.accessToken = accessToken

        if (accessToken !== '') {
          this.connect(accessToken)
        }
      })
  }

  disconnect() {
    if (this.hubConnection) {
      this.hubConnection.stop()
    }
  }

  connect(accessToken: string) {
    this.disconnect()

    // this.hubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(environment.pushUrl, {
    //     transport: signalR.HttpTransportType.LongPolling,
    //     accessTokenFactory: () => accessToken
    //   })
    //   .build()

    // this.hubConnection
    //   .start()
    //   .then(() => console.log('Connection started'))
    //   .catch(err => console.log('Error while establishing connection'))
  }

  ngOnDestroy(): void {
    this.disconnect()
  }
}

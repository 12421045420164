export class VacationExportViewModel {
  id: number
  location: string
  officialPositionName: string
  leaveTypeName: string
  description: string
  fullName: string
  textVacationDays: string
  article: string
  numberOfVacationDays: number
  totalDays: number
  fromDate: Date
  toDate: Date
  actionDate: Date
  company: string
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'


export interface AppState {
    modules: BaseModel[]
}

export const initialState: AppState = {
    modules: []
}

export namespace appSelectors {
    export const getAppState = createFeatureSelector<AppState>('app')
    export const getModules = createSelector(getAppState, state => state.modules)
}

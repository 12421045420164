import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from '../common/api.service'
import { HttpObjectService } from '../common/http-object.service'

import { ProjectByTeamLeadView } from 'app/core/models/project-teamlead-view'
import { TeamLeadProjectInput } from 'app/core/models/teamlead-project-input'

@Injectable()

export class ProjectService extends HttpObjectService {
  object = 'project'

  constructor(public apiService: ApiService) {
    super(apiService)
  }

  getAllProjectsByTeamLead(model): Observable<ProjectByTeamLeadView[]> {
    return this.apiService.post('project/projectsByTL', model, true)
  }

  getAllSelectedProjectsByTeamLead(model): Observable<any[]> {
    return this.apiService.post('project/selectedProjectsByTL', model, true)
  }

  updateProjectToTeamLead(model: TeamLeadProjectInput): Observable<any> {
    return this.apiService.post('project/updateProjectByTeamLead', model, true)
  }
}

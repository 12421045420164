import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Months, Resources } from 'app/core/constants'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

import { CompensationService } from 'app/core/services/compensation.service'

import {
  CompensationDateInputModel,
  CompensationInputModel,
  CompensationPackedInputModel,
  UserCompensation,
} from 'app/core/models'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  templateUrl: './compensation.component.html',
})

export class CompensationComponent extends BaseComponent implements OnInit {
  currentSortingColumn: string = 'fullname'
  isLoaded: boolean = false

  userList: UserCompensation[] = []
  compensationInputModel: CompensationInputModel = new CompensationInputModel
  calendarMonths: any = Months
  compensationCalculationInputModel: CompensationInputModel[] = []
  public compensationDate: CompensationDateInputModel = new CompensationDateInputModel
  compensationPackedInputModel: CompensationPackedInputModel = new CompensationPackedInputModel
  currentYear = new Date().getFullYear()

  constructor(private route: ActivatedRoute,
    private router: Router,
    private compensationService: CompensationService,
    private modalService: ModalService) {
    super()
  }

  ngOnInit() {
    this.compensationService.getPossibleDateRange()
      .first()
      .subscribe(dateRange => {

        this.compensationDate = dateRange

        if (this.compensationDate.toMonth === 0 ||
          this.compensationDate.toYear === 0 ||
          this.compensationDate.fromMonth === 0 ||
          this.compensationDate.fromYear === 0) {

          this.modalService.info(ModalTypes.Warning, Resources.thereAreNoTimeSheetOvertimes)

          this.router.navigate(['home'])
        }
      })
  }

  private transferDays(): void {
    for (let i = 0; i < this.userList.length; i++) {
      this.compensationCalculationInputModel[i] = {
        userId: this.userList[i].id,
        calculatedDays: this.userList[i].calculatedDays
      }
    }

    this.compensationPackedInputModel.compensationDateInputModel = this.compensationDate
    this.compensationPackedInputModel.compensationCalculationInputModel = this.compensationCalculationInputModel

    this.compensationService.transferDays(this.compensationPackedInputModel)
      .first()
      .subscribe(_ => {
        this.router.navigate(['home'])
      })
  }

  showCompensation(): void {
    if (this.checkForNonValues()) {
      this.modalService.info(ModalTypes.Warning, Resources.enterValidDate)
    } else if (this.validateDateRange()) {
      this.modalService.info(ModalTypes.Warning, Resources.invalidDateRangeEqual)
    } else {
      this.compensationService.getAllUsersWithCompensations(this.compensationDate)
        .first()
        .subscribe(obs => {
          this.userList = obs.users
          this.compensationDate.totalHoursTransferred = obs.totalHoursTransferred

          if (this.userList.length !== 0) {
            this.isLoaded = true
          }

          if (this.userList.length === 0) {
            this.isLoaded = false

            this.modalService.info(ModalTypes.Warning, Resources.weCantShowYou)
          }

          if (this.checkForNonValues()) {
            this.modalService.info(ModalTypes.Warning, Resources.enterValidDate)
          } else if (this.validateDateRange()) {
            this.modalService.info(ModalTypes.Warning, Resources.invalidDateRangeEqual)
          }
        })
    }
  }

  private validateDateRange(): boolean {
    const firstDayInMonth = 1
    const lastDayInMonth = new Date(this.compensationDate.toYear, this.compensationDate.toMonth, 0).getDate()

    const fromDate = new Date(this.compensationDate.fromYear, this.compensationDate.fromMonth - 1, firstDayInMonth)
    const toDate = new Date(this.compensationDate.toYear, this.compensationDate.toMonth - 1, lastDayInMonth)

    if (fromDate > toDate) {
      return true
    }

    return false
  }

  private checkForNonValues(): boolean {
    if ((this.compensationDate.fromMonth <= 0 ||
      this.compensationDate.fromYear <= 0 ||
      this.compensationDate.toMonth <= 0 ||
      this.compensationDate.toYear <= 0) &&
      (this.compensationDate.fromMonth > 12 ||
        this.compensationDate.fromYear > this.currentYear ||
        this.compensationDate.toMonth > 12 ||
        this.compensationDate.toYear > this.currentYear)) {

      return true
    }

    return false
  }

  private sortTableCol(colName) {
    if (this.currentSortingColumn === colName) {
      this.currentSortingColumn = '-' + this.currentSortingColumn
    } else {
      this.currentSortingColumn = colName
    }
  }
}

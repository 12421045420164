import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from '../common/api.service'

import { TimeSheetSetting } from 'app/core/models/time-sheet'

@Injectable()

export class TimeSheetSettingService {

    constructor(private httpClient: ApiService) { }

    get(): Observable<TimeSheetSetting> {
        return this.httpClient.get(`time-sheet-setting`)
    }

    save(model: TimeSheetSetting): Observable<TimeSheetSetting> {
        return this.httpClient.post('time-sheet-setting/save', model)
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

import { TranslateService } from '@ngx-translate/core'
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ng2-multiselect'

import { AbstractValueAccessor, MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'

@Component({
  selector: 'f-multiselect',
  templateUrl: 'f-multiselect.component.html',
  styleUrls: ['f-multiselect.component.scss'],
  providers: [
    MakeValidatorProviders(FormMultiSelectComponent),
    MakeValueAccessorProviders(FormMultiSelectComponent)
  ]
})
export class FormMultiSelectComponent extends AbstractValueAccessor implements OnInit {
  @Input() required = false
  @Input() inputLabel: string = null
  @Input() disabled = false
  @Input() readonly = false
  @Input() labelCss: string = ''
  @Input() cssClass: string = ''
  @Input() isHorizontal?: boolean = false
  @Input() name: string
  @Input() settings: IMultiSelectSettings = {
    keyToSelect: 'id',
    lableToDisplay: 'name',
    isSimpleArray: false,
    buttonClasses: 'btn btn-outline-secondary w-100 text-truncate',
    checkedStyle: 'fontawesome',
  }
  @Input() options: IMultiSelectOption
  @Input() texts: IMultiSelectTexts = {
    defaultTitle: this.translateService.instant('general.select'),
    checked: this.translateService.instant('general.checked'),
    checkedPlural: this.translateService.instant('general.checkedPlural')
  }

  @Input() withAction: boolean = false
  @Input() actionOn: boolean = false
  @Input() actionDisabled: boolean = false

  @Input() actionOffTitle: string = 'general.all'
  @Input() actionOnTitle: string = 'general.activePlural'

  @Input() onIconClass = 'fas fa-filter'
  @Input() offIconClass = 'fas fa-check-double'

  @Output() actionChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private translateService: TranslateService) {
    super()
  }

  ngOnInit(): void {
    if (this.withAction) {
      this.settings.buttonClasses = `${this.settings.buttonClasses} btn-rounded-left`
    }
  }

  public validate(c: FormControl) {
    return this.baseValid(c.value) ? null : {
      jsonParseError: { valid: false }
    }
  }

  protected baseValid(value) {
    return !this.required || (value != null && value !== undefined && value.length > 0)
  }


  get isValid() {
    return this.baseValid(this.value)
  }

  changeAction(event) {
    this.actionOn = event
    this.actionChange.emit(event)
  }
}

import { Component, Input } from '@angular/core'
import { BaseComponent } from 'app/components/base/base.component'

@Component({
    selector: 'number-card',
    templateUrl: './number-card.component.html',
    styleUrls: [
        './number-card.component.scss',
    ],
})

export class NumberCardComponent extends BaseComponent {

    @Input() number: number = 0
    @Input() numberColor: string = ''

    @Input() bottomText: string = ''
    @Input() prefix: string = ''
    @Input() postfix: string = ''

    constructor(
    ) {
        super()
    }
}

import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { FormControl, NgControl } from '@angular/forms'

import { Guid } from 'guid-typescript'

import { AbstractValueAccessor } from '../abstract-value-accessor'

@Component({
    selector: 'f-base',
    template: ''
})

export class FormBaseComponent extends AbstractValueAccessor implements OnInit {
    @Input() disabled = false
    @Input() readonly = false
    @Input() required = false

    @Input() inputLabel: string = null
    @Input() placeholder = ''
    @Input() labelCss = ''
    @Input() cssClass = ''
    @Input() groupCss = 'form-group'

    @Input() isHorizontal: boolean = false
    @Input() name: string

    @Output() blur: EventEmitter<any> = new EventEmitter<any>()

    id: string = null
    control: NgControl

    constructor(public injector: Injector) {
        super()
        this.id = Guid.create().toString()
    }

    ngOnInit() {
        this.control = this
            .injector
            .get(NgControl)
    }

    get isValid() {
        return true
    }

    validate(c: FormControl) {

        return this.control.valid ? null : {
            jsonParseError: {
                valid: false,
            },
        }
    }

    onBlur() {
        this.blur.emit()
        this.onTouchedCallback()
    }
}

import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

@Component({ selector: 's-t-col', template: '' })
export class STColumnComponent {
  @Input() name: string
  @Input() title: string

  @Input() zClass = ''
  @Input() hClass = 'text-left'
  @Input() visible: boolean = true

  @Input() colspan: number = 1
  @Input() rowspan: number = 1
  @Input() groupTitle: string = ''

  @Input() static: boolean = false
  @Input() hideIfAllEmpty: boolean = true

  @Output() checkAll = new EventEmitter()

  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
}

@Component({ selector: 'column-template-loader', template: '' })
export class ColumnTemplateLoader implements OnChanges {
  @Input() column: any;
  @Input() rowData: any;
  @Input() rowIndex: number;

  constructor(public viewContainer: ViewContainerRef) { }

  ngOnChanges(changes: {
    [propKey: string]: SimpleChange
  }) {
    this
      .viewContainer
      .clear()
    const view = this
      .viewContainer
      .createEmbeddedView(this.column.template, {
        '\$implicit': this.column,
        'item': this.rowData,
        'rowIndex': this.rowIndex
      });
  }
}

@Component({ selector: 's-t-filter', template: '' })
export class STFilterComponent {
  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  constructor() { }
}

@Component({ selector: 's-t-filter-loader', template: '' })
export class STFilterLoaderComponent implements OnChanges {
  @Input() filter: any;

  constructor(public viewContainer: ViewContainerRef) { }

  ngOnChanges(changes: {
    [propKey: string]: SimpleChange
  }) {
    this
      .viewContainer
      .clear()
    const view = this
      .viewContainer
      .createEmbeddedView(this.filter.template, { '\$implicit': this.filter })
  }
}

@Component({ selector: 's-t-button', template: '' })
export class STButtonComponent {
  @Input() icon: string = 'fa fa-plus'
  @Input() title: string
  @Input() cssClass = 'btn-primary'
  @Input() disabled: boolean = false

  @Input() tooltipTitle = ''
  @Input() tooltipInfo = ''
  @Input() hasTooltip: boolean

  @Output() onClick = new EventEmitter<any>()

  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
}

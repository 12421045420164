import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnChanges {
  @Input() symbol: number = 9733
  @Input() max: number = 10
  @Input() readonly: boolean = false
  @Input() resettable: boolean = true

  @Input() source: any[] = []

  @Input() rate: number = 0

  @Output() rateChange: EventEmitter<number> = new EventEmitter<number>()
  @Output() hover: EventEmitter<any> = new EventEmitter<any>()
  @Output() leave: EventEmitter<any> = new EventEmitter<any>()

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['source'] && this.source && this.source.length > 0) {
      this.max = this.source.length
    }
  }

  getSymbol(index: number) {
    if (this.source && this.source.length > 0 && this.source[index] && this.source[index].symbol) {
      return `&#${this.source[index].symbol};`
    }

    return `&#${this.symbol};`
  }

  title(index: number) {
    if (this.source && this.source.length > 0 && this.source[index].title) {
      return this.source[index].title
    }

    return index + 1
  }

  hovered(event) {
    this.hover.emit(event)
  }

  leaved(event) {
    this.leave.emit(event)
  }

  rateChanged(event) {
    this.rateChange.emit(this.rate)
  }
}

export class Admin {
    id: number
    fullname: string
    password: string
    username: string
    email: string
    phoneNumber: string
    newPassword: string
    adminLevel: string
    company: string
    companyId: number
    tenantId: string
    tenant: string
    statusId: number
    status: string
    avatar: string
    generatePassword: boolean = true
    sendPasswordEmail: boolean
}

import { Injectable } from '@angular/core'

@Injectable()
export class NgTableService {

    rowsOnPageSet: any = [10, 25, 50, 100]
    rowsOnPage = 10
    activePage = 1
    lastPage = 2

    prepareSorters(children, sorters) {
        children.forEach(ch => {
            if (ch.name !== undefined) {
                sorters[ch.name] = { 'sorter': ch, 'asc': false, 'desc': false }
            }
        })

        return sorters
    }

    initSorter(criteria, sorters) {
        const colName = criteria
        .split(' ')[0]
        .toLowerCase()

        const direction = criteria
        .split(' ')[1]

        if (sorters[colName] && direction) {
            const keys = Object.keys(sorters)

            keys.forEach(k => {
                if (k === colName) {
                    sorters[k].asc = direction === 'asc'
                    sorters[k].desc = direction === 'desc'
                } else {
                    sorters[k].asc = false
                    sorters[k].desc = false
                }
            })
        }
    }

    clickSorter(criteria, sorters) {
        if (sorters[criteria]) {
            const keys = Object.keys(sorters)

            keys.forEach(k => {
                if (k === criteria) {
                    sorters[k].asc = !sorters[k].asc
                    sorters[k].desc = !sorters[k].asc
                } else {
                    sorters[k].asc = false
                    sorters[k].desc = false
                }
            })
        }
    }

    createFilterObject(filterPropNames, filterQuery) {
        const resultObj = {}

        filterPropNames.forEach(prop => {
            resultObj[prop] = filterQuery
        });

        return resultObj
    }
}

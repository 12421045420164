import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { AppModules } from 'app/core'
import { NotificationType } from 'app/core/enums/notification-type.enum'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { TenantSettingsComponent } from './tenant-settings.component'
import { TenantComponent } from './tenant.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

import { AuthStoreService, TenantService, ToastrService, TranslationService } from 'app/core/services'
import { TenantSettingsService } from 'app/core/services/tenant-settings.service'

import { Notification } from 'app/core/models'
import { TenantActiveModel } from 'app/core/models/tenant/tenant-model'

import { SetTenantAction } from 'app/core/store/actions/auth.actions'
import * as TenantActions from 'app/core/store/actions/tenant.actions'
import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: './tenant-mgmt.component.html'
})
export class TeanantMgmtComponent extends BaseManagementComponent implements OnInit {
  constructor(
    public store: Store<State>,
    private modal: ModalService,
    private tenantService: TenantService,
    private authStoreService: AuthStoreService,
    private translationService: TranslationService,
    private toastrService: ToastrService,
    private tenantSettingsService: TenantSettingsService
  ) {
    super(store)
  }

  ngOnInit() {

    if (!this.userTenantId) {
      this.toastrService.notification(new Notification(NotificationType.Warning, this.translationService.translateMsg('messages.tenantSelectionRequired')))
    }
  }

  get clickableRows(): boolean {
    return this.isGlobalAdmin
  }

  public add() {
    const item: TenantActiveModel = { id: '', name: '', isActive: true, modules: [] }
    this.openModal(item)
  }

  public edit(item) {
    this.openModal(item)
  }

  public settings() {
    let canAccessTestsModule = false

    this.authStoreService.tenantModules$
      .first()
      .subscribe(tm => {
        canAccessTestsModule = tm.some(m => m === AppModules.Tests)

        this.tenantSettingsService.getTenantSettings()
          .first()
          .subscribe(item => this.openSettingsModal(item, canAccessTestsModule))
      })
  }

  setTenant(tenant: any): void {
    if (this.isGlobalAdmin && tenant.id !== this.userTenantId) {
      this.modal.confirm(ModalTypes.Warning, this.translationService.translateMsg('messages.confirmTenantSelection'))
        .subscribe(state => {
          if (state) {
            this.store.dispatch(new SetTenantAction(tenant.id))
          }
        })
    }
  }

  openModal(item) {
    this.modal.open(TenantComponent, ModalSizeTypes.Medium, { entity: item })
      .subscribe(result => {
        if (result.isSubmit) {
          this.tenantService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
            this.store.dispatch(new TenantActions.GetListOptions())
            this.toastrService.success(this.translationService.translateMsg('general.saveSuccessful'))
          })
        }
      })
  }

  changeActive(id: number) {
    this.tenantService.changeIsActive(id)
      .first()
      .subscribe(_ => {
        this.store.dispatch(new FilterAction())
      })
  }

  openSettingsModal(item, canAccessTestsModule: boolean) {
    this.modal.open(TenantSettingsComponent, ModalSizeTypes.Medium, { entity: item, canAccessTestsModule })
      .subscribe(result => {
        if (result.isSubmit) {
          this.tenantSettingsService.save(result.data).first().subscribe(() => {
            this.toastrService.success(this.translationService.translateMsg('general.saveSuccessful'))
          })
        }
      })
  }
}

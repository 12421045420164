import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import 'rxjs/Rx'

import { Store } from '@ngrx/store'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { DownloaderService, TimeSheetService, NomsStoreService, UserStoreService, SettingsStoreService } from 'app/core/services'

import { TSAdvanceReportSetting, BaseModel } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'
import { Observable } from 'rxjs/Rx'
import { FileType } from 'app/core'

@Component({
  selector: 'time-sheet-report',
  templateUrl: './time-sheet-report.component.html'
})
export class TimeSheetReportComponent extends BaseAuthComponent implements OnInit, AfterViewInit {

  filter: TableFilter = new TableFilter()

  singleUser: boolean = false

  userOptions$: Observable<BaseModel[]>
  projectOptions$: Observable<BaseModel[]>
  workTimeTypeOptions$: Observable<BaseModel[]>

  isAdvance = false
  withActiveFilterUsers = true

  colVisibility: any = {}

  advFilter: TSAdvanceReportSetting = new TSAdvanceReportSetting()

  constructor(public timeSheetService: TimeSheetService,
    private reportDownloaderService: DownloaderService,
    store: Store<State>,
    private route: ActivatedRoute,
    private settingsStoreService: SettingsStoreService,
    private nomsStoreService: NomsStoreService,
    private userStoreService: UserStoreService) {
    super(store)

    this.filter.customFilter = {}
    this.filter.customFilter.withInActiveUsers = !this.withActiveFilterUsers
    this.filter.orderBy = 'Date desc'
  }

  ngOnInit(): void {

    this.projectOptions$ = this.nomsStoreService.projectListOptions$
      .takeWhile(_ => this.isAlive)

    this.workTimeTypeOptions$ = this.nomsStoreService.workTimeTypes$
      .takeWhile(_ => this.isAlive)

    this.settingsStoreService.getTimesheetSettings$
      .takeWhile(_ => this.isAlive)
      .subscribe(s => {
        this.singleUser = s ? s.singleUser : false
      })

    if (this.userRole === 'User') {
      this.filter.customFilter.userId = this.userId
    }

    if (this.isTL || this.isCompanyAdmin) {
      this.userOptions$ = this.userStoreService.listOptions$
        .takeWhile(_ => this.isAlive)
    }
  }

  ngAfterViewInit() {
    this.route.params.takeWhile(_ => this.isAlive).subscribe(params => {
      this.isAdvance = (params['isAdvance'] === 'true')

      this.filter.customFilter.advFilter = this.isAdvance ? this.advFilter : null

      this.filterData()
    })
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))

    this.setVisibility()
  }

  setVisibility() {
    if (this.isAdvance) {
      const allFalse = !this.advFilter.groupByUser &&
        !this.advFilter.groupByProject &&
        !this.advFilter.groupByWorkTimeType &&
        !this.advFilter.groupByTask &&
        !this.advFilter.groupByDate

      this.colVisibility.date = allFalse || this.advFilter.groupByDate
      this.colVisibility.user = allFalse || this.advFilter.groupByUser
      this.colVisibility.project = allFalse || this.advFilter.groupByProject
      this.colVisibility.workTimeType = allFalse || this.advFilter.groupByWorkTimeType
      this.colVisibility.task = allFalse || this.advFilter.groupByTask
      this.colVisibility.description = allFalse
    } else {
      this.colVisibility = {
        project: true,
        task: true,
        date: true,
        user: true,
        workTimeType: true,
        description: true
      }
    }
  }

  exportReports() {
    this.timeSheetService.export(this.filter)
      .first()
      .subscribe(
        response => {
          this.reportDownloaderService.downloadFile(response, 'time-sheet-report', FileType.Excel)
        })
  }

  isGroupingWithoutDates(): boolean {
    return (this.advFilter.groupByUser || this.advFilter.groupByProject || this.advFilter.groupByWorkTimeType || this.advFilter.groupByTask || this.advFilter.groupByDate)
      && (!this.filter.customFilter.fromDate || !this.filter.customFilter.toDate);
  }

  withInActiveChange(withInActive: boolean) {
    this.filter.customFilter.withInActiveUsers = !withInActive
    if (this.filter.customFilter.withInActiveUsers) {
      this.userOptions$ = this.userStoreService.listOptionsAll$
        .takeWhile(_ => this.isAlive)
    } else {
      this.userOptions$ = this.userStoreService.listOptions$
        .takeWhile(_ => this.isAlive)
    }
  }
}

import { Component, Injector, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormBaseComponent } from '../f-base.component'

@Component({
    selector: 'f-color-picker',
    templateUrl: './f-color-picker.component.html',
    styleUrls: ['./f-color-picker.component.scss'],
    providers: [
        MakeValidatorProviders(FormColorPickerComponent),
        MakeValueAccessorProviders(FormColorPickerComponent)
    ]
})
export class FormColorPickerComponent extends FormBaseComponent {

    constructor(public injector: Injector) {
        super(injector)
    }

    @Input() position: 'right' | 'left' | 'top' | 'bottom' = 'bottom'
    @Input() mode: 'color' | 'grayscale' | 'presets' = 'color'

    @Input() cmykEnabled: boolean = false
    @Input() fallbackColor: string = '#000000' // used when the color is not well-formed or is undefined
}

import { RouterModule, Routes } from '@angular/router'

import { NotAuthorizedComponent } from './components/common/not-authorized/not-authorized.component'
import { LoginExternalComponent } from './components/external-accounts/external-login/external-login.component'
import { LinkAccountsComponent } from './components/external-accounts/link-accounts/link-accounts.component'
import { HandbookComponent } from './components/handbook/handbook.component'
import { HomeComponent } from './components/home/home.component'
import { LoginComponent } from './components/login/login.component'
import { ProfileComponent } from './components/profile/profile.component'
import { Roles } from './core/constants'
import { ExternalAccountEndpoints } from './core/constants/external-account-endpoints'

import { AuthGuard, NotRoleGuard } from 'app/core/guards'


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile/:userId',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ExternalAccountEndpoints.linkAccount,
    component: LinkAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ExternalAccountEndpoints.loginAccount,
    component: LoginExternalComponent,
  },
  {
    path: 'profile/admin/:userId',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'handbook',
    component: HandbookComponent,
    canActivate: [AuthGuard, NotRoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'notes',
    loadChildren: () => import('app/app-modules/+notes/notes.module').then(m => m.NotesModule),
    canActivate: [AuthGuard, NotRoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'tickets',
    loadChildren: () => import('app/app-modules/+tickets/tickets.module').then(m => m.TicketsModule),
    canActivate: [AuthGuard, NotRoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'tests',
    loadChildren: () => import('app/app-modules/+tests/tests.module').then(m => m.TestsModule),
    canActivate: [AuthGuard, NotRoleGuard],
    data: { roles: [Roles.companyAdminRole] }
  },
  {
    path: 'wizard',
    loadChildren: () => import('app/app-modules/+test-survey-wizard/test-survey-wizard.module').then(m => m.TestSurveyModule),
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  // otherwise redirect to calendar
  {
    path: '**',
    redirectTo: 'home'
  }
];

export const routing = RouterModule.forRoot(appRoutes);

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard, NotRoleGuard } from 'app/core/guards'
import { HomeOfficeMgmtComponent } from './home-office-mgmt.component'
import { HomeOfficeReportComponent } from './home-office-report/home-office-report.component'
import { Roles } from 'app/core'

const homeOfficeRoutes: Routes = [
    {
        path: 'home-office-all',
        component: HomeOfficeMgmtComponent,
        canActivate: [AuthGuard, NotRoleGuard],
        data: { roles: [Roles.companyAdminRole] }
    }, {
        path: 'home-office-report',
        component: HomeOfficeReportComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(homeOfficeRoutes)],
    exports: [RouterModule]
})
export class HomeOfficeRoutingModule { }

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'

import { TenantStoreService } from 'app/core/services/store/tenant-store.service'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'
import { BaseModel } from 'app/core/models'

@Component({
  selector: 'tenant-select',
  templateUrl: './tenant-select.component.html',
})

export class TenantSelectComponent extends BaseAuthComponent implements OnInit, AfterViewInit {
  @Input() disabled = true
  @Input() readonly = false
  @Input() required = false

  @Input() inputLabel: string = null
  @Input() placeholder = ''
  @Input() labelCss = ''
  @Input() cssClass = ''
  @Input() groupCss = 'form-group'
  @Input() hasNull = false

  @Input() isHorizontal?= false
  @Input() name: string

  @Input() tenantId: string = ''
  @Output() tenantIdChange: EventEmitter<string> = new EventEmitter<string>()

  tenants$: Observable<BaseModel[]>

  constructor(store: Store<State>, private tenantStoreService: TenantStoreService
  ) {
    super(store)
  }

  ngOnInit() {
    this.tenants$ = this.tenantStoreService.tenantsListOptions$
      .takeWhile(_ => this.isAlive)
      .map(data => {
        if (!this.isGlobalAdmin) {
          return data.filter(d => d.id === this.userTenantId)
        }

        return data;
      })
  }

  ngAfterViewInit(): void {
    if (!this.tenantId && !this.hasNull) {
      this.tenantId = this.userTenantId
      this.tenantIdChange.emit(this.tenantId)
    }
  }

  tenantChange() {
    this.tenantIdChange.emit(this.tenantId)
  }
}

import { Component, Injector, OnInit } from '@angular/core'
import { NgControl } from '@angular/forms'

import { TranslateService } from '@ngx-translate/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../abstract-value-accessor'
import { FormBaseComponent } from '../forms/f-base.component'

@Component({
  selector: 'reminder',
  templateUrl: './reminder.component.html',
  providers: [
    MakeValidatorProviders(ReminderComponent),
    MakeValueAccessorProviders(ReminderComponent)
  ]
})
export class ReminderComponent extends FormBaseComponent implements OnInit {
  reminderTypes: any[] = [
    { id: 1, name: this.translateService.instant('general.email') },
    { id: 2, name: this.translateService.instant('general.notification') },
  ]

  reminderDates: any[]

  constructor(private translateService: TranslateService, injector: Injector) {
    super(injector)
  }

  ngOnInit() {
    this.control = this
      .injector
      .get(NgControl)

    this.isObject = true

    this.loadReminderDates()
  }

  onDateChange(periodMins: number) {
    this.value = { id: this.value.id, type: this.value.type, periodMins: periodMins }
  }

  onTypeChange(t) {
    this.value = { id: this.value.id, type: t, periodMins: this.value.periodMins }
  }

  loadReminderDates() {
    this.reminderDates = [
      { id: 0, name: this.translateService.instant('general.onTime') },
      { id: 5, name: `5 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 10, name: `10 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 15, name: `15 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 20, name: `20 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 30, name: `30 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 45, name: `45 ${this.translateService.instant('general.minutes')} ${this.translateService.instant('general.before')}` },
      { id: 60, name: `1 ${this.translateService.instant('general.hour')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 2, name: `2 ${this.translateService.instant('general.hours')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 3, name: `3 ${this.translateService.instant('general.hours')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 12, name: `12 ${this.translateService.instant('general.hours')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 24, name: `1 ${this.translateService.instant('general.dayLowercase')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 24 * 2, name: `2 ${this.translateService.instant('general.daysLowercase')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 24 * 3, name: `3 ${this.translateService.instant('general.daysLowercase')} ${this.translateService.instant('general.before')}` },
      { id: 60 * 24 * 7, name: `1 ${this.translateService.instant('general.week')} ${this.translateService.instant('general.before')}` }
    ]
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { of } from 'rxjs/observable/of'

import { Store } from '@ngrx/store'

import { TimeSheetAdvanceReportSettingService, ToastrService } from 'app/core/services'

import { TSAdvanceReportSetting } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'

@Component({
  selector: 'app-adv-settings',
  templateUrl: './adv-report-settings.component.html'
})

export class AdvReportTSSettingsComponent extends BaseAuthComponent implements OnInit {

  @Input() entity: TSAdvanceReportSetting = new TSAdvanceReportSetting()
  @Output() entityChange = new EventEmitter<TSAdvanceReportSetting>();

  criterias: any[] = [{ id: 'Date', name: 'Date' }, { id: 'User', name: 'User' }, { id: 'Project', name: 'Project' }]
  criteria = 'Date'
  directions: any[] = [{ id: 'Asc', name: 'Asc' }, { id: 'Desc', name: 'Desc' }]
  direction = 'Asc'

  showWarning = false

  visibleColumns: any[] = [
    { name: 'date', checked: false, groupBy: 'groupByDate', label: '{{"general.date" | translate}}' },
    { name: 'user', checked: false, groupBy: 'groupByUser', label: '{{\'general.user\' | translate}}' },
    { name: 'project', checked: false, groupBy: 'groupByProject', label: '{{"general.project" | translate}}' },
    { name: 'workTimeType', checked: false, groupBy: 'groupByWorkTimeType', label: '{{\'general.workTimeType\' | translate}}' },
    { name: 'task', checked: false, groupBy: 'groupByTask', label: '{{\'general.task\' | translate}}' },
    { name: 'time', checked: false, groupBy: '', label: '{{"general.hoursTime" | translate}}' },
    { name: 'description', checked: false, groupBy: '', label: '{{"general.description" | translate}}' },
  ]


  constructor(private timeSheetSettingsService: TimeSheetAdvanceReportSettingService,
    private toastrService: ToastrService, store: Store<State>) {
    super(store)
  }

  ngOnInit() {
    // this.timeSheetService.get(this.userId)
    //   .takeWhile(() => this.isAlive)
    //   .subscribe(data => {
    //     if (data) {
    //       this.entity = Object.assign({}, data)
    //     } else {
    //       this.entity.vacationUserId = this.userId
    //     }
    //   })
  }

  onChangeGroupBy($event: boolean, groupBy: string) {
    this.visibleColumns.find(c => c.groupBy === groupBy).checked = $event
    this.onChangeVisible()
  }

  onChangeVisible() {
    this.showWarning = false
    this.visibleColumns.forEach(c => {
      if (c.checked && (c.groupBy === '' || !this.entity[c.groupBy])) {
        this.showWarning = true
        return
      }
    })

    this.entity.visibleColumns = this.visibleColumns
      .filter(c => c.checked)
      .map(c => c.name)
  }

  search = (term: string) => {
    return this.timeSheetSettingsService.search(term, this.userId)
      .catch(() => {
        return of([])
      })
  }

  selectSSetting($event) {
  }

  saveSettings() { }

  loadSettings() { }

}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { of } from 'rxjs/observable/of'
import { mergeMap } from 'rxjs/operators'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'

import * as TimeSheetActions from '../actions/time-sheet.actions'

import { TimeSheetService } from 'app/core/services'

@Injectable()
export class TimeSheetEffects {
    constructor(
        private actions$: Actions,
        private timeSheetService: TimeSheetService,
    ) { }

    @Effect()
    GetWorkDays$: Observable<Action> = this.actions$.pipe(
        ofType<TimeSheetActions.GetWorkDays>(TimeSheetActions.GET_WORKDAYS),
        mergeMap(() => {
            return this.timeSheetService.getAllWorkDaysInMonth().map((workDays: number) => {
                return new TimeSheetActions.GetWorkDaysSuccess(workDays)
            }, _ => of(new TimeSheetActions.GetWorkDaysError()))
        })
    )

    @Effect()
    GetEnteredHours$: Observable<Action> = this.actions$.pipe(
        ofType<TimeSheetActions.GetEnteredHours>(TimeSheetActions.GET_ENTERED_HOURS),
        mergeMap(() => {
            const currentMonth = new Date().getMonth() + 1

            return this.timeSheetService.getTotalEnteredHours(currentMonth).map((enteredHours: number) => {
                return new TimeSheetActions.GetEnteredHoursSuccess(enteredHours)
            }, _ => of(new TimeSheetActions.GetEnteredHoursError()))
        })
    )

    @Effect()
    GetCurrentWorkDays$: Observable<Action> = this.actions$.pipe(
        ofType<TimeSheetActions.GetCurrentWorkDays>(TimeSheetActions.GET_CURRENT_WORK_DAYS),
        mergeMap(() => {
            return this.timeSheetService.getAllWorkDaysInMonth(false).map((currentWorkDays: number) => {
                return new TimeSheetActions.GetCurrentWorkDaysSuccess(currentWorkDays)
            }, _ => of(new TimeSheetActions.GetCurrentWorkDaysError()))
        })
    )

}

import { EventEmitter, Injectable } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'

@Injectable()

export class TranslationService {

  onLangChange: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe(event => {
      this.onLangChange.emit(event)
    })
  }

  public translateMsgObservable(msg: string): Observable<string> {
    return this.translate.get(msg)
  }

  public translateMsg(msg: string): string {
    return this.translate.instant(msg)
  }
}

import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { Store } from '@ngrx/store'
import * as moment from 'moment'

import { EnumStoreService, ToastrService, UserService, UserStoreService, VacationRequestService } from 'app/core/services'

import { BaseModel, VacationRequestInput } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseAuthComponent } from 'app/components/base/base-auth.component'


@Component({
  templateUrl: 'vacation-request.component.html'
})

export class VacationRequestComponent extends BaseAuthComponent implements OnInit {
  entity: VacationRequestInput = new VacationRequestInput()

  leaveTypes: BaseModel[] = []
  days: number = 0
  disableSaveBtn = false
  minDate = new Date()

  users$: Observable<BaseModel[]>
  isMyRequest: boolean = false

  totalVacationDays: number = 0

  constructor(
    private requestService: VacationRequestService,
    store: Store<State>,
    private toastr: ToastrService,
    private userService: UserService,
    private enumStoreService: EnumStoreService,
    private userStoreService: UserStoreService) {
    super(store)
  }

  ngOnInit() {
    if (!this.entity.vacationUserId && !this.isCompanyAdmin) {
      this.entity.vacationUserId = this.userId
    }

    this.enumStoreService.leaveTypes$
      .takeWhile(_ => this.isAlive)
      .subscribe(leaveTypes => {
        this.leaveTypes = leaveTypes
        this.entity.leaveTypeId = this.leaveTypes[0].id
      })

    this.users$ = this.userStoreService.listOptions$
      .takeWhile(_ => this.isAlive)

    this.getUserDays()

    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())
  }

  getDays() {
    const valid = (this.entity.fromDate && this.entity.toDate) &&
      moment(this.entity.fromDate) <= moment(this.entity.toDate) &&
      moment(this.entity.toDate) >= moment(this.entity.fromDate)

    if (valid) {
      this.requestService.getBusinessDaysCount(moment(this.entity.fromDate).toISOString(), moment(this.entity.toDate).toISOString())
        .subscribe((days) => {
          this.days = days

          if (this.days > this.totalVacationDays && this.entity.leaveTypeId === 1) {
            this.toastr.warning('messages.notEnoughDays', null, true)
            this.disableSaveBtn = true
          } else {
            this.disableSaveBtn = false
          }
          this.entity.selectedVacationDays = days
        })
    }
  }

  onSubmit(dialog) {
    this.entity.vacationUserId = this.entity.vacationUserId
    dialog.close({ isSubmit: true, data: this.entity })
  }


  takeLeaveType(event) {
    if (Number(event) === 3) {
      this.requestService.getCompensationDaysByUserId(this.entity.vacationUserId, Number(event))
        .first()
        .subscribe(days => {
          // this.totalVacationDays = days
        })
    }
  }

  private getUserDays() {
    if (this.entity.vacationUserId) {
      this.userService.getVacationDays(this.entity.vacationUserId)
        .first()
        .subscribe(days => {
          this.totalVacationDays = days

          if (this.entity.selectedVacationDays > this.totalVacationDays && this.entity.leaveTypeId === 1) {
            this.toastr.warning('messages.notEnoughDays', null, true)
            this.disableSaveBtn = true
          } else {
            this.disableSaveBtn = false
          }
        })
    }
  }

  changeUser() {
    this.getUserDays()
  }

  get disableUsers() {
    return (!this.isTL && !this.isCompanyAdmin) || this.isMyRequest
  }
}

import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { CalendarEventCreatedService } from 'app/core'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { HomeOfficeComponent } from './home-office.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { HomeOfficeService } from 'app/core/services/home-office.service'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: './home-office-mgmt.component.html'
})
export class HomeOfficeMgmtComponent extends BaseManagementComponent {

  parentId: number = this.userId

  constructor(
    public store: Store<State>,
    private modal: ModalService,
    private homeOfficeService: HomeOfficeService,
    private calendarEventCreatedService: CalendarEventCreatedService,
  ) {
    super(store)
  }

  public edit(item) {
    this.modal.open(HomeOfficeComponent, ModalSizeTypes.Medium, {
      entity: item,
      isMyRequest: true,
    })
      .subscribe(result => {
        if (result.isSubmit) {
          this.homeOfficeService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
          })
        }
      })
  }

  public add() {
    this.modal.open(HomeOfficeComponent, ModalSizeTypes.Medium, {
      entity:
      {
        vacationUserId: this.userId,
        date: new Date()
      },
      isMyRequest: true,
    })
      .subscribe(result => {
        if (result.isSubmit) {
          this.homeOfficeService.save(result.data).first().subscribe(() => {
            this.calendarEventCreatedService.subject.next(result.componentId)
            this.store.dispatch(new FilterAction())
          })
        }
      })
  }
}

import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'

import { FormBaseComponent } from '../f-base.component'

@Component({
  selector: 'f-slider',
  templateUrl: './f-slider.component.html',
  styleUrls: ['./f-slider.component.scss'],
  providers: [
    MakeValidatorProviders(FormSliderComponent),
    MakeValueAccessorProviders(FormSliderComponent)
  ]

})
export class FormSliderComponent extends FormBaseComponent {

  @Input() offLabel: string
  @Input() onLabel: string
  @Input() colorClass = 'primary-dark'
}

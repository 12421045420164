import { Injectable } from '@angular/core'



@Injectable()
export class CollectionManipulator {

  constructor() {
  }

  sortByNameAlphabeticaly(a, b) {
    return new String(a.name).localeCompare(b.name)
  }

  replaceRecordOnTheSameIndex(collection, record) {
    let recordToRemove = collection.filter(r => r.id === record.id)[0];
    let index = collection.indexOf(recordToRemove)
    collection.splice(index, 1, record)
  }
}
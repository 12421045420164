import { Injectable } from '@angular/core';
import { ApiService } from './common/api.service'
import { TenantSettingsModel } from '../models/tenant/tenant-settings-model';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TenantSettingsService {
    constructor(private httpClient: ApiService) { }

    save(tenantSettingsModel: TenantSettingsModel) {
        return this.httpClient.post('tenant-settings/save', tenantSettingsModel, true)
    }

    getTenantSettings(): Observable<TenantSettingsModel> {
        return this.httpClient.get('tenant-settings', true)
    }

    transfer() {
        return this.httpClient.put('tenant-settings/transfer', true, true)
    }
}

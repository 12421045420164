import { DatepickerToggleDirective } from './datepicker-toggle/datepicker-toggle.directive'
import { InsertionDirective } from './insertion/insertion.directive'
import { MaxDateValidator } from './max-date/directive'
import { MaxLengthValidator } from './max-length/directive'
import { MaxValidator } from './max/directive'
import { MinDateValidator } from './min-date/directive'
import { MinLengthValidator } from './min-length/directive'
import { MinValidator } from './min/directive'
import { DivisibleByValidator } from './divisible-by/directive'

export const sharedDirectives = [
  MaxValidator,
  MinValidator,
  DivisibleByValidator,
  MaxDateValidator,
  MinDateValidator,
  MinLengthValidator,
  MaxLengthValidator,

  DatepickerToggleDirective,
  InsertionDirective,
]

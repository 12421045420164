import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { Store } from '@ngrx/store'
import { TableFilter } from 'app/smart-table/models/table-filter.model'
import { ChangeFilterAction } from 'app/smart-table/store/smarter-table.actions'

import { ChangePasswordComponent } from 'app/app-modules/shared-components/change-password/change-password.component'
import { ResetUserPasswordComponent } from '../reset-user-password/reset-user-password.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { AuthStoreService, PicturesService, TenantStoreService, ToastrService, UserService } from 'app/core/services'

import { User } from 'app/core/models'

import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'

@Component({
  templateUrl: 'user-mgmt.component.html'
})

export class UserMgmtComponent extends BaseManagementComponent {
  user: User
  filter: TableFilter = new TableFilter()
  isEdit: boolean

  addDisabled: boolean

  constructor(private userService: UserService,
    public store: Store<State>,
    private modal: ModalService,
    private toastr: ToastrService,
    private router: Router,
    private tenantStoreService: TenantStoreService,
    private authStoreService: AuthStoreService,
    private picturesService: PicturesService,
  ) {
    super(store)
    this.filter.orderBy = 'Fullname asc'

    if (this.isCompanyAdmin) {
      this.userService.maxUserCountReached()
        .first()
        .subscribe(data => {
          this.addDisabled = data
        })
    }
  }

  filterData() {
    this.store.dispatch(new ChangeFilterAction(this.filter))
    this.tenantStoreService.selectTenantId = this.filter.customFilter.tenantId
  }

  public add() {
    this.router.navigate(['/user'])
  }

  openChangePasswordModal(id: number) {
    this.modal.open(ChangePasswordComponent, ModalSizeTypes.Medium, { id })
      .subscribe(result => {
        if (result.isSubmit) {
          this.userService.changePassword(result.data).first().subscribe(_ => {
            this.toastr.success('messages.passwordSuccessfullyChanged', null, true)
          })
        }
      })
  }

  changeActive(id: number) {
    this.userService.changeIsActive(id)
      .first()
      .subscribe(_ => {
        this.filterData()
      })
  }

  getProfilePicture(userId: number): string {
    return this.picturesService.getProfilePic(userId)
  }

  getCurrentUserStatus() {
    return this.authStoreService.statusId$
      .takeWhile(_ => this.isAlive)
  }

  resetUserPassword(email: string) {
    this.modal.open(ResetUserPasswordComponent, ModalSizeTypes.Medium, { entity: { email } })
      .subscribe(result => {
        if (result.isSubmit) {
          this.userService.resetPassword(result.data)
            .first()
            .subscribe(_ => {
              this.toastr.success('messages.passwordSuccessfullyGenerated', null, true)
            })
        }
      })
  }
}

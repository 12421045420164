import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AuthGuard, IntIdGuard, ModuleAdminGuard, NotRoleGuard, RoleGuard, TokenGuard } from 'app/core/guards'
import { DialogsModule } from 'app/dialogs/dialogs.module'

import {
  AdminService,
  ApiService,
  AuthHelperService,
  AuthService,
  AuthStoreService,
  CalendarEventCreatedService,
  CompanyService,
  CompensationService,
  DownloaderService,
  EnumStoreService,
  ExportService,
  HomeOfficeService,
  InfiniteScrollService,
  LanguageStoreService,
  LoadingStoreService,
  LocationService,
  MicrosoftService,
  NomsStoreService,
  NotificationService,
  PermanentlyHolidayDateService,
  PicturesService,
  PositionService,
  ProjectService,
  RecommendationBookService,
  SettingsService,
  SettingsStoreService,
  SickLeaveService,
  TenantService,
  TenantStoreService,
  TimeSheetAdvanceReportSettingService,
  TimeSheetService,
  TimeSheetSettingService,
  TimeSheetStoreService,
  ToastrService,
  TranslationService,
  UserService,
  UserStoreService,
  VacationApproveService,
  VacationRequestService,
  WorkScheduleChangeService,
} from './services'
import { AppService } from './services/app.service'
import { ExternalLinksService } from './services/external-links.service'
import { LoadingInterceptorService } from './services/interceptors/loading.interceptor.service'
import { PushService } from './services/push.service'
import { MicrosoftStoreService } from './services/store/microsoft-store.service'
import { TenantSettingsService } from './services/tenant-settings.service'
import { UserEventNotificationService } from './services/user-event-notification.service'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  imports: [CommonModule, DialogsModule, HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  declarations: [],
  exports: [TranslateModule],
  providers: [
    HttpClient,

    AuthGuard,
    RoleGuard,
    NotRoleGuard,
    IntIdGuard,
    ModuleAdminGuard,
    TokenGuard,

    AppService,
    AuthService,
    AuthHelperService,
    ToastrService,
    UserService,
    AdminService,
    ApiService,
    WorkScheduleChangeService,
    PermanentlyHolidayDateService,
    TimeSheetService,
    TimeSheetSettingService,
    TimeSheetStoreService,
    ProjectService,
    VacationRequestService,
    LocationService,
    PositionService,
    VacationApproveService,
    TimeSheetAdvanceReportSettingService,
    CompensationService,
    RecommendationBookService,
    SickLeaveService,
    DownloaderService,
    CompanyService,
    TenantService,
    ExportService,
    InfiniteScrollService,
    SettingsService,
    HomeOfficeService,
    EnumStoreService,
    TenantSettingsService,
    CalendarEventCreatedService,

    AuthStoreService,
    NomsStoreService,
    LoadingStoreService,
    LanguageStoreService,
    TenantStoreService,
    UserEventNotificationService,
    MicrosoftStoreService,
    UserStoreService,
    SettingsStoreService,

    PicturesService,
    ExternalLinksService,

    TranslationService,
    PushService,
    NotificationService,

    MicrosoftService,

    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptorService, multi: true }
  ]
})
export class CoreModule {
  constructor() {

  }
}



export class CalendarEventView  {
  id: number
  startDate: Date
  endDate: Date
  description: string
  color: any
  eventOwner: string
  eventType: number
  isWorkday?: boolean
}

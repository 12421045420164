import { Component, OnInit } from '@angular/core'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  templateUrl: './add-permanent-holidays.component.html',
})

export class AddPermanentHolidaysComponent extends BaseComponent implements OnInit {
  year: number = 0
  allowedYears = []

  ngOnInit() {

    this.year = new Date().getFullYear()

    this.allowedYears = Array(3).fill(this.year).map((x, i) => {
      const year = x + i
      return { id: year, name: year }
    })

    this.year = this.year - 1
  }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.year })
  }
}

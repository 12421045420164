import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

import { isPresent } from '../../utils/helpers'

export const divisibleByFn = (divisibleBy: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!isPresent(divisibleBy)) { return null }
        if (isPresent(Validators.required(control))) { return null }

        const isDivisible = +control.value % divisibleBy === 0
        return isDivisible ? null : { divisibleBy }
    }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAuthComponent } from 'app/components/base/base-auth.component';
import { SelectOption } from 'app/core/models/common/select-option';
import { LanguageStoreService, TranslationService, AuthStoreService } from 'app/core/services';
import { State } from 'app/core/store/states';
import { Locales, AppModules } from 'app/core';
import { SettingsStoreService } from 'app/core/services/store/settings-store.service';

@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.component.html'
})

export class UserSettingsComponent extends BaseAuthComponent implements OnInit {
    colorThemes = ['general.colored', 'general.black']
    selectOptions = ['general.dropdown', 'general.typeahead']
    questionVisibilityOptions = ['general.samePage', 'general.separatePage']

    colorOptions = []
    nomsSelectOptions = []
    surveyQuestionsVisibilityOptions = []

    canAccessTestsModule = false

    selectedLanguage: string
    languages: SelectOption[] = [
        new SelectOption(Locales.bgBG, 'Български'),
        new SelectOption(Locales.enUS, 'English'),
    ]

    userSettings
    constructor(
        store: Store<State>,
        private languageStoreService: LanguageStoreService,
        private translationService: TranslationService,
        private authStoreService: AuthStoreService,
        private settingsStoreService: SettingsStoreService,
    ) {
        super(store)
    }

    ngOnInit() {
        this.updateChoices()

        this.translationService.onLangChange.subscribe(_ => {
            this.updateChoices()
        })

        this.languageStoreService.language$
            .takeWhile(_ => this.isAlive)
            .subscribe(lang => {
                this.selectedLanguage = lang
            })

        this.authStoreService.tenantModules$
            .takeWhile(_ => this.isAlive)
            .subscribe(tm => {
                this.canAccessTestsModule = tm.some(m => m === AppModules.Tests)
            })

        this.settingsStoreService.userSettings$
            .takeWhile(_ => this.isAlive)
            .subscribe(data => {
                this.userSettings = Object.assign({}, data)
            })
    }

    updateChoices(): void {
        this.colorOptions = this.colorThemes.map((o, colorIndex) => new SelectOption(colorIndex > 0, this.translationService.translateMsg(o)))
        this.nomsSelectOptions = this.selectOptions.map((o, selectIndex) => new SelectOption(selectIndex > 0, this.translationService.translateMsg(o)))
        this.surveyQuestionsVisibilityOptions = this.questionVisibilityOptions.map((o, selectIndex) => new SelectOption(selectIndex > 0, this.translationService.translateMsg(o)))
    }

    save() {
        this.settingsStoreService.userSettings = this.userSettings
    }

    setLanguage() {
        this.languageStoreService.language = this.selectedLanguage
    }
}

import { ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnInit, ViewChild, OnChanges } from '@angular/core';

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormTypeaheadComponent } from '../f-typeahead/f-typeahead.component'


@Component({
    selector: 'f-image-typeahead',
    templateUrl: 'f-image-typeahead.component.html',
    styleUrls: ['./f-image-typeahead.component.scss'],
    providers: [
        MakeValidatorProviders(FormImageTypeaheadComponent),
        MakeValueAccessorProviders(FormImageTypeaheadComponent)
    ]
})
export class FormImageTypeaheadComponent extends FormTypeaheadComponent implements OnChanges {

    @Input() getImageFn: (id: any) => string = null
    @Input() imageField: string = 'image'
    @Input() selected: any = null

    @ViewChild('typeaheadInput', { static: true }) typeaheadInput: ElementRef
    @ViewChild('typeaheadResult', { static: true, read: ElementRef }) typeaheadResult: ElementRef

    previousValue: any = null
    result: any = {}
    inResultMode: boolean = false

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
    ) {
        super(injector)
    }

    ngOnChanges() {
        if (this.selected && this.selected.id) {
            this.result = this.selected
            this.title = this.result.name
            this.writeValue(this.result.id)
            this.previousValue = this.result.id
            this.inResultMode = true
        }
    }

    selectionChanged(eventData: any) {
        this.result = eventData.item
        this.previousValue = this.result.id

        if (this.result) {
            this.inResultMode = true
        }
    }

    switchMode() {
        if (this.inResultMode) {
            if (!this.disabled) {
                this.inResultMode = false
            }
        } else if (this.result) {
            this.inResultMode = true
        }

        this.cd.detectChanges()
    }

    @HostListener('document:click', ['$event'])
    public closeOnOutsideEvent($event: MouseEvent) {
        if (!this.inResultMode &&
            !(this.typeaheadInput.nativeElement === $event.target || this.typeaheadInput.nativeElement.contains($event.target)) &&
            !(this.typeaheadResult.nativeElement === $event.target || this.typeaheadResult.nativeElement.contains($event.target))) {
            if (this.value) {
                this.inResultMode = true
                this.cd.detectChanges()
            } else if (this.previousValue) {
                this.title = ''
                this.previousValue = null
                this.selectedItem.emit({})
            }
        }
    }
}

export class PermanentlyHolidayDate {
  date: string
  holiday: boolean
  description: string

  GetDate(): Date {
    return new Date(this.date)
  }

  GetDescription(): string {
    return this.description
  }
}

import { BaseModel } from '../models/common/base-model'
import { TranslationService } from '../services/common/traslation.service'

export const enumToArray = (base: any, translationService: TranslationService = null): BaseModel[] => {
    const keys = Object.keys(base)

    return keys.filter((item) => isNaN(Number(item)) && item !== 'null')
        .map(key => {
            const id = isNaN(parseInt(base[key], 10)) ? null : parseInt(base[key], 10)
            const name = translationService ?
                translationService.translateMsg(`general.${key.charAt(0).toLowerCase() + key.slice(1)}`)
                : key.replace(/([a-z])([A-Z])/g, '$1 $2')

            return { id: id, name: name }
        })
}

import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'

import { divisibleByFn } from './validator'

const DIVISIBLE_BY_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DivisibleByValidator),
    multi: true
}

@Directive({
    selector: '[divisibleBy][formControlName],[divisibleBy][formControl],[divisibleBy][ngModel]',
    providers: [DIVISIBLE_BY_VALIDATOR]
})
export class DivisibleByValidator implements Validator, OnInit, OnChanges {
    @Input() divisibleBy: number

    private validator: ValidatorFn
    private onChange: () => void

    ngOnInit() {
        this.validator = divisibleByFn(this.divisibleBy)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const key in changes) {
            if (key === 'divisibleBy') {
                this.validator = divisibleByFn(changes[key].currentValue)
                if (this.onChange) { this.onChange() }
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c)
    }

    registerOnValidatorChange(divisibleBy: () => void): void {
        this.onChange = divisibleBy
    }
}

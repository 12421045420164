import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BaseModel } from 'app/core/models';
import { CompanyModel } from 'app/core/models/noms/company';
import { CompanyService } from 'app/core/services';
import { State } from 'app/core/store/states';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { mergeMap } from 'rxjs/operators';

import * as CompanyActions from '../../actions/noms/company.actions';


@Injectable()
export class CompanyEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private companyService: CompanyService
    ) { }

    @Effect()
    GetListOptions$: Observable<Action> = this.actions$.pipe(
        ofType<CompanyActions.GetListOptions>(CompanyActions.GET_LIST_OPTIONS),
        mergeMap((action) => {
            return this.companyService.getListOptions(true).map((companies: BaseModel[]) => {
                return new CompanyActions.GetListOptionsSuccess(companies)
            }, _ => of(new CompanyActions.GetListOptionsError()))
        })
    )
}

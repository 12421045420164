import { Component, Injector, OnInit, Output, EventEmitter } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormBaseComponent } from '../../forms/f-base.component'

@Component({
  selector: 'reminder-list',
  templateUrl: './reminder-list.component.html',
  providers: [
    MakeValidatorProviders(ReminderListComponent),
    MakeValueAccessorProviders(ReminderListComponent)
  ]
})
export class ReminderListComponent extends FormBaseComponent implements OnInit {

  @Output() onUpdate = new EventEmitter<any>()

  @Output() onDelete = new EventEmitter<any>()

  constructor(injector: Injector) {
    super(injector)
  }

  ngOnInit() {
    super.ngOnInit()
    this.value = []
    this.isObject = true
    this.isArray = true
  }

  add() {
      const reminder = { id: 0, type: 1, periodMins: 15 }
      this.value = [...this.value, reminder]

      this.onUpdate.emit(reminder)
  }

  remove(index: number) {
    const reminder = this.value.splice(index, 1)[0]
    this.onDelete.emit(reminder.id)
  }

  updateReminder(reminder, index) {
    this.value[index] = reminder
    this.onUpdate.emit(reminder)
  }
}

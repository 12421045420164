import { Injectable } from '@angular/core'
import { HttpObjectService, ApiService } from 'app/core/services';

@Injectable()

export class SmartTableService extends HttpObjectService {
  constructor(public apiService: ApiService) {
    super(apiService, '')
  }
}

import { forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms'

const noop = () => {
};

export abstract class AbstractValueAccessor implements ControlValueAccessor, Validator {
    // The internal data model
    private innerValue: any = '';

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    protected onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    // get accessor
    get value(): any {
        return this.innerValue;
    };

    isObject: boolean = false
    isArray: boolean = false

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            if (this.isObject) {
                if (this.isArray) {
                    this.innerValue = Object.assign([], v);
                } else {
                    this.innerValue = Object.assign({}, v);
                }
            } else {
                this.innerValue = v;
            }

            this.onChangeCallback(v);
        }
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            if (this.isObject) {
                if (this.isArray) {
                    this.innerValue = Object.assign([], value);
                } else {
                    this.innerValue = Object.assign({}, value);
                }
            } else {
                this.innerValue = value;
            }
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(c: FormControl) {
        return this.value !== '' ? null : {
            jsonParseError: {
                valid: false,
            },
        }
    }

}

export function MakeValueAccessorProviders(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true
    };
}

export function MakeValidatorProviders(type: any) {
    return {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => type),
        multi: true,
    }
}

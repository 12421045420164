import { Action } from '@ngrx/store'
import { type } from '../../util'

export const ActionTypes = {
  LOAD: type('[LOADING] LOAD'),
  LOAD_DONE: type('[LOADING] LOAD_DONE'),
  SET_LOADING_TEXT: type('SET_LOADING_TEXT'),
}

export class LoadAction implements Action {
  type = ActionTypes.LOAD
}

export class LoadDoneAction implements Action {
  type = ActionTypes.LOAD_DONE
}

export class SetLoadingTextAction implements Action {
  type = ActionTypes.SET_LOADING_TEXT

  constructor(public payload = '') { }
}

export type Actions
  = LoadAction
  | LoadDoneAction
  | SetLoadingTextAction

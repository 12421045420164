import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'

import { Store } from '@ngrx/store'
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar'
import { CalendarColors, CalendarEventType, CalendarLegend, Locales } from 'app/core'
import { CustomDateFormatter } from 'app/shared/utils/custom.date.formatter.provider'
import { addDays, addMonths, addWeeks, isSameDay, subDays, subMonths, subWeeks } from 'date-fns'

import { BaseAuthComponent } from '../base/base-auth.component'

import { LanguageStoreService, SettingsStoreService, TranslationService, VacationRequestService } from 'app/core/services'

import { CalendarEventView } from 'app/core/models/calendar-event-view'

import { State } from 'app/core/store/states'

import * as moment from 'moment'

@Component({
  selector: 'mwl-demo-app',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar.html',
  styleUrls: ['./home-styles.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent extends BaseAuthComponent implements OnInit {
  events: any[] = []
  eventOwners: any[] = []
  eventTypes: any[] = []
  eventOwnersStorage: any[] = []
  typesStorage: any[] = []
  allEvents: any[] = []
  activeDayIsOpen = false

  tempEvents: CalendarEvent[] = []
  tempDays: any[] = []

  locale: string
  weekStartsOn = 1
  view = 'month'
  viewDate: Date = new Date()
  hasHolidayDates: boolean
  from: string
  to: string

  constructor(
    private router: Router,
    private vacationRequestService: VacationRequestService,
    private languageStoreService: LanguageStoreService,
    private translationService: TranslationService,
    private settingsStoreService: SettingsStoreService,
    public store: Store<State>,
  ) {
    super(store)
    this.getCalendarEvents()
  }

  ngOnInit(): void {
    this.languageStoreService.language$
      .takeWhile(() => this.isAlive)
      .subscribe((lang) => {
        this.locale = lang
      })

      this.settingsStoreService.userSettings$
      .takeWhile(_ => this.isAlive)
      .subscribe(userSettings => {
        if (userSettings) {
          this.userSettings.blackCalendarIcon = userSettings.blackCalendarIcon
          this.updateIconsColor()
        }
      })

    this.from = this.translationService.translateMsg('general.from')
    this.to = this.translationService.translateMsg('general.to')

    this.translationService.onLangChange
      .takeWhile(_ => this.isAlive)
      .subscribe(_ => {
        this.from = this.translationService.translateMsg('general.from')
        this.to = this.translationService.translateMsg('general.to')

        this.getCalendarEvents()
      })
  }

  updateIconsColor() {
    this.allEvents = this.allEvents.map(e => { e.color = this.getEventColor(e.type, e.isWorkday); return e })
    this.events = this.events.map(e => { e.color = this.getEventColor(e.type, e.isWorkday); return e })
    this.eventTypes = this.eventTypes.map(l => { l.color = this.getEventColor(l.type).primary; return l })
  }

  getCalendarEvents() {
    this.events = []
    this.allEvents = []

    this.vacationRequestService.getCalendarEvents(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1)
      .first()
      .subscribe(e => {
        if (e) {
          e.forEach(el => {
            this.processEvents(el)
          })

          this.getFilters()
          this.filterEvents()
        }
      })
  }

  beforeMonthViewRender({ body }: { body: any[] }): void {
    body.forEach(day => {
      day.events.forEach((event, index) => {
        if (event.type === CalendarEventType.Holiday || event.type === CalendarEventType.Change) {
          day.cssClass = event.type === CalendarEventType.Holiday ? 'perm-holiday-cell' :
            (event.isWorkday ? 'workday-cell' : 'holiday-cell')
          day.title = event.title
          day.events.splice(index, 1)
        }
      })

      day.badgeTotal = day.events.length
    })
  }

  handleHasHolidayDates(hasHolidayDates) {
    this.hasHolidayDates = hasHolidayDates
  }

  processEvents(event: CalendarEventView) {
    const calendarEvent = {
      start: new Date(event.startDate),
      end: event.endDate ? new Date(event.endDate) : null,
      title: '',
      color: this.getEventColor(event.eventType, event.isWorkday),
      type: event.eventType,
      owner: event.eventOwner,
      isWorkday: event.isWorkday
    }

    switch (event.eventType) {
      case CalendarEventType.Vacation:
        calendarEvent['title'] =
          `${event.eventOwner} ${event.description} ${this.translationService.translateMsg('general.vacation')} ${this.from} ${calendarEvent.start.toLocaleDateString(Locales.bgBG)} ${this.to} ${calendarEvent.end.toLocaleDateString(Locales.bgBG)}`
        calendarEvent['icon'] = 'fas fa-plane-departure'
        break
      case CalendarEventType.SickLeave:
        calendarEvent['title'] =
          `${event.eventOwner} ${this.translationService.translateMsg('general.sickLeave')} ${this.from} ${calendarEvent.start.toLocaleDateString(Locales.bgBG)} ${this.to} ${calendarEvent.end.toLocaleDateString(Locales.bgBG)}`
        calendarEvent['icon'] = 'fas fa-thermometer'
        break
      case CalendarEventType.HomeOffice:
        calendarEvent['title'] = `${event.eventOwner} ${this.translationService.translateMsg('general.homeOffice')}`
        calendarEvent['icon'] = 'fas fa-couch'
        break;
      case CalendarEventType.Holiday:
        calendarEvent['title'] = event.description
        break
      case CalendarEventType.Change:
        calendarEvent['title'] = event.description
        break
      case CalendarEventType.BirthDate:
        calendarEvent['title'] = `${event.eventOwner} ${event.description}`
        calendarEvent['icon'] = 'fas fa-birthday-cake'
        break;
      case CalendarEventType.EmploymentDate:
        calendarEvent['title'] = `${event.eventOwner} ${event.description}`
        calendarEvent['icon'] = 'fas fa-briefcase'
        break;
      case CalendarEventType.Note:
        calendarEvent['title'] = `${event.description} ${event.eventOwner || ''} ${this.translationService.translateMsg('general.note')}`
        calendarEvent['icon'] = 'fas fa-clipboard'
        break;
      case CalendarEventType.Task:
        calendarEvent['title'] = `${event.description} ${event.eventOwner || ''} ${this.translationService.translateMsg('general.task')}`
        calendarEvent['icon'] = 'fas fa-tasks'
        break;
      case CalendarEventType.Appointment:
        calendarEvent['title'] = `${event.description} ${event.eventOwner || ''} ${this.translationService.translateMsg('general.appointment')}`
        calendarEvent['icon'] = 'fas fa-calendar-week'
        break;
      case CalendarEventType.Meeting:
        calendarEvent['title'] = `${event.description} ${event.eventOwner || ''} ${this.translationService.translateMsg('general.meeting')}`
        calendarEvent['icon'] = 'fas fa-handshake'
        break;
      case CalendarEventType.Event:
        calendarEvent['title'] = `${event.description} ${event.eventOwner || ''} ${this.translationService.translateMsg('general.event')}`
        calendarEvent['icon'] = 'fas fa-calendar-check'
        break;
    }

    this.allEvents = [...this.allEvents, calendarEvent]
  }

  getFilters() {
    this.eventOwners.forEach(o => {
      const existingOwner = this.eventOwnersStorage.find(owner => owner.title === o.title)

      if (existingOwner) {
        existingOwner.checked = o.checked
      } else {
        this.eventOwnersStorage = [...this.eventOwnersStorage, o]
      }
    })
    this.eventTypes.forEach(e => {
      const existingTypes = this.typesStorage.find(event => event.type === e.type)

      if (existingTypes) {
        existingTypes.checked = e.checked
      } else {
        this.typesStorage = [...this.typesStorage, e]
      }
    })

    this.eventOwners = []
    this.eventTypes = []

    this.eventTypes = Object.keys(CalendarLegend)
      .filter(type => this.allEvents.some(e => e.type === CalendarLegend[type].type))
      .map(type => {
        const item = CalendarLegend[type]

        const oldType = this.typesStorage.find(t => t.type === item.type)
        item.checked = oldType ? oldType.checked : true
        item.color = this.getEventColor(item.type).primary

        return item
      })

    this.allEvents
      .filter(e => e.type !== CalendarEventType.Holiday && e.type !== CalendarEventType.Change)
      .forEach(e => {
        const existInOwners = this.eventOwners.some(o => o.title === e.owner)

        if (!existInOwners) {
          const oldOwner = this.eventOwnersStorage.find(t => t.title === e.owner)

          const owner = {
            title: e.owner,
            icon: 'fas fa-user',
            color: null,
            checked: oldOwner ? oldOwner.checked : true
          }

          this.eventOwners = [...this.eventOwners, owner]
        }
      })
  }

  getEventColor(eventType: number, isWorkday: boolean = false) {
    switch (eventType) {
      case CalendarEventType.Vacation:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.orange
      case CalendarEventType.SickLeave:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.darkRed
      case CalendarEventType.HomeOffice:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.darkGreen
      case CalendarEventType.Holiday:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.green
      case CalendarEventType.Change:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : (isWorkday ? CalendarColors.red : CalendarColors.blue)
      case CalendarEventType.BirthDate:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.purpul
      case CalendarEventType.EmploymentDate:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.darkBlue
      case CalendarEventType.Note:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.gray
      case CalendarEventType.Task:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.gray
      case CalendarEventType.Appointment:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.gray
      case CalendarEventType.Meeting:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.gray
      case CalendarEventType.Event:
        return this.userSettings.blackCalendarIcon ? CalendarColors.black : CalendarColors.gray

    }
  }

  increment(): void {
    const addFn: any = {
      day: addDays,
      week: addWeeks,
      month: addMonths
    }[this.view]

    this.viewDate = addFn(this.viewDate, 1)
    this.activeDayIsOpen = false

    this.getCalendarEvents()
  }

  decrement(): void {
    const subFn: any = {
      day: subDays,
      week: subWeeks,
      month: subMonths
    }[this.view]

    this.viewDate = subFn(this.viewDate, 1)
    this.activeDayIsOpen = false

    this.getCalendarEvents()
  }

  today(): void {
    this.viewDate = new Date()
    this.activeDayIsOpen = false

    this.getCalendarEvents()
  }

  dayClicked({ date, events }: { date: Date, events: CalendarEvent[] }): void {
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
      this.activeDayIsOpen = false
    } else {
      this.activeDayIsOpen = true
      this.viewDate = date
    }
  }

  filterEvents() {
    this.events = this.allEvents.filter(e => {
      const eventType = this.eventTypes.find(item => item.type === e['type'])
      const eventOwner = this.eventOwners.find(item => item.title === e['owner'])

      if ((eventType === undefined || eventType.checked) && (eventOwner === undefined || eventOwner.checked)) {
        return e
      }
    })

    if (this.activeDayIsOpen) {
      const areEventsInList = this.events.some(e => {
        const isSameOrAfter = moment(this.viewDate).isSameOrAfter(e.start)
        const isSameOrBefore = moment(this.viewDate).isSameOrBefore(e.end)
        return isSameOrAfter && isSameOrBefore
      })

      this.activeDayIsOpen = areEventsInList
    }
  }

  eventClicked(item): void {
    const event = item.event
    if (event.type === CalendarEventType.Note || event.type === CalendarEventType.Appointment ||
      event.type === CalendarEventType.Event || event.type === CalendarEventType.Meeting ||
      event.type === CalendarEventType.Task) {
      const eventId = event.title.split(' ')[0]
      this.router.navigate([`/notes/${eventId}`])
    }
  }
}

import { Injectable } from '@angular/core'
import { BaseModel, ChangePasswordModel, User, UserClaims, UserSimpleInfo } from 'app/core/models'
import { Observable } from 'rxjs/Observable'

import { ResetUserPasswordModel } from '../models/user/reset-user-password'
import { UserProfile } from '../models/user/user-profile'
import { ApiService } from './common/api.service'

@Injectable()
export class UserService {

  constructor(private httpClient: ApiService) { }

  getUser(id: number): Observable<User> {
    return this.httpClient.get('users/' + id)
  }

  getAvatar(id: number): Observable<any> {
    return this.httpClient.get('users/avatar/' + id)
  }

  getPosibleTeamLeads(id: number): Observable<BaseModel[]> {
    if (id) {
      return this.httpClient.get(`users/${id}/possible-team-leads`)
    } else {
      return this.httpClient.get(`users/possible-team-leads`)
    }
  }

  getListOptions(isBackground = false, withInActive = false) {
    return this.httpClient.get(`users/list-options/${withInActive}`, true, isBackground)
  }

  getListOptionsNoFiltered(isBackground = false, withInActive = false) {
    return this.httpClient.get(`users/list-options-no-filtered/${withInActive}`, true, isBackground)
  }

  getListOptionsWithEmails(isBackground = false) {
    return this.httpClient.get('users/list-options-with-emails', true, isBackground)
  }

  getAllUsersSimpleInfo(): Observable<UserSimpleInfo[]> {
    return this.httpClient.get('users/all-users-simple-info')
  }

  getAllClients() {
    return this.httpClient.get('users/get-all-clients')
  }

  getAllSubordinatesNames(userId: number, includeTeamlead: boolean = true) {
    return this.httpClient.get(`users/${userId}/get-all-subordinates-names/${includeTeamlead}`)
  }

  update(user: User) {
    return this.httpClient.post('account', user)
  }

  register(user: User): Observable<any> {
    return this.httpClient.post('account/register', user)
  }

  save(user: User) {
    return this.httpClient.post('account/save', user)
  }

  saveGuest(role: string, user: User) {
    return this.httpClient.post(`account/save-guest/${role}`, user)
  }

  addClaimsToUsers(model: UserClaims) {
    return this.httpClient.post('account/add-claim', model)
  }

  resetPassword(model: ResetUserPasswordModel) {
    return this.httpClient.post('account/reset-password', model, false)
  }

  changePassword(model: ChangePasswordModel) {
    return this.httpClient.post('account/change-password', model)
  }

  forgotPassword(id: number) {
    return this.httpClient.post('forgotPass', { id: id })
  }

  getAllCurrentTeamLeads(): Observable<BaseModel[]> {
    return this.httpClient.get('users/team-leads')
  }

  getOtherGuests(model): Observable<BaseModel[]> {
    return this.httpClient.post('users/guest/other', model)
  }

  getProfile(userId: number): Observable<UserProfile> {
    return this.httpClient.get('users/profile/' + userId)
  }

  public changeIsActive(id: number): Observable<number> {
    return this.httpClient.patch('users/is-active/' + id)
  }

  getStatus(userId: number): Observable<BaseModel> {
    return this.httpClient.get(`users/status/${userId}`)
  }

  getStatuses() {
    return this.httpClient.get('users/statuses', true, true)
  }

  saveStatus(status: number) {
    return this.httpClient.put(`users/status`, status)
  }

  setTenant(tenantId: string) {
    return this.httpClient.put(`users/tenant`, { tenantId })
  }

  markAsRead(hasBeenRead, userId): Observable<any> {
    const body = JSON.stringify(hasBeenRead)

    return this.httpClient.put(`users/${userId}/handbook-read`, body)
  }

  getVacationDays(userId: number): Observable<number> {
    return this.httpClient.get(`users/days/${userId}`)
  }

  maxUserCountReached(): Observable<boolean> {
    return this.httpClient.get('users/max-user-count-reached')
  }
}

import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { ApiService } from './common/api.service'

import {
  AllRecommendationsWithCount,
  BaseModel,
  CloseRecomModel,
  DetailsInputModelAnswers,
  EditRecomBookViewModel,
  RecomBookWithCount,
  RecomDetails,
  RecommendationAnswerInput,
  RecommendationBookInput,
} from 'app/core/models'

@Injectable()

export class RecommendationBookService {

  constructor(private httpClient: ApiService) {
  }

  getRecomTypes(): Observable<BaseModel[]> {
    return this.httpClient.get('recommendationType')
  }

  getRecomById(recomId: number): Observable<RecomDetails> {
    return this.httpClient.get('recommendationBook/simple/' + recomId)
  }

  addRecord(recom: RecommendationBookInput) {
    return this.httpClient.post('recommendationBook', recom)
  }

  getAllRecom(model, page: number, itemsPerPage: number): Observable<AllRecommendationsWithCount> {
    const params = `?page=${page}&itemsPerPage=${itemsPerPage}`
    return this.httpClient.post('recommendationBook/all' + params, model)
  }

  getRecordsByUserId(userId: number, page: number, itemsPerPage: number): Observable<RecomBookWithCount> {
    const params = `?page=${page}&itemsPerPage=${itemsPerPage}`
    return this.httpClient.get(`recommendationBook/user/${userId}` + params)
  }

  getEditRecomBookInfo(recommendationBookId: number): Observable<EditRecomBookViewModel> {
    return this.httpClient.get(`recommendationBook/edit/` + recommendationBookId)
  }

  closeRecom(model: CloseRecomModel) {
    return this.httpClient.put('recommendationBook/change-state', model)
  }

  deleteRecom(id: number, userId: number): Observable<any> {
    const params = `?userId=${userId}`
    return this.httpClient.delete('recommendationBook/delete/' + id + params, null)
  }

  addRecomAnswer(model: RecommendationAnswerInput) {
    return this.httpClient.post('recommendationBookAnswer', model)
  }

  checkUserAccess(recommendationBookId: number, userId: number) {
    const params = `?recommendationBookId=${recommendationBookId}&userId=${userId}`
    return this.httpClient.get(`recommendationBook/userAccess/` + params)
  }

  answeredUserRecords(id: number) {
    return this.httpClient.get(`recommendationBook/answered-records/` + id)
  }

  editRecomAnswer(recomAnswer: RecommendationAnswerInput): Observable<any> {
    return this.httpClient.put('recommendationBookAnswer/edit', recomAnswer)
  }

  editRecom(recomModel: RecommendationBookInput, userRole: string): Observable<any> {
    return this.httpClient.put('recommendationBook/edit', recomModel)
  }

  getAllRecomAnswers(model: DetailsInputModelAnswers, page: number, itemsPerPage: number) {
    const params = `?page=${page}&itemsPerPage=${itemsPerPage}`
    return this.httpClient.post(`recommendationBookAnswer/book-answers` + params, model)
  }

  deleteRecomAnswer(answerId: number, userId: number) {
    const params = `?userId=${userId}`
    return this.httpClient.delete('recommendationBookAnswer/delete/' + answerId + params, null)
  }
}

import { Component } from '@angular/core'

import { WorkScheduleChange } from 'app/core/models'

@Component({
  templateUrl: './work-schedule-change.component.html',
})

export class WorkScheduleChangeComponent {
  entity: WorkScheduleChange = new WorkScheduleChange()

  constructor() {
  }

  minLength = 1;
  maxLength = 50;

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

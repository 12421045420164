import { Injectable } from '@angular/core'

import { ApiService } from './common/api.service'


@Injectable()
export class NotificationService {

  constructor(
    private httpClient: ApiService) { }

  object = 'notification'

  sendNotification(id: number, type: number) {
    return this.httpClient.post(`timeSheet/month-report/notification/${id}/${type}`, {})
  }
}

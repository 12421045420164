import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'
import { FormBaseComponent } from 'app/shared/components/forms/f-base.component'

@Component({
  selector: 'f-number',
  templateUrl: 'f-number.component.html',
  providers: [
    MakeValidatorProviders(FormNumberComponent),
    MakeValueAccessorProviders(FormNumberComponent)
  ]
})
export class FormNumberComponent extends FormBaseComponent {
  @Input() max?: number
  @Input() min?: number
  @Input() divisibleBy?: number
}

import { Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { RecordType } from 'app/app-modules/shared-components/time-sheet-modal/enums/record-type-enum'
import { ExportRecordInput } from 'app/app-modules/shared-components/time-sheet-modal/models/export-record-input'
import { TimeSheetModalComponent } from 'app/app-modules/shared-components/time-sheet-modal/time-sheet-modal.component'
import { FileType, RequestStatus } from 'app/core'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { VacationRequestComponent } from '../vacation-request/vacation-request.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { DownloaderService, TimeSheetService, ToastrService, UserService, VacationRequestService } from 'app/core/services'

import { VacationRequest } from 'app/core/models'

import { GetEnteredHours } from 'app/core/store/actions/time-sheet.actions'
import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'


@Component({
  templateUrl: './my-requests.component.html'
})
export class MyRequestsComponent extends BaseManagementComponent implements OnInit {
  totalVacationDays: number = 0

  constructor(public store: Store<State>,
    private modal: ModalService,
    private requestService: VacationRequestService,
    private timeSheetService: TimeSheetService,
    private toastrService: ToastrService,
    private downloaderService: DownloaderService,
    private userService: UserService,
  ) {
    super(store)
  }

  ngOnInit(): void {
    this.getUserDays()
  }

  canDelete(item: VacationRequest) {
    return item.status === RequestStatus.New || item.status === RequestStatus.PartiallyApproved || item.status === RequestStatus.Approved
  }

  canPrint(item: VacationRequest) {
    return item.status === RequestStatus.Approved
  }

  add() {
    this.modal.open(VacationRequestComponent, ModalSizeTypes.Medium,
      {
        entity:
        {
          vacationUserId: this.userId
        },
        isMyRequest: true
      })
      .subscribe(result => {
        if (result.isSubmit) {
          this.requestService.addRequest(result.data).first().subscribe(() => {
            this.getUserDays()
            this.store.dispatch(new FilterAction())
          })
        }
      })
  }

  cancelRequest(request: VacationRequest) {
    this.requestService.cancelRequest(request.id, this.userId)
      .first()
      .subscribe(() => this.store.dispatch(new FilterAction()))
  }

  print(requestId) {
    this.requestService
      .print(requestId).first()
      .subscribe(response => {
        this.downloaderService.downloadFile(response, 'vacation-request', FileType.Pdf)
      })
  }

  export(item) {
    this.timeSheetService.checkAny(item.fromDate, item.toDate, this.userId)
      .first()
      .subscribe(exists => {
        if (exists) {
          this.toastrService.warning('messages.vacationRequestАlreadyExported', null, true)
          return
        }

        const recordToExport: ExportRecordInput = {
          fromDate: item.fromDate,
          toDate: item.toDate,
          recordType: RecordType.Holiday
        }
        this.modal.open(TimeSheetModalComponent, ModalSizeTypes.Large, { recordToExport: recordToExport })
          .first()
          .subscribe(result => {
            if (result.isSubmit) {
              this.timeSheetService.exportLeave(result.data)
              .first()
              .subscribe(() => { 
                this.store.dispatch(new FilterAction()) 
                this.store.dispatch(new GetEnteredHours()) 
              })
            }
          })

      })
  }

  private getUserDays() {
    if (this.userId) {
      this.userService.getVacationDays(this.userId)
        .first()
        .subscribe(days => {
          this.totalVacationDays = days
        })
    }
  }
}

import { Injectable } from '@angular/core'

import { ApiService } from '../common/api.service'
import { HttpObjectService } from '../common/http-object.service'
import { Observable } from 'rxjs/Observable'


@Injectable()

export class TenantService extends HttpObjectService {
  object = 'tenant'

  constructor(public apiService: ApiService) {
    super(apiService)
  }

  getEnterpriseListOptions(isBackground = false): Observable<any[]> {
    const resourceUrl = `${this.object}/enterprise-list-options`

    return this.apiService.get(resourceUrl, true, isBackground)
  }
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { SickLeaveMgmtComponent } from './sick-leave-mgmt.component'
import { SickLeaveReportComponent } from './sick-leave-report/sick-leave-report.component'

import { AuthGuard, NotRoleGuard } from 'app/core/guards'
import { Roles } from 'app/core'

const sickLeaveRoutes: Routes = [
    {
        path: 'my-sick-leaves',
        component: SickLeaveMgmtComponent,
        canActivate: [AuthGuard, NotRoleGuard],
        data: { roles: [Roles.companyAdminRole] }
    }, {
        path: 'sick-leave-report',
        component: SickLeaveReportComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(sickLeaveRoutes)],
    exports: [RouterModule]
})
export class SickLeaveRoutingModule { }

import { Component } from '@angular/core'

import { CompanyModel } from 'app/core/models/noms/company'

import { BaseComponent } from 'app/components/base/base.component'

@Component({
  templateUrl: './company.component.html',
})

export class CompanyComponent extends BaseComponent {
  entity: CompanyModel = { id: 0, tenantId: '', name: '', isActive: true }

  onSubmit(dialog) {
    dialog.close({ isSubmit: true, data: this.entity })
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/states';
import { timeSheetSelectors } from 'app/core/store/states/time-sheet.states';
import { Observable } from 'rxjs/Observable';
import * as TimeSheetActions from 'app/core/store/actions/time-sheet.actions';

@Injectable()
export class TimeSheetStoreService {

    constructor(private store$: Store<State>) { }

    public get workDays$(): Observable<number> {
        return this.store$.select(timeSheetSelectors.getWorkDays)
    }

    public get currentWorkDays$(): Observable<number> {
        return this.store$.select(timeSheetSelectors.getCurrentWorkDays)
    }

    public get monthlyEnteredHours$(): Observable<number> {
        return this.store$.select(timeSheetSelectors.getEnteredHours)
    }

    public resetMonthlyEnteredHours() {
        this.store$.dispatch(new TimeSheetActions.GetEnteredHours())
    }
}

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { loadingSelectors, LoadingState } from 'app/core/store/states/loading-mask.states'
import { Observable } from 'rxjs/Observable'

@Injectable()

export class LoadingStoreService {

    constructor(private store$: Store<LoadingState>) { }

    public get isLoading$(): Observable<boolean> {
        return this.store$.select(loadingSelectors.getLoaderStatus)
    }
}

import { Component } from '@angular/core';
import { TenantSettingsModel } from 'app/core/models/tenant/tenant-settings-model';

@Component({
    templateUrl: './tenant-settings.component.html',
  })

export class TenantSettingsComponent {

    entity: TenantSettingsModel
    canAccessTestsModule: boolean

    onSubmit(dialog) {
        dialog.close({ isSubmit: true, data: this.entity })
    }
}

import { Component, Input } from '@angular/core'

import { MakeValidatorProviders, MakeValueAccessorProviders } from 'app/shared/components/abstract-value-accessor'

import { FormBaseComponent } from '../f-base.component'

@Component({
  selector: 'f-radio',
  templateUrl: './f-radio.component.html',
  styleUrls: [
    'f-radio.component.scss',
  ],
  providers: [
    MakeValidatorProviders(FormRadioComponent),
    MakeValueAccessorProviders(FormRadioComponent)
  ]
})
export class FormRadioComponent extends FormBaseComponent {
  @Input() option: any
  @Input() listName: string
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';

import { SharedModule } from '../shared';
import { BaseDialogComponent } from './components/base-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { MenuDialogComponent } from './components/menu-dialog/menu-dialog.component';
import { ModalService } from './modal.service';

@NgModule({
  imports: [SharedModule, CommonModule, FormsModule],
  declarations: [
    ConfirmDialogComponent,
    DialogFormComponent,
    BaseDialogComponent,
    MenuDialogComponent,
  ],
  exports: [
    ConfirmDialogComponent,
    DialogFormComponent,
    BaseDialogComponent,
    MenuDialogComponent,
  ],
  providers: [NgForm, ModalService],
  entryComponents: [
    ConfirmDialogComponent,
    DialogFormComponent,
    BaseDialogComponent,
    MenuDialogComponent,
  ]
})
export class DialogsModule {}

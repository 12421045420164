export const Months = [
  {id: 1, name: 'months.january'},
  {id: 2, name: 'months.february'},
  {id: 3, name: 'months.march'},
  {id: 4, name: 'months.april'},
  {id: 5, name: 'months.may'},
  {id: 6, name: 'months.june'},
  {id: 7, name: 'months.july'},
  {id: 8, name: 'months.august'},
  {id: 9, name: 'months.september'},
  {id: 10, name: 'months.october'},
  {id: 11, name: 'months.november'},
  {id: 12, name: 'months.december'}
]

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { Roles } from 'app/core'

import { MonthReportComponent } from './month-report/month-report.component'
import { TimeSheetSettingsComponent } from './settings/settings.component'
import { TimeSheetReportComponent } from './time-sheet-report/time-sheet-report.component'
import { TimeSheetMgmtComponent } from './time-sheet/time-sheet-mgmt.component'

import { AuthGuard, NotRoleGuard, RoleGuard } from 'app/core/guards'

const timeSheetRoutes: Routes = [
    {
        path: 'time-sheet',
        component: TimeSheetMgmtComponent,
        canActivate: [AuthGuard, NotRoleGuard],
        data: { roles: [Roles.companyAdminRole] }
    }, {
        path: 'time-sheet-report/:isAdvance',
        component: TimeSheetReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'time-sheet-team',
        component: TimeSheetMgmtComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.teamLeadRole, Roles.companyAdminRole], isTeams: true }
    },
    {
        path: 'time-sheet-settings',
        component: TimeSheetSettingsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'month-report',
        component: MonthReportComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(timeSheetRoutes)],
    exports: [RouterModule]
})
export class TimeSheetRoutingModule { }

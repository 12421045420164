import { Component } from '@angular/core'

import { Store } from '@ngrx/store'
import { FilterAction } from 'app/smart-table/store/smarter-table.actions'

import { CompanyComponent } from './company.component'

import { ModalSizeTypes } from 'app/dialogs/interfaces/modal-size-types'
import { ModalService } from 'app/dialogs/modal.service'

import { CompanyService, TenantStoreService } from 'app/core/services'

import { CompanyModel } from 'app/core/models/noms/company'

import * as CompanyActions from 'app/core/store/actions/noms/company.actions'
import { State } from 'app/core/store/states'

import { BaseManagementComponent } from 'app/components/base/base-management.component'


@Component({
  templateUrl: './company-mgmt.component.html'
})
export class CompanyMgmtComponent extends BaseManagementComponent {

  constructor(public store: Store<State>,
    private modal: ModalService,
    private companyService: CompanyService) {
    super(store)
  }

  public add() {
    const item: CompanyModel = { id: 0, tenantId: '', name: '', isActive: true }
    this.openModal(item, false)
  }

  public edit(item) {
    this.openModal(item, true)
  }

  openModal(item, isEdit: boolean) {
    this.modal.open(CompanyComponent, ModalSizeTypes.Small, { entity: item, isEdit })
      .subscribe(result => {
        if (result.isSubmit) {
          this.companyService.save(result.data).first().subscribe(() => {
            this.store.dispatch(new FilterAction())
            this.store.dispatch(new CompanyActions.GetListOptions())
          })
        }
      })
  }
}

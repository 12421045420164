import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  OnChanges,
  QueryList,
  SimpleChange,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

import { STColumnComponent } from './s-t-column.component'

@Component({ selector: 's-t-row', template: '' })
export class STRowComponent {
  @Input() zClass = ''

  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>
  @ContentChildren(STColumnComponent) columns: QueryList<STColumnComponent>
}

@Directive({ selector: '[row-template-loader]' })
export class RowTemplateLoader implements OnChanges {
  @Input() row: any

  constructor(public viewContainer: ViewContainerRef) { }

  ngOnChanges(changes: {
    [propKey: string]: SimpleChange
  }) {
    this
      .viewContainer
      .clear()
    const view = this
      .viewContainer
      .createEmbeddedView(this.row.template, {
        '\$implicit': this.row,
      });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { BaseModel } from 'app/core/models'

export interface ProjectState {
    listOptions: BaseModel[]
}

export const initialState: ProjectState = {
    listOptions: []
}

export namespace projectSelectors {
    export const getProjectState = createFeatureSelector<ProjectState>('project')
    export const getProjectListOptions = createSelector(getProjectState, state => state.listOptions)
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'

import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'

import { MakeValidatorProviders, MakeValueAccessorProviders } from '../../abstract-value-accessor'
import { FormBaseComponent } from '../f-base.component'


@Component({
    selector: 'f-typeahead',
    templateUrl: 'f-typeahead.component.html',
    styleUrls: ['./f-typeahead.component.scss'],
    providers: [
        MakeValidatorProviders(FormTypeaheadComponent),
        MakeValueAccessorProviders(FormTypeaheadComponent)
    ]
})
export class FormTypeaheadComponent extends FormBaseComponent {
    @Input() search: (term: string) => Observable<any[]>
    @Input() openOnFocus = true
    @Input() title: string = null

    @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild('instance', {static: true}) instance: NgbTypeahead

    focus$ = new Subject<any>();
    click$ = new Subject<any>();

    model: any = null

    dropdownTouched = false
    formatter = (x: { name: string }) => x.name

    onBlur() {
        super.onBlur();
        this.dropdownTouched = true;
    }

    searching = (text$: Observable<string>): Observable<any[]> => {
        const source = text$
            .debounceTime(300)
            .distinctUntilChanged()
            .merge(this.openOnFocus ? this.focus$ : Observable.empty())
            .merge(this.openOnFocus ? this.click$.filter(() => !this.instance.isPopupOpen()) : Observable.empty())
            .switchMap(term => this.search(term))

        return source
    }

    writeValue(value: any) {
        super.writeValue(value)
        if (value) {
            this.model = Object.assign({}, { id: value, name: this.title })
        } else {
            this.model = Object.assign({}, { id: value, name: '' })
        }

    }

    updateValue(value: any) {
        if (value) {
            this.value = value.id
        } else {
            this.value = undefined
        }
    }

    selectItem(payload) {
        this.selectedItem.emit(payload.item)
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/components/base/base.component';
import { ExternalProvider } from 'app/core';
import { ExternalProviderInfo } from 'app/core/models/auth/external-provider-info';

@Component({
    selector: 'external-account-button',
    templateUrl: './external-account-button.component.html',
    styleUrls: [
        './external-account-button.component.scss'
    ]
})

export class ExternalAccountButtonComponent extends BaseComponent implements OnInit {

    @Input() providerInfo: ExternalProviderInfo
    @Input() hasRemove: boolean = false
    @Input() hasAdd: boolean = false
    @Input() isLoginButton: boolean = false

    @Output() linkRemoved: EventEmitter<ExternalProviderInfo> = new EventEmitter<ExternalProviderInfo>()

    providerName: string = ''

    constructor(
    ) {
        super()
    }

    ngOnInit() {
        this.providerName = ExternalProvider[this.providerInfo.provider]
    }

    redirectToAuthPage() {
        if (this.isLoginButton) {
            window.location.href = this.providerInfo.authUrl
        }
    }

    addLink() {
        window.location.href = this.providerInfo.authUrl
    }

    removeLink() {
        this.linkRemoved.emit(this.providerInfo)
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { ModalTypes } from '../interfaces/modal-types'

@Component({
  selector: 'base-dialog-form',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['../styles.scss']
})
export class BaseDialogComponent {
  @Input() closeText = 'Ok'
  @Input() modalContent = ''
  @Input() type = ModalTypes.Info
  @Output() dialogClose = new EventEmitter<any>()

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  get modalClass() {
    switch (this.type) {
      case ModalTypes.Info: return 'info';
      case ModalTypes.Alert: return 'alert';
      case ModalTypes.Warning: return 'warning';
      case ModalTypes.Success: return 'success';
    }
  }

  onClose() {
    this.activeModal.close(false)
    this.dialogClose.emit()
  }
}

import { Type } from '@angular/core';

export class ModalMenuItem {
    id: number
    component: Type<any>
    title: string
    iconClass: string

    constructor(component: Type<any>, title: string, iconClass: string) {
        this.component = component
        this.title = title
        this.iconClass = iconClass
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'

import { TimeSheetSetting, UserSettings } from 'app/core/models'

export interface State {
    userSettings: UserSettings
    timeSheetSettings: TimeSheetSetting
}

export const initialState: State = {
    userSettings: new UserSettings(),
    timeSheetSettings: new TimeSheetSetting()
}

export namespace settingsSelectors {
    export const getSettingsState = createFeatureSelector<State>('settings')
    export const getUserSettings = createSelector(getSettingsState, state => state.userSettings)
    export const getTimeSheetSettings = createSelector(getSettingsState, state => state.timeSheetSettings)
}

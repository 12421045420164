import { ChangeDetectorRef, Component, OnInit } from '@angular/core'

import { Store } from '@ngrx/store'
import { PicturesService } from 'app/core'

import { Comment } from '../../models/comment'
import { AbstractCommentComponent } from '../abstract-comment/abstract-comment.component'

import { State } from 'app/core/store/states'

@Component({
    selector: 'app-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss']
})

export class AddCommentComponent extends AbstractCommentComponent implements OnInit {
    comment: Comment

    constructor(
        store: Store<State>,
        picturesService: PicturesService,
        cd: ChangeDetectorRef,
    ) {
        super(store, picturesService, cd)
    }

    ngOnInit() {
        this.initializeComment()
    }

    initializeComment() {
        this.comment = new Comment()
    }

    add() {
        this.toggleTypingMode(false)
        if (this.comment.text) {
            this.onSave.emit(this.comment)
            this.initializeComment()
        }
    }
}

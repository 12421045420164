import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { BaseActionComponent } from './base-action.component'

import { ModalTypes } from 'app/dialogs/interfaces/modal-types'
import { ModalService } from 'app/dialogs/modal.service'

@Component({
  selector: 'delete-action',
  templateUrl: 'delete-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteActionComponent extends BaseActionComponent {

  @Input() confirmMessage: string = 'messages.areYouSure'

  constructor(public el: ElementRef, private modal: ModalService, private translate: TranslateService) {
    super(el)
  }

  onBtnClick(event) {
    event.stopPropagation()
    const message = this.translate.instant(this.confirmMessage)

    this.modal.confirm(ModalTypes.Warning, message).subscribe(state => {
      if (state && !this.disabled) {
        this.onClick.emit(event)
      }
    })
  }
}

import { createSelector } from '@ngrx/store'
import { AppModules } from 'app/core/enums'

import { selectors } from './auth.states'

import { SidebarItemNodeModel } from 'app/components/common/sidebar/models/sidebar-item-node.model'

export const getMenuState = createSelector(selectors.getAuthState, selectors.isModuleAdmin, selectors.getTenantModules, (state, adminCheck, tenantClaims) => {

    const menu = [new SidebarItemNodeModel({ name: 'general.home', route: 'home', visible: true, icon: 'fas fa-home' }),
    new SidebarItemNodeModel(
        {
            name: 'general.administration', route: '', children: [
                new SidebarItemNodeModel({
                    name: 'general.noms', route: '', children: [
                        new SidebarItemNodeModel({ name: 'general.projects', route: 'projects', visible: state.isCompanyAdmin, icon: 'fas fa-project-diagram' }),
                        new SidebarItemNodeModel({ name: 'general.positions', route: 'positions', visible: state.isCompanyAdmin, icon: 'fas fa-briefcase' }),
                        new SidebarItemNodeModel({ name: 'general.locations', route: 'locations', visible: state.isCompanyAdmin, icon: 'fas fa-map-marker-alt' }),
                        new SidebarItemNodeModel({ name: 'general.companies', route: 'companies', visible: state.isCompanyAdmin, icon: 'far fa-building' })
                    ],
                    visible: state.isCompanyAdmin, icon: 'far fa-list-alt'
                }),
                new SidebarItemNodeModel({ name: 'general.tenants', route: 'tenants', visible: state.isCompanyAdmin, icon: 'fas fa-city' }),
                new SidebarItemNodeModel({
                    name: 'general.addHoliday/Workday', route: 'change-day',
                    visible: state.isCompanyAdmin, icon: 'far fa-calendar-plus'
                }),
                new SidebarItemNodeModel({
                    name: 'general.addVacationDays', route: 'add-vacation-days',
                    visible: state.isCompanyAdmin, icon: 'fas fa-calendar-week'
                }),
                // new SidebarItemNodeModel({ name: 'Add Compensation', route: 'compensation', visible: state.isCompanyAdmin, icon: '' }),
                new SidebarItemNodeModel({ name: 'general.admins', route: 'admins', visible: state.isCompanyAdmin, icon: 'fas fa-user-tie' }),
                new SidebarItemNodeModel({ name: 'general.users', route: 'users', visible: true, icon: 'fas fa-users' }),
            ], visible: true, icon: 'fas fa-cogs'
        }),
    new SidebarItemNodeModel({
        name: 'general.handbook', route: 'handbook', visible: !state.isCompanyAdmin && tenantClaims.includes(AppModules.Handbook),
        icon: state.readHandbook ? 'fas fa-book-open' : 'fas fa-book'
    }),
    new SidebarItemNodeModel(
        {
            name: 'general.notes', route: '', children: [
                new SidebarItemNodeModel({
                    name: 'general.noms', route: '', children: [
                        new SidebarItemNodeModel({
                            name: 'general.groups', route: 'notes/noms/groups',
                            visible: !state.isCompanyAdmin, icon: 'far fa-object-group'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.labels', route: 'notes/noms/labels',
                            visible: !state.isCompanyAdmin, icon: 'fas fa-tags'
                        })
                    ],
                    visible: adminCheck('Notes'), icon: 'far fa-list-alt'
                }),
                new SidebarItemNodeModel({ name: 'general.myNotes', route: 'notes/my', visible: !state.isCompanyAdmin, icon: 'far fa-clipboard' })
            ], visible: !state.isCompanyAdmin && tenantClaims.includes(AppModules.Notes), icon: 'far fa-sticky-note'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.tickets', route: '', children: [
                new SidebarItemNodeModel({
                    name: 'general.noms', route: '', children: [
                        new SidebarItemNodeModel({
                            name: 'general.groups', route: 'tickets/noms/groups',
                            visible: !state.isCompanyAdmin, icon: 'far fa-object-group'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.labels', route: 'tickets/noms/labels',
                            visible: !state.isCompanyAdmin, icon: 'fas fa-tags'
                        })
                    ],
                    visible: adminCheck('Tickets'), icon: 'far fa-list-alt'
                }),
                new SidebarItemNodeModel({
                    name: 'general.tickets', route: 'tickets',
                    visible: !state.isCompanyAdmin, icon: 'fas fa-ticket-alt'
                })
            ], visible: !state.isCompanyAdmin && tenantClaims.includes(AppModules.Tickets), icon: 'fas fa-ticket-alt'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.vacation', route: '', children: [
                new SidebarItemNodeModel({ name: 'general.myRequests', route: 'my-requests', visible: !state.isCompanyAdmin, icon: 'fas fa-map-marked-alt' }),
                new SidebarItemNodeModel({
                    name: 'general.reports', route: 'vacation-request-report',
                    visible: true, icon: 'far fa-calendar-check'
                }),
                new SidebarItemNodeModel({
                    name: 'general.teamRequests', route: 'team-requests',
                    visible: state.isTL, icon: 'fas fa-check-double'
                }),
                new SidebarItemNodeModel({
                    name: 'general.annualTeamReport', route: 'annual-team-requests',
                    visible: state.isCompanyAdmin || state.isTL, icon: 'fas fa-paper-plane'
                }),
                new SidebarItemNodeModel({
                    name: 'general.leavesByYear', route: 'leaves-by-year',
                    visible: true, icon: 'fas fa-list'
                })
            ], visible: true, icon: 'fas fa-plane-departure'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.sickLeave', route: '', children: [
                new SidebarItemNodeModel({ name: 'general.mySickLeaves', route: 'my-sick-leaves', visible: !state.isCompanyAdmin, icon: 'fas fa-pills' }),
                new SidebarItemNodeModel({
                    name: 'general.reports', route: 'sick-leave-report',
                    visible: true, icon: 'far fa-calendar-check'
                })
            ], visible: tenantClaims.includes(AppModules.SickLeave), icon: 'fas fa-thermometer'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.homeOffice', route: '', children: [
                new SidebarItemNodeModel({
                    name: 'general.myHomeOfficeDays', route: 'home-office-all',
                    visible: !state.isCompanyAdmin, icon: 'fas fa-keyboard'
                }),
                new SidebarItemNodeModel({
                    name: 'general.reports', route: 'home-office-report',
                    visible: true, icon: 'far fa-calendar-check'
                })
            ], visible: tenantClaims.includes(AppModules.HomeOffice), icon: 'fas fa-couch'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.timeSheet', route: '', children: [
                new SidebarItemNodeModel({ name: 'general.timeSheetOperations', route: 'time-sheet', visible: !state.isCompanyAdmin, icon: 'far fa-calendar-alt' }),
                new SidebarItemNodeModel({
                    name: 'general.reports',
                    route: 'time-sheet-report/false', visible: true, icon: 'far fa-calendar-check'
                }),
                new SidebarItemNodeModel({
                    name: 'general.advanceReport', route: 'time-sheet-report/true',
                    visible: true, icon: 'far fa-calendar-plus'
                }),
                new SidebarItemNodeModel({
                    name: 'general.timeSheetTeamOperations', route: 'time-sheet-team',
                    visible: state.isTL || state.isCompanyAdmin, icon: 'fas fa-user-friends'
                }),
                new SidebarItemNodeModel({
                    name: 'general.monthReport', route: 'month-report',
                    visible: true, icon: 'fas fa-flag'
                })
            ], visible: tenantClaims.includes(AppModules.TimeSheet), icon: 'fas fa-business-time'
        }),
    new SidebarItemNodeModel(
        {
            name: 'general.tests', route: '', children: [
                new SidebarItemNodeModel({
                    name: 'general.setup', route: '', children: [
                        new SidebarItemNodeModel({
                            name: 'general.noms', route: '', children: [
                                new SidebarItemNodeModel({
                                    name: 'general.campaigns', route: 'tests/setup/noms/campaign',
                                    visible: true, icon: 'fa fa-chalkboard-teacher'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.domains', route: 'tests/setup/noms/domain',
                                    visible: true, icon: 'far fa-object-group'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.categories', route: 'tests/setup/noms/category',
                                    visible: true, icon: 'far fa-object-ungroup'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.subCategories', route: 'tests/setup/noms/sub-category',
                                    visible: true, icon: 'fas fa-vector-square'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.topics', route: 'tests/setup/noms/topic',
                                    visible: true, icon: 'fas fa-pen-alt'
                                })
                            ],
                            visible: true, icon: 'far fa-list-alt'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.rateScales', route: 'tests/setup/scales',
                            visible: !state.isCompanyAdmin, icon: 'fas fa-balance-scale'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.tests', route: '', children: [
                                new SidebarItemNodeModel({
                                    name: 'general.questions', route: 'tests/setup/test-questions',
                                    visible: true, icon: 'fas fa-question'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.testTemplates', route: 'tests/setup/test-templates',
                                    visible: true, icon: 'fas fa-file-contract'
                                })
                            ],
                            visible: true, icon: 'far fa-list-alt'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.surveys', route: '', children: [
                                new SidebarItemNodeModel({
                                    name: 'general.questions', route: 'tests/setup/survey-questions',
                                    visible: true, icon: 'fas fa-question'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.groups', route: 'tests/setup/survey-groups',
                                    visible: true, icon: 'fas fa-object-group'
                                }),
                                new SidebarItemNodeModel({
                                    name: 'general.surveys', route: 'tests/setup/surveys',
                                    visible: true, icon: 'fas fa-poll'
                                })
                            ],
                            visible: true, icon: 'far fa-list-alt'
                        }),
                        new SidebarItemNodeModel({
                            name: 'general.surveyCampaigns', route: 'tests/setup/survey-campaigns',
                            visible: true, icon: 'fas fa-map-signs'
                        })],
                    visible: adminCheck(AppModules.Tests), icon: 'far fa-list-alt'
                }),
                new SidebarItemNodeModel({
                    name: 'general.surveyCampaigns', route: 'tests/survey-campaigns',
                    visible: true, icon: 'fas fa-map-signs'
                }),
                new SidebarItemNodeModel({
                    name: 'general.evaluation', route: 'tests/evaluations',
                    visible: adminCheck(AppModules.Tests),
                    icon: 'fas fa-award'
                }),
            ], visible: !state.isCompanyAdmin && tenantClaims.includes(AppModules.Tests), icon: 'fas fa-check'
        }),
    ]

    return menu
})


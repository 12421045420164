import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'

import { minFn } from './validator'

const MIN_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinValidator),
    multi: true
}

@Directive({
    selector: '[min][formControlName],[min][formControl],[min][ngModel]',
    providers: [MIN_VALIDATOR]
})
export class MinValidator implements Validator, OnInit, OnChanges {
    @Input() min: number

    private validator: ValidatorFn
    private onChange: () => void

    ngOnInit() {
        this.validator = minFn(this.min)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const key in changes) {
            if (key === 'min') {
                this.validator = minFn(changes[key].currentValue)
                if (this.onChange) { this.onChange() }
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c)
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn
    }
}
